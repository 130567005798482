import PropTypes from 'prop-types'
import React from 'react'

import ScoreSquare from 'components/Atomic/Atoms/ScoreSquare'
import Typography from 'components/Atomic/Atoms/Typography'

import './legendStatistic.scss'

const variantScore = {
  credible: {
    label: 'Credible',
    scores: [5, 4],
  },
  misinformation: {
    label: 'Misinformation',
    scores: [1, 2, 3],
  },
  satire: {
    label: 'Not Eligible/Satire',
    scores: ['satire'],
  },
  undefined: {
    label: 'Undefined',
    scores: ['undefined'],
  },
}

const LegendStatistic = ({ count, type, variant }) => {
  const { label, scores } = variantScore[variant]

  return (
    <div className="legendStatistic">
      <div className="legendStatistic--squareGroup">
        {scores.map(score => (
          <ScoreSquare key={score} score={score} />
        ))}
        <Typography className="legendStatistic--label" variant="body-text-2">
          {label}
        </Typography>
      </div>
      <hr className="legendStatistic--dotsConnector" />
      <Typography className="legendStatistic--numbers" variant="body-text-2">
        {count} {type}
      </Typography>
    </div>
  )
}

LegendStatistic.defaultProps = {
  count: 0,
  type: 'Links',
  variant: 'undefined',
}

LegendStatistic.propTypes = {
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  type: PropTypes.string,
  variant: PropTypes.oneOf([
    'credible',
    'misinformation',
    'satire',
    'undefined',
  ]),
}

export default LegendStatistic

import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import { Typography, Icon, Button, Divider } from 'components'
import {
  useAuth,
  useRoles,
} from 'hooks'

import EditReview from '../EditReview'

import './reviewDetails.scss'

const ReviewDetails = ({
  claimantId,
  id,
  claimantName,
  conclusionStandardForm,
  factcheckerVerdict,
  fullReviewLink,
  initialClaim,
  isFactcheckerAuthorized,
  isLoggedIn,
  linkToPage,
  reviewBy,
  reviewTitle,
  standardClaim,
  topics,
  initialLabel,
  conclusionScore,
  reviewByUrl,
}) => {

  const { auth } = useAuth()
  const { isEditor } = useRoles(auth)

  const [showEditReview, setShowEditReview] = useState(false)
  const toggleEditReview = () => setShowEditReview(!showEditReview)
  const onCloseEditReview = () => {
    toggleEditReview()
  }

  return (
    <div className="review-details">
      <div className="review-details--header">
        <Typography variant="h3">Review details:</Typography>

        {isLoggedIn &&
          (linkToPage ? (
            <Link to={`/review/${id}`}>
              <Button variant="light">
                <Typography variant="title-3">Review page</Typography>
                <Icon icon="arrowup45" />
              </Button>
            </Link>
          ) : ((isFactcheckerAuthorized || isEditor) &&
            <Button
              className="review-details--header--edit"
              onClick={toggleEditReview}
              type="button"
              variant="light"
            >
              <Typography variant="title-3">Edit</Typography>
              <Icon icon="edit" />
            </Button>
          ))}
      </div>

      {isLoggedIn && (
        <div className="review-details--section">
          <div className="review-details--section--review-and-claim">
            <div className="review-details--section--review-and-claim--review">
              <Typography className="review-details--title" variant="title-3">
                Review:
              </Typography>
              <Typography
                className="review-details--review"
                variant="body-text-4"
              >
                {reviewTitle}
              </Typography>
              <div className="review-details--row">
                <Typography variant="body-text-3">Factchecker:</Typography>
                <a href={reviewByUrl} rel="noopener noreferrer" target="_blank">
                  <Typography variant="link-text-3">{reviewBy}</Typography>
                </a>
              </div>

              <div className="review-details--row">
                <Typography variant="body-text-3">Review link:</Typography>
                <a
                  href={fullReviewLink}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <Typography variant="link-text-3">
                    {fullReviewLink}
                  </Typography>
                </a>
              </div>
            </div>

            <Divider
              className="review-details--divider"
              orientation="vertical"
            />
            <div className="review-details--section--review-and-claim--claim">
              <Typography className="review-details--title" variant="title-3">
                Claim:
              </Typography>
              <Typography
                className="review-details--review"
                variant="body-text-4"
              >
                {initialClaim}
              </Typography>
              <div className="review-details--row">
                <Typography variant="body-text-3">Claimant:</Typography>
                <Typography variant="body-text-4">{claimantName}</Typography>
              </div>
              <div className="review-details--row">
                <Typography variant="body-text-3">Standard claim:</Typography>
                <Typography variant="body-text-4">
                  {standardClaim || 'Unset'}
                </Typography>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="review-details--section">
        <Typography className="review-details--title" variant="title-3">
          Verdict:
        </Typography>
        <div className="review-details--row">
          <Typography variant="body-text-3">Factchecker verdict:</Typography>
          <Typography variant="body-text-4">{factcheckerVerdict}</Typography>
        </div>
        <div className="review-details--row">
          <Typography variant="body-text-3">Verdict standard form:</Typography>
          <Typography variant="body-text-4">
            {conclusionStandardForm || 'Unset'}
          </Typography>
        </div>

        <div className="review-details--section">
          <Typography className="review-details--title" variant="title-3">
            Topics:
          </Typography>
          {topics && topics.length > 0 && (
            <Typography variant="body-text-4">{topics.join(', ')}</Typography>
          )}
        </div>
      </div>
      {(isFactcheckerAuthorized || isEditor) &&
        <EditReview
          claimantId={claimantId}
          claimantName={claimantName}
          conclusionScore={conclusionScore}
          conclusionStandardForm={conclusionStandardForm}
          factcheckerVerdict={factcheckerVerdict}
          fullReviewLink={fullReviewLink}
          initialClaim={initialClaim}
          initialLabel={initialLabel}
          onClose={onCloseEditReview}
          reviewBy={reviewBy}
          reviewTitle={reviewTitle}
          show={showEditReview}
          standardClaim={standardClaim}
        />
      }
    </div>
  )
}
export default ReviewDetails

ReviewDetails.defaultProps = {
  claim: '',
  claimBy: '',
  conclusionStandardForm: '',
  factcheckerVerdict: '',
  fullReviewLink: '',
  isFactcheckerAuthorized: false,
  isLoggedIn: false,
  reviewBy: '',
  review: '',
  standardClaim: '',
  topics: [],
}

ReviewDetails.propTypes = {
  claim: PropTypes.string,
  claimBy: PropTypes.string,
  conclusionStandardForm: PropTypes.string,
  factcheckerVerdict: PropTypes.string,
  fullReviewLink: PropTypes.string,
  isFactcheckerAuthorized: PropTypes.bool,
  isLoggedIn: PropTypes.bool,
  reviewBy: PropTypes.string,
  review: PropTypes.string,
  standardClaim: PropTypes.string,
  topics: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
}

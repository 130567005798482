import { useInfiniteQuery } from 'react-query'

import { normalizeSuggestedAppearance } from 'normalizer'
import { API_URL } from 'utils/config'

import { useAxios } from '../index'

export default function useClaimSuggestedAppearances(userParams) {
  const axios = useAxios()

  const getClaimSuggestedAppearances = async (
    {
      claimId = '',
      paginate = 10,
      method = '',
      values = { keyword: '', fromDate: '', toDate: '', url: '' },
    },
    { pageParam = 1 }
  ) => {
    if (method === 'buzzsumo' && !values.keyword) {
      return {
        data: [],
        hasNextPage: false,
      }
    } else {
      let url = `${API_URL}/claims/${claimId}/candidates?method=${method}&keywords=${values.keyword}&fromDate=${values.fromDate}&toDate=${values.toDate}&url=${values.url}&page=${pageParam}&paginate=${paginate}`
      const { data, headers } = await axios.get(url)
      return {
        data,
        hasNextPage: headers['has-more'] === 'True' && pageParam + 1,
      }
    }
  }

  return useInfiniteQuery(
    ['claim-suggested-appearances', userParams],
    params => getClaimSuggestedAppearances(userParams, params), //Api Calls
    {
      enabled: !!userParams.claimId && !!userParams.method,
      select: data => {
        const dataFormatted = {
          data: data.pages.flatMap(page =>
            page.data.map(appearance => {
              return normalizeSuggestedAppearance(appearance)
            })
          ),
        }
        return dataFormatted
      },
      getNextPageParam: lastPage => lastPage.hasNextPage,
    }
  )
}

import React, { useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import ReviewsWithNoAppearancePage from 'components/Pages/ReviewsWithNoAppearancePage'
import { useReviews } from 'hooks'

const ReviewsWithoutAppearances = () => {
  let navigate = useNavigate()

  const [searchParams] = useSearchParams()
  const urlKeywords = searchParams.get('keywords') || ''
  const urlFactcheckers = searchParams.get('filterByFactcheckers') || ''

  const [search, setSearch] = useState(urlKeywords)

  const {
    data: reviews,
    fetchNextPage: fetchNextReviewPage,
    hasNextPage: hasNextReviewPage,
    isLoading: reviewIsLoading,
    refetch: refetchReviews,
  } = useReviews({ factcheckers: urlFactcheckers, search: search, linkFilter: 'withoutLinks', onlyNotTreated: true })

  const onSearch = value => {
    navigate(`/dashboard/reviews/without-appearances?keywords=${value.search}&filterByFactcheckers=${urlFactcheckers}`)
    setSearch(value.search)
  }

  return (
    <ReviewsWithNoAppearancePage
      defaultSearch={search}
      defaultFilterByFactcheckers={urlFactcheckers}
      fetchNextReviewPage={fetchNextReviewPage}
      hasNextReviewPage={hasNextReviewPage}
      onSearch={onSearch}
      refetchReviews={refetchReviews}
      reviewIsLoading={reviewIsLoading}
      reviews={reviews}
    />
  )
}

export default ReviewsWithoutAppearances

import PropTypes from 'prop-types'
import React from 'react'
import './divider.scss'

/* eslint-disable react/button-has-type */
const Divider = ({ borderStyle, score, thickness, orientation, className }) => (
  <div
    className={`divider--${orientation} divider--${orientation}--${borderStyle} divider--${orientation}-${thickness} divider--score-${score} ${className}`}
  />
)

export default Divider

Divider.defaultProps = {
  borderStyle: 'dashed',
  className: null,
  orientation: 'horizontal',
  score: null,
  thickness: 'thin',
}

Divider.propTypes = {
  borderStyle: PropTypes.oneOf(['dashed', 'solid']),
  className: PropTypes.string,
  orientation: PropTypes.oneOf(['horizontal', 'vertical']),
  score: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  thickness: PropTypes.oneOf(['thin', 'thick']),
}

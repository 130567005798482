import PropTypes from 'prop-types'
import React from 'react'

import { Typography, Metric, Claim } from 'components'

import './feedbackRequest.scss'

import Divider from '../../../Atomic/Atoms/Divider'
import DeleteFeedbackRequest from '../DeleteFeedbackRequest'
import EditFeedbackRequest from '../EditFeedbackRequest'

const FeedbackRequest = ({ onSuccess, request, variant }) => {
  return (
    <div className="feedback-request">
      <Typography
        className="feedback-request--publicQuestion"
        variant={variant === 'list' ? 'title-1' : 'h2'}
      >
        {`Public question: ${request.publicQuestion}`}
      </Typography>

      <div className="feedback-request--claim">
        <Divider
          borderStyle="solid"
          className="feedback-request--claim--divider"
          orientation="vertical"
          thickness="thick"
        />
        <Claim
          claim={request.claim.text}
          conclusionLabel={request.scoreLabel}
          conclusionScore={request.score}
          displayInteraction={false}
        />
      </div>

      <div className="feedback-request--metrics">
        {variant === 'list' && (
          <Metric number={request.scientistFeedbacks.length} text="Feedbacks" />
        )}
      </div>
      {variant === 'header' && (
        <div className="feedback-request--actions">
          <EditFeedbackRequest
            feedbackRequest={request}
            onSuccess={onSuccess}
          />
          <DeleteFeedbackRequest
            feedbackRequest={request}
            onSuccess={onSuccess}
          />
        </div>
      )}
    </div>
  )
}
FeedbackRequest.defaultProps = {
  onSuccess: () => {},
  variant: 'list',
}

FeedbackRequest.propTypes = {
  onSuccess: PropTypes.func,
  request: PropTypes.shape({
    claim: PropTypes.shape({
      text: PropTypes.string,
    }),
    publicQuestion: PropTypes.string,
    scientistFeedbacks: PropTypes.arrayOf(PropTypes.shape({})),
    score: PropTypes.number,
    scoreLabel: PropTypes.string,
  }).isRequired,
  variant: PropTypes.oneOf(['list', 'header']),
}

export default FeedbackRequest

const scoreLabel = (label, score) => {
  if (score) {
    return score
  } else if (score === 0) {
    return score
  } else if (label) {
    return label.toLowerCase()
  } else {
    return null
  }
}

const normalizeConclusion = ({
  conclusionTag,
  conclusion,
  fallback,
  initialLabel,
}) => {
  const { label } = conclusionTag || {}
  const { standardForm, score } = conclusion || {}
  initialLabel = label || initialLabel

  if (standardForm) {
    return {
      initialLabel: initialLabel,
      label: standardForm,
      score: scoreLabel(standardForm, score),
    }
  } else if (label) {
    return {
      initialLabel: initialLabel,
      label: label,
      score: score || 'other',
    }
  } else {
    return {
      initialLabel: initialLabel,
      label: fallback ? fallback : 'Unset',
      score: score || null,
    }
  }
}

export default normalizeConclusion

import { useMutation } from 'react-query'

import { useAxios } from 'hooks/index'
import { API_URL } from 'utils/config'

// useDownloadAppearanceDocument hook

export default function useDownloadAppearanceDocument(data) {
  const instances = useAxios()
  const downloadAppearanceDocument = async inputData => {
    const { data } = await instances.get(
      `${API_URL}/content-documents/${inputData.id}/download/`,
      { responseType: 'blob' }
    )

    // Create a temporary link to the file and trigger a download
    const url = window.URL.createObjectURL(new Blob([data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', inputData.filename)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    return data
  }
  return useMutation(data => downloadAppearanceDocument(data), data)
}

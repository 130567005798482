import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import { APP_NAME } from 'utils/config'

export const SET_ACTIVE_ROLE = 'SET_ACTIVE_ROLE'

const initialState = {
  activeRole: null,
}

const rolesReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ACTIVE_ROLE:
      return { ...state, activeRole: action.payload.activeRole }
    default:
      return state
  }
}

export const setActiveRole = activeRole => ({
  payload: {
    activeRole,
  },
  type: SET_ACTIVE_ROLE,
})

const rolesPersistConfig = {
  key: `${APP_NAME}-webapp-roles`,
  storage,
  whitelist: ['activeRole'],
}

export default persistReducer(rolesPersistConfig, rolesReducer)

import { useQuery } from 'react-query'

import { normalizeFeedbackRequest } from 'normalizer'
import { API_URL } from 'utils/config'

import { useAxios } from '../index'

export default function useFeedbackRequest({ id }) {
  const axios = useAxios()

  const getFeedbackRequest = async ({ id }) => {
    const { data } = await axios.get(`${API_URL}/feedback-requests/${id}`)
    return data
  }

  return useQuery(
    ['feedback-request', id],
    () => getFeedbackRequest({ id: id }),
    {
      select: data => normalizeFeedbackRequest(data),
    }
  )
}

import React from 'react'
import { useMediaQuery } from 'react-responsive'

import AboutDatabaseDesktop from './AboutDatabaseDesktop'
import AboutDatabaseMobile from './AboutDatabaseMobile'

const AboutDatabase = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' })

  if (isMobile) {
    return <AboutDatabaseMobile />
  } else {
    return <AboutDatabaseDesktop />
  }
}

export default AboutDatabase

import React from 'react'
import { createContext } from 'react'

import { useLocalStorage } from 'hooks'

const AuthContext = createContext({})

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useLocalStorage('auth', {})
  const isAuth = !!(auth.accessToken && Date.now() <= Date.parse(auth.exp))

  return (
    <AuthContext.Provider value={{ auth, setAuth, isAuth }}>
      {children}
    </AuthContext.Provider>
  )
}

export default AuthContext

import { useInfiniteQuery } from 'react-query'

import { API_URL } from 'utils/config'

import { normalizeSource } from '../../normalizer'
import { useAxios } from '../index'

export default function useSources(userParams = {}, dataLimit = 'all') {
  const axios = useAxios()

  const getSources = async (
    { search = '', paginate = 20, sourceTypes = '', sortBy = 'relevance' },
    { pageParam = 1 }
  ) => {
    const { data, headers } = await axios.get(
      `${API_URL}/sources?page=${pageParam}&keywords=${search}&paginate=${paginate}&sourceTypes=${sourceTypes}&sortBy=${sortBy}`
    )
    return {
      data,
      hasNextPage: headers['has-more'] === 'True' && pageParam + 1,
    }
  }

  return useInfiniteQuery(
    ['sources', userParams],
    params => getSources(userParams, params), //Api Calls
    {
      select: data => {
        const dataFormatted = data.pages.flatMap(page =>
          page.data.map(source => normalizeSource(source, dataLimit))
        )
        return dataFormatted
      },
      getNextPageParam: lastPage => lastPage.hasNextPage,
    }
  )
}

import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'

import Button from 'components/Atomic/Atoms/Button'
import Divider from 'components/Atomic/Atoms/Divider'
import Typography from 'components/Atomic/Atoms/Typography'
import ConclusionTag from 'components/Business/Conclusions/ConclusionTag'
import { numberShortener } from 'utils/shorteners'

import './claimCardDesktop.scss'

const ClaimCardDesktop = ({
  claim,
  claimBy,
  conclusionLabel,
  conclusionScore,
  goToReview,
  linkNumber,
  interactionNumber,
  reviewId,
  variant,
}) => {
  return (
    <div className={`claim-card-desktop claim-card-desktop-${variant}`}>
      <Divider
        borderStyle="solid"
        orientation="vertical"
        score={conclusionScore}
        thickness="thick"
      />
      <div className="claim-card-desktop--section">
        <div className="claim-card-desktop--section--header">
          <Typography variant="title-1">Claim:</Typography>

          {variant !== 'light' && (
            <ConclusionTag label={conclusionLabel} score={conclusionScore} />
          )}
        </div>

        <div className="claim-card-desktop--section--body">
          <Typography
            className="claim-card-desktop--section--body--claim"
            variant="body-text-2"
          >
            {claim}
          </Typography>

          <div className="claim-card-desktop--section--body--link">
            {claimBy && (
              <Typography variant="body-text-4">
                claimed by &apos;{claimBy}&apos;
              </Typography>
            )}
          </div>
        </div>

        {variant !== 'light' && (
          <div className="claim-card-desktop--section--footer">
            {linkNumber !== undefined && interactionNumber !== undefined && (
              <Typography variant="title-3">
                {linkNumber} Links: {numberShortener(interactionNumber)}{' '}
                interactions &nbsp;
              </Typography>
            )}
            {goToReview && reviewId && (
              <Link to={`/review/${reviewId}`}>
                <Button type="internalLink" variant="ghost">
                  Go to Review
                </Button>
              </Link>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

ClaimCardDesktop.defaultProps = {
  claimBy: 'Unknown',
  conclusionLabel: 'Not rated',
  conclusionScore: null,
  factCheckContentUrl: null,
  goToReview: true,
  interactionNumber: 0,
  linkNumber: 0,
  reviewId: null,
  tagLabel: '',
}

ClaimCardDesktop.propTypes = {
  claim: PropTypes.string,
  claimBy: PropTypes.string,
  conclusionLabel: PropTypes.string,
  conclusionScore: PropTypes.oneOf([0, 1, 2, 3, 4, 5]),
  factCheckContentUrl: PropTypes.string,
  goToReview: PropTypes.bool,
  interactionNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  linkNumber: PropTypes.number,
  reviewId: PropTypes.string,
  tagLabel: PropTypes.string,
}

export default ClaimCardDesktop

import React from 'react'
import { Outlet, Route, Routes } from 'react-router-dom'

import { Feedback, RequestFeed, CreateFeedbackRequest } from './pages'

const ReviewRouter = () => {
  return (
    <>
      <Routes>
        <Route element={<RequestFeed />} index />
        <Route path=":feedbackRequestId">
          <Route element={<Feedback />} index />
        </Route>
        <Route element={<RequestFeed />} path="pending" />
        <Route element={<CreateFeedbackRequest />} path="create" />
      </Routes>

      <Outlet />
    </>
  )
}

export default ReviewRouter

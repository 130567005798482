import React from 'react'
import { useNavigate } from 'react-router-dom'

import { HomePage as PageHomePage } from 'components'
import { useReviews, useTopSources, useContact, useStats } from 'hooks'

const HomePage = () => {
  let navigate = useNavigate()
  const { data: reviews } = useReviews({ paginate: 3 })
  const { data: topSources } = useTopSources()
  const contact = useContact()
  const { data: stats } = useStats('appearances,verdictsApproved')

  const nbUrl = stats?.find(stat => stat.modelName === 'appearances')?.count
  const nbReviews = stats?.find(
    stat => stat.modelName === 'verdictsApproved'
  )?.count

  const onSearch = ({ search }) => {
    if (search) {
      navigate(`/search?keywords=${search}&sortBy=relevance`)
    } else {
      navigate('/search')
    }
  }

  const onContact = values => {
    contact.mutate(values)
  }

  return (
    <PageHomePage
      nbReviews={nbReviews}
      nbUrl={nbUrl}
      onContact={onContact}
      onSearch={onSearch}
      reviews={reviews}
      sources={topSources}
    />
  )
}
export default HomePage

import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'

import { Search, Typography } from 'components/index'

import './welcomeAndSearch.scss'

const WelcomeAndSearch = ({ onSearch, nbReviews, nbUrl }) => (
  <div className="welcomeAndSearch">
    <div className="welcomeAndSearch--content">
      <div className="welcomeAndSearch--content--title">
        <Typography color="primary-white" variant="h1">
          A collaborative database of
        </Typography>
        <Typography
          className="welcomeAndSearch--content--title--underline-wrapper"
          color="primary-white"
          variant="h1"
        >
          feedback on &nbsp;
          <div className="welcomeAndSearch--content--title--underline">
            information credibility
          </div>
        </Typography>
      </div>
      <div className="welcomeAndSearch--content--subtitle">
        <Typography color="primary-white" variant="title-2">
          Search a topic, a URL or media to find associated reviews. Learn about
          claims’ credibility and sources’ reliability.
        </Typography>
      </div>
      <div className="welcomeAndSearch--content--searchbar">
        <Search
          defaultSearch=""
          onSearch={onSearch}
          placeholder="Enter a URL, keyword, media name"
          variant="ghost-reverse"
        />
      </div>

      <div className="welcomeAndSearch--content--learn-more">
        <Typography color="primary-white" variant="title-3">
          {nbReviews} reviews {nbUrl} content URLs flagged
        </Typography>
        <Link to="/about">
          <Typography color="primary-white" variant="link-text-2">
            {' '}
            Learn More ->
          </Typography>
        </Link>
      </div>
    </div>
    <img
      alt=""
      className="welcomeAndSearch--bg"
      src="/static/assets/homepage/Final.svg"
    />
  </div>
)

WelcomeAndSearch.propTypes = {
  nbReviews: PropTypes.number.isRequired,
  nbUrl: PropTypes.number.isRequired,
  onSearch: PropTypes.func.isRequired,
}

export default WelcomeAndSearch

import { useInfiniteQuery } from 'react-query'

import { useAuth } from 'hooks'
import { normalizeAppearance } from 'normalizer'
import { API_URL } from 'utils/config'

import { useAxios } from '../index'

export default function useClaimAppearances(userParams) {
  const axios = useAxios()

  const { auth } = useAuth()

  const getClaimAppearances = async (
    { claimId = '', paginate = 10, tagLabel = '' },
    { isLoggedIn = false, pageParam = 1 }
  ) => {
    let url = `${API_URL}/claims/${claimId}/appearances?page=${pageParam}&paginate=${paginate}`
    if (tagLabel) {
      url += `&tagLabel=${tagLabel}`
    }
    const { data, headers } = await axios.get(url)
    return {
      data,
      hasNextPage:
        headers['has-more'] === 'True' && isLoggedIn && pageParam + 1,
      totalDataCount: headers['total-data-count'],
    }
  }

  return useInfiniteQuery(
    ['claim-appearances', userParams],
    params =>
      getClaimAppearances(userParams, { isLoggedIn: !!auth, ...params }), //Api Calls
    {
      enabled: !!userParams.claimId,
      select: data => {
        const dataFormatted = {
          data: data.pages.flatMap(page =>
            page.data.map(appearance => {
              return normalizeAppearance(appearance)
            })
          ),
        }
        return {
          data: dataFormatted,
          totalDataCount: data.pages[0].totalDataCount,
        }
      },
      getNextPageParam: lastPage => lastPage.hasNextPage,
    }
  )
}

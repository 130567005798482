import PropTypes from 'prop-types'
import React, { useState } from 'react'

import { Button, ConfirmBar, Dropdown, Icon, TextField, VerdictsTable, Typography } from 'components'

import './factcheckerVerdictsPage.scss'
import {Link} from "react-router-dom"

const FactcheckerVerdictsPage = ({
                                   defaultScore,
                                   defaultSearch,
                                   page,
                                   onChangePage,
                                   onModifyGenericConclusion,
                                   onSearch,
                                   user,
                                   verdicts,
                                 }) => {

  const factcheckingChannelIds = user.factcheckingChannels.map(channel => channel.id)

  const channelConclusion = (conclusions) => {
    return conclusions.find(conclusion => factcheckingChannelIds.includes(conclusion.channelId))
  }

  const normalizedVerdicts = verdicts.reduce((acc, verdict) => {
    if (!verdict.label) {
      return acc
    }
    const conclusion = channelConclusion(verdict.conclusions)
    return [
      ...acc,
      {
        id: verdict.id,
        label: verdict.label,
        conclusionId: conclusion?.id,
        conclusionStandardForm: conclusion?.standardForm,
        conclusionScore: conclusion?.score,
      },
    ]
  }, [])

  const [keyword, setKeyword] = useState(defaultSearch)
  const [score, setScore] = useState(defaultScore)

  const onChangeKeyword = e => {
    setKeyword(e.target.value)
  }
  const onSearchKeyword = e => {
    if (e.key === 'Enter') {
      onSearch(keyword, score)
    }
  }

  const onChooseScore = value => {
    setScore(value)
    onSearch(keyword, value)
  }

  const SCORE_OPTIONS = [
    { value: '', text: 'All' },
    { value: 1, text: 1 },
    { value: 2, text: 2 },
    { value: 3, text: 3 },
    { value: 4, text: 4 },
    { value: 5, text: 5 },
  ]

  const onNext = () => {
    onChangePage(page + 1)
  }

  const onPrev = () => {
    onChangePage(page - 1)
  }

  const [showConfirmModifyConclusion, setShowConfirmModifyConclusion] =
    useState(false)
  const toggleShowConfirmModifyConclusion = () => {
    setShowConfirmModifyConclusion(!showConfirmModifyConclusion)
  }

  const onModifyConclusion = (conclusionId, data) => {
    onModifyGenericConclusion(conclusionId, data)
    setTimeout(() => {
      setShowConfirmModifyConclusion(false)
    }, 3000)
    toggleShowConfirmModifyConclusion()
  }

  return (
    <>
      <div className="verdict-tags-page">
        <div className="verdict-tags-page--search">

          <Link className="verdict-tags-page--search--btn-back" to="/dashboard" variant="light">
            <Icon icon="arrowleft" size={24}/>
          </Link>

          <Typography variant="h3">
            Standard verdict options:
          </Typography>

          <div className="verdict-tags-page--search--bar">
            <TextField
              maxLength="255"
              onChange={onChangeKeyword}
              onKeyDown={onSearchKeyword}
              placeholder="Keywords: Type and press enter"
              value={keyword}
            />
          </div>

          <div className="verdict-tags-page--search--rating">
            <Dropdown
              defaultValue={defaultScore}
              inputRef="score-select-field"
              onChange={e => onChooseScore(e.target.value)}
              options={SCORE_OPTIONS}
            />
          </div>
        </div>

        <Typography color="secondary-grey" variant="body-text-2">
          Lorem ipsum dolor sit amet consectetur. Hendrerit nisl varius tempor ullamcorper diam sed nisi pretium.
        </Typography>
        <div className="verdict-tags-page--table">
          <VerdictsTable
            onModifyConclusion={onModifyConclusion}
            verdicts={normalizedVerdicts}
          />
          <div className="verdict-tags-page--table--buttons">
            <Button disabled={page === 1} onClick={onPrev} variant="ghost">
              <Icon icon="arrowleft" />
            </Button>
            <Button onClick={onNext} variant="ghost">
              <Icon icon="arrowright" />
            </Button>
          </div>
        </div>
      </div>
      <ConfirmBar
        show={showConfirmModifyConclusion}
        text="Conclusion has been modified"
      />
    </>
  )
}

export default FactcheckerVerdictsPage

FactcheckerVerdictsPage.defaultProps = {
  defaultScore: '',
  defaultSearch: '',
  page: 1,
  verdicts: [],
}

FactcheckerVerdictsPage.propTypes = {
  defaultScore: PropTypes.string,
  defaultSearch: PropTypes.string,
  onChangePage: PropTypes.func.isRequired,
  onModifyGenericConclusion: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  page: PropTypes.number,
  verdicts: PropTypes.arrayOf(PropTypes.shape({})),
}

import { useQuery } from 'react-query'

import { API_URL } from 'utils/config'

import { normalizeSource } from '../../../normalizer'
import { useAxios } from '../../index'

export default function useOrganization(organizationId) {
  const axios = useAxios()

  const getOrganizationById = async organizationId => {
    const { data } = await axios.get(
      `${API_URL}/organizations/${organizationId}`
    )
    return data
  }

  return useQuery(
    ['organizations', organizationId],
    () => getOrganizationById(organizationId),
    {
      select: data => normalizeSource(data),
    }
  )
}

export default ({
  linkData,
  linkNumber,
  interactionData,
  interactionNumber,
  misinformationInteractionNumber,
  misinformationLinkNumber,
  sourceVariant,
}) => {
  const variants = {
    'all-data': {
      data: interactionData,
      label: interactionNumber ? interactionNumber : '?',
      metrics: [
        {
          number: linkNumber,
          text: 'links:',
        },
        {
          number: misinformationLinkNumber,
          text: 'misinformation',
        },
      ],
      unit: 'int.',
    },
    'all-links': {
      data: linkData,
      label: linkNumber,
      metrics: [
        {
          number: linkNumber,
          text: 'links',
        },
      ],
      unit: 'links',
    },
    'misinformation-links': {
      data: linkData,
      label: misinformationLinkNumber,
      metrics: [
        {
          number: linkNumber,
          text: 'links:',
        },
        {
          number: misinformationLinkNumber,
          text: 'misinformation',
        },
      ],
      unit: 'links',
    },
    'all-interactions': {
      data: interactionData,
      label: interactionNumber ? interactionNumber : '?',
      metrics: [
        {
          number: interactionNumber,
          text: 'interactions',
        },
      ],
      unit: 'int.',
    },
    'misinformation-interactions': {
      data: interactionData,
      label: interactionNumber ? misinformationInteractionNumber : '?',
      metrics: [
        {
          number: interactionNumber,
          text: 'int.:',
        },
        {
          number: misinformationInteractionNumber,
          text: 'with misinfo.',
        },
      ],
      unit: 'int.',
    },
  }

  const metricVariant = sourceVariant.includes('interactions')
    ? 'interactions'
    : 'default'

  return { ...variants[sourceVariant], metricVariant }
}

import PropTypes from 'prop-types'
import React, { useEffect } from 'react'

import Button from 'components/Atomic/Atoms/Button'
import Icon from 'components/Atomic/Atoms/Icon'

import './sideBar.scss'

const SideBar = ({ show, children, onClose }) => {
  useEffect(() => {
    if (show) {
      document.body.style.position = 'fixed'
    } else if (!show) {
      document.body.style.position = 'unset'
    }
  }, [show])

  if (!show) return null

  const onClickWrapper = () => {
    onClose()
  }

  const onClickContent = e => {
    e.stopPropagation()
  }

  return (
    <div
      className="sideBar"
      onClick={onClickWrapper}
      role="button"
      tabIndex={0}
    >
      <div
        className="sideBar--content"
        onClick={onClickContent}
        role="button"
        tabIndex={0}
      >
        <Button
          className="sideBar--content--close"
          onClick={onClose}
          variant="ghost-reverse-link"
        >
          <Icon color="#FFFFFB" icon="cross" />
        </Button>
        {children}
      </div>
    </div>
  )
}

export default SideBar

SideBar.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

import PropTypes from 'prop-types'
import React from 'react'
import { useMediaQuery } from 'react-responsive'

import AppearanceCardDesktop from './AppearanceCardDesktop'
import AppearanceCardMobile from './AppearanceCardMobile'

const AppearanceCard = appearance => {
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' })

  if (isMobile) {
    return <AppearanceCardMobile {...appearance} />
  } else {
    return <AppearanceCardDesktop {...appearance} />
  }
}

export default AppearanceCard

AppearanceCard.defaultProps = {
  appearance: {},
}

AppearanceCard.propTypes = {
  appearance: PropTypes.shape({}),
}

import PropTypes from 'prop-types'
import React from 'react'

import Typography from '../Typography'

import './metrics.scss'

const Metrics = ({ metrics, variant }) => {
  if (!metrics[0]?.number && variant === 'interactions') {
    return (
      <Typography variant="numbers">
        <span className="metrics-text">No data</span>&nbsp;
      </Typography>
    )
  }
  return metrics.map(({ number, text }) => (
    <Typography key={text} variant="numbers">
      <b>{number}</b>&nbsp;<span className="metrics-text">{text}</span>&nbsp;
    </Typography>
  ))
}

export default Metrics

Metrics.defaultProps = {
  metrics: [],
  variant: 'default',
}

Metrics.propTypes = {
  metrics: PropTypes.arrayOf(PropTypes.shape()),
  variant: PropTypes.oneOf(['default', 'interactions']),
}

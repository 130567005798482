import { useQuery } from 'react-query'

import { API_URL } from 'utils/config'

import { useAxios } from '../index'


export default function useFactcheckerVerdicts(searchParams) {
  const axios = useAxios()

  const getVerdicts = async ({
    keywords = '',
    score = null,
    page = 1,
  }) => {
    let url = `${API_URL}/factcheckers/tags?page=${page}&keywords=${keywords}`
    if (score) {
      url = `${url}&score=${score}`
    }

    const { data } = await axios.get(url)
    return data
  }
  return useQuery(
    ['verdict-tags', searchParams],
    () => getVerdicts(searchParams), //Api Calls
    {
      select: data => {
        return data
      },
    }
  )
}

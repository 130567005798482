import PropTypes from 'prop-types'
import React, { useState } from 'react'

export const tabContext = React.createContext(null)

const TabsContext = ({ defaultTab, children }) => {
  let [activeTab, setActiveTab] = useState(defaultTab)

  return (
    <tabContext.Provider value={[activeTab, setActiveTab]}>
      {children}
    </tabContext.Provider>
  )
}

export default TabsContext

TabsContext.defaultProps = {
  defaultTab: null,
}

TabsContext.propTypes = {
  defaultTab: PropTypes.string,
}

import { useMutation } from 'react-query'

import { API_URL } from 'utils/config'

import { useAxios } from '../index'

export default function useSyncReview(data) {
  const instances = useAxios()
  const syncReview = async syncReviewData => {
    const { data } = await instances.patch(
      `${API_URL}/verdicts/${syncReviewData.reviewId}/sync`,
      syncReviewData.syncData
    )
    return data
  }
  return useMutation(data => syncReview(data), data)
}

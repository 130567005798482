import { useMutation } from 'react-query'

import { useAxios } from 'hooks'

import { API_URL } from '../../utils/config'

export default function useModifyGenericConclusion(data) {
  const instances = useAxios()
  const modifyGenericConclusion = async modifyConclusionData => {
    const { data } = await instances.patch(
      `${API_URL}/conclusions/${modifyConclusionData.conclusionId}/generic`,
      modifyConclusionData.conclusionData
    )
    return data
  }
  return useMutation(data => modifyGenericConclusion(data), data)
}

import React, { useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

import { ConclusionsPage } from 'components'
import {
  useConclusions,
  useModifyGenericConclusion,
  useDeleteGenericConclusion,
} from 'hooks'

const Conclusions = () => {
  let navigate = useNavigate()
  const { search: urlSearch } = useLocation()

  const urlConclusionPage =
    Number(new URLSearchParams(urlSearch).get('page')) || 1
  const urlConclusionKeywords =
    new URLSearchParams(urlSearch).get('keywords') || ''
  const urlConclusionScore = new URLSearchParams(urlSearch).get('score') || ''

  const [searchConclusion, setSearchConclusion] = useState(
    urlConclusionKeywords
  )
  const [scoreConclusion, setScoreConclusion] = useState(urlConclusionScore)

  const [page, setPage] = useState(urlConclusionPage)

  const { data: conclusions, refetch: refetchConclusions } = useConclusions({
    keywords: searchConclusion,
    distinctStandardFormScore: true,
    score: scoreConclusion,
    page: page,
  })

  const onSearch = (keywords, score) => {
    navigate(
      `/dashboard/conclusions?page=1&keywords=${keywords}&score=${score}`
    )
    setPage(1)
    setSearchConclusion(keywords)
    setScoreConclusion(score)
    refetchConclusions()
  }

  const onChangePage = newPage => {
    setPage(newPage)
    navigate(
      `/dashboard/conclusions?page=${newPage}&keywords=${urlConclusionKeywords}`
    )
    refetchConclusions()
  }

  const modifyGenericConclusion = useModifyGenericConclusion()
  const onModifyGenericConclusion = async (conclusionId, conclusionData) => {
    const data = {
      conclusionId: conclusionId,
      conclusionData: conclusionData,
    }
    modifyGenericConclusion.mutate(data, {
      onSuccess: () => refetchConclusions(),
    })
  }

  const deleteGenericConclusion = useDeleteGenericConclusion()
  const onDeleteGenericConclusion = async conclusionId => {
    deleteGenericConclusion.mutate(conclusionId, {
      onSuccess: () => refetchConclusions(),
    })
  }

  return (
    <ConclusionsPage
      conclusions={conclusions}
      defaultScore={scoreConclusion}
      defaultSearch={searchConclusion}
      onChangePage={onChangePage}
      onDeleteGenericConclusion={onDeleteGenericConclusion}
      onModifyGenericConclusion={onModifyGenericConclusion}
      onSearch={onSearch}
      page={page}
    />
  )
}

export default Conclusions

import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'

import { Button, Typography, FooterBar } from 'components'
import './confirmFactcheckScrapped.scss'

const ConfirmFactcheckScrapped = ({ reviewId, show, onClose }) => (
  <FooterBar show={show}>
    <div className="confirm-factcheck-scrapped">
      <div className="confirm-factcheck-scrapped--text">
        <Typography color="primary-white" variant="title-1">
          This article has been imported:
        </Typography>
      </div>
      <div className="confirm-factcheck-scrapped--actions">
        <Button onClick={onClose} variant="ghost-reverse-link">
          <Typography
            className="confirm-factcheck-scrapped--actions--cancel"
            color="primary-white"
            variant="title-3"
          >
            Close
          </Typography>
        </Button>
        <Link to={`/review/${reviewId}`}>
          <Button type="internalLink" variant="ghost-reverse">
            Go to Review page
          </Button>
        </Link>
      </div>
    </div>
  </FooterBar>
)

ConfirmFactcheckScrapped.defaultProps = {
  reviewId: null,
  show: false,
}

ConfirmFactcheckScrapped.propTypes = {
  reviewId: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool,
}

export default ConfirmFactcheckScrapped

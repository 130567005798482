import PropTypes from 'prop-types'
import React from 'react'

import Button from 'components/Atomic/Atoms/Button'
import Typography from 'components/Atomic/Atoms/Typography'
import FooterBar from 'components/Atomic/Molecules/FooterBar'
import './confirmDeleteQuotation.scss'

const ConfirmDeleteQuotation = ({
  isAppearanceDeleted,
  show,
  onClose,
  onSubmit,
}) => {
  if (isAppearanceDeleted) {
    return (
      <FooterBar onClose={onClose} show={show}>
        <div className="quotation-deleted">
          <Typography color="primary-white" variant="title-1">
            Appearance has been deleted
          </Typography>
        </div>
      </FooterBar>
    )
  } else {
    return (
      <FooterBar show={show}>
        <div className="delete-quotation">
          <div className="delete-quotation--text">
            <Typography color="primary-white" variant="title-1">
              Delete 1 appearance:
            </Typography>
          </div>
          <div className="delete-quotation--actions">
            <Button onClick={onClose} variant="ghost-reverse-link">
              <Typography
                className="delete-quotation--actions--cancel"
                color="primary-white"
                variant="title-3"
              >
                Cancel
              </Typography>
            </Button>
            <Button onClick={onSubmit} variant="ghost-reverse">
              Delete
            </Button>
          </div>
        </div>
      </FooterBar>
    )
  }
}

ConfirmDeleteQuotation.defaultProps = {
  isAppearanceDeleted: false,
  show: false,
}

ConfirmDeleteQuotation.propTypes = {
  isAppearanceDeleted: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  show: PropTypes.bool,
}

export default ConfirmDeleteQuotation

import {useMutation, useQueryClient} from 'react-query'

import { useAxios } from 'hooks'

import { API_URL } from '../../utils/config'

const useAddMember = () => {
  const axios = useAxios()
  const queryClient = useQueryClient()

  const addMember = async values => {
    let endpoint = `${API_URL}/organizations/${values.id}/factcheckers/add/`

    const { data } = await axios.post(endpoint, {
      ...values.value
    })

    return data
  }

  return useMutation(addMember,{
    onSuccess: (data, values) => {
      queryClient.invalidateQueries(["organizations", values.id])
    },
  })
}

export default useAddMember

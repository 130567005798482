import { useQuery } from 'react-query'

import { normalizeReview } from 'normalizer'
import { API_URL } from 'utils/config'

import { useAxios } from '../index'

export default function useReview({ id }) {
  const axios = useAxios()

  const getReview = async ({ id }) => {
    const { data } = await axios.get(`${API_URL}/verdicts/${id}`)
    return data
  }

  return useQuery(['review', id], () => getReview({ id: id }), {
    select: data => normalizeReview(data),
  })
}

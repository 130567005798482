import PropTypes from 'prop-types'
import React from 'react'

import './scoreSquare.scss'

const ScoreSquare = ({ score }) => {
  return <div className={`scoreSquare scoreSquare-${score}`} key={score} />
}

ScoreSquare.defaultProps = {
  score: 'undefined',
}

ScoreSquare.propTypes = {
  score: PropTypes.oneOf([0, 1, 2, 3, 4, 5, 'satire', 'undefined']),
}

export default ScoreSquare

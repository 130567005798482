import { useMutation } from 'react-query'

import { API_URL } from 'utils/config'

import { useAxios } from '../index'

export default function useContact(data) {
  const axios = useAxios()

  const postContact = async contactData => {
    const { data } = await axios.post(`${API_URL}/contacts`, contactData)
    return data
  }

  return useMutation(data => postContact(data), data)
}

const normalizeSuggestedAppearance = suggestedAppearance => {
  return {
    facebookAngryCount: suggestedAppearance.linkingContent?.facebookAngryCount,
    facebookCommentCount:
      suggestedAppearance.linkingContent?.facebookCommentCount,
    facebookHahaCount: suggestedAppearance.linkingContent?.facebookHahaCount,
    facebookLikeCount: suggestedAppearance.linkingContent?.facebookLikeCount,
    facebookLoveCount: suggestedAppearance.linkingContent?.facebookLoveCount,
    facebookSadCount: suggestedAppearance.linkingContent?.facebookSadCount,
    facebookShares: suggestedAppearance.linkingContent?.facebookShares,
    facebookWowCount: suggestedAppearance.linkingContent?.facebookWowCount,
    id: suggestedAppearance.id,
    imageUrl: suggestedAppearance.linkingContent?.externalThumbUrl,
    interactionNumber: suggestedAppearance.linkingContent?.totalInteractions,
    pinterestShares: suggestedAppearance.linkingContent?.pinterestShares,
    redditEngagements: suggestedAppearance.linkingContent?.redditEngagements,
    semanticScore: suggestedAppearance.semanticScore,
    syntacticScore: suggestedAppearance.syntacticScore,
    title: suggestedAppearance.linkingContent?.title,
    twitterShares: suggestedAppearance.linkingContent?.twitterShares,
    url: suggestedAppearance.linkingContent?.url,
    urlDisplayed:
      suggestedAppearance.linkingContent?.url?.length > 40
        ? `${suggestedAppearance.linkingContent?.url.substring(0, 40)}...`
        : suggestedAppearance.linkingContent?.url,

    youtubeCommentCount:
      suggestedAppearance.linkingContent?.youtubeCommentCount,
    youtubeDislikeCount:
      suggestedAppearance.linkingContent?.youtubeDislikeCount,
    youtubeLikeCount: suggestedAppearance.linkingContent?.youtubeLikeCount,
    youtubeTrendingScore:
      suggestedAppearance.linkingContent?.youtubeTrendingScore,
  }
}

export default normalizeSuggestedAppearance

import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import {
  ConfirmBar,
  SideBar,
  Button,
  Divider,
  TextField,
  Typography,
  AddSource,
} from 'components'
import { useSourceEdition } from 'hooks'

import SideBarBody from '../../../Atomic/Molecules/SideBar/SideBarBody'
import SideBarFooter from '../../../Atomic/Molecules/SideBar/SideBarFooter'
import SideBarHeader from '../../../Atomic/Molecules/SideBar/SideBarHeader'

import SearchCanonicalChannel from './SearchCanonicalChannel'
import SearchChannels from './SearchChannels'
import SearchMedia from './SearchMedia'
import SearchMedias from './SearchMedias'
import SearchOrganization from './SearchOrganization'
import SearchPersons from './SearchPersons'
import Wikimedia from './Wikimedia'

import './editSource.scss'

export const EditSource = ({ onRefetchSource, source }) => {
  const {
    id,
    sourceType,
    sourceName,
    wikidataIdentifier,
    organization,
    medium,
    channels,
    media,
    mbfcUrl,
    owners,
  } = source
  let navigate = useNavigate()
  const editSource = useSourceEdition(sourceType)

  const methods = useForm({
    defaultValues: {
      name: sourceName,
      id,
      wikidataIdentifier,
      organizationId: organization?.id,
      organizationName: organization?.name,
      mediumId: medium?.id,
      mediumName: medium?.name,
      channels,
      media,
      mbfcUrl,
      owners,
    },
  })
  const [show, setShow] = useState(false)
  const [showAdd, setShowAdd] = useState(false)

  const onClose = () => {
    setShow(false)
  }

  const onCloseAdd = () => {
    setShowAdd(false)
  }

  const [showSourceEdited, setShowSourceEdited] = useState(false)
  const toggleShowSourceEdited = () => setShowSourceEdited(!showSourceEdited)
  const onSend = values => {
    if ('mediumId' in values && values['mediumId'] === '') {
      values['mediumId'] = null
    }
    if ('organizationId' in values && values['organizationId'] === '') {
      values['organizationId'] = null
    }
    onClose()
    editSource.mutate(values)

    if (values['canonicalChannelId']) {
      navigate(`/${sourceType}/${values['canonicalChannelId']}`)
    }

    toggleShowSourceEdited()
    setTimeout(() => {
      setShowSourceEdited(false)
    }, 3000)
  }

  return (
    <div className="edit-source">
      <Button
        data-cy="btn-edit-source"
        onClick={() => setShow(true)}
        variant="ghost"
      >
        Edit
      </Button>

      <SideBar onClose={onClose} show={show}>
        <FormProvider {...methods}>
          <form
            className="edit-source--form"
            onSubmit={methods.handleSubmit(onSend)}
          >
            <SideBarHeader>
              <Typography color="primary-white" variant="h2">
                Edit {sourceType}:
              </Typography>
            </SideBarHeader>
            <SideBarBody>
              <div className="edit-source--section">
                <Typography color="primary-white" variant="title-3">
                  Name:
                </Typography>
                <TextField
                  onChange={() => {
                    methods.setValue('wikidataIdentifier', null)
                  }}
                  placeholder="Name"
                  register={methods.register}
                  registerName="name"
                  rounded={false}
                />
              </div>

              <Divider className="edit-source--divider edit-source--divider--margin" />

              <div className="edit-source--section">
                <Typography color="primary-white" variant="title-2">
                  Identifier
                </Typography>
                <Typography color="primary-white" variant="body-text-2">
                  Search Wikimedia to find the unique identifier for this source
                </Typography>
              </div>
              <Wikimedia />

              <Divider className="edit-source--divider edit-source--divider--margin" />

              {sourceType === 'Channel' && (
                <>
                  <div className="edit-source--section">
                    <Typography color="primary-white" variant="title-2">
                      Ownerships
                    </Typography>
                    <Typography color="primary-white" variant="body-text-2">
                      Indicate which organization(s) or person(s) own or control
                      this source
                    </Typography>
                  </div>
                  <SearchMedia setShowAdd={setShowAdd} />
                  <SearchPersons />

                  <Divider className="edit-source--divider edit-source--divider--margin" />

                  <div className="edit-source--section">
                    <Typography color="primary-white" variant="title-2">
                      Canonical channel
                    </Typography>
                    <Typography color="primary-white" variant="body-text-2">
                      If this channel is a duplicate, indicate its canonical
                      URL.
                    </Typography>
                    <SearchCanonicalChannel originalSourceId={id} />
                  </div>

                  <Divider className="edit-source--divider edit-source--divider--margin" />

                  <div className="edit-source--section">
                    <Typography color="primary-white" variant="title-2">
                      Media Bias Fact Check
                    </Typography>
                    <Typography color="primary-white" variant="body-text-2">
                      URL of the MBFC page of this channel/website
                    </Typography>
                  </div>
                  <div className="edit-source--section">
                    <Typography
                      className="edit-source--label"
                      color="primary-white"
                      variant="title-3"
                    >
                      MBFC URL:
                    </Typography>

                    <TextField
                      className="edit-source--input"
                      placeholder="Mbfc url"
                      register={methods.register}
                      registerName="mbfcUrl"
                      rounded={false}
                    />
                  </div>
                </>
              )}

              {sourceType === 'Media' && (
                <>
                  <div className="edit-source--section">
                    <Typography color="primary-white" variant="title-2">
                      Ownerships
                    </Typography>
                    <Typography color="primary-white" variant="body-text-2">
                      Indicate which organization or person(s) own or control
                      this media, and which channels(s) it controls.
                    </Typography>
                  </div>
                  <SearchOrganization setShowAdd={setShowAdd} />
                  <SearchPersons />
                  <SearchChannels setShowAdd={setShowAdd} />
                </>
              )}

              {sourceType === 'Organization' && (
                <>
                  <SearchOrganization setShowAdd={setShowAdd} />
                  <SearchPersons />
                  <SearchMedias setShowAdd={setShowAdd} />
                </>
              )}
            </SideBarBody>

            <SideBarFooter>
              <Button onClick={onClose} type="button" variant="ghost-reverse">
                Cancel
              </Button>
              <Button type="submit" variant="ghost-reverse">
                Save
              </Button>
            </SideBarFooter>
          </form>
        </FormProvider>
      </SideBar>

      <AddSource onClose={onCloseAdd} show={showAdd} sourceType={showAdd} />
      <ConfirmBar
        checkAnimation
        onClose={toggleShowSourceEdited}
        show={showSourceEdited}
        text="Source updated"
      />
    </div>
  )
}

export default EditSource

EditSource.defaultProps = {
  persons: [],
}

EditSource.propTypes = {
  persons: PropTypes.arrayOf(PropTypes.shape({})),
}

import PropTypes from 'prop-types'
import React from 'react'
import { useMediaQuery } from 'react-responsive'

import FooterDesktop from './FooterDesktop'
import FooterMobile from './FooterMobile'

export const Footer = ({ onSubmit }) => {
  const isDesktop = useMediaQuery({ query: '(min-width: 767px)' })
  return (
    <div>
      {isDesktop ? (
        <FooterDesktop onSubmit={onSubmit} />
      ) : (
        <FooterMobile onSubmit={onSubmit} />
      )}
    </div>
  )
}

Footer.propTypes = {
  onSubmit: PropTypes.func.isRequired,
}

export default Footer

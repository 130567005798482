import { useMutation } from 'react-query'

import { API_URL } from 'utils/config'

import { useAxios } from '../index'

const useEditFeedbackRequest = feedbackRequestId => {
  const axios = useAxios()

  const patchClaim = async values => {
    const { data } = await axios.patch(
      `${API_URL}/feedback-requests/${feedbackRequestId}`,
      values
    )
    return data
  }

  return useMutation(data => patchClaim(data))
}

export default useEditFeedbackRequest

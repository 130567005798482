import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'

import { Badge, Button, Checkbox, Dropdown, Typography } from 'components'
import SearchConclusion from 'components/Business/Conclusions/SearchConclusion'

import './editConclusion.scss'

export const EditConclusion = ({
  cancel,
  conclusionStandardForm,
  factchecker,
  factcheckerVerdict,
  onClose,
  onSubmit,
  score,
  variant,
}) => {
  const SCORE_OPTIONS = [
    { value: null, text: '' },
    { value: 0, text: 0 },
    { value: 1, text: 1 },
    { value: 2, text: 2 },
    { value: 3, text: 3 },
    { value: 4, text: 4 },
    { value: 5, text: 5 },
  ]

  const typographyColor = variant === 'ghost' ? 'primary-white' : 'primary-dark'


  useForm()

  const { handleSubmit, register, setValue } = useForm({
    defaultValues: {
      standardForm: conclusionStandardForm,
    },
  })

  const [conclusionWithoutScore, setConclusionWithoutScore] = useState(false)

  const onCheckboxClick = () => {
    setConclusionWithoutScore(!conclusionWithoutScore)
    if (!conclusionWithoutScore) {
      setValue('score', null)
    }
  }

  const [standardFormChosen, setStandardFormChosen] = useState(
    conclusionStandardForm
  )

  const reinitializeInitialConclusion = () => {
    setStandardFormChosen('')
    setValue('standardForm', null)
  }

  const onStandardFormSelectChange = value => {
    setValue('standardForm', value.standardForm)
    if (factchecker in value.channelScores) {
      setValue('score', value.channelScores[factchecker])
    }
    setStandardFormChosen(value.standardForm)
  }

  const onStandardFormAdd = value => {
    setValue('standardForm', value)
    setStandardFormChosen(value)
  }

  const onSubmitForm = values => {
    if (values['score']) {
      values['score'] = parseInt(values['score'])
    }
    onSubmit(values)
    onClose()
  }

  return (
    <form onSubmit={handleSubmit(onSubmitForm)}>
      <Typography color={typographyColor} variant="title-2">
        <strong>Verdict:</strong>
      </Typography>
      <div className="edit-conclusion--row">
        <strong>
          <Typography color={typographyColor} variant="title-3">
            <strong>Factchecker verdict:</strong>
          </Typography>
          <Typography color={typographyColor} variant="body-text-2">
            {factcheckerVerdict}
          </Typography>
        </strong>
      </div>
      <div className="edit-conclusion--row">
        <div className="edit-conclusion--row--label">
          <Typography color={typographyColor} variant="title-3">
            Standard form:
          </Typography>
        </div>
        <SearchConclusion
          addButton={true}
          onAdd={onStandardFormAdd}
          onChange={onStandardFormSelectChange}
        />
        {standardFormChosen && (
          <div className="edit-conclusion--row--badge">
            <Badge
              onDelete={reinitializeInitialConclusion}
              text={standardFormChosen}
            />
          </div>
        )}
      </div>

      <div className="edit-conclusion--row">
        <div className="edit-conclusion--row--label">
          <Typography color={typographyColor} variant="title-3">
            Score:
          </Typography>
        </div>
        <Dropdown
          defaultValue={score}
          options={SCORE_OPTIONS}
          {...register('score')}
        />
        <div className="edit-conclusion--row--checkbox">
          <Checkbox
            checked={conclusionWithoutScore}
            onChange={onCheckboxClick}
          />
          <Typography color={typographyColor} variant="body-text-2">
            Verdict without score
          </Typography>
        </div>
      </div>

      <div className="claim-verdict--edit-conclusion--buttons">
        {cancel && (
          <Button
            onClick={onClose}
            variant={
              variant === 'ghost' ? 'ghost-reverse-link' : 'default-link'
            }
          >
            Cancel
          </Button>
        )}
        <Button
          type="submit"
          variant={variant === 'ghost' ? 'ghost-reverse' : 'default'}
        >
          Save changes
        </Button>
      </div>
    </form>
  )
}

export default EditConclusion

EditConclusion.defaultProps = {
  cancel: true,
  conclusionStandardForm: '',
  factchecker: '',
  factcheckerVerdict: '',
  score: null,
  variant: 'default',
}

EditConclusion.propTypes = {
  cancel: PropTypes.bool,
  conclusionStandardForm: PropTypes.string,
  factchecker: PropTypes.string,
  factcheckerVerdict: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  score: PropTypes.number,
  variant: PropTypes.oneOf(['default', 'ghost']),
}

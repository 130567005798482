import PropTypes from 'prop-types'
import React, { useMemo, useState } from 'react'
import { useTable } from 'react-table'

import { Button, Icon, TextField, Typography } from 'components'
import './conclusionsTable.scss'

export const ConclusionsTable = ({
  conclusions,
  onDeleteConclusion,
  onModifyConclusion,
}) => {
  const [editableConclusion, setEditableConclusion] = useState({
    id: null,
    standardForm: null,
  })

  const columns = useMemo(
    () => [
      {
        Header: 'Standard form',
        accessor: 'standardForm', // accessor is the "key" in the data
        isEditable: true,
      },
      {
        Header: 'Credibility rating',
        accessor: 'score',
        isEditable: true,
      },
      {
        Header: 'Number of use',
        accessor: 'numberOfUse',
        isEditable: false,
      },
    ],
    []
  )

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns: columns, data: conclusions })

  const handleClickEditRow = row => {
    if (editableConclusion.id === row.original.id) {
      setEditableConclusion({ id: null, standardForm: null, score: null })
    } else {
      setEditableConclusion({
        id: row.original.id,
        standardForm: row.original.standardForm,
        score: row.original.score,
      })
    }
  }

  const onChangeKeyword = (e, column) => {
    setEditableConclusion({ ...editableConclusion, [column]: e.target.value })
  }
  const onSearchKeyword = e => {
    if (e.key === 'Enter') {
      onModifyConclusion(editableConclusion.id, {
        standardForm: editableConclusion.standardForm,
        score: Number(editableConclusion.score),
      })
      setEditableConclusion({ id: null, standardForm: null, score: null })
    }
  }

  const deleteConclusion = row => {
    onDeleteConclusion(row.original)
  }
  return (
    <table {...getTableProps()} className="conclusion-table">
      <thead className="conclusion-table--head">
        {headerGroups.map(headerGroup => (
          <tr
            {...headerGroup.getHeaderGroupProps()}
            key={`headerGroupe-${headerGroup.id}`}
          >
            {headerGroup.headers.map(column => (
              <th
                {...column.getHeaderProps()}
                className="conclusion-table--head--cell"
                key={`headerProp-${column.id}`}
              >
                <Typography color="primary-white" variant="title-3">
                  {column.render('Header')}
                </Typography>
              </th>
            ))}
            <th className="conclusion-table--head--cell">&nbsp;</th>
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map(row => {
          prepareRow(row)
          return (
            <tr
              {...row.getRowProps()}
              className="conclusion-table--body--row"
              key={`rowProp-${row.id}`}
            >
              {row.cells.map(cell => {
                if (
                  editableConclusion.id === row.original.id &&
                  cell.column.isEditable
                ) {
                  return (
                    <td
                      {...cell.getCellProps()}
                      className="conclusion-table--body--row--cell"
                    >
                      <TextField
                        maxLength="255"
                        onChange={e => onChangeKeyword(e, cell.column.id)}
                        onKeyDown={onSearchKeyword}
                        rounded={false}
                        value={editableConclusion[cell.column.id]}
                      />
                    </td>
                  )
                } else {
                  return (
                    <td
                      {...cell.getCellProps()}
                      className="conclusion-table--body--row--cell conclusion-table--body--row--cell--clickable"
                      onClick={() => handleClickEditRow(row)}
                    >
                      <Typography color="primary-dark" variant="body-text-2">
                        {cell.render('Cell')}
                      </Typography>
                    </td>
                  )
                }
              })}
              <td className="conclusion-table--body--row--cell">
                <div className="conclusion-table--body--row--cell--actions">
                  <Button
                    onClick={() => deleteConclusion(row)}
                    variant="default-link"
                  >
                    <Icon icon="trash" />
                  </Button>
                </div>
              </td>
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

ConclusionsTable.defaultProps = {
  conclusions: [],
}

ConclusionsTable.propTypes = {
  conclusions: PropTypes.arrayOf(PropTypes.shape({})),
  onDeleteConclusion: PropTypes.func.isRequired,
  onModifyConclusion: PropTypes.func.isRequired,
}

export default ConclusionsTable

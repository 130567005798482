import PropTypes from 'prop-types'
import React, { useState } from 'react'

import {
  AppearanceDocuments,
  ConfirmBar,
  QuotationShares,
  Typography,
  Image,
  Button,
  AppearanceTagsSelectField,
} from 'components'
import { useAddAppearanceDocument, useResetAppearanceShares } from 'hooks'

import QuotationCardFooter from './QuotationCardFooter'
import QuotationCardHeader from './QuotationCardHeader'

import './quotationCard.scss'
import AddQuotationDocumentForm from "../AddQuotationDocumentForm"

const QuotationCard = ({
   interactions,
   appearanceArchiveUrl,
   appearanceContentId,
   appearanceId,
   appearanceImageUrl,
   appearanceIsFlaggedOnFacebook,
   appearanceSource,
   appearanceTitle,
   appearanceUrl,
   appearanceUrlType,
   appearancesChecked,
   channelDisplayName,
   channelId,
   conclusionLabel,
   conclusionScore,
   contentType,
   documents,
   linkingContent,
   interactionNumber,
   isFactcheckerAuthorized,
   isLoggedIn,
   onCheck,
   onDeleteAppearanceVerdict,
   onModifyAppearanceVerdict,
   onReattributeAppearanceVerdict,
   onRefetchClaimAppearances,
   onResetAppearanceSharesVerdict,
   sharesNumber,
   showHeader,
 }) => {
  const [isSharesDocumentsListOpen, setIsSharesDocumentsListOpen] = useState(false)

  const [showAppearanceDropdownActions, setShowAppearanceDropdownActions] =
    useState(false)

  const toggleAppearanceDrodownActions = () => {
    setShowAppearanceDropdownActions(!showAppearanceDropdownActions)
  }
  const onCheckboxClick = () => {
    onCheck({
      id: appearanceId,
      url: appearanceUrl,
    })
  }
  const isAppearanceChosen = () => {
    if (
      appearancesChecked.map(appearance => appearance.id).includes(appearanceId)
    ) {
      return true
    }
    return false
  }

  const [editAppearance, setEditAppearance] = useState(false)
  const toggleEditAppearance = () => {
    setEditAppearance(!editAppearance)
  }

  const handleEditAppearanceClick = () => {
    toggleEditAppearance()
    setShowAppearanceDropdownActions(false)
  }

  const [editTagLabel, setEditTagLabel] = useState(conclusionLabel)
  const onChooseTagLabel = value => {
    setEditTagLabel(value)
  }

  const onEditAppearance = () => {
    const appearance = {
      id: appearanceId,
      tag: editTagLabel,
    }
    onModifyAppearanceVerdict(appearance)
    toggleEditAppearance()
  }

  const onDeleteAppearance = () => {
    onDeleteAppearanceVerdict(appearanceId)
  }

  const onReattributeAppearance = () => {
    onReattributeAppearanceVerdict(appearanceId)
  }

  const resetAppearanceShares = useResetAppearanceShares()
  const [isOnSynchronizingShares, setIsOnSynchronizingShares] = useState(false)
  const [showAppearanceSharesReseted, setShowAppearanceSharesReseted] =
    useState(false)
  const [appearanceSharesResetedMessage, setAppearanceSharesResetedMessage] =
    useState('')
  const toggleShowAppearanceSharesReseted = () => {
    setShowAppearanceSharesReseted(!showAppearanceSharesReseted)
  }
  const onResetAppearanceShares = async () => {
    setIsOnSynchronizingShares(true)
    resetAppearanceShares.mutate(appearanceId, {
      onSuccess: result => {
        setIsOnSynchronizingShares(false)
        onRefetchClaimAppearances()
        if (Number(result) > 0) {
          setAppearanceSharesResetedMessage(`${result} shares synchronized`)
        } else {
          setAppearanceSharesResetedMessage('No share has been found')
        }
        toggleShowAppearanceSharesReseted()
        setTimeout(() => {
          setShowAppearanceSharesReseted(false)
        }, 3000)
      },
    })
  }

  // Add appearance document
  const [showAddQuotationDocument, setShowAddQuotationDocument] =
    useState(false)
  const toggleShowAddQuotationDocument = () => {
    setShowAddQuotationDocument(!showAddQuotationDocument)
  }
  const closeShowAddQuotationDocument = () => {
    setShowAddQuotationDocument(false)
  }

  const addAppearanceDocument = useAddAppearanceDocument()
  const onAddAppearanceDocument = async data => {
    addAppearanceDocument.mutate(data, {
      onSuccess: result => {
        closeShowAddQuotationDocument()
        onRefetchClaimAppearances()
      }
    })
  }

  // Appearance documents
  const [isDocumentsListOpen] = useState(false)

  const onDropdownBlur = e => {
    if (!e.currentTarget.contains(e.relatedTarget)) {
      setShowAppearanceDropdownActions(false)
    }
  }

  return (
    <>
      <div className="quotation-card">
        <div className="quotation-card--image">
          <Image
            defaultImage={contentType === 'media' ? 'media' : 'default'}
            name="content"
            url={appearanceImageUrl}
          />
        </div>
        <div className="quotation-card--body">
          <div className="quotation-card--body--section">
            {showHeader && (
              <QuotationCardHeader
                appearanceArchiveUrl={appearanceArchiveUrl}
                appearanceId={appearanceId}
                appearanceIsFlaggedOnFacebook={appearanceIsFlaggedOnFacebook}
                appearanceSource={appearanceSource}
                appearanceUrl={appearanceUrl}
                appearanceUrlType={appearanceUrlType}
                appearancesChecked={appearancesChecked}
                channelDisplayName={channelDisplayName}
                channelId={channelId}
                contentType={contentType}
                editAppearance={editAppearance}
                handleEditAppearanceClick={handleEditAppearanceClick}
                isAppearanceChosen={isAppearanceChosen}
                isFactcheckerAuthorized={isFactcheckerAuthorized}
                isLoggedIn={isLoggedIn}
                onAddDocument={toggleShowAddQuotationDocument}
                onCheck={onCheck}
                onCheckboxClick={onCheckboxClick}
                onDeleteAppearance={onDeleteAppearance}
                onDeleteAppearanceVerdict={onDeleteAppearanceVerdict}
                onDropdownBlur={onDropdownBlur}
                onReattributeAppearance={onReattributeAppearance}
                onResetAppearanceShares={onResetAppearanceShares}
                onResetAppearanceSharesVerdict={onResetAppearanceSharesVerdict}
                showAppearanceDropdownActions={showAppearanceDropdownActions}
                toggleAppearanceDrodownActions={toggleAppearanceDrodownActions}
                toggleEditAppearance={toggleEditAppearance}
              />
            )}

            <div className="quotation-card--body--section--content">
              <Typography
                className="quotation-card--body--section--content--title"
                variant="body-text-2"
              >
                {appearanceTitle ? appearanceTitle : 'No title'}
              </Typography>
            </div>
          </div>
          {editAppearance ? (
            <div className="quotation-card--body--edit-footer">
              <div className="quotation-card--body--edit-footer--select-field">
                <AppearanceTagsSelectField
                  defaultValue={editTagLabel}
                  inputRef="quotation-card--body--edit-footer--tags-select"
                  onChoose={e => onChooseTagLabel(e.target.value)}
                  undefinedText="Choose your rating"
                />
              </div>
              <Button onClick={onEditAppearance} variant="ghost">
                <Typography variant="title-1">Save</Typography>
              </Button>
            </div>
          ) : (
            <QuotationCardFooter
              appearanceArchiveUrl={appearanceArchiveUrl}
              conclusionLabel={conclusionLabel}
              conclusionScore={conclusionScore}
              documents={documents}
              interactionNumber={interactionNumber}
              isDocumentsListOpen={isDocumentsListOpen}
              isLoggedIn={isLoggedIn}
              isOnSynchronizingShares={isOnSynchronizingShares}
              isSharesDocumentsListOpen={isSharesDocumentsListOpen}
              setEditAppearance={setEditAppearance}
              setIsSharesDocumentsListOpen={setIsSharesDocumentsListOpen}
              sharesNumber={sharesNumber}
              showHeader={showHeader}
            />
          )}
        </div>
      </div>
      {isSharesDocumentsListOpen && (
        <div className="claim-card-desktop--links-list">
          <QuotationShares
            appearanceId={appearanceId}
            interactions={interactions}
            isLoggedIn={isLoggedIn}
          />
          { documents.length > 0 && (
            <AppearanceDocuments
              contentDocuments={documents}
            />
          )}
        </div>
      )}

      <ConfirmBar
        onClose={toggleShowAppearanceSharesReseted}
        show={showAppearanceSharesReseted}
        text={appearanceSharesResetedMessage}
      />

      <AddQuotationDocumentForm
        contentId={appearanceContentId}
        onAddAppearanceDocument={onAddAppearanceDocument}
        onClose={closeShowAddQuotationDocument}
        show={showAddQuotationDocument}
      />

    </>
  )
}

export default QuotationCard

QuotationCard.defaultProps = {
  appearanceArchiveUrl: null,
  appearanceContentId: null,
  appearanceId: null,
  appearanceImageUrl: null,
  appearanceIsFlaggedOnFacebook: true,
  appearanceTitle: null,
  appearanceUrl: null,
  appearanceUrlType: null,
  appearancesChecked: [],
  channelDisplayName: null,
  channelId: null,
  conclusionLabel: null,
  conclusionScore: null,
  interactionNumber: null,
  isFactcheckerAuthorized: false,
  isLoggedIn: false,
  onCheck: () => null,
  onDeleteAppearanceVerdict: () => null,
  onModifyAppearanceVerdict: () => null,
  onReattributeAppearanceVerdict: () => null,
  onRefetchClaimAppearances: () => null,
  onResetAppearanceSharesVerdict: () => null,
  sharesNumber: null,
  showHeader: true,
}

QuotationCard.propTypes = {
  appearanceArchiveUrl: PropTypes.string,
  appearanceContentId: PropTypes.string,
  appearanceId: PropTypes.string,
  appearanceImageUrl: PropTypes.string,
  appearanceIsFlaggedOnFacebook: PropTypes.bool,
  appearanceTitle: PropTypes.string,
  appearanceUrl: PropTypes.string,
  appearanceUrlType: PropTypes.string,
  appearancesChecked: PropTypes.arrayOf(PropTypes.shape({})),
  channelDisplayName: PropTypes.string,
  channelId: PropTypes.string,
  conclusionLabel: PropTypes.string,
  conclusionScore: PropTypes.number,
  interactionNumber: PropTypes.number,
  isFactcheckerAuthorized: PropTypes.bool,
  isLoggedIn: PropTypes.bool,
  onCheck: PropTypes.func,
  onDeleteAppearanceVerdict: PropTypes.func,
  onModifyAppearanceVerdict: PropTypes.func,
  onReattributeAppearanceVerdict: PropTypes.func,
  onRefetchClaimAppearances: PropTypes.func,
  onResetAppearanceSharesVerdict: PropTypes.func,
  sharesNumber: PropTypes.number,
  showHeader: PropTypes.bool,
}

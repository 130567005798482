import React from 'react'

import { FactcheckerAddReview } from 'components'

const FactcheckerAddReviewPage =({ auth }) => {
  return (
    <FactcheckerAddReview user={auth.user} />
  )
}

export default FactcheckerAddReviewPage


import React, { useContext } from 'react'

import Typography from 'components/Atomic/Atoms/Typography'

import { tabContext } from './TabsContext'

export const Tab = ({
  className,
  text,
  value,
  dataCy,
  children,
  callback = () => null,
}) => {
  const [tabValue, setTabValue] = useContext(tabContext)

  return (
    <div
      className={`${className} ${
        value === tabValue ? `${className}-isActive` : ''
      } `}
      data-cy={dataCy}
      onClick={() => {
        callback(value)
        setTabValue(value)
      }}
      role="button"
      style={{ cursor: 'pointer' }}
    >
      {children ? (
        children
      ) : (
        <Typography
          color="secondary-grey"
          variant={value === tabValue ? 'title-3' : 'body-text-2'}
        >
          {text}
        </Typography>
      )}
    </div>
  )
}

export default Tab

import PropTypes from 'prop-types'
import React from 'react'
import { PieChart } from 'react-minimal-pie-chart'

import BackGround from './background.svg'
import './interactionCircle.scss'

const InteractionCircle = ({ data, label, startAngle, unit, variant }) => (
  <div className={`circleColored circleColored-${variant}`}>
    <img
      alt=""
      className={`circleColored--bg circleColored--bg-${variant}`}
      src={BackGround}
    />
    <span className="circleColored--label">
      <span
        className={`circleColored--label--value circleColored--label--value-${variant}`}
      >
        {label}
      </span>
      <span
        className={`circleColored--label--unit circleColored--label--unit-${variant}`}
      >
        {unit}
      </span>
    </span>
    <PieChart data={data} lineWidth={22} startAngle={startAngle} />
  </div>
)

export default InteractionCircle

InteractionCircle.defaultProps = {
  startAngle: -90,
  unit: 'Int.',
  variant: 'small',
  data: [],
}

InteractionCircle.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  startAngle: PropTypes.number,
  unit: PropTypes.string,
  variant: PropTypes.oneOf(['small', 'big']),
}

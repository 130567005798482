import { useQuery } from 'react-query'

import { API_URL } from 'utils/config'

import { useAxios } from '../index'
export default function useExpertiseTag(search) {
  const axios = useAxios()

  const getExpertiseTag = async search => {
    const { data } = await axios.get(
      `${API_URL}/tags?tag-start=${search}&type=EXPERTISE`
    )
    return data
  }

  return useQuery(['expertise-tag', search], () => getExpertiseTag(search))
}

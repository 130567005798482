const normalizeShare = share => {
  return {
    shareDate: share.linkingContent?.publishedDate,
    shareArchiveUrl: share.linkingContent?.archiveUrl,
    shareId: share.id,
    shareTitle: share.linkingContent?.title,
    shareSummary:
      share.linkingContent?.summary?.length > 100
        ? `${share.linkingContent?.summary.substring(0, 100)}...`
        : share.linkingContent?.summary,
    shareUrl: share.linkingContent?.url,
    channelDisplayName: share.channelDisplayName,
    channelId: share.linkingContent?.channel?.id,
    channelUrl: share.linkingContent?.channel?.url,
    id: share.id,
    interactionNumber: share.linkingContent?.totalInteractions,
    sharesNumber: share.linkingContent?.sharesCount,
  }
}

export default normalizeShare

import { useMutation } from 'react-query'

import { useAxios } from 'hooks'

import { API_URL } from '../../utils/config'

export default function useAddAppearances(data) {
  const instances = useAxios()
  const addAppearance = async addAppearancesData => {
    const { data } = await instances.post(
      `${API_URL}/claims/${addAppearancesData.claimId}/appearances`,
      addAppearancesData.appearancesData
    )
    return data
  }
  return useMutation(data => addAppearance(data), data)
}

import PropTypes from 'prop-types'
import React, {
  useEffect,
  useState,
} from "react"
import { useFormContext } from 'react-hook-form'

import {
  Button,
  Icon,
  Typography,
} from 'components'

import AppearancesForm from './AppearancesForm'
import AppearancesList from './AppearancesList'


const AppearancesSection = ({
  appearances,
  append,
  update,
}) => {
  const [showAdd, setShowAdd] = useState(false)
  const {
    clearErrors,
    setError,
    trigger,
  } = useFormContext()

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (showAdd) {
      setError('appearances', { type: 'required' })
    } else {
      clearErrors('appearances')
      trigger()
    }
  }, [showAdd, setError])

  return (
    <>
      <Typography
        className="create-review--section-title"
        variant="h2"
      >
        4. Appearances
        <Button
          onClick={() => setShowAdd(!showAdd)}
          type="button"
          variant={showAdd ? "light" : "ghost"}
        >
          {showAdd ? <>Close <Icon icon="cross" /></> : "Add"}
        </Button>
      </Typography>
      <div className="create-review--section">
        {showAdd ? (
          <AppearancesForm
            appearances={appearances}
            append={append}
            onAdd={() => setShowAdd(false)}
            update={update}
          />
        ) : (
          <AppearancesList appearances={appearances} />
        )}
      </div>
    </>
  )
}


AppearancesSection.propTypes = {
  appearances: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    url: PropTypes.string,
  })),
  append: PropTypes.func,
  update: PropTypes.func,
}

AppearancesSection.defaultProps = {
  appearances: [],
  append: () => {},
  update: () => {},
}

export default AppearancesSection

import PropTypes from 'prop-types'
import React, { useState } from 'react'

import { Badge, Select, Typography } from 'components/index'
import { useChannels } from 'hooks'

export const SearchChannel = ({
  channelSelected,
  getValues,
  setChannelSelected,
  setShowAdd,
  setValue,
  setValueIdLocation,
  setValueNameLocation,
}) => {
  const [search, setSearch] = useState('')
  const { data, isLoading } = useChannels(search)
  const defaultValue = getValues(setValueIdLocation)
    ? getValues(setValueIdLocation)
    : null

  const options = data?.pages[0].data || []
  const onSearch = search => {
    setSearch(search)
  }
  const onChange = value => {
    setValue(setValueIdLocation, value.id)
    setValue(setValueNameLocation, value.name)
    setChannelSelected(value)
  }

  const onAdd = () => {
    setShowAdd(true)
  }

  return (
    <div className="add-new-quotation--row">
      <div className="add-new-quotation--row--label">
        <Typography variant="title-3">Related channel:</Typography>
      </div>

      <Select
        addButton
        addButtonText="Add channel"
        defaultValue={defaultValue}
        getOptionLabel={option =>
          option.name
            ? `${option.name} - ${option.url} (${option.appearancesCount} appearances)`
            : ''
        }
        getOptionValue={option => option.id}
        isLoading={isLoading}
        isSearchable
        onAdd={onAdd}
        onChange={onChange}
        onSearch={onSearch}
        options={options}
        placeholder="Search for a channel"
      />

      {channelSelected.name && (
        <Badge
          onDelete={() => onChange({ id: null, name: null })}
          text={`${channelSelected.name} - ${channelSelected.url}`}
        />
      )}
    </div>
  )
}


SearchChannel.propTypes = {
  channelSelected: PropTypes.shape({
    name: PropTypes.string,
    url: PropTypes.string,
  }),
  getValues: PropTypes.func,
  setChannelSelected: PropTypes.func,
  setShowAdd: PropTypes.func,
  setValue: PropTypes.func,
  setValueIdLocation: PropTypes.string,
  setValueNameLocation: PropTypes.string,
}

SearchChannel.defaultProps = {
  channelSelected: {
    name: null,
    url: null,
  },
  getValues: () => {},
  setChannelSelected: () => {},
  setShowAdd: () => {},
  setValue: () => {},
  setValueIdLocation: "linkingContent.channelId",
  setValueNameLocation: "linkingContent.channelName",
}


export default SearchChannel

import React from 'react'
import { useSearchParams } from 'react-router-dom'

import { ResetPasswordPage } from 'components'

const ResetPassword = () => {
  const [searchParams] = useSearchParams()
  const token = searchParams.get('token') || ''

  return (
    <ResetPasswordPage
      token={token}
    />
  )
}

export default ResetPassword

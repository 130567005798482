export default function useRoles(auth) {
  let roles = []
  if (auth.user) {
    roles = auth.user.roles.map(role => role.type.label)
  }

  const isEditor = roles.includes('editor')
  const isScientist = roles.includes('scientist')
  const isFactchecker = roles.includes('factchecker')

  return {
    roles,
    isEditor,
    isScientist,
    isFactchecker,
  }
}

import capitalize from 'utils/capitalize'

import { numberShortener } from '../../utils/shorteners'
import normalizeInteractionDataAndStats from './interactionDataAndStats'

const normalizeSource = (source, dataType = 'all') => {
  return {
    ...normalizeInteractionDataAndStats(source, dataType),
    channelType: source.channelType,
    factcheckingUsers: source.factcheckingUsers,
    id: source.id,
    sourceType: capitalize(source.sourceType),
    sourceName: source.name,
    linkNumber: source.appearancesCount,
    misinformationLinkNumber: source.misinformationAppearancesCount,
    interactionNumber: numberShortener(source.appearancesTotalInteractionsSum),
    misinformationInteractionNumber: numberShortener(
      source.misinformationsAppearancesTotalInteractionsSum
    ),
    mbfcUrl: source.mbfcUrl,
    mbfcFactualReporting: source.mbfcFactualReporting,
    mbfcScore: source.mbfcScore,
    mediaCount: source.mediaCount,
    media: source.media
      ? source.media.map(media => normalizeSource(media))
      : null,
    medium: source.medium,
    name: source?.name,
    organization: source.organization,
    channelsCount: source.channelsCount,
    channels: source.channels
      ? source.channels.map(channel => normalizeSource(channel))
      : null,
    owners: source.owners,
    url: source.url,
    wikidataIdentifier: source.wikidataIdentifier,
  }
}

export default normalizeSource

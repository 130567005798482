import PropTypes from 'prop-types'
import React, {
  useMemo,
  useState,
} from 'react'

import {
  Divider,
  Loader,
  Typography,
} from 'components'
import { useFactcheckers } from 'hooks'
import capitalize from 'utils/capitalize'

import './factcheckerAddReview.scss'

import FactcheckLoad from "./FactcheckLoad"
import ReviewForm from "./ReviewForm"

const FactcheckerAddReview = ({ user }) => {
  const { data: factcheckers, error, isLoading } = useFactcheckers(user.id)
  const [factcheck, setFactcheck] = useState({})
  const [isFormClear, setIsFormClear] = useState(false)
  const factchecker = useMemo(() => factcheckers?.[0], [factcheckers])

  if (isLoading) {
    return (
      <div className="factchecker-add-review">
        <Loader />
      </div>
    )

  }

  if (error?.response?.status >= 400 || (!isLoading && !factchecker)) {
    return (
      <div className="factchecker-add-review">
        <Typography variant="body-text-2">User not authorized for this page.</Typography>
      </div>
    )
  }


  return (
    <div className="factchecker-add-review">
      <div className="factchecker-add-review--column">
        <Typography variant="h2">1. Url of your review/fact-check article</Typography>
        <Typography variant="body-text-2">
          Factchecker: {capitalize((factchecker?.name || ''), true)}
        </Typography>

        <FactcheckLoad
          factcheck={factcheck}
          factchecker={factchecker}
          isFormClear={isFormClear}
          setFactcheck={setFactcheck}
          setIsFormClear={setIsFormClear}
        />
      </div>

      <Divider
        borderStyle="dashed"
        className="factchecker-add-review--divider"
        orientation="vertical"
        thickness="thin"
      />

      <div className="factchecker-add-review--column">
        <ReviewForm
          factcheck={factcheck}
          factchecker={factchecker}
          isFormClear={isFormClear}
          setIsFormClear={setIsFormClear}
        />
      </div>
    </div>
  )
}


FactcheckerAddReview.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string,
    channel: PropTypes.shape({
      name: PropTypes.string,
      scrappable: PropTypes.bool,
      url: PropTypes.string,
    }),
  }).isRequired,
}


export default FactcheckerAddReview

import PropTypes from 'prop-types'
import React, {
  useCallback,
  useEffect,
  useState,
} from 'react'
import {
  FormProvider,
  useFieldArray,
  useForm,
} from 'react-hook-form'

import {
  Badge,
  Button,
  Checkbox,
  ConfirmBar,
  Divider,
  Dropdown,
  SearchConclusion,
  SearchPerson,
  SearchTopic,
  TextAreaField,
  Typography,
} from 'components'
import {
  useCreateFactcheck,
} from 'hooks'
import capitalize from 'utils/capitalize'

import AppearancesSection from './AppearancesSection'
import ConfirmFactcheckImported from './ConfirmFactcheckImported'

import './reviewForm.scss'

const LANGUAGE_OPTIONS = [
  { value: 'English', text: 'English' },
  { value: 'French', text: 'French' },
  { value: 'German', text: 'German' },
  { value: 'Spanish', text: 'Spanish' },
]

const SCORE_OPTIONS = [
  { value: null, text: '' },
  { value: 0, text: 0 },
  { value: 1, text: 1 },
  { value: 2, text: 2 },
  { value: 3, text: 3 },
  { value: 4, text: 4 },
  { value: 5, text: 5 },
]

const DEFAULT_VALUES = {
  alternateName: null,
  claimant: {
    author: null,
    authorType: null,
    id: null,
  },
  claimReviewed: '',
  conclusion: {
    standardForm: null,
  },
  datePublished: null,
  factchecker: null,
  linkedUrls: null,
  ratingValue: null,
  reset: false,
  title: '',
  topicTags: null,
  url: '',
}

const ReviewForm = ({
  factcheck,
  factchecker,
  isFormClear,
  setIsFormClear,
}) => {
  const methods = useForm({
    defaultValues: {
      ...DEFAULT_VALUES,
      factchecker,
    },
    mode: 'onChange'
  })
  const {
    alternateName,
    appearances,
    claimant,
    claimReviewed,
    conclusion,
    datePublished,
    linkedUrls,
    ratingValue,
    raw,
    title,
    topicTags,
    url,
  } = factcheck

  const {
    control,
    formState: {
      errors,
      isLoading,
      isSubmitting,
      isValid,
    },
    getValues,
    setValue,
    register,
    trigger,
    watch,
  } = methods

  const {
    append,
    fields,
    replace,
    update,
  } = useFieldArray({
    control,
    name: 'appearances',
  })

  const createFactcheck = useCreateFactcheck()

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (isFormClear) {
      setConclusionWithoutScore(false)
      methods.reset(DEFAULT_VALUES)
      replace([])
    } else {
      // Reset cannot be used here due to a bug with resetting the topic tags field
      setValue('alternateName', getValues('alternateName') || alternateName)
      setValue('claimant.author', getValues('claimant.author') || claimant?.author)
      setValue('claimant.authorType', getValues('claimant.authorType') || claimant?.authorType)
      setValue('claimant.id', getValues('claimant.id') || claimant?.id)
      setValue('claimReviewed', getValues('claimReviewed') || claimReviewed)
      setValue('conclusion.standardForm', getValues('conclusion.standardForm') || conclusion?.standardForm)
      setValue('datePublished', getValues('datePublished') || datePublished)
      setValue('linkedUrls', getValues('linkedUrls') || linkedUrls)
      setValue('ratingValue', getValues('ratingValue') || ratingValue)
      setValue('raw', getValues('raw') || raw)
      setValue('url', getValues('url') || url)
      setValue('title', getValues('title') || title)
      setValue('topicTags', getValues('topicTags') || topicTags)
      trigger()
    }
  }, [
    alternateName,
    appearances,
    claimant,
    claimReviewed,
    datePublished,
    isFormClear,
    ratingValue,
    replace,
    title,
    topicTags,
    trigger,
    url,
  ])

  const [showConfirmFactcheckImported, setShowConfirmFactcheckImported] = useState(false)
  const toggleShowConfirmFactcheckImported = () => setShowConfirmFactcheckImported(!showConfirmFactcheckImported)
  const [factcheckImportedId, setFactcheckImportedId] = useState(null)

  const [errorMessage, setErrorMessage] = useState(null)

  const onSubmitForm = useCallback(values => {
    createFactcheck.mutate(values, {
      onSuccess: data => {
        setFactcheckImportedId(data["id"])
        toggleShowConfirmFactcheckImported()
      },

      onError: err => {
        const errorMessage = (Object.entries(err.response.data?.[0] || {})
                                    .map(([error, message]) => `${capitalize(error)}: ${message}`) || ["Unknown error"])
                                    ?.[0]
        setErrorMessage(errorMessage)
        setTimeout(() => {
          setErrorMessage(null)
        }, 5000)
      }
    })
    setIsFormClear(true)
  }, [createFactcheck, setIsFormClear])

  const [conclusionWithoutScore, setConclusionWithoutScore] = useState(false)

  const onCheckboxClick = useCallback(() => {
    setConclusionWithoutScore(!conclusionWithoutScore)
    if (!conclusionWithoutScore) {
      setValue('ratingValue', null)
    }
  }, [conclusionWithoutScore, setValue])

  const standardForm = watch('conclusion.standardForm')
  const onStandardFormDelete = useCallback(() => {
    setValue('conclusion.standardForm', null)
  }, [setValue])

  const onStandardFormSelectChange = useCallback(value => {
    setValue('conclusion.standardForm', value.standardForm)
  }, [setValue])

  const onStandardFormAdd = useCallback(value => {
    setValue('conclusion.standardForm', value)
  }, [setValue])

  return (
    <FormProvider {...methods}>
      <form
        className="create-review--form"
        onSubmit={methods.handleSubmit(onSubmitForm)}
      >
        <Typography variant="h2">2. Review details</Typography>

        <div className="create-review--section">
          <Typography variant="title-3">
            Title*
          </Typography>
          <TextAreaField
            maxLength="2000"
            placeholder="Headline of your article"
            register={methods.register}
            registerName="title"
            registerValidation={{ required: true }}
            rounded={false}
            rows={4}
          />
        </div>

        <div className="create-review--section">
          <Typography variant="title-3">
            Claim*
          </Typography>
          <TextAreaField
            maxLength="2000"
            placeholder="The claim being reviewed in your article"
            register={methods.register}
            registerName="claimReviewed"
            registerValidation={{ required: true }}
            rounded={false}
            rows={4}
          />
        </div>

        <div className="create-review--section">
          <Typography variant="title-3">
            Claimant:
          </Typography>
          <SearchPerson
            labelField="claimant.author"
            valueField="claimant.id"
          />
        </div>

        <div className="create-review--section">
          <Typography variant="title-3">
            Language*:
          </Typography>
          <Dropdown
            className="create-review--dropdown"
            defaultValue='English'
            options={LANGUAGE_OPTIONS}
            {...register('verdictLanguage')}
          />
        </div>

        <div className="create-review--section">
          <Typography className="review-details--title" variant="title-3">
            Topic tags:
          </Typography>
          <SearchTopic />
        </div>

        <Divider className="create-review--divider" />

        <Typography variant="h2">3. Verdict</Typography>
        <div className="create-review--section">
          <Typography variant="title-1">
            Factchecker verdict*
          </Typography>
          <TextAreaField
            maxLength="2000"
            placeholder="Write here"
            register={methods.register}
            registerName="alternateName"
            registerValidation={{ required: true }}
            rounded={false}
            rows={4}
          />
        </div>

        <div className="create-review--section">
          <Typography variant="title-3">
            Standard form:
          </Typography>
          <SearchConclusion
            addButton={true}
            onAdd={onStandardFormAdd}
            onChange={onStandardFormSelectChange}
          />
          {standardForm && (
            <Badge
              onDelete={onStandardFormDelete}
              text={standardForm}
            />
          )}

          <Typography variant="title-3">
            Score:
          </Typography>

          <Dropdown
            className="create-review--dropdown"
            defaultValue={ratingValue}
            disabled={conclusionWithoutScore}
            options={SCORE_OPTIONS}
            {...register('ratingValue')}
          />

          <div className="create-review--noscore">
            <Checkbox
              checked={conclusionWithoutScore}
              onChange={onCheckboxClick}
            />
            <Typography variant="body-text-2">
              Conclusion without score
            </Typography>
          </div>
        </div>

        <Divider className="create-review--divider" />

        <AppearancesSection
          appearances={fields}
          append={append}
          update={update}
        />

        <Divider className="create-review--divider" />

        <div className="create-review--submit">
          <Button
            disabled={Object.keys(errors).length || isLoading || isSubmitting || !isValid}
            type="submit"
            variant="ghost"
          >
            Add this review →
          </Button>
        </div>
      </form>
      <ConfirmBar
        onClose={() => setErrorMessage(null)}
        show={errorMessage ? true : false}
        text={errorMessage}
      />
      <ConfirmFactcheckImported
        onClose={toggleShowConfirmFactcheckImported}
        reviewId={factcheckImportedId}
        show={showConfirmFactcheckImported}
      />
    </FormProvider>
  )
}

ReviewForm.propTypes = {
  factcheck: PropTypes.shape({
    alternateName: PropTypes.string,
    appearances: PropTypes.arrayOf(PropTypes.shape({})),
    claimant: PropTypes.shape({
      author: PropTypes.string,
      authorType: PropTypes.string,
      id: PropTypes.string,
    }),
    claimReviewed: PropTypes.string,
    conclusion: PropTypes.shape({
      standardForm: PropTypes.string,
    }),
    datePublished: PropTypes.string,
    linkedUrls: PropTypes.arrayOf(PropTypes.string),
    ratingValue: PropTypes.number,
    raw: PropTypes.string,
    title: PropTypes.string,
    topicTags: PropTypes.arrayOf(PropTypes.string),
    url: PropTypes.string,
  }),
  factchecker: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    url: PropTypes.string,
  }).isRequired,
  isFormClear: PropTypes.bool,
  setIsFormClear: PropTypes.func,
}

ReviewForm.defaultProps = {
  factcheck: {
    alternateName: null,
    appearances: [],
    claimant: {},
    claimReviewed: '',
    conclusion: {},
    datePublished: null,
    linkedUrls: null,
    ratingValue: null,
    raw: null,
    title: '',
    topicTags: null,
    url: '',
  },
  isFormClear: false,
  setIsFormClear: () => {},
}

export default ReviewForm

import PropTypes from 'prop-types'
import React from 'react'

import { Button, Icon, Metric, ConclusionTag } from 'components'
import { numberShortener } from 'utils/shorteners'

import './appearanceCardFooter.scss'

const AppearanceCardFooter = ({
  conclusionLabel,
  conclusionScore,
  interactionNumber,
  setIsClaimsListOpen,
  isClaimsListOpen,
}) => {
  return (
    <div className="appearance-card-footer">
      <div className="appearance-card-footer--group-left">
        {interactionNumber !== undefined && interactionNumber > 0 && (
          <Metric
            number={numberShortener(interactionNumber)}
            text="interactions"
          />
        )}
      </div>
      <div className="appearance-card-footer--group-right">
        <Button
          onClick={() => setIsClaimsListOpen(!isClaimsListOpen)}
          variant="default-link"
        >
          <div className="appearance-card-footer--group-left--claims">
            <ConclusionTag label={conclusionLabel} score={conclusionScore} />
            {isClaimsListOpen ? <Icon icon="down" /> : <Icon icon="right" />}
          </div>
        </Button>
      </div>
    </div>
  )
}

export default AppearanceCardFooter

AppearanceCardFooter.defaultProps = {
  conclusionLabel: null,
  conclusionScore: null,
  interactionNumber: null,
}

AppearanceCardFooter.propTypes = {
  conclusionLabel: PropTypes.string,
  conclusionScore: PropTypes.number,
  interactionNumber: PropTypes.number,
}

import PropTypes from 'prop-types'
import React from 'react'
import { useMediaQuery } from 'react-responsive'

import SourcePageDesktop from './SourcePageDesktop'
import SourcePageMobile from './SourcePageMobile'

const SourcePage = source => {
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' })

  if (isMobile) {
    return <SourcePageMobile {...source} />
  } else {
    return <SourcePageDesktop {...source} />
  }
}

export default SourcePage

SourcePage.defaultProps = {
  source: {},
}

SourcePage.propTypes = {
  source: PropTypes.shape({}),
}

import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'

import { Typography, SourceCard, Button, Loader } from 'components'

import './sourcesList.scss'

const SourcesList = ({ sources }) => (
  <div className="sourcesList">
    <div className="sourcesList--dotted-list">
      <div className="sourcesList--dotted-list--header">
        <div className="sourcesList--dotted-list--header--content sourcesList--dotted-list--header--content--center">
          <Typography
            className="sourcesList--dotted-list--header--content--title"
            variant="h2"
          >
            Sources of viral misinformation*
          </Typography>
        </div>
      </div>
      <div className="sourcesList--dotted-list--body">
        <div className="sourcesList--dotted-list--body--content">
          {sources.length > 0 ? (
            sources.map(source => (
              <div
                className="sourcesList--dotted-list--body--content--element"
                key={`source-${source.sourceType}-${source.id}`}
              >
                <SourceCard {...source} variant="misinformation-interactions" />
              </div>
            ))
          ) : (
            <Loader />
          )}
        </div>
      </div>
    </div>

    <div className="sourcesList--asterisk">
      <Typography variant="body-text-4">
        *Information sources ordered by the total number of
        <br />
        social media interactions on their content flagged as misinformation
        (false, misleading or missing context).
      </Typography>
    </div>

    <div className="sourcesList--browse-more-link">
      <Link to="/search?section=sources">
        <Button type="internalLink" variant="ghost">
          Browse more
        </Button>
      </Link>
    </div>
  </div>
)

export default SourcesList

SourcesList.defaultProps = {
  sources: [],
}

SourcesList.propTypes = {
  sources: PropTypes.arrayOf(PropTypes.shape({})),
}

import React from 'react'

import { Typography, Divider } from 'components'

const AboutOpenFeedbackUseDesktop = () => (
  <div className="about-us--desktop--section">
    <div className="about-us--desktop--section--header">
      <Typography variant="h2">How is Open Feedback used?</Typography>
    </div>

    <div className="about-us--desktop--section--content">
      <div className="about-us--desktop--section--content--col">
        <Typography
          className="about-us--desktop--section--content--title"
          variant="title-1"
        >
          Helping scientific research
        </Typography>

        <Typography color="secondary-grey" variant="body-text-2">
          Open Feedback’s objective is to serve as a useful database for
          scientists studying online misinformation. For example,{' '}
          <b>
            <a
              href="https://www.sciencedirect.com/science/article/pii/S0306457321002818"
              rel="noopener noreferrer"
              target="_blank"
            >
              this research article
            </a>
          </b>{' '}
          used the data to identify Facebook pages and groups that repeatedly
          share misinformation articles in their posts. It showed that Facebook
          was penalizing “repeat offender” pages by reducing the distribution of
          their posts after they share 2 or more ‘false’ links within 90 days
          while groups were not affected by the policy in 2020.
        </Typography>
      </div>
      <div className="about-us--desktop--section--content--col">
        <img alt="schema" src="/static/assets/aboutUs/schema.png" />
      </div>
    </div>

    <div className="about-us--desktop--section about-us--desktop--section-sub">
      <div className="about-us--desktop--section--header" />

      <div className="about-us--desktop--section--content">
        <div className="about-us--desktop--section--content--col">
          <Typography
            className="about-us--desktop--section--content--title"
            variant="title-1"
          >
            Helping platforms
          </Typography>

          <Typography color="secondary-grey" variant="body-text-2">
            Open Feedback is used by platforms that need a trusted source
            identifying URLs that have been reviewed for potentially containing
            misleading information.
            <br />
            For example, The Internet Archive’s wayback machine is using Open
            Feedback to identify URLs that are associated with a review and
            display a notice to provide context for their users.
          </Typography>
        </div>

        <div className="about-us--desktop--section--content--divider">
          <Divider borderStyle="dashed" orientation="vertical" />
        </div>

        <div className="about-us--desktop--section--content--col">
          <Typography
            className="about-us--desktop--section--content--title"
            variant="title-1"
          >
            Helping the public assess information
          </Typography>

          <Typography color="secondary-grey" variant="body-text-2">
            The site serves as a pedagogical resource to Internet users who wish
            to verify whether an article they are reading contains information
            that has been reviewed, or whether an information source has a
            record track of making claims that can mislead its audience.
          </Typography>
        </div>
      </div>
    </div>
  </div>
)

export default AboutOpenFeedbackUseDesktop

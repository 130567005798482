import PropTypes from 'prop-types'
import React, { useState } from 'react'

import { Typography, Button } from 'components'
import ContactForm from 'components/Atomic/Organisms/ContactForm'

const AboutDatabaseBuildDesktop = ({ onContact }) => {
  const [show, setShow] = useState(false)
  const toggleContactForm = () => setShow(!show)

  return (
    <div className="about-us--desktop--section">
      <div className="about-us--desktop--section--header">
        <Typography variant="h2">How is the database built?</Typography>
      </div>

      <div className="about-us--desktop--section--content">
        <div className="about-us--desktop--section--content--col">
          <Typography color="secondary-grey" variant="body-text-2">
            Contributors including scientists, journalists and fact-checkers add
            fact-checking articles, claims and appearances to the database and
            ensure that the verdicts and ratings attributed to content are
            accurate.
            <br />
            <br />
            Part of the information displayed on this site comes from the{' '}
            <b>claim review schema</b> filled by fact-checking organizations in
            their articles.
          </Typography>
        </div>
        <div className="about-us--desktop--section--content--col-center">
          <Typography
            className="about-us--desktop--section--content--title"
            variant="title-1"
          >
            *However, keep in mind that this platform hosts user generated
            content and might not be 100% accurate. If you see a mistake, please
            contact us.
          </Typography>
          <Button onClick={toggleContactForm} variant="ghost">
            {' '}
            Send us a message{' '}
          </Button>
        </div>
      </div>
      <ContactForm
        onClose={toggleContactForm}
        onSubmit={onContact}
        show={show}
      />
    </div>
  )
}

export default AboutDatabaseBuildDesktop

AboutDatabaseBuildDesktop.propTypes = {
  onContact: PropTypes.func.isRequired,
}

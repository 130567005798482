import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'

import { Button, ErrorMessage, ConfirmBar, TextField } from 'components'
import { useScrapFactcheck } from 'hooks'
import { validateUrl } from 'validator'

import ConfirmFactcheckScrapped from './ConfirmFactcheckScrapped'
import './factcheckScrapper.scss'

export const FactcheckScrapper = ({ factchecker }) => {
  const [showFactcheckScrapped, setShowFactcheckScrapped] = useState(false)
  const toggleShowFactcheckScrapped = () =>
    setShowFactcheckScrapped(!showFactcheckScrapped)

  const [showFactcheckScrappedError, setShowFactcheckScrappedError] =
    useState(false)
  const toggleShowFactcheckScrappedError = () =>
    setShowFactcheckScrappedError(!showFactcheckScrappedError)

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm()

  const [scrappedFactcheckId, setScrappedFactcheckId] = useState(null)
  const scrapFactcheck = useScrapFactcheck()
  const onScrapFactcheck = async data => {
    scrapFactcheck.mutate(data, {
      onSuccess: data => {
        setScrappedFactcheckId(data.id)
        toggleShowFactcheckScrapped()
        setTimeout(() => {
          setShowFactcheckScrapped(false)
        }, 5000)
      },

      onError: () => {
        toggleShowFactcheckScrappedError()
        setTimeout(() => {
          setShowFactcheckScrappedError(false)
        }, 5000)
      },
    })
  }
  const onSubmitForm = values => {
    const data = {
      ...values,
      factchecker: factchecker.name,
    }
    onScrapFactcheck(data)
  }

  return (
    <>
      <form
        className="factcheck-scrapper"
        onSubmit={handleSubmit(onSubmitForm)}
      >
        <TextField
          maxLength="2000"
          placeholder="Paste url of external factcheck"
          register={register}
          registerName="url"
          registerValidation={{
            required: true,
            maxLength: 2000,
            validate: validateUrl,
          }}
          rounded={false}
        />
        {errors?.url?.type === 'validate' && (
          <ErrorMessage message="Please enter a valid url (e.g https://open.feedback.org)" />
        )}
        {factchecker && !factchecker.scrappable && (
          <ErrorMessage message="Please choose a scrappable factchecker" />
        )}
        <div className="factcheck-scrapper--button">
          <Button
            disabled={!factchecker || !factchecker.scrappable || errors?.url}
            type="submit"
            variant="ghost"
          >
            Scrap
          </Button>
        </div>
      </form>
      <ConfirmBar
        onClose={toggleShowFactcheckScrappedError}
        show={showFactcheckScrappedError}
        text="An error has occured during import"
      />

      <ConfirmFactcheckScrapped
        onClose={toggleShowFactcheckScrapped}
        show={showFactcheckScrapped}
        reviewId={scrappedFactcheckId}
      />
    </>
  )
}

FactcheckScrapper.defaultProps = {
  factchecker: null,
}

FactcheckScrapper.propTypes = {
  factchecker: PropTypes.shape({
    name: PropTypes.string,
    scrappable: PropTypes.bool,
  }),
}

export default FactcheckScrapper

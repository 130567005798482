import PropTypes from 'prop-types'
import React, { useState } from 'react'

import { Button, Divider, ReviewDetails } from 'components'
import AddQuotation from 'components/Business/Quotations/AddQuotation'
import {
  useAddAppearances,
  useClaimSuggestedAppearances,
  useReview,
  useReviewEdition
} from 'hooks'

import './editReview.scss'

const EditReview = ({ reviewId, resetReview, refetchReviews }) => {
  const { data: review, refetch: refetchReview } = useReview({ id: reviewId })

  const editReview = useReviewEdition(reviewId)
  const onMarkReviewAsTreated = () => {
    editReview.mutate({
      'isTreated': true,
    }, {
      onSuccess: () => {
        resetReview()
        refetchReviews()
      }
    })
  }
  
  const [suggestedAppearanceMethod, setSuggestedAppearanceMethod] =
    useState('outerlinks')
  const [suggestedAppearanceSearchValues, setSuggestedAppearanceSearchValues] =
    useState({
      keyword: '',
      fromDate: '',
      toDate: '',
    })
  const {
    data: suggestedAppearances,
    fetchNextPage: fetchNextClaimSuggestedAppearancesPage,
    hasNextPage: hasNextClaimSuggestedAppearancesPage,
    isLoading: claimSuggestedAppearancesAreLoading,
    refetch: refetchClaimSuggestedAppearances,
  } = useClaimSuggestedAppearances({
    claimId: review?.claimId,
    paginate: 10,
    method: suggestedAppearanceMethod,
    values: suggestedAppearanceSearchValues,
  })

  const onModifySuggestedAppearanceSearch = (search, values) => {
    setSuggestedAppearanceMethod(search)
    setSuggestedAppearanceSearchValues(values)
    refetchClaimSuggestedAppearances()
  }

  const addAppearances = useAddAppearances()
  const onAddAppearance = async appearanceData => {
    const data = {
      claimId: review.claimId,
      appearancesData: [
        {
          link: {
            linkedClaimId: review.claimId,
          },
          ...appearanceData,
        },
      ],
    }
    addAppearances.mutate(data, {
      onSuccess: () => refetchReview(),
    })
  }

  const onAddSuggestedAppearances = async appearancesData => {
    const data = {
      claimId: review.claimId,
      appearancesData: appearancesData.map(appearanceDatum => ({
        link: {
          linkedClaimId: review.claimId,
        },
        ...appearanceDatum,
      })),
    }

    addAppearances.mutate(data, {
      onSuccess: () => {
        refetchReview()
        refetchClaimSuggestedAppearances()
      },
    })
  }

  if (!review) return null

  return (
    <div className="reviews-with-no-appearances-page--review-profile">
      <ReviewDetails
        claimantId={review.claimantId}
        claimantName={review.claimBy}
        conclusionScore={review.conclusionScore}
        conclusionStandardForm={review.conclusionStandardForm}
        factcheckerVerdict={review.factcheckerVerdict}
        fullReviewLink={review.reviewUrl}
        id={review.id}
        initialClaim={review.initialClaim}
        isLoggedIn
        linkToPage
        reviewBy={review.reviewBy}
        reviewByUrl={review.reviewByUrl}
        reviewTitle={review.review}
        standardClaim={review.standardClaim}
        topics={review.topicTags}
      />

      <AddQuotation
        claimSuggestedAppearancesAreLoading={
          claimSuggestedAppearancesAreLoading
        }
        fetchNextClaimSuggestedAppearancesPage={
          fetchNextClaimSuggestedAppearancesPage
        }
        hasNextClaimSuggestedAppearancesPage={
          hasNextClaimSuggestedAppearancesPage
        }
        onAddSuggestedAppearances={onAddSuggestedAppearances}
        onModifySuggestedAppearanceSearch={onModifySuggestedAppearanceSearch}
        onSubmit={onAddAppearance}
        show
        suggestedAppearances={suggestedAppearances?.data}
      />

      <Divider className="reviews-with-no-appearances-page--review-profile--divider" />


      <div className="reviews-with-no-appearances-page--review-profile--treated-button">
        <Button
          onClick={onMarkReviewAsTreated}
          type="button"
          variant="ghost"
        >
          Mark as treated
        </Button>
      </div>
    </div>
  )
}

export default EditReview

EditReview.defaultProps = {}

EditReview.propTypes = {
  reviewId: PropTypes.string.isRequired,
}

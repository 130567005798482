import PropTypes from 'prop-types'
import React from "react"

import NoData from 'components/Atomic/Atoms/NoData'
import QuotationCard from 'components/Business/Quotations/QuotationCard'

const AppearancesList = ({ appearances }) => {
  return (
    <div>
      {!appearances.length && (
        <NoData
          description={null}
          title="No appearances here yet"
        />
      )}
      {appearances.map(appearance => (appearance.url || appearance.title) && (
        <QuotationCard
          appearanceIsFlaggedOnFacebook={appearance.flaggedOnFacebook}
          appearanceSource={appearance.source}
          appearanceTitle={appearance.url || appearance.title}
          appearanceUrl={appearance.url}
          channelDisplayName={appearance.author || appearance.channel?.[0]?.name}
          conclusionLabel={appearance.tag}
          contentType={appearance.isMedia && "media"}
          documents={appearance.document && [appearance.document]}
          key={appearance.id}
        />
      ))}
    </div>
  )
}


AppearancesList.propTypes = {
  appearances: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    url: PropTypes.string,
  })),
}

AppearancesList.defaultProps = {
  appearances: [],
}

export default AppearancesList

import { useQuery } from 'react-query'

import { API_URL } from 'utils/config'

import { normalizeSource } from '../../normalizer'
import { useAxios } from '../index'

export default function useTopSources() {
  const axios = useAxios()

  const getTopSources = async () => {
    const { data } = await axios.get(`${API_URL}/top-sources`)
    return data
  }

  return useQuery(['top-sources'], getTopSources, {
    select: data => {
      const dataFormatted = data.map(source => normalizeSource(source))

      return dataFormatted
    },
  })
}

import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import {
  Button,
  ClaimCard,
  ConfirmBar,
  Icon,
  InfiniteScroll,
  Loader,
  Search,
  Typography,
} from 'components'

import EditReviewConclusion from './EditReviewConclusion'

import './reviewsWithNoConclusionPage.scss'

const ReviewsWithNoConclusionPage = ({
  defaultSearch,
  fetchNextReviewPage,
  hasNextReviewPage,
  onSearch,
  refetchReviews,
  reviewIsLoading,
  reviews,
}) => {
  const [reviewId, setReviewId] = useState(null)
  const navigate = useNavigate()
  const onClick = () => navigate('/dashboard')

  const [showReviewEdited, setShowReviewEdited] = useState(false)
  const toggleShowReviewEdited = () => setShowReviewEdited(!showReviewEdited)

  const markReviewAsTreated = () => {
    setReviewId(null)
    toggleShowReviewEdited()
    setTimeout(() => {
      setShowReviewEdited(false)
    }, 3000)
  }


  return (
    <div className="reviews-with-no-conclusions-page">
      <div className="reviews-with-no-conclusions-page--reviews">
        <div className="reviews-with-no-conclusions-page--reviews--title">
          <Button onClick={onClick} variant="default-link">
            <Icon icon="arrowleft" />
          </Button>
          <Typography variant="title-1">
            Reviews without standard verdict:
          </Typography>
        </div>
        <div className="reviews-with-no-conclusions-page--reviews--search">
          <Search
            defaultSearch={defaultSearch}
            onSearch={onSearch}
            placeholder="Type a title, key word.. "
            variant="ghost"
          />
        </div>

        <div id="reviews-with-no-conclusions-page--reviews--infinite-scroll">
          <InfiniteScroll
            className="reviews-with-no-conclusions-page--reviews--review-list"
            dataLength={reviews.length}
            hasMore={hasNextReviewPage}
            isLoading={reviewIsLoading}
            loader={<Loader />}
            next={fetchNextReviewPage}
            scrollableTarget="reviews-with-no-conclusions-page--reviews--infinite-scroll"
          >
            {reviews.map(review => (
              <div
                className="reviews-with-no-conclusions-page--reviews--review-list--review"
                key={`review-${review.id}`}
                onClick={() => setReviewId(review.id)}
              >
                <ClaimCard
                  claim={review.claim}
                  claimBy={review.claimBy}
                  conclusionLabel={review.conclusionLabel}
                  conclusionScore={review.conclusionScore}
                  goToReview={false}
                  interactionNumber={undefined}
                  linkNumber={review.linkNumber}
                  links={undefined}
                  reviewId={review.id}
                />
              </div>
            ))}
          </InfiniteScroll>
        </div>
      </div>
      {reviewId && <EditReviewConclusion 
          reviewId={reviewId} 
          refetchReviews={refetchReviews}
          resetReview={markReviewAsTreated} 
        />
      }

      <ConfirmBar
        checkAnimation
        onClose={toggleShowReviewEdited}
        show={showReviewEdited}
        text="Review marked as treated"
      />
    </div>
  )
}

export default ReviewsWithNoConclusionPage

ReviewsWithNoConclusionPage.defaultProps = {
  defaultSearch: '',
  hasNextReviewPage: undefined,
  reviewIsLoading: true,
  reviews: [],
}

ReviewsWithNoConclusionPage.propTypes = {
  defaultSearch: PropTypes.string,
  fetchNextReviewPage: PropTypes.func.isRequired,
  hasNextReviewPage: PropTypes.bool,
  onSearch: PropTypes.func.isRequired,
  reviewIsLoading: PropTypes.bool,
  reviews: PropTypes.arrayOf(PropTypes.shape({})),
}

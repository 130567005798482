import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { Typography, TextField, Button, Icon, Select } from 'components'
import {
  useExpertiseTag,
  useCreateFeedbackRequest,
  useRoles,
  useAuth,
} from 'hooks'

import './createFeebackRequest.scss'

const Create = () => {
  const { handleSubmit, register, setValue } = useForm()

  let navigate = useNavigate()
  const createFeedbackRequest = useCreateFeedbackRequest()
  const { auth } = useAuth()
  const { isEditor, isScientist } = useRoles(auth)

  const [search, setSearch] = useState('')
  const { data, isLoading } = useExpertiseTag(search)
  const [tagSelected, setTagSelected] = useState([])

  const onChange = value => {
    const formValue = value.map(tag => tag.label)
    setValue('expertiseTags', formValue)
    setTagSelected(value)
  }
  const onSearch = search => {
    setSearch(search)
  }

  useEffect(() => {
    if (createFeedbackRequest.isSuccess) {
      return navigate('/feedback')
    }
  }, [createFeedbackRequest.isSuccess, navigate])

  const onSubmit = values => {
    createFeedbackRequest.mutate(values)
  }

  if (!isEditor || !isScientist) {
    navigate('/feedback')
  }

  return (
    <div className="create-feedback-request">
      <div className="create-feedback-request--border">
        <Typography variant="h2">Ask Us a question:</Typography>
        <Typography
          className="create-feedback-request--details"
          variant="body-text-2"
        >
          We help fact-checkers and journalists connect with scientists to
          quickly and effectively review climate change-related claims. Ask us a
          question or submit your request for help checking a claim. We’ll do
          our best to assist you in your review and connect you with relevant
          scientists.
        </Typography>

        <form
          className="create-feedback-request--form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Typography
            className="create-feedback-request--label"
            variant="title-2"
          >
            Your info*:
          </Typography>

          <TextField
            placeholder="Name"
            register={register}
            registerName="requesterName"
            registerValidation={{ required: true }}
            rounded={false}
          />

          <TextField
            placeholder="Your professional e-mail address"
            register={register}
            registerName="requesterEmail"
            registerValidation={{ required: true }}
            rounded={false}
            type="email"
          />

          <Typography
            className="create-feedback-request--label"
            variant="title-2"
          >
            Public question*:
          </Typography>

          <TextField
            placeholder="Short version of your question (publicly visible)"
            register={register}
            registerName="publicQuestion"
            registerValidation={{ required: true }}
            rounded={false}
          />

          <Typography
            className="create-feedback-request--label"
            variant="title-2"
          >
            Context:
          </Typography>

          <TextField
            placeholder="Explain the context for your question or claim verification request (private: only shown to scientists)"
            register={register}
            registerName="context"
            registerValidation={{ required: true }}
            rounded={false}
          />

          <Typography
            className="create-feedback-request--label"
            variant="title-2"
          >
            Claim:
          </Typography>

          <TextField
            placeholder="The claim you want to check, if any"
            register={register}
            registerName="claim.text"
            registerValidation={{ required: true }}
            rounded={false}
          />

          <Typography
            className="create-feedback-request--label"
            variant="title-2"
          >
            URL:
          </Typography>

          <TextField
            placeholder="Link to the article/social media post containing the claim (archived version prefered)"
            register={register}
            registerName="content.url"
            registerValidation={{ required: true }}
            rounded={false}
          />

          <Typography
            className="create-feedback-request--label"
            variant="title-2"
          >
            Related Expertise:
          </Typography>

          <Select
            getOptionValue={option => option.label}
            isLoading={isLoading}
            isMulti
            isSearchable
            onChange={onChange}
            onSearch={onSearch}
            options={data}
            placeholder="Search for an expertise Tag"
            value={tagSelected}
          />

          <Button type="submit" variant="ghost">
            Submit &nbsp; <Icon icon="arrowright" />
          </Button>
        </form>
      </div>
    </div>
  )
}

export default Create

import React, { useState } from 'react'
import { useParams, useLocation, useNavigate } from 'react-router-dom'

import { ReviewPage } from 'components'
import {
  useAddAppearance,
  useAddAppearances,
  useContact,
  useDeleteAppearance,
  useModifyAppearances,
  useModifyAppearancesVerdicts,
  useReview,
  useClaimAppearances,
  useCreateVerdictConclusion,
  useModifyConclusion,
  useModifyClaim,
  useSyncReview,
} from 'hooks'

const Review = () => {
  let navigate = useNavigate()
  const { search: urlSearch } = useLocation()
  const urlTagLabel = new URLSearchParams(urlSearch).get('tag') || ''
  const [tagLabel, setTagLabel] = useState(urlTagLabel)

  const { reviewId } = useParams()
  const { data: review, refetch: refetchReview } = useReview({ id: reviewId })

  const onChooseTagLabel = value => {
    navigate(`/review/${reviewId}?tag=${value}`)
    setTagLabel(value)
  }

  const {
    data: claimAppearancesData,
    fetchNextPage: fetchNextClaimAppearancesPage,
    hasNextPage: hasNextClaimAppearancesPage,
    isLoading: claimAppearancesPageAreLoading,
    refetch: refetchClaimAppearances,
  } = useClaimAppearances({
    claimId: review?.claimId,
    paginate: 10,
    tagLabel: tagLabel,
  })
  const { data: appearances } = claimAppearancesData || {}

  const modifyAppearances = useModifyAppearances()
  const onFlagAppearances = async (appearances, flagStatus) => {
    const data = appearances.map(appearance => ({
      id: appearance.id,
      flaggedOnFacebook: flagStatus,
    }))
    modifyAppearances.mutate(data, {
      onSuccess: () => refetchClaimAppearances(),
    })
  }

  const modifyAppearancesVerdicts = useModifyAppearancesVerdicts()
  const onModifyAppearanceVerdict = async appearance => {
    modifyAppearancesVerdicts.mutate([appearance], {
      onSuccess: () => refetchClaimAppearances(),
    })
  }

  const deleteAppearance = useDeleteAppearance()
  const onDeleteAppearance = async appearanceId => {
    deleteAppearance.mutate(appearanceId, {
      onSuccess: () => refetchClaimAppearances(),
    })
  }

  const addAppearance = useAddAppearance()
  const onAddAppearance = async (claimId, appearanceData) => {
    const data = {
      claimId: claimId,
      appearanceData: {
        link: {
          linkedClaimId: claimId,
        },
        ...appearanceData,
      },
    }
    addAppearance.mutate(data, {
      onSuccess: () => refetchClaimAppearances(),
    })
  }

  const addAppearances = useAddAppearances()
  const onAddSuggestedAppearances = async (claimId, appearancesData) => {
    const data = {
      claimId: claimId,
      appearancesData: appearancesData.map(appearanceDatum => ({
        link: {
          linkedClaimId: claimId,
        },
        ...appearanceDatum,
      })),
    }

    addAppearances.mutate(data, {
      onSuccess: () => {
        refetchClaimAppearances()
        //refetchClaimSuggestedAppearances()
      },
    })
  }

  const createConclusion = useCreateVerdictConclusion()
  const onCreateConclusion = async (
    factcheckerId,
    initialTagId,
    conclusionData
  ) => {
    const data = {
      verdictId: review.id,
      conclusionData: {
        channelId: factcheckerId,
        tagId: initialTagId,
        ...conclusionData,
      },
    }
    createConclusion.mutate(data, {
      onSuccess: () => refetchReview(),
    })
  }

  const modifyConclusion = useModifyConclusion()
  const onModifyConclusion = async (conclusionId, conclusionData) => {
    const data = {
      conclusionId: conclusionId,
      conclusionData: conclusionData,
    }
    modifyConclusion.mutate(data, {
      onSuccess: () => refetchReview(),
    })
  }

  const modifyClaim = useModifyClaim()
  const onModifyClaim = async (claimId, claimData) => {
    const data = {
      claimId: claimId,
      claimData: claimData,
    }
    modifyClaim.mutate(data, {
      onSuccess: () => refetchReview(),
    })
  }

  const syncReview = useSyncReview()
  const onSyncReview = async data => {
    const targets = Object.keys(data.targets).reduce((targets, target) => {
      if (data.targets[target]) {
        targets.push(target)
      }
      return targets
    }, [])
    const tasks = Object.keys(data.tasks).reduce((tasks, task) => {
      if (data.tasks[task]) {
        tasks.push(task)
      }
      return tasks
    }, [])
    syncReview.mutate(
      {
        reviewId: review.id,
        syncData: {
          targets: targets,
          tasks: tasks,
        },
      },
      {
        onSuccess: () => refetchReview(),
      }
    )
  }

  const contact = useContact()

  const onContact = values => {
    contact.mutate(values)
  }

  if (review?.isLoading) return null
  return (
    <ReviewPage
      appearances={appearances?.data}
      claimAppearancesPageAreLoading={claimAppearancesPageAreLoading}
      defaultTagLabel={tagLabel}
      fetchNextClaimAppearancesPage={fetchNextClaimAppearancesPage}
      hasNextClaimAppearancesPage={hasNextClaimAppearancesPage}
      onAddAppearance={onAddAppearance}
      onAddSuggestedAppearances={onAddSuggestedAppearances}
      onChooseTagLabel={onChooseTagLabel}
      onContact={onContact}
      onCreateConclusion={onCreateConclusion}
      onDeleteAppearanceVerdict={onDeleteAppearance}
      onFlagAppearances={onFlagAppearances}
      onModifyAppearanceVerdict={onModifyAppearanceVerdict}
      onModifyClaim={onModifyClaim}
      onModifyConclusion={onModifyConclusion}
      onRefetchClaimAppearances={refetchClaimAppearances}
      onResetAppearanceSharesVerdict={() => {}}
      onSyncReview={onSyncReview}
      review={review}
    />
  )
}

export default Review

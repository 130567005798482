import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'

import { Button, Dropdown, Loader, InfiniteScroll, TextField } from 'components'
import ConfirmAddQuotations from 'components/Business/Quotations/ConfirmAddQuotations'
import SuggestedQuotationCard from 'components/Business/Quotations/SuggestedQuotationCard'
import { useClaimSuggestedAppearances } from 'hooks'

import './addSuggestedQuotations.scss'

const AddSuggestedQuotations = ({
  appearanceUrls,
  claimId,
  claimOuterLinksCandidatesCount,
  onAddChosenSuggestedAppearances,
}) => {
  const SEARCH_TYPE = [
    { value: 'outerlinks', text: 'Outer links' },
    { value: 'buzzsumo', text: 'Buzzsumo content' },
    { value: 'backlinks', text: 'Buzzsumo backlinks' },
  ].filter(
    option =>
      option.value !== 'outerlinks' || claimOuterLinksCandidatesCount > 0
  )
  const [suggestedAppearanceMethod, setSuggestedAppearanceMethod] = useState(
    claimOuterLinksCandidatesCount > 0 ? 'outerlinks' : 'buzzsumo'
  )
  const [suggestedAppearanceSearchValues, setSuggestedAppearanceSearchValues] =
    useState({
      keyword: '',
      fromDate: '',
      toDate: '',
      url: appearanceUrls[0]?.text,
    })

  const onChooseSuggestedAppearanceSearch = e => {
    setSuggestedAppearanceMethod(e.target.value)
  }

  const onChooseUrl = e => {
    setSuggestedAppearanceSearchValues({
      ...suggestedAppearanceSearchValues,
      url: e.target.value,
    })
  }

  const {
    data: suggestedAppearances,
    fetchNextPage: fetchNextClaimSuggestedAppearancesPage,
    hasNextPage: hasNextClaimSuggestedAppearancesPage,
    isLoading: claimSuggestedAppearancesAreLoading,
    refetch: refetchClaimSuggestedAppearances,
  } = useClaimSuggestedAppearances({
    claimId: claimId,
    paginate: 10,
    method: suggestedAppearanceMethod,
    values: suggestedAppearanceSearchValues,
  })

  const onModifySuggestedAppearanceSearch = (search, values) => {
    setSuggestedAppearanceMethod(search)
    setSuggestedAppearanceSearchValues(values)
    refetchClaimSuggestedAppearances()
  }

  const { handleSubmit, register } = useForm()

  const onSubmitForm = values => {
    onModifySuggestedAppearanceSearch(suggestedAppearanceMethod, values)
  }

  const [suggestedAppearancesChosen, setSuggestedAppearancesChosen] = useState(
    []
  )
  const onAddChosenSuggestedAppearance = appearance => {
    setSuggestedAppearancesChosen([
      ...suggestedAppearancesChosen,
      {
        flaggedOnFacebook: false,
        linkingContent: {
          ...appearance,
        },
        tag: {
          facebookFlag: 'False',
        },
      },
    ])
  }
  const onModifySuggestedAppearance = (url, tagLabel, isFlaggedOnFacebook) => {
    const index = suggestedAppearancesChosen.findIndex(
      appearance => appearance.linkingContent.url === url
    )
    if (tagLabel) {
      suggestedAppearancesChosen[index].tag.facebookFlag = tagLabel
    }
    if (isFlaggedOnFacebook) {
      suggestedAppearancesChosen[index].flaggedOnFacebook = isFlaggedOnFacebook
    }
    setSuggestedAppearancesChosen(suggestedAppearancesChosen)
  }
  const onRemoveSuggestedAppearance = url => {
    setSuggestedAppearancesChosen(
      suggestedAppearancesChosen.filter(
        appearanceChosen => appearanceChosen.linkingContent.url !== url
      )
    )
  }
  const onCancelAddSuggestedAppearances = () => {
    setSuggestedAppearancesChosen([])
  }

  const onClickAddSuggestedAppearances = () => {
    onAddChosenSuggestedAppearances(suggestedAppearancesChosen)
    setSuggestedAppearancesChosen([])
  }

  return (
    <>
      <div className="add-suggested-quotations-search-type">
        <Dropdown
          defaultValue="outerlinks"
          inputRef="select-suggested-appearances-search-type"
          onChange={onChooseSuggestedAppearanceSearch}
          options={SEARCH_TYPE}
        />
      </div>
      {suggestedAppearanceMethod === 'buzzsumo' && (
        <form
          className="add-suggested-quotation--buzzsumo-form"
          onSubmit={handleSubmit(onSubmitForm)}
        >
          <TextField
            maxLength="255"
            placeholder="Search keyword"
            register={register}
            registerName="keyword"
            rounded={false}
          />
          <TextField
            register={register}
            registerName="fromDate"
            rounded={false}
            type="date"
          />
          <TextField
            register={register}
            registerName="toDate"
            rounded={false}
            type="date"
          />
          <Button type="submit" variant="ghost">
            Search
          </Button>
        </form>
      )}

      {suggestedAppearanceMethod === 'backlinks' && (
        <div className="add-suggested-quotations-search-backlink">
          <Dropdown
            className="select-suggested-appearances-backlinks-appearance-url"
            inputRef="select-suggested-appearances-backlinks-appearance-url"
            onChange={onChooseUrl}
            options={appearanceUrls}
          />
        </div>
      )}
      <InfiniteScroll
        className="add-suggested-quotations-list"
        dataLength={suggestedAppearances?.data.length}
        hasMore={hasNextClaimSuggestedAppearancesPage}
        isLoading={claimSuggestedAppearancesAreLoading}
        loader={<Loader />}
        next={fetchNextClaimSuggestedAppearancesPage}
      >
        {suggestedAppearances?.data.map(suggestedAppearance => (
          <div
            className="add-appearance--suggested-appearances-list--appearance"
            key={suggestedAppearance.id}
          >
            <SuggestedQuotationCard
              {...suggestedAppearance}
              edit={
                suggestedAppearancesChosen
                  .map(appearance => appearance.linkingContent.url)
                  .includes(suggestedAppearance.url)
                  ? true
                  : false
              }
              onAdd={onAddChosenSuggestedAppearance}
              onModify={onModifySuggestedAppearance}
              onRemove={onRemoveSuggestedAppearance}
            />
          </div>
        ))}
      </InfiniteScroll>
      <ConfirmAddQuotations
        isAppearanceAdded={false}
        nbAppearances={suggestedAppearancesChosen.length}
        onClose={onCancelAddSuggestedAppearances}
        onSubmit={onClickAddSuggestedAppearances}
        show={suggestedAppearancesChosen.length > 0}
      />
    </>
  )
}

export default AddSuggestedQuotations

AddSuggestedQuotations.defaultProps = {
  areLoading: false,
  claimOuterLinksCandidatesCount: 0,
  fetchNextClaimSuggestedAppearancesPage: () => {},
  hasNextClaimSuggestedAppearancesPage: undefined,
  onModifySuggestedAppearanceSearch: () => {},
  suggestedAppearances: [],
}

AddSuggestedQuotations.propTypes = {
  appearanceUrls: PropTypes.arrayOf(PropTypes.object).isRequired,
  areLoading: PropTypes.bool,
  claimOuterLinksCandidatesCount: PropTypes.number,
  fetchNextClaimSuggestedAppearancesPage: PropTypes.func,
  hasNextClaimSuggestedAppearancesPage: PropTypes.bool,
  onAddChosenSuggestedAppearances: PropTypes.func.isRequired,
  onModifySuggestedAppearanceSearch: PropTypes.func,
  suggestedAppearances: PropTypes.arrayOf(PropTypes.shape({})),
}

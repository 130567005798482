import { useQuery } from 'react-query'

import { normalizeFactchecker } from 'normalizer'
import { API_URL } from 'utils/config'

import { useAxios } from '../index'

export default function useFactcheckers(userId) {
  const axios = useAxios()
  let url = `${API_URL}/factcheckers`
  if (userId) {
    url = `${url}?userId=${userId}`
  }

  const getFactcheckers = async () => {
    const { data } = await axios.get(url)
    return data
  }
  const {data, error, isLoading} = useQuery(
    ['factcheckers'],
    () => getFactcheckers(), //Api Calls
  )

  return {
    data: (data || []).map(factchecker =>
          normalizeFactchecker(factchecker)),
    error,
    isLoading
  }
}

import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'

import './quotationCardHeader.scss'

import { Typography, Button, Checkbox, Icon } from 'components'
import {
  useAuth,
  useRoles,
} from 'hooks'

const QuotationCardHeader = ({
                               appearanceArchiveUrl,
                               appearanceIsFlaggedOnFacebook,
                               appearanceSource,
                               appearanceUrl,
                               appearanceUrlType,
                               channelDisplayName,
                               channelId,
                               contentType,
                               isFactcheckerAuthorized,
                               isLoggedIn,
                               editAppearance,
                               toggleEditAppearance,
                               isAppearanceChosen,
                               onCheckboxClick,
                               toggleAppearanceDrodownActions,
                               showAppearanceDropdownActions,
                               onDropdownBlur,
                               handleEditAppearanceClick,
                               onAddDocument,
                               onReattributeAppearance,
                               onResetAppearanceShares,
                               onDeleteAppearance,
                               ref,
                             }) => {
  const { auth } = useAuth()
  const { isEditor } = useRoles(auth)
  return (
    <div className="quotation-card-header">
      <div className="quotation-card-header--channel">
        <div>
          <Icon icon={appearanceUrlType} />
        </div>
        <Typography variant="body-text-4">
          {contentType === 'media' ? (
            <span>{appearanceSource}</span>
          ) : (
            <>
              {channelId ? (
                <Link to={`/channel/${channelId}`}>{channelDisplayName}</Link>
              ) : (
                <span>{channelDisplayName}</span>
              )}
            </>
          )}
        </Typography>
      </div>

      {isLoggedIn ? (
        <div className="quotation-card-header--actions">
          {editAppearance ? (
            <Button
              className="quotation-card-header--actions--cancel"
              onClick={toggleEditAppearance}
              variant="light"
            >
              <Typography variant="title-1">Cancel</Typography>
              <Icon icon="cross" />
            </Button>
          ) : (
            <>
              {isLoggedIn && (
                <>
                  {appearanceIsFlaggedOnFacebook ? (
                    <Icon color="#3A5DAE" icon="valid" />
                  ) : (
                    <Icon icon="remove" />
                  )}
                </>
              )}
              {(isFactcheckerAuthorized || isEditor) && (
                <div>
                  <Checkbox
                    checked={isAppearanceChosen()}
                    onChange={onCheckboxClick}
                  />
                </div>
              )}
              <div className="quotation-card-header--actions--icon">
                <Button
                  onClick={toggleAppearanceDrodownActions}
                  variant="default-link"
                >
                  <Icon icon="morevertical" />
                </Button>
                {showAppearanceDropdownActions && (
                  <div
                    className="quotation-card-header--actions--icon--dropdown"
                    onBlur={onDropdownBlur}
                    role="menu"
                    tabIndex={0}
                  >
                    {appearanceArchiveUrl && (
                      <div className="quotation-card-header--actions--icon--dropdown--item">
                        <a
                          href={appearanceArchiveUrl}
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          <Typography variant="link-text-2">
                            Archive link
                          </Typography>
                        </a>
                      </div>
                    )}

                    {appearanceUrl && (
                      <div className="quotation-card-header--actions--icon--dropdown--item">
                        <a
                          href={appearanceUrl}
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          <Typography variant="link-text-2">
                            Original link
                          </Typography>
                        </a>
                      </div>
                    )}

                    {(isFactcheckerAuthorized || isEditor) && (
                      <>
                        <div className="quotation-card-header--actions--icon--dropdown--item">
                          <Button
                            onClick={onAddDocument}
                            variant="default-link"
                          >
                            <Typography variant="link-text-2">Add document</Typography>
                          </Button>
                        </div>
                        {appearanceUrl && (
                        <div className="quotation-card-header--actions--icon--dropdown--item">
                          <Button
                            onClick={onResetAppearanceShares}
                            variant="default-link"
                          >
                            <Typography variant="link-text-2">
                              Reset shares
                            </Typography>
                          </Button>
                        </div>
                        )}

                        {appearanceUrl && (
                        <div className="quotation-card-header--actions--icon--dropdown--item">
                          <Button
                            onClick={onReattributeAppearance}
                            variant="default-link"
                          >
                            <Typography variant="link-text-2">
                              Reattribute appearance
                            </Typography>
                          </Button>
                        </div>
                        )}
                        <div className="quotation-card-header--actions--icon--dropdown--item">
                          <Button
                            onClick={onDeleteAppearance}
                            variant="default-link"
                          >
                            <Typography variant="link-text-2">
                              Delete appearance
                            </Typography>
                          </Button>
                        </div>
                      </>
                    )}
                    </div>
                )}
              </div>
            </>
          )}
        </div>
      ) : (
        appearanceArchiveUrl && (
          <Typography variant="body-text-4">
            <a
              href={appearanceArchiveUrl}
              rel="noopener noreferrer"
              target="_blank"
            >
              Archive link
            </a>
          </Typography>
        )
      )}
    </div>
  )
}

export default QuotationCardHeader

QuotationCardHeader.defaultProps = {
  appearanceArchiveUrl: null,
  appearanceId: null,
  appearanceIsFlaggedOnFacebook: true,
  appearanceSource: null,
  appearanceUrl: null,
  appearanceUrlType: null,
  appearancesChecked: [],
  channelDisplayName: null,
  channelId: null,
  contentType: 'article',
  isLoggedIn: false,
}

QuotationCardHeader.propTypes = {
  appearanceArchiveUrl: PropTypes.string,
  appearanceId: PropTypes.string,
  appearanceIsFlaggedOnFacebook: PropTypes.bool,
  appearanceSource: PropTypes.string,
  appearanceUrl: PropTypes.string,
  appearanceUrlType: PropTypes.string,
  appearancesChecked: PropTypes.arrayOf(PropTypes.shape({})),
  channelDisplayName: PropTypes.string,
  channelId: PropTypes.string,
  contentType: PropTypes.string,
  isLoggedIn: PropTypes.bool,
}

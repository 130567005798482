import PropTypes from 'prop-types'
import React from 'react'
import { Link, useNavigate } from 'react-router-dom'

import { Metrics, Typography, InteractionCircle, Icon } from 'components/index'
import { useSourceCardVariant } from 'hooks/sources'

import './sourceCard.scss'

const SourceCard = ({
  channelType,
  linkData,
  linkNumber,
  linkToSourcePage,
  interactionNumber,
  misinformationInteractionNumber,
  misinformationLinkNumber,
  interactionData,
  showSourceType,
  showSourceStats,
  sourceType,
  variant,
  id,
  sourceName,
}) => {
  let navigate = useNavigate()

  const onClick = () => {
    if (linkToSourcePage) navigate(``)
  }

  const { data, label, metrics, unit, metricVariant } = useSourceCardVariant({
    linkData,
    linkNumber,
    interactionData,
    interactionNumber,
    misinformationInteractionNumber,
    misinformationLinkNumber,
    sourceVariant: variant,
  })

  let icon = sourceType
  let type = sourceType
  if (channelType) {
    icon = channelType
    type = `${channelType} ${sourceType}`
  }

  return (
    <Link
      className="source-card"
      data-link={linkToSourcePage}
      onClick={onClick}
      role="navigation"
      to={`/${sourceType}/${id}`}
    >
      <InteractionCircle data={data} label={label} unit={unit} />
      <div className="source-card--body">
        <div className="source-card--body--header">
          {showSourceType && (
            <>
              <div className="source-card--body--header--icon">
                <Icon icon={icon} />
              </div>
              <Typography color="secondary-grey" variant="body-text-4">
                {type}
              </Typography>
            </>
          )}
        </div>

        <Typography className="source-card--body--name" variant="title-1">
          {sourceName}
        </Typography>

        {showSourceStats && (
          <div className="source-card--body--metrics">
            <Metrics metrics={metrics} variant={metricVariant} />
          </div>
        )}
      </div>
    </Link>
  )
}

export default SourceCard

SourceCard.defaultProps = {
  channelType: null,
  id: null,
  interactionData: [{ title: 'Unknown', value: 10, color: '#FFA951' }],
  interactionNumber: 0,
  linkData: [{ title: 'Unknown', value: 10, color: '#FFA951' }],
  linkNumber: 0,
  linkToSourcePage: true,
  misinformationInteractionNumber: 0,
  misinformationLinkNumber: 0,
  showSourceStats: true,
  showSourceType: true,
  sourceName: 'Unknown',
  sourceType: 'Unknown',
  stats: {},
  variant: 'all-data',
}

SourceCard.propTypes = {
  channelType: PropTypes.string,
  id: PropTypes.string,
  interactionData: PropTypes.arrayOf(PropTypes.shape({})),
  interactionNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  linkData: PropTypes.arrayOf(PropTypes.shape({})),
  linkNumber: PropTypes.number,
  linkToSourcePage: PropTypes.bool,
  misinformationInteractionNumber: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  misinformationLinkNumber: PropTypes.number,
  showSourceStats: PropTypes.bool,
  showSourceType: PropTypes.bool,
  sourceName: PropTypes.string,
  sourceType: PropTypes.oneOf(['Channel', 'Organization', 'Media', 'Unknown']),
  stats: PropTypes.shape({}),
  variant: PropTypes.string,
}

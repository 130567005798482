import React from 'react'
import { Link } from 'react-router-dom'

import { Typography, FeedbackRequest } from 'components'
import { useAuth, useFeedbackRequests, useRoles } from 'hooks'

import './feedbackRequestFeed.scss'

const FeedbackRequestFeed = () => {
  const { data: request } = useFeedbackRequests()

  const feedbackRequest = request || []
  const { auth } = useAuth()
  const { isEditor } = useRoles(auth)

  return (
    <div className="request-feed">
      <div className="request-feed--border">
        <div className="request-feed--title">
          <Typography variant="h2">Scientists’ Feedback:</Typography>
          {isEditor && (
            <Link to="/feedback/create">
              <Typography variant="btn-text">Create new request +</Typography>
            </Link>
          )}
        </div>

        {feedbackRequest &&
          feedbackRequest.map(request => (
            <div className="request-feed--section" key={request.id}>
              <Link to={`/feedback/${request.id}`}>
                <FeedbackRequest request={request} />
              </Link>
            </div>
          ))}

        {feedbackRequest && feedbackRequest.length === 0 && (
          <Typography className="request-feed--no-data" variant="body-text-2">
            {' '}
            No Feedback Request
          </Typography>
        )}
      </div>
    </div>
  )
}

export default FeedbackRequestFeed

import PropTypes from 'prop-types'
import React from 'react'

import { Tab, TabPanel, TabsContext } from 'components'

import AddNewQuotation from './AddNewQuotation'
import AddSuggestedQuotations from './AddSuggestedQuotations'

import './addQuotation.scss'

export const AddQuotation = ({
  appearanceUrls,
  claimId,
  claimOuterLinksCandidatesCount,
  onAddSuggestedAppearances,
  onClose,
  onSubmit,
  show,
}) => {
  const onAdd = values => {
    onClose()
    onSubmit(values)
  }

  const onAddChosenSuggestedAppearances = values => {
    onClose()
    onAddSuggestedAppearances(values)
  }

  return (
    show && (
      <div className="add-quotation">
        <TabsContext defaultTab="new-link">
          <div className="add-quotation--tabs">
            <Tab
              className="add-quotation--tabs--tab"
              text="New link:"
              value="new-link"
            />
            <Tab
              className="add-quotation--tabs--tab"
              text="Suggested appearances:"
              value="suggested-appearances"
            />
          </div>

          <TabPanel value="new-link">
            <AddNewQuotation onSubmitForm={onAdd} />
          </TabPanel>

          <TabPanel value="suggested-appearances">
            <AddSuggestedQuotations
              appearanceUrls={appearanceUrls}
              claimId={claimId}
              claimOuterLinksCandidatesCount={claimOuterLinksCandidatesCount}
              onAddChosenSuggestedAppearances={
                onAddChosenSuggestedAppearances
              }
            />
          </TabPanel>
        </TabsContext>
      </div>
    )
  )
}

AddQuotation.defaultProps = {
  appearanceUrls: [],
  claimOuterLinksCandidatesCount: 0,
  onClose: () => null,
  show: false,
}

AddQuotation.propTypes = {
  appearanceUrls: PropTypes.arrayOf(PropTypes.object),
  claimId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  claimOuterLinksCandidatesCount: PropTypes.number,
  onAddSuggestedAppearances: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  show: PropTypes.bool,
}

export default AddQuotation

import React, { useState } from 'react'
import { useFormContext } from 'react-hook-form'

import { Badge, Select, Typography } from 'components/index'
import { useMedias } from 'hooks'

export const SearchMedia = ({ setShowAdd }) => {
  const [search, setSearch] = useState('')
  const { setValue, getValues, watch } = useFormContext()
  const { data, isLoading } = useMedias(search)
  const defaultValue = getValues('mediumId')
    ? {
        id: getValues('mediumId'),
        name: getValues('mediumName'),
      }
    : null
  const options = data?.pages[0].data || []

  const onSearch = search => {
    setSearch(search)
  }

  const onChange = value => {
    setValue('mediumName', value.name)
    setValue('mediumId', value.id)
  }

  const onAdd = () => {
    setShowAdd('Media')
  }

  const media = watch('mediumName')

  return (
    <div className="edit-source--section">
      <Typography color="primary-white" variant="title-3">
        Controlled by media:
      </Typography>
      <Select
        addButton
        addButtonText="Add media"
        defaultValue={defaultValue}
        getOptionLabel={option => option.name}
        getOptionValue={option => option.id}
        isLoading={isLoading}
        isSearchable
        onAdd={onAdd}
        onChange={onChange}
        onSearch={onSearch}
        options={options}
        placeholder="Search for a media"
      />

      {media && (
        <Badge
          onDelete={() => onChange({ id: null, name: null })}
          text={media}
        />
      )}
    </div>
  )
}

export default SearchMedia

import { useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'

import { useAuth, useAxios } from 'hooks'

import { API_URL } from '../../utils/config'

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

export default function useLogin() {
  let navigate = useNavigate()
  const { setAuth } = useAuth()
  const instances = useAxios()

  const loginQueries = async loginData => {
    const response = await instances.post(`${API_URL}/users/jwt`, loginData)
    const { data, status } = response
    if (status === 200) {
      await setAuth({
        accessToken: data.token,
        exp: data.exp,
      })

      // TODO THIS IS A DIRTY FIX, WHEN YOU LOG IN THEN LOGOUT YOU CANNOT LOG IN AGAIN
      await sleep(100)

      navigate(0)
    }
  }

  const loginRequest = useMutation(data => loginQueries(data))

  const login = data => {
    loginRequest.mutate(data)
  }

  const logout = () => {
    setAuth({})
    navigate(`/`)
  }

  return {
    loginData: loginRequest,
    login,
    logout,
  }
}

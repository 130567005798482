import PropTypes from 'prop-types'
import React from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'

import {
  AppearanceCard,
  Typography,
  ChannelCard,
  Divider,
  MediaCard,
  SourceCard,
  SourceInfo,
  SourceStats,
  FullReport,
  Loader,
} from 'components'

import './sourcePageMobile.scss'

const SourcePageMobile = ({
  source,
  appearances,
  fetchNextAppearancePage,
  hasNextAppearancePage,
  onContact,
}) => {
  return (
    <div className="source-page-mobile">
      <SourceCard
        {...source}
        linkToSourcePage={false}
        showSourceStats={false}
      />

      <Divider borderStyle="dashed" thickness="thin" />

      <div className="source-page-mobile--source-info">
        <SourceInfo owners={source.owners} />
      </div>

      <Divider borderStyle="dashed" thickness="thin" />

      <div className="source-page-mobile--source-stats">
        <SourceStats stats={source.stats} />
      </div>

      <Divider borderStyle="dashed" thickness="thin" />

      {source.media && (
        <>
          <div className="source-page-mobile--related-medias">
            <Typography variant="title-1">Related media:</Typography>
            {source.media.map(media => (
              <div
                className="source-page-mobile--related-medias--media"
                key={media.id}
              >
                <MediaCard {...media} />
              </div>
            ))}
          </div>
          <Divider borderStyle="dashed" thickness="thin" />
        </>
      )}

      {source.channels && (
        <>
          <div className="source-page-mobile--related-channels">
            <Typography variant="title-1">Related channels:</Typography>
            {source.channels.map(channel => (
              <div
                className="source-page-mobile--related-channels--channel"
                key={channel.id}
              >
                <ChannelCard {...channel} />
              </div>
            ))}
          </div>
          <Divider borderStyle="dashed" thickness="thin" />
        </>
      )}

      <div className="source-page-mobile--appearanceList">
        <Typography variant="title-1">
          {source.linkNumber} Links:&nbsp;
          {source.interactionNumber} interactions
        </Typography>
        {appearances.length === 0 && <Loader />}
        <InfiniteScroll
          dataLength={appearances.length}
          hasMore={hasNextAppearancePage}
          loader={<Loader />}
          next={fetchNextAppearancePage}
        >
          {appearances.map(appearance => (
            <div
              className="source-page-mobile--appearanceList--appearance"
              key={appearance.id}
            >
              <AppearanceCard
                {...appearance}
                appearanceId={appearance.id}
                sourceId={source.id}
                sourceType={source.sourceType}
              />
            </div>
          ))}
        </InfiniteScroll>
      </div>
      <FullReport onSubmit={onContact} />
    </div>
  )
}

SourcePageMobile.defaultProps = {
  appearances: [],
  fetchNextAppearancePage: () => null,
  hasNextAppearancePage: false,
  onContact: () => null,
  onRefetchSource: () => null,
  source: {
    channels: null,
    media: null,
    owners: [],
  },
}

SourcePageMobile.propTypes = {
  appearances: PropTypes.arrayOf(PropTypes.shape({})),
  fetchNextAppearancePage: PropTypes.func,
  hasNextAppearancePage: PropTypes.bool,
  onContact: PropTypes.func,
  onRefetchSource: PropTypes.func,
  source: PropTypes.shape({
    id: PropTypes.string,
    channels: PropTypes.arrayOf(PropTypes.shape({})),
    linkNumber: PropTypes.number,
    interactionNumber: PropTypes.string,
    media: PropTypes.arrayOf(PropTypes.shape({})),
    owners: PropTypes.arrayOf(PropTypes.shape({})),
    sourceType: PropTypes.string,
    stats: PropTypes.shape({}),
  }),
}

export default SourcePageMobile

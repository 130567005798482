import PropTypes from 'prop-types'
import React from 'react'

import Typography from 'components/Atomic/Atoms/Typography'

import './sourceInfo.scss'
import urlShortener from 'utils/urlShortener'

const SourceInfo = ({
  isLoggedIn,
  mbfcUrl,
  mbfcFactualReporting,
  owners,
  url,
}) => (
  <div className="sourceInfo">
    <div className="sourceInfo--section">
      <Typography variant="title-1">Owned by:&nbsp;</Typography>
      {owners.length > 0 ? (
        <>
          {owners.map((owner, index) => (
            <Typography key={owner.id} variant="link-text-2">
              {index > 0 && <span> , </span>} {owner.name}
            </Typography>
          ))}
        </>
      ) : (
        <Typography variant="link-text-2">Unknown</Typography>
      )}
    </div>

    {isLoggedIn && mbfcUrl && (
      <>
        <div className="sourceInfo--section">
          <Typography variant="title-1">
            Media Bias Fact Check :&nbsp;
          </Typography>

          <Typography variant="body-text-2">
            Factual Reporting - {mbfcFactualReporting}&nbsp;
          </Typography>
        </div>
        <div className="sourceInfo--section">
          <Typography variant="link-text-2">
            <a href={mbfcUrl} rel="noopener noreferrer" target="_blank">
              {mbfcUrl}
            </a>
          </Typography>
        </div>
      </>
    )}

    {isLoggedIn && urlShortener && (
      <div className="sourceInfo--section">
        <Typography variant="title-1"> Url :&nbsp;</Typography>
        <Typography variant="body-text-2">{url}</Typography>
      </div>
    )}
  </div>
)

SourceInfo.defaultProps = {
  owners: [],
}

SourceInfo.propTypes = {
  owners: PropTypes.arrayOf(PropTypes.shape({})),
}

export default SourceInfo

const normalizeConclusions = conclusion => {
  return {
    id: conclusion.id,
    channelScores: conclusion.channelScores,
    numberOfUse: conclusion.numberOfUse,
    standardForm: conclusion.standardForm,
    score: conclusion.score,
  }
}

export default normalizeConclusions

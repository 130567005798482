import PropTypes from 'prop-types'
import React, { useState } from 'react'

import Copyright from '../../../Atoms/Copyright'
import Logo from '../../../Atoms/Logo'
import ContactForm from '../../ContactForm'
import FooterMenu from '../FooterMenu'

import './footermobile.scss'

export const FooterMobile = ({ onSubmit }) => {
  const [show, setShow] = useState(false)
  const toggleContactForm = () => setShow(!show)
  return (
    <footer className="footer-mobile">
      <div className="footer-mobile--logo-menu">
        <div className="footer-mobile--logo-menu--logo">
          <Logo title={false} variant="white" />
        </div>

        <div className="footer-mobile--logo-menu--navlink">
          <div className="footer-mobile--logo-menu--navlink--links">
            <FooterMenu contactClick={toggleContactForm} />
          </div>
          <div className="footer-mobile--logo-menu--navlink--title">
            <Copyright />
          </div>
        </div>
      </div>
      <ContactForm
        onClose={toggleContactForm}
        onSubmit={onSubmit}
        show={show}
      />
    </footer>
  )
}

FooterMobile.propTypes = {
  onSubmit: PropTypes.func.isRequired,
}

export default FooterMobile

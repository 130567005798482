import PropTypes from 'prop-types'
import React, { memo, useCallback } from 'react'
import { components } from 'react-select'
import { default as ReactSelect } from 'react-select'

import { Button, Icon, Loader, Typography } from 'components'

import './select.scss'

const customStyles = {
  control: (provided, { isFocused }) => {
    return {
      alignItems: 'center',
      background: '#FFFFFB',
      border: '1px solid #18201E',
      borderColor: isFocused ? '#3A5DAE' : '#18201E',
      color: '#5A7770',
      display: 'flex',
      fontFamily: 'Space Mono, sans-serif',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 'normal',
      height: '36px',
      minHeight: '32px',
      lineHeight: '24px',
      padding: '0 16px',
    }
  },
  multiValue: () => ({
    display: 'none',
  }),
  valueContainer: provided => ({
    ...provided,
    padding: 0,
    fontFamily: 'Space Mono, sans-serif',
  }),
  menuList: provided => ({
    ...provided,
    '& .menuListHeader': {
      padding: '10px 16px',
      fontFamily: "'Darker Grotesque'",
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '20px',
      lineHeight: '24px',
    },
    '& .menuListButton': {
      display: 'flex',
      width: '100%',
      justifyContent: 'center',
      padding: '16px 0',
    },
  }),
  option: (provided, { isFocused }) => ({
    padding: '10px 16px',
    cursor: 'pointer',
    fontFamily: "'Space Mono'",
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '24px',
    backgroundColor: isFocused ? ' #3A5DAE' : undefined,
    color: isFocused ? ' #FFFFFB' : '#18201E',
  }),
}

/* eslint-disable react/no-multi-comp */
const IndicatorsContainer = () => <Icon icon="search" />

const LoadingMessage = () => {
  return <Loader />
}

const NoOptionsMessage = () => (
  <div className="select--nodata">
    <img alt="logo" src="/static/assets/new/noData.svg" />
  </div>
)

const MenuList = memo(props => {
  const onClick = useCallback(
    e => {
      e.stopPropagation()
      props.selectProps.onMenuClose()
      props.selectProps.onAdd(props.selectProps.inputValue)
    },
    [props.selectProps]
  )

  return (
    <components.MenuList {...props}>
      <div className="menuListHeader">Search results:</div>
      {props.children}
      {props.selectProps.addButton && (
        <div className="menuListButton">
          <Button onClick={onClick} type="button">
            {props.selectProps.addButtonText}
          </Button>
        </div>
      )}
    </components.MenuList>
  )
})

const Select = ({
  addButton,
  addButtonText,
  className,
  defaultValue,
  getOptionLabel,
  getOptionValue,
  inputValue,
  isLoading,
  isMulti,
  onAdd,
  onChange,
  onSearch,
  options,
  placeholder,
  value,
}) => {
  const getLabel = value =>
    getOptionLabel ? getOptionLabel(value) : value.label
  const getValue = value =>
    getOptionValue ? getOptionValue(value) : value.value

  const removeValues = labelToRemove => () => {
    onChange(value.filter(val => getLabel(val) !== labelToRemove))
  }

  return (
    <div className={`select ${className}`}>
      <ReactSelect
        addButton={addButton}
        addButtonText={addButtonText}
        components={{
          MenuList,
          IndicatorsContainer,
          LoadingMessage,
          NoOptionsMessage,
        }}
        defaultOptions
        defaultValue={defaultValue}
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
        inputValue={inputValue}
        isLoading={isLoading}
        isMulti={isMulti}
        isSearchable
        onAdd={onAdd}
        onChange={onChange}
        onInputChange={onSearch}
        options={options || []}
        placeholder={placeholder}
        styles={customStyles}
        value={value}
      />

      {isMulti && value && (
        <div className="select--selected">
          {value.map(val => (
            <span className="select--selected--value" key={getValue(val)}>
              <Typography color="primary-white" variant="title-3">
                {getLabel(val)}
              </Typography>

              <div
                className="select--selected--value--remove"
                onClick={removeValues(getLabel(val))}
                role="button"
                tabIndex="0"
              >
                <Icon color="#FFFFFB" icon="cross" />
              </div>
            </span>
          ))}
        </div>
      )}
    </div>
  )
}

Select.defaultProps = {
  addButton: false,
  addButtonText: 'Add',
  className: '',
  isMulti: false,
  onAdd: null,
  options: [],
  placeholder: '',
  value: null,
}

Select.propTypes = {
  addButton: PropTypes.bool,
  addButtonText: PropTypes.string,
  className: PropTypes.string,
  isMulti: PropTypes.bool,
  onAdd: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({})),
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.shape({})),
    PropTypes.shape({}),
  ]),
}

MenuList.displayName = 'MenuList'

export default Select

const capitalize = (text, allWords) => {
  if (allWords) {
    const words = text.split(' ')
    const capitalizedWords = words.map(word => {
      if (word.trim() === '') {
        return ''
      }
      return word.charAt(0).toUpperCase() + word.slice(1)
    })
    const capitalizedStr = capitalizedWords.join(' ')
    return capitalizedStr
  } else {
    return text.charAt(0).toUpperCase() + text.slice(1)
  }
}

export default capitalize

import { useState } from 'react'
import React from 'react'
import { useFormContext } from 'react-hook-form'

import { Badge, Select, Typography } from 'components/index'
import { useOrganizations } from 'hooks'

export const SearchOrganization = ({ setShowAdd }) => {
  const [search, setSearch] = useState('')

  const { setValue, getValues, watch } = useFormContext()
  const { data, isLoading } = useOrganizations(search)

  const defaultValue = getValues('organizationId')
    ? {
        id: getValues('organizationId'),
        name: getValues('organizationName'),
      }
    : null

  const options = data?.pages[0].data || []

  const onSearch = search => {
    setSearch(search)
  }

  const onChange = value => {
    setValue('organizationName', value.name)
    setValue('organizationId', value.id)
  }

  const onAdd = () => {
    setShowAdd('Organization')
  }

  const organization = watch('organizationName')

  return (
    <div className="edit-source--section">
      <Typography color="primary-white" variant="title-3">
        Controlled by organization:
      </Typography>
      <Select
        addButton
        addButtonText="Add organization"
        defaultValue={defaultValue}
        getOptionLabel={option => option.name}
        getOptionValue={option => option.id}
        isLoading={isLoading}
        isSearchable
        onAdd={onAdd}
        onChange={onChange}
        onSearch={onSearch}
        options={options}
        placeholder="Search for an organization"
      />

      {organization && (
        <Badge
          onDelete={() => onChange({ id: null, name: null })}
          text={organization}
        />
      )}
    </div>
  )
}

export default SearchOrganization

import { useMutation } from 'react-query'

import { useAxios } from 'hooks'

import { API_URL } from '../../utils/config'

export default function useCreateVerdictConclusion(data) {
  const instances = useAxios()
  const addConclusion = async addConclusionData => {
    const { data } = await instances.post(
      `${API_URL}/verdicts/${addConclusionData.verdictId}/conclusions`,
      addConclusionData.conclusionData
    )
    return data
  }
  return useMutation(data => addConclusion(data), data)
}

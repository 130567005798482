import { useQuery } from 'react-query'

import { API_URL } from 'utils/config'

import { useAxios } from '../index'
export default function useTopicTag(search) {
  const axios = useAxios()

  const getTopicTag = async ({
    search = '',
    page = 1
  }) => {
    const { data } = await axios.get(
      `${API_URL}/tags?page=${page}&tag-start=${search}&type=TOPIC`
    )
    return data
  }

  return useQuery(['topic-tag', search], () => getTopicTag(search))
}

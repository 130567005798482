import PropTypes from 'prop-types'
import React from 'react'

import Typography from '../Typography'
import './loader.scss'
import loader from './assets/load.mp4'

const Loader = ({ variant }) => {
  return (
    <div className={`loader-wrapper loader-wrapper--${variant}`}>
      <video autoPlay className="loader" loop muted>
        <source src={loader} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <Typography variant="btn-text">Loading...</Typography>
    </div>
  )
}

export default Loader

Loader.defaultProps = {
  variant: 'big',
}

Loader.propTypes = {
  variant: PropTypes.oneOf(['small', 'big']),
}

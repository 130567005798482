import parse from 'html-react-parser'
import React, { useCallback, useState } from 'react'

import { Button, Typography } from 'components'

import EditFeedback from '../../../../modules/Feedback/pages/EditFeedback'

import './feedbackCard.scss'

const FeedbackCard = ({ feedback, onEditSuccess }) => {
  const [isEditable, setEditable] = useState(false)
  const onSuccess = useCallback(() => {
    onEditSuccess()
    setEditable(!isEditable)
  }, [isEditable, onEditSuccess])

  return (
    <div className="feedback-card">
      <Typography className="feedback-card--title" variant="title-1">
        <span className="feedback-card--scientist-name">
          {feedback.scientist.firstName} {feedback.scientist.lastName}
        </span>
        <Button
          onClick={() => setEditable(!isEditable)}
          variant="ghost-reverse"
        >
          <Typography onClick variant="btn-text">
            Edit Feedback
          </Typography>
        </Button>
      </Typography>
      <Typography variant="body-text-4">
        {feedback.scientist.academicWebsite}
      </Typography>

      {isEditable ? (
        <EditFeedback feedback={feedback} onSuccess={onSuccess} />
      ) : (
        <>
          <div className="feedback-card--feedback">
            {parse(feedback.comment)}
          </div>

          <div className="feedback-card--infos">
            <div className="feedback-card--infos--rating">
              <Typography variant="title-1">
                Score {feedback.rating}{' '}
              </Typography>
            </div>
            <div className="feedback-card--infos--tags">
              {feedback.scientistFeedbackTags.map(feedbackTags => (
                <Typography
                  className="feedback-card--infos--tags--tag"
                  key={feedbackTags.id}
                  variant="body-text-4"
                >
                  {feedbackTags.tag.label}
                </Typography>
              ))}
            </div>
          </div>

          <Typography variant="body-text-2">
            {feedback.reviewSourceLinks}
          </Typography>
        </>
      )}
    </div>
  )
}

export default FeedbackCard

import { useMutation, useQueryClient } from 'react-query'

import { API_URL } from '../../utils/config'
import { useAxios } from '../index'

const useSourceEdition = sourceType => {
  const queryClient = useQueryClient()
  const axios = useAxios()

  const editSources = async values => {
    let url = `${API_URL}/${sourceType.toLowerCase()}s/${values.id}`

    const { data } = await axios.patch(url, { ...values })
    return data
  }

  const queryName = `sources-${sourceType.toLowerCase()}`

  return useMutation(editSources, {
    onMutate: async updatedSource => {
      await queryClient.cancelQueries([queryName, updatedSource.id])
      const previousSource = queryClient.getQueryData([
        queryName,
        updatedSource.id,
      ])
      return { previousSource, updatedSource }
    },
    onError: (err, updatedSource, context) => {
      queryClient.setQueryData(
        [queryName, updatedSource.id],
        context.previousSource
      )
    },
    onSettled: updatedSource => {
      queryClient.invalidateQueries([queryName, updatedSource.id])
    },
  })
}

export default useSourceEdition

import PropTypes from 'prop-types'
import React from 'react'

import './dropdown.scss'

const Dropdown = React.forwardRef(
  (
    {
      className,
      defaultValue,
      onBlur,
      onChange,
      label,
      name,
      options,
      placeholder,
      selectedValue,
      variant,
      ...rest
    },
    ref
  ) => (
    <select
      className={`select-field ${className}`}
      data-variant={variant}
      defaultValue={defaultValue}
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      ref={ref}
      {...rest}
    >
      {placeholder && (
        <option defaultValue={defaultValue} disabled value="">
          {placeholder}
        </option>
      )}
      {options.map(option => (
        <option
          key={option.value}
          value={option.value}
        >
          {option.text}
        </option>
      ))}
    </select>
  )
)

Dropdown.displayName = 'Dropdown'

export default Dropdown

Dropdown.defaultProps = {
  defaultValue: null,
  options: [],
  placeholder: null,
  variant: 'default',
}

Dropdown.propTypes = {
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  options: PropTypes.arrayOf(PropTypes.shape({})),
  placeholder: PropTypes.string,
  variant: PropTypes.oneOf(['default', 'ghost']),
}

import React, { useState } from 'react'
import { useNavigate } from "react-router-dom";
import { useForm } from 'react-hook-form'

import { TextField, Button, Typography, ErrorMessage } from 'components'
import { useResetPassword } from 'hooks'

import './resetPassword.scss'

const ResetPasswordPage = ({ token }) => {
  const { handleSubmit, register } = useForm()
  const navigate = useNavigate()
  
  const [errorMessage, setErrorMessage] = useState(null)

  const resetPassword = useResetPassword()
  
  const onSend = values => {
    resetPassword.mutate({ ...values, token }, {
      onSuccess: () => {
        navigate("/")
      },
      onError: (error) => {
        setErrorMessage(error.response.data.message)
      }
    })
  }

  return (
    <div className="resetPasswordPage">
      <Typography className="resetPasswordPage--title" variant="h2">
        Reset password
      </Typography>

      <form onSubmit={handleSubmit(onSend)} className="resetPasswordPage--form">
        <TextField
          placeholder="Password"
          register={register}
          registerName="password"
          type="password"
        />
        <Button type="submit">Submit</Button>
      </form>
      
      {errorMessage && <ErrorMessage  message={errorMessage} />}
    </div>

  )
}

export default ResetPasswordPage

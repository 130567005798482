import React, { useState } from 'react'

import { Button, ConfirmBar, Icon, Typography } from 'components'
import { useSourceStatisticsRecalculate } from 'hooks'

import './sourceStatisticsRecalculate.scss'

export const SourceStatisticsRecalculate = ({ source }) => {
  const { id, sourceType } = source

  const [
    showStatisticsRecalculatedConfirmBar,
    setShowStatisticsRecalculatedConfirmBar,
  ] = useState(false)
  const toggleShowStatisticsRecalculatedConfirmBar = () =>
    setShowStatisticsRecalculatedConfirmBar(
      !showStatisticsRecalculatedConfirmBar
    )

  const recalculateSourceStatistics = useSourceStatisticsRecalculate(
    id,
    sourceType
  )
  const onRecalculate = async () => {
    await recalculateSourceStatistics.mutate(source)
    toggleShowStatisticsRecalculatedConfirmBar()
    setTimeout(() => {
      setShowStatisticsRecalculatedConfirmBar(false)
    }, 3000)
  }

  return (
    <>
      <div className="source-statistics-recalculate">
        <Button onClick={onRecalculate} type="button" variant="light">
          <Typography variant="title-3">Recalculate</Typography>
          <Icon icon="refresh" />
        </Button>
      </div>

      <ConfirmBar
        checkAnimation
        onClose={toggleShowStatisticsRecalculatedConfirmBar}
        show={showStatisticsRecalculatedConfirmBar}
        text="Statistics updated"
      />
    </>
  )
}

export default SourceStatisticsRecalculate

import { useInfiniteQuery } from 'react-query'

import { useAuth } from 'hooks'
import { normalizeShare } from 'normalizer'
import { API_URL } from 'utils/config'

import { useAxios } from '../index'

export default function useAppearanceShares(userParams) {
  const axios = useAxios()

  const { auth } = useAuth()

  const getAppearanceShares = async (
    { appearanceId = '', paginate = 10, platform = null },
    { isLoggedIn = false, pageParam = 1 }
  ) => {
    let url = `${API_URL}/appearances/${appearanceId}/shares?page=${pageParam}&paginate=${paginate}&platform=${platform}`
    const { data, headers } = await axios.get(url)
    return {
      data,
      hasNextPage:
        headers['has-more'] === 'True' && isLoggedIn && pageParam + 1,
      totalDataCount: headers['total-data-count'],
    }
  }

  return useInfiniteQuery(
    ['appearance-shares', userParams],
    params =>
      getAppearanceShares(userParams, { isLoggedIn: !!auth, ...params }), //Api Calls
    {
      enabled: !!userParams.appearanceId,
      select: data => {
        const dataFormatted = {
          data: data.pages.flatMap(page =>
            page.data.map(share => {
              return normalizeShare(share)
            })
          ),
        }
        return {
          data: dataFormatted,
          totalDataCount: data.pages[0].totalDataCount,
        }
      },
      getNextPageParam: lastPage => lastPage.hasNextPage,
    }
  )
}

import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'

import { Typography } from 'components'

import './sourcePageOwners.scss'

const SourcePageOwners = ({ persons, organization, media }) => {
  return (
    <div className="source-page-owners">
      <div className="source-page-owners--owners">
        <Typography variant="title-1">Owned by:</Typography>

        {organization && (
          <Link to={`/organization/${organization.id}`}>
            <Typography variant="link-text-2">{organization.name}</Typography>
          </Link>
        )}

        {persons.map(person => (
          <Typography variant="body-text-2">{person.name}</Typography>
        ))}
      </div>

      {media && (
        <div className="source-page-owners--media">
          <Typography variant="title-1">Related media:</Typography>
          <Link to={`/Media/${media.id}`}>
            <Typography color="primary-dark" variant="link-text-2">
              {media.name}
            </Typography>
          </Link>
        </div>
      )}
    </div>
  )
}

SourcePageOwners.defaultProps = {
  persons: [],
  organization: null,
  media: null,
}

SourcePageOwners.propTypes = {
  persons: PropTypes.arrayOf(PropTypes.shape({})),
  organization: PropTypes.shape({}),
  media: PropTypes.arrayOf(PropTypes.shape({})),
}

export default SourcePageOwners

import PropTypes from 'prop-types'
import React, {
  useCallback,
  useEffect,
  useState,
} from 'react'
import {
  useForm,
} from 'react-hook-form'

import {
  Button,
  Checkbox,
  Dropdown,
  ErrorMessage,
  Icon,
  SearchResources as SearchChannel,
  TextField,
  Typography,
} from 'components'
import { useChannels } from 'hooks'
import { validateUrl } from 'validator'

import QuotationFile from "../../../Business/Quotations/AddQuotation/AddNewQuotation/QuotationFile"

const AppearancesForm = ({
  appearances,
  append,
  onAdd,
}) => {
  const RATING_TAGS_OPTIONS = [
    { value: 'True', text: 'True' },
    { value: 'Partly false', text: 'Partly false' },
    { value: 'False', text: 'False' },
    { value: 'Missing context', text: 'Missing context' },
    { value: 'Satire', text: 'Satire' },
    { value: 'Not eligible', text: 'Not eligible' },
  ]

  const {
    control,
    formState: {
      errors,
      isValid,
    },
    handleSubmit,
    register,
    setValue,
  } = useForm({
    defaultValues: appearances,
    mode: 'onChange',
  })

  const [isFlaggedOnFacebook, setIsFlaggedOnFacebook] = useState(false)
  const [files, setFiles] = useState([])
  const [descriptions, setDescriptions] = useState([])
  const onFlaggedOnFacebookCheckboxClick = () => {
    setIsFlaggedOnFacebook(!isFlaggedOnFacebook)
  }

  const [isMedia, setIsMedia] = useState(false)
  const onIsMediaCheckboxClick = () => {
    setIsMedia(!isMedia)
  }

  const handleOnAdd = useCallback(data => {
    const dataWithFile = {
      ...data,
      document: {
        ...data.document,
        file: files,
        description: descriptions,
      }
    }
    append(dataWithFile)
    onAdd()
  }, [
    append,
    descriptions,
    files,
    onAdd,
  ])

  useEffect(() => {
    setValue('flaggedOnFacebook', isFlaggedOnFacebook)
    setValue('isMedia', isMedia)
  }, [
    isFlaggedOnFacebook,
    isMedia,
    setValue,
  ])

  return (
    <>
      <div className="add-new-quotation--row">
        <Typography
          variant="body-text-2"
        >
          If you want to add an appearance without a URL link (e.g. image, TV video, content deleted from the web), tick the ‘Without link’ box.
        </Typography>

        <div className="add-new-quotation--row--checkbox">
          <Checkbox
            checked={isMedia}
            onChange={onIsMediaCheckboxClick}
          />
          <Typography variant="body-text-2">
            Without link
          </Typography>
        </div>
      </div>

      {
        !isMedia ? (
          <div className="add-new-quotation--row">
            <div className="add-new-quotation--row--label">
              <Typography variant="title-2">Appearance link:</Typography>
            </div>
            <TextField
              maxLength="2000"
              placeholder="Paste your link*"
              register={register}
              registerName="url"
              registerValidation={{
                required: true,
                maxLength: 2000,
                validate: validateUrl,
              }}
              rounded={false}
            />
            {errors.linkingContent?.url?.type === 'required' && (
              <ErrorMessage message="Url is required" />
            )}
            {errors.linkingContent?.url?.type === 'validate' && (
              <ErrorMessage message="Please enter a valid url (e.g https://open.feedback.org)" />
            )}

            <div className="add-new-quotation--row--checkbox">
              <Checkbox
                checked={isFlaggedOnFacebook}
                onChange={onFlaggedOnFacebookCheckboxClick}
              />
              <Typography variant="body-text-2">
                Link has been flagged on Facebook
              </Typography>
            </div>
          </div>
        )
        :
        (
          <div className="add-new-quotation--row">
            <div className="add-new-quotation--row--label">
              <Typography variant="title-2">Appearance title:*</Typography>
              <Typography variant="body-text-2">Give a unique title to your appearance summarising its content</Typography>
            </div>
            <TextField
              maxLength="2000"
              placeholder="e.g. ‘US Senate testimony of Mr Steve Kirsch on the supposed dangers of vaccination’"
              register={register}
              registerName="title"
              registerValidation={{
                maxLength: 2000,
                required: true,
              }}
              rounded={false}
            />
            {errors.linkingContent?.title?.type === 'required' && (
              <ErrorMessage message="Title is required" />
            )}
          </div>
        )
      }

      { isMedia ?
        (
          <div className="add-new-quotation--row">
            <div className="add-new-quotation--row--label">
              <Typography variant="title-2">Source:*</Typography>
              <Typography variant="body-text-2">Identify the channel if one exists (e.g. GB News Live) or the origin of the file (Whatsapp message)</Typography>
            </div>
            <TextField
              maxLength="2000"
              placeholder="e.g. GB News Live"
              register={register}
              registerName="document.source"
              registerValidation={{
                maxLength: 2000,
                required: true,
              }}
              rounded={false}
            />
          </div>
        )
        :
        (
          <SearchChannel
            className="add-new-quotation--row"
            control={control}
            getOptionLabel={channel => channel.name}
            getOptionValue={channel => channel.id}
            isMulti={false}
            name="channel"
            placeholder="Search a channel or add a new one"
            title="Related channel"
            useResources={useChannels}
          />
        )
      }

      <div className="add-new-quotation--row">
        <div className="add-new-quotation--row--label">
          <Typography variant="title-2">Rating:</Typography>
        </div>
        <Dropdown
          defaultValue="False"
          options={RATING_TAGS_OPTIONS}
          placeholder="Choose your rating tag"
          {...register('tag')}
        />
      </div>



      <div className="add-new-quotation--row">
        <div className="add-new-quotation--row--label">
          <Typography variant="title-2">Media content:</Typography>
        </div>
        <QuotationFile
          descriptions={descriptions}
          files={files}
          register={register}
          setDescriptions={setDescriptions}
          setFiles={setFiles}
        />
      </div>

      <div className="add-new-quotation--button">
        <Button
          disabled={!isValid}
          onClick={handleSubmit(handleOnAdd)}
          type="button"
          variant="light"
        >
          Add this appearance&nbsp;&nbsp;<Icon icon="arrowright" />
        </Button>
      </div>
    </>
  )
}

AppearancesForm.propTypes = {
  appearances: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    url: PropTypes.string,
  })),
  append: PropTypes.func,
  onAdd: PropTypes.func,
  update: PropTypes.func,
}

AppearancesForm.defaultProps = {
  appearances: [],
  append: () => {},
  onAdd: () => {},
  update: () => {},
}


export default AppearancesForm

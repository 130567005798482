import PropTypes from 'prop-types'
import React from 'react'

import Typography from '../Typography'

import './noData.scss'
const NoData = ({ title, description }) => (
  <div className="no-data">
    <img alt="logo" src="/static/assets/new/noData.svg" />
    <Typography variant="title-1">{title}</Typography>
    <Typography variant="body-text-2">{description}</Typography>
  </div>
)

export default NoData

NoData.defaultProps = {
  title: 'Sorry, we couldn’t find any matches for your search.',
  description: 'Please try searching with another term.',
}

NoData.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
}

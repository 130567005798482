import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'

import './reviewAndClaimDesktop.scss'

import Divider from 'components/Atomic/Atoms/Divider'
import Claim from 'components/Business/Claims/Claim'
import Verdict from 'components/Business/Review/Review'

const ReviewAndClaimDesktop = ({
  id,
  reviewBy,
  reviewByUrl,
  review,
  reviewDate,
  reviewUrl,
  claim,
  claimBy,
  conclusionLabel,
  conclusionScore,
  linkNumber,
  interactionNumber,
}) => {
  return (
    <div className="review-card-desktop">
      <Link className="review-card-desktop--link" to={`/review/${id}`} />
      <div className="review-card-desktop--verdict-section">
        <Verdict
          review={review}
          reviewBy={reviewBy}
          reviewByUrl={reviewByUrl}
          reviewDate={reviewDate}
          reviewUrl={reviewUrl}
          reviewVariant="title-1"
          variant="ghost"
        />
      </div>
      <Divider borderStyle="dashed" orientation="vertical" thickness="thin" />
      <div className="review-card-desktop--claim-section">
        <Claim
          claim={claim}
          claimBy={claimBy}
          conclusionLabel={conclusionLabel}
          conclusionScore={conclusionScore}
          interactionNumber={interactionNumber}
          linkNumber={linkNumber}
        />
      </div>
    </div>
  )
}

export default ReviewAndClaimDesktop

ReviewAndClaimDesktop.defaultProps = {
  claim: '',
  claimBy: '',
  conclusionLabel: '',
  conclusionScore: null,
  id: null,
  interactionNumber: 0,
  linkNumber: 0,
  review: '',
  reviewBy: '',
  reviewByUrl: '',
  reviewDate: '',
  reviewUrl: '',
}

ReviewAndClaimDesktop.propTypes = {
  claim: PropTypes.string,
  claimBy: PropTypes.string,
  conclusionLabel: PropTypes.string,
  conclusionScore: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  id: PropTypes.string,
  interactionNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  linkNumber: PropTypes.number,
  review: PropTypes.string,
  reviewBy: PropTypes.string,
  reviewByUrl: PropTypes.string,
  reviewDate: PropTypes.string,
  reviewUrl: PropTypes.string,
}

import React, { useState } from 'react'
import { useParams, useLocation, useNavigate } from 'react-router-dom'

import { SourcePages as ChannelPage } from 'components'
import { useSource, useContact, useSourceAppearances } from 'hooks'
import { normalizeAppearance } from 'normalizer'

const Source = () => {
  let navigate = useNavigate()
  const { sourceId } = useParams()
  const { pathname, search: urlSearch } = useLocation()
  const sourceType = pathname.split('/')[1]
  const { data: source, refetch: refetchSource } = useSource(
    sourceId,
    sourceType
  )

  const urlTagLabel = new URLSearchParams(urlSearch).get('tag') || ''
  const [tagLabel, setTagLabel] = useState(urlTagLabel)

  const onChooseTagLabel = value => {
    navigate(`/${sourceType}/${sourceId}?tag=${value}`)
    setTagLabel(value)
  }

  const {
    data: appearances,
    fetchNextPage: fetchNextAppearancePage,
    hasNextPage: hasNextAppearancePage,
    isLoading: appearancesAreLoading,
  } = useSourceAppearances(sourceId, sourceType, tagLabel)
  const normalizedAppearances = appearances?.map(claim =>
    normalizeAppearance(claim)
  )

  const contact = useContact()

  const onContact = values => {
    contact.mutate(values)
  }

  return (
    <ChannelPage
      appearances={normalizedAppearances}
      appearancesAreLoading={appearancesAreLoading}
      defaultTagLabel={tagLabel}
      fetchNextAppearancePage={fetchNextAppearancePage}
      hasNextAppearancePage={hasNextAppearancePage}
      onChooseTagLabel={onChooseTagLabel}
      onContact={onContact}
      onRefetchSource={refetchSource}
      source={source}
    />
  )
}

export default Source

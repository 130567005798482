import PropTypes from 'prop-types'
import React from 'react'

import { InteractionCircle } from 'components'

import './noSourceStats.scss'

const NoSourceStats = ({ label, unit }) => {
  return (
    <div className="no-source-stats">
      <InteractionCircle label={label} unit={unit} variant="big" />
      <div>No data here yet</div>
    </div>
  )
}

NoSourceStats.defaultProps = {
  label: '?',
  unit: 'Int.',
}

NoSourceStats.propTypes = {
  label: PropTypes.string,
  unit: PropTypes.string,
}

export default NoSourceStats

import PropTypes from 'prop-types'
import React from 'react'
import './textField.scss'

const TextField = ({
  inputRef,
  register,
  registerName,
  registerValidation = {},
  className,
  rounded = true,
  type = 'text',
  ...rest
}) => (
  <input
    className={`text-field ${className}`}
    data-rounded={rounded}
    type={type}
    {...(inputRef
      ? { ...{ ref: inputRef } }
      : register
      ? { ...register(registerName, registerValidation) }
      : {})}
    {...rest}
  />
)

TextField.defaultProps = {
  className: '',
  inputRef: null,
  register: null,
  registerName: '',
  registerValidation: {},
  rounded: true,
  type: 'text',
}

TextField.propTypes = {
  className: PropTypes.string,
  inputRef: PropTypes.func,
  register: PropTypes.func,
  registerName: PropTypes.string,
  registerValidation: PropTypes.shape({}),
  rounded: PropTypes.bool,
  type: PropTypes.string,
}

export default TextField

import PropTypes from 'prop-types'
import React, { Suspense } from 'react'
import { Link } from 'react-router-dom'

import {
  Divider,
  Icon,
  SourceStatisticCard,
  StatisticCard,
  Typography,
} from 'components'
import FactcheckImport from 'components/Business/Factchecks/FactcheckImport'
import './dashboardPage.scss'

const DashboardPage = ({ stats }) => {
  return (
    <div className="dashboard">
      <div className="dashboard--factchecks">
        <Typography variant="h2">Factcheck import:</Typography>
        <Typography color="secondary-grey" variant="body-text-2">
          Please, choose a factchecker before import an article or a CSV file.
        </Typography>

        <FactcheckImport />
      </div>

      <Divider
        borderStyle="dashed"
        className="dashboard--divider"
        orientation="vertical"
        thickness="thin"
      />

      <div className="dashboard--metrics">
        <div className="dashboard--metrics--header">
          <Typography variant="h2">Reviews:</Typography>
          <Link className="dashboard--metrics--header--link" to="/search">
            <Typography variant="link-text-1">
              {stats.verdictsApproved}
            </Typography>
            <Icon icon="search" />
          </Link>
        </div>
        <div className="dashboard--metrics--statistics-container">
          <Suspense fallback={<div>Loading...</div>}>
            <Link
              className="dashboard--metrics--statistics-container--statistic-card"
              to="/dashboard/reviews/without-appearances"
            >
              <StatisticCard
                number={stats.verdictsWithoutAppearance}
                title="Without appearance"
                total={stats.verdictsApproved}
              />
            </Link>
          </Suspense>
        </div>

        <div className="dashboard--metrics--statistics-container">
          <Suspense fallback={<div>Loading...</div>}>
            <Link
              className="dashboard--metrics--statistics-container--statistic-card"
              to="/dashboard/reviews/without-conclusions"
            >
              <StatisticCard
                className="dashboard--metrics--statistics-container--statistic-card"
                number={stats.verdictsWithoutConclusion}
                title="Without conclusion"
                total={stats.verdictsApproved}
              />
            </Link>
          </Suspense>
        </div>

        <div className="dashboard--metrics--statistics-container">
          <div className="dashboard--metrics--verdicts-metrics-container--card dashboard--metrics--verdicts-metrics-container--card--clickable">
            <Link to="/dashboard/conclusions">
              <Typography variant="body-text-2">
                Standard verdict options:
              </Typography>
              <div className="dashboard--metrics--verdicts-metrics-container--card--footer">
                <div className="dashboard--metrics--verdicts-metrics-container--card--footer--item--before-divider">
                  <Typography variant="title-1">{stats.conclusions}</Typography>
                </div>
                <div className="dashboard--metrics--verdicts-metrics-container--card--footer--item--before-divider">
                  <Divider
                    borderStyle="dashed"
                    className="dashboard-statistic-card--body--footer--divider"
                    orientation="vertical"
                    thickness="thin"
                  />

                  <Typography
                    className="dashboard-statistic-card--add"
                    variant="title-2"
                  >
                    Add <Icon icon="add" />
                  </Typography>
                </div>
              </div>
            </Link>
          </div>
        </div>

        <div className="dashboard--metrics--header--sources">
          <Typography variant="h2">Sources:</Typography>

          <div className="dashboard--metrics--header--sources--row">
            <div className="dashboard--metrics--header--sources--row--item">
              <SourceStatisticCard
                icon="organization"
                link="/search?section=sources&filterBySourceType=organization"
                total={stats.organizations}
                title="Organisation"
              />
            </div>
            <div className="dashboard--metrics--header--sources--row--item">
              <SourceStatisticCard
                icon="media"
                link="/search?section=sources&filterBySourceType=media"
                total={stats.medias}
                title="Medias"
              />
            </div>
          </div>
          <div className="dashboard--metrics--header--sources--row">
            <div className="dashboard--metrics--header--sources--row--item">
              <SourceStatisticCard
                icon="website"
                link="/search?section=sources&filterBySourceType=website"
                total={stats['website-channels']}
                title="Website"
              />
            </div>
            <div className="dashboard--metrics--header--sources--row--item">
              <SourceStatisticCard
                icon="person"
                link="/search?section=sources&filterBySourceType=socialmedia"
                total={stats['social-media-channels']}
                title="Social media channels"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DashboardPage

DashboardPage.defaultProps = {
  stats: {
    conclusions: 0,
    organizations: 0,
    channels: 0,
    medias: 0,
    persons: 0,
    verdictsApproved: 0,
    verdictsWithoutAppearance: 0,
    verdictsWithoutConclusion: 0,
  },
}

DashboardPage.propTypes = {
  stats: PropTypes.shape({
    channels: PropTypes.number,
    conclusions: PropTypes.number,
    medias: PropTypes.number,
    organizations: PropTypes.number,
    persons: PropTypes.number,
    verdictsApproved: PropTypes.number,
    verdictsWithoutAppearance: PropTypes.number,
    verdictsWithoutConclusion: PropTypes.number,
  }),
}

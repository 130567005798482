import PropTypes from 'prop-types'
import React from 'react'

import './Claim.scss'

import Typography from 'components/Atomic/Atoms/Typography'
import ConclusionTag from 'components/Business/Conclusions/ConclusionTag'

import pluralize from '../../../../utils/pluralize'

const Claim = ({
  claim,
  claimBy,
  conclusionLabel,
  conclusionScore,
  displayInteraction,
  linkNumber,
  interactionNumber,
  variant,
}) => {
  const textColor = variant === 'dark' ? 'primary-white' : 'primary-dark'

  return (
    <div className="claim" data-variant={variant}>
      <div className="claim--header">
        <Typography color={textColor} variant="title-1">
          Claim:
        </Typography>

        <ConclusionTag
          color={textColor}
          label={conclusionLabel}
          score={conclusionScore}
        />
      </div>

      <div className="claim--body">
        <Typography
          className="claim--body--claim"
          color={textColor}
          variant="body-text-2"
        >
          {claim}
        </Typography>

        {claimBy && (
          <div className="claim--body--claimBy">
            <Typography
              color={
                textColor === 'primary-dark' ? 'secondary-grey' : textColor
              }
              variant="body-text-4"
            >
              claimed by &apos;{claimBy}&apos;
            </Typography>
          </div>
        )}
      </div>

      <div className="claim--footer">
        {displayInteraction && (
          <Typography color={textColor} variant="numbers">
            <b>{linkNumber}</b>&nbsp;
            {pluralize(linkNumber, 'Link')}
            <b>&nbsp;{interactionNumber}</b>&nbsp;
            {pluralize(interactionNumber, 'Interaction')}
          </Typography>
        )}
      </div>
    </div>
  )
}

export default Claim

Claim.defaultProps = {
  claim: '',
  claimBy: '',
  conclusionLabel: '',
  conclusionScore: null,
  displayInteraction: true,
  interactionNumber: 0,
  linkNumber: 0,
  variant: 'white',
}

Claim.propTypes = {
  claim: PropTypes.string,
  claimBy: PropTypes.string,
  conclusionLabel: PropTypes.string,
  conclusionScore: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  displayInteraction: PropTypes.bool,
  interactionNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  linkNumber: PropTypes.number,
  variant: PropTypes.oneOf(['white', 'dark']),
}

import PropTypes from 'prop-types'
import React from 'react'

import { Footer, OurApi } from 'components'

import Partners from './Partners'
import ReviewsList from './ReviewsList'
import SourcesList from './SourcesList'
import WelcomeAndSearch from './WelcomeAndSearch'

import './homePage.scss'

const HomePage = ({
  onSearch,
  reviews,
  sources,
  onContact,
  nbReviews,
  nbUrl,
}) => {
  return (
    <div className="homepage">
      <WelcomeAndSearch
        nbReviews={nbReviews}
        nbUrl={nbUrl}
        onSearch={onSearch}
      />
      <ReviewsList reviews={reviews} />
      <SourcesList sources={sources} />
      <Partners />
      <div className="homepage--our-api-wrapper">
        <OurApi onSubmit={onContact} />
      </div>
      <Footer onSubmit={onContact} />
    </div>
  )
}

export default HomePage

HomePage.defaultProps = {
  nbReviews: 0,
  nbUrl: 0,
  reviews: [],
  sources: [],
}

HomePage.propTypes = {
  nbReviews: PropTypes.number,
  nbUrl: PropTypes.number,
  onContact: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  reviews: PropTypes.arrayOf(PropTypes.shape({})),
  sources: PropTypes.arrayOf(PropTypes.shape({})),
}

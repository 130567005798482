import React, { useState } from 'react'
import { useFormContext, useFieldArray } from 'react-hook-form'

import { Badge, Select, Typography } from 'components/index'
import { useMedias } from 'hooks'

export const SearchMedias = ({ setShowAdd }) => {
  const { control } = useFormContext()
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'media',
  })

  const onAdd = () => {
    setShowAdd('Media')
  }

  const onChange = value => {
    append(value)
  }

  const onRemove = value => {
    remove(value)
  }

  const [search, setSearch] = useState('')
  const { data, isLoading } = useMedias(search)
  const options = data?.pages[0].data || []

  const onSearch = search => {
    setSearch(search)
  }

  return (
    <div className="edit-source--section">
      <Typography color="primary-white" variant="title-3">
        Related media(s):
      </Typography>
      <Select
        addButton
        addButtonText="Add media"
        getOptionLabel={option => option.name}
        getOptionValue={option => option.id}
        isLoading={isLoading}
        isSearchable
        onAdd={onAdd}
        onChange={onChange}
        onSearch={onSearch}
        options={options}
        placeholder="Search for a media"
      />

      {fields &&
        fields.length > 0 &&
        fields.map(media => (
          <Badge onDelete={() => onRemove(media)} text={media.name} />
        ))}
    </div>
  )
}

export default SearchMedias

import { useMutation, useQueryClient } from 'react-query'

import { API_URL } from 'utils/config'

import { useAxios } from '../../index'


const useOrganizationEditon = () => {
  const queryClient = useQueryClient()
  const axios = useAxios()

  const editOrganization = async values => {
    let url = `${API_URL}/organizations/${values.id}`

    const { data } = await axios.patch(url, { ...values })
    return data
  }

  return useMutation(editOrganization, {
    onMutate: async updatedSource => {
      await queryClient.cancelQueries(['organizations', updatedSource.id])
      const previousSource = queryClient.getQueryData([
        'organizations',
        updatedSource.id,
      ])
      return { previousSource, updatedSource }
    },
    onError: (err, updatedSource, context) => {
      queryClient.setQueryData(
        ['organizations', updatedSource.id],
        context.previousSource
      )
    },
    onSettled: updatedSource => {
      queryClient.invalidateQueries(['organizations', updatedSource.id])
    },
  })
}

export default useOrganizationEditon

import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'

import Button from 'components/Atomic/Atoms/Button'
import Typography from 'components/Atomic/Atoms/Typography'

export const FooterMenu = ({ contactClick }) => {
  return (
    <>
      <Typography color="primary-white" variant="menu-text-1">
        <Link to="/about">About</Link>
      </Typography>
      <Button onClick={contactClick} variant="ghost-reverse-link">
        <Typography color="primary-white" variant="menu-text-1">
          Contact
        </Typography>
      </Button>
    </>
  )
}

FooterMenu.propTypes = {
  contactClick: PropTypes.func.isRequired,
}

export default FooterMenu

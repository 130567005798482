import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'

import Logo from '../../../Atoms/Logo'
import Typography from '../../../Atoms/Typography'
import SideBar from '../../../Molecules/SideBar'

import './headerMobile.scss'

export const HeaderMobile = ({ isAuth, onLoginClick, onLogoutClick }) => {
  const [show, setShow] = useState(false)
  const toggleMobileMenu = () => setShow(!show)
  const location = useLocation()

  useEffect(() => {
    setShow(false)
  }, [location])

  return (
    <header className="header-mobile">
      <Link className="header-mobile--logo" to="/">
        <Logo />
      </Link>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
      <div
        className="header-mobile--menu"
        onClick={toggleMobileMenu}
        role="button"
        tabIndex={0}
      >
        <img alt="logo" src="/static/assets/new/menu.svg" />
        <SideBar onClose={toggleMobileMenu} show={show}>
          <div className="header-mobile--menu--header">
            <Logo title={false} variant="white" />
            <Typography color="primary-white" variant="h1">
              Menu
            </Typography>
          </div>
          <Link to="/">
            <Typography color="primary-white" variant="h2">
              Home
            </Typography>
          </Link>
          <Link to="/search">
            <Typography color="primary-white" variant="h2">
              Search
            </Typography>
          </Link>
          <Link to="/about">
            <Typography color="primary-white" variant="h2">
              About
            </Typography>
          </Link>

          {isAuth ? (
            <div onClick={onLogoutClick}>
              <Typography color="primary-white" variant="h2">
                Logout
              </Typography>
            </div>
          ) : (
            <div onClick={onLoginClick}>
              <Typography color="primary-white" variant="h2">
                Sign in
              </Typography>
            </div>
          )}

          <div className="header-mobile--menu--footer">
            <Typography color="primary-white" variant="body-text-4">
              © Open Feedback. All rights reserved.
            </Typography>
          </div>
        </SideBar>
      </div>
    </header>
  )
}

HeaderMobile.defaultProps = {
  isAuth: false,
}

HeaderMobile.propTypes = {
  isAuth: PropTypes.bool,
  onLoginClick: PropTypes.func.isRequired,
  onLogoutClick: PropTypes.func.isRequired,
}

export default HeaderMobile

import { useMutation } from 'react-query'

import { API_URL } from '../../utils/config'
import { useAxios } from '../index'

const useAddSource = data => {
  const axios = useAxios()

  const addSource = async values => {
    const { sourceType, name, url } = values
    let endpoint = `${API_URL}/${sourceType.toLowerCase()}s`

    const { data } = await axios.post(endpoint, {
      name,
      url,
    })
    return data
  }

  return useMutation(data => addSource(data), data)
}

export default useAddSource

import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'

import {
  MACHINE_ENV,
  SENTRY_DSN,
  SENTRY_TRACES_SAMPLE_RATE,
  VERSION,
} from './config'

export default () => {
  if (SENTRY_TRACES_SAMPLE_RATE) {
    Sentry.init({
      dsn: SENTRY_DSN,
      environment: MACHINE_ENV,
      integrations: [new Integrations.BrowserTracing()],
      release: VERSION,
      tracesSampleRate: parseFloat(SENTRY_TRACES_SAMPLE_RATE),
    })
    Sentry.setTag('component', 'webapp')
  }
}

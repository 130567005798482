import React, { useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { useParams, useNavigate } from 'react-router-dom'

import { FeedbackEditor, ErrorMessage, Select } from 'components'
import { Typography, Dropdown, TextField, Button } from 'components'
import { useAuth, useCreateFeedback, useRoles } from 'hooks'

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import 'draft-js/dist/Draft.css'
import './addFeedback.scss'

const SCORE_OPTIONS = [
  { value: null, text: '' },
  { value: -2, text: '-2 : Very low' },
  { value: -1, text: '-1 : Low' },
  { value: 0, text: '0 : Neutral' },
  { value: 1, text: '1 : High' },
  { value: 2, text: '2 : Very high' },
]

const TAG_OPTIONS = [
  { value: 1, label: 'Accurate' },
  { value: 2, label: 'Alarmist' },
  { value: 3, label: 'Biased' },
  { value: 4, label: 'Derogatory' },
  { value: 3, label: 'Flawed reasoning' },
  { value: 5, label: 'Imprecise' },
  { value: 6, label: 'Inaccurate' },
  { value: 7, label: 'Inappropriate backing' },
  { value: 8, label: 'Insightful' },
  { value: 9, label: 'Misleading' },
  { value: 10, label: 'Sound reasoning' },
  { value: 11, label: 'Unbiased' },
]

const AddFeedback = ({ onSuccess }) => {
  const { feedbackRequestId } = useParams()
  const { handleSubmit, register, control } = useForm({
    defaultValues: {
      feedbackRequestId: feedbackRequestId,
    },
  })

  let navigate = useNavigate()
  const createReview = useCreateFeedback()
  const { auth } = useAuth()
  const { isEditor, isScientist } = useRoles(auth)

  useEffect(() => {
    if (createReview.isSuccess) {
      onSuccess()
    }
  }, [createReview.isSuccess, navigate, feedbackRequestId, onSuccess])

  const onReview = values => {
    const data = {
      ...values,
      tags: values.tags ? values.tags.map(tag => tag.label) : [],
      rating: values.rating ? +values.rating : null,
    }
    createReview.mutate(data)
  }

  if (!isEditor || !isScientist) {
    navigate('/feedback')
  }
  return (
    <div className="add-feedback">
      {createReview.isError && (
        <ErrorMessage message="Oops ! Something went Wrong" />
      )}
      <form onSubmit={handleSubmit(onReview)}>
        <Typography className="add-feedback--label" variant="title-2">
          Your info:
        </Typography>
        <div className="add-feedback--line">
          <TextField
            placeholder="First Name"
            register={register}
            registerName="scientist.firstName"
            registerValidation={{ required: true }}
            rounded={false}
          />
          <TextField
            placeholder="Last Name"
            register={register}
            registerName="scientist.lastName"
            registerValidation={{ required: true }}
            rounded={false}
          />
        </div>

        <TextField
          placeholder="mail"
          register={register}
          registerName="scientist.email"
          registerValidation={{ required: true }}
          rounded={false}
        />

        <Typography className="add-feedback--label" variant="title-2">
          Assessment:
        </Typography>

        <Controller
          control={control}
          defaultValue=""
          name="comment"
          render={({ field }) => (
            <FeedbackEditor onChange={field.onChange} value={field.value} />
          )}
        />

        <Typography className="add-feedback--label" variant="title-2">
          Credibility:
        </Typography>
        <div className="add-feedback--line">
          <Dropdown
            className="add-feedback--select-score"
            defaultValue={0}
            options={SCORE_OPTIONS}
            {...register('rating')}
          />

          <Controller
            control={control}
            name="tags[]"
            render={({ field: { onChange, value, ref } }) => {
              return (
                <Select
                  className="add-feedback--select-tag"
                  inputRef={ref}
                  isMulti
                  onChange={onChange}
                  options={TAG_OPTIONS}
                  value={value}
                />
              )
            }}
          />
        </div>

        <div className="add-feedback--action">
          <Button variant="ghost"> Save </Button>
        </div>
      </form>
    </div>
  )
}

export default AddFeedback

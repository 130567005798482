import React from 'react'
import { useMediaQuery } from 'react-responsive'

import AboutDatabaseBuildDesktop from './AboutDatabaseBuildDesktop'
import AboutDatabaseBuildMobile from './AboutDatabaseBuildMobile'

const AboutDatabaseBuild = ({ onContact }) => {
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' })

  if (isMobile) {
    return <AboutDatabaseBuildMobile onContact={onContact} />
  } else {
    return <AboutDatabaseBuildDesktop onContact={onContact} />
  }
}

export default AboutDatabaseBuild

import React from 'react'

import { AboutUs as AboutUsPage } from 'components'
import { useContact } from 'hooks'

const AboutUs = () => {
  const contact = useContact()

  const onContact = values => {
    contact.mutate(values)
  }

  return <AboutUsPage onContact={onContact} />
}
export default AboutUs

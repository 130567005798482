import PropTypes from 'prop-types'
import React, { useState } from 'react'

import {
  Button,
  ConfirmBar,
  ConfirmDeleteQuotation,
  FullReport,
  Icon,
  ReviewDetails,
  Typography,
} from 'components'
import AppearanceTagsSelectField from 'components/Business/Appearances/AppearanceTagsSelectField'
import AddQuotation from 'components/Business/Quotations/AddQuotation'
import EditQuotationsForm from 'components/Business/Quotations/EditQuotationsForm'
import ReviewHeader from 'components/Business/Review/ReviewHeader'
import SyncReview from 'components/Business/Review/SyncReview'
import {
  useAuth,
  useFactcheckerAuth,
  useReattributeAppearance,
  useRoles,
} from 'hooks'

import QuotationsList from './QuotationsList'

import './reviewPage.scss'

const ReviewPage = ({
    appearances,
    claimAppearancesPageAreLoading,
    defaultTagLabel,
    hasNextClaimAppearancesPage,
    fetchNextClaimAppearancesPage,
    onAddAppearance,
    onAddSuggestedAppearances,
    onChooseTagLabel,
    onContact,
    onDeleteAppearanceVerdict,
    onFlagAppearances,
    onModifyAppearanceVerdict,
    onRefetchClaimAppearances,
    onResetAppearanceSharesVerdict,
    onSyncReview,
    review,
  }) => {
  const { auth } = useAuth()
  const isLoggedIn = !!auth.user
  const { isEditor } = useRoles(auth)
  const isFactcheckerAuthorized = useFactcheckerAuth(review.factcheckerId)

  const [appearancesChosen, setAppearancesChosen] = useState([])
  const onAddChosenAppearance = appearance => {
    setAppearancesChosen([...appearancesChosen, appearance])
  }
  const onRemoveChosenAppearance = appearance => {
    setAppearancesChosen(
      appearancesChosen.filter(
        appearanceChosen => appearanceChosen.id !== appearance.id
      )
    )
  }

  let showEditAppearanceForm = false
  if (appearancesChosen.length >= 1) {
    showEditAppearanceForm = true
  }

  const onSelectAppearance = appearance => {
    if (
      !appearancesChosen
        .map(appearanceChosen => appearanceChosen.id)
        .includes(appearance.id)
    ) {
      onAddChosenAppearance(appearance)
    } else {
      onRemoveChosenAppearance(appearance)
    }
  }

  const onEditAppearanceClose = () => {
    setAppearancesChosen([])
  }

  const onCopy = () => {
    const urls = appearancesChosen.reduce(
      (text, appearance) => `${text}${appearance.url}\n`,
      ''
    )
    navigator.clipboard.writeText(urls)
  }

  const onFlag = () => {
    onFlagAppearances(appearancesChosen, true)
  }

  const onUnflag = () => {
    onFlagAppearances(appearancesChosen, false)
  }

  // ADD NEW APPEARANCE
  const [showAddNewAppearanceForm, setShowAddNewAppearanceForm] =
    useState(false)
  const toggleAddNewAppearanceForm = () =>
    setShowAddNewAppearanceForm(!showAddNewAppearanceForm && (isFactcheckerAuthorized || isEditor))

  const [showNewAppearanceAddedConfimBar, setShowNewAppearanceAddedConfimBar] =
    useState(false)
  const toggleShowNewAppearanceAddedConfimBar = () =>
    setShowNewAppearanceAddedConfimBar(!showNewAppearanceAddedConfimBar)

  const addNewLink = values => {
    toggleShowNewAppearanceAddedConfimBar()
    setTimeout(() => {
      setShowNewAppearanceAddedConfimBar(false)
    }, 3000)
    onAddAppearance(review.claimId, values)
  }

  const addNewSuggestedAppearances = values => {
    toggleShowNewAppearanceAddedConfimBar()
    setTimeout(() => {
      setShowNewAppearanceAddedConfimBar(false)
    }, 3000)
    onAddSuggestedAppearances(review.claimId, values)
  }

  // Delete appearance
  const [appearanceToDelete, setAppearanceToDelete] = useState(null)
  const [showConfirmDeleteAppearance, setShowConfirmDeleteAppearance] =
    useState(false)
  const toggleShowConfirmDeleteAppearance = () =>
    setShowConfirmDeleteAppearance(!showConfirmDeleteAppearance)
  const [showAppearanceDeleted, setShowAppearanceDeleted] = useState(false)
  const toggleShowAppearanceDeleted = () =>
    setShowAppearanceDeleted(!showAppearanceDeleted)

  const onDeleteAppearance = appearance => {
    console.log('on delete')
    setAppearanceToDelete(appearance)
    toggleShowConfirmDeleteAppearance()
  }
  const onDeleteAppearanceConfirm = () => {
    onDeleteAppearanceVerdict(appearanceToDelete)
    toggleShowAppearanceDeleted()
    setTimeout(() => {
      setShowConfirmDeleteAppearance(false)
      setShowAppearanceDeleted(false)
    }, 3000)
  }

  // Reattribute appearance
  const reattributeAppearance = useReattributeAppearance()
  const [showAppearanceReattributed, setShowAppearanceReattributed] =
    useState(false)
  const [appearanceReattributedMessage, setAppearanceReattributedMessage] =
    useState('')
  const toggleShowAppearanceReattributed = () =>
    setShowAppearanceReattributed(!showAppearanceReattributed)

  const onReattributeAppearance = async appearanceId => {
    reattributeAppearance.mutate(appearanceId, {
      onSuccess: result => {
        onRefetchClaimAppearances()
        if (result === 'Reattributed') {
          setAppearanceReattributedMessage(
            'Appearance has been reattributed to channel'
          )
        } else {
          setAppearanceReattributedMessage('No channel has been found')
        }
        toggleShowAppearanceReattributed()
        setTimeout(() => {
          setShowAppearanceReattributed(false)
        }, 3000)
      },
    })
  }

  const appearanceUrls = appearances.reduce((acc, appearance) => {
    return [
      ...acc,
      {
        text: appearance.appearanceUrl,
        value: appearance.appearanceUrl,
      },
    ]
  }, [])

  return (
    <div className="reviewpage-desktop">
      <div className="reviewpage-desktop--review">
        <ReviewHeader {...review} />
      </div>
      <div className="reviewpage-desktop--dotted-list">
        <div className="reviewpage-desktop--dotted-list--content">
          <div className="reviewpage-desktop--dotted-list--content--header" />

          {isLoggedIn && (isEditor || isFactcheckerAuthorized) && (
            <div className="reviewpage-desktop--dotted-list--content--header">
              <SyncReview
                onSubmit={onSyncReview}
                syncLastDate={review.syncLastDate}
              />
            </div>
          )}

          <div className="reviewpage-desktop--dotted-list--content--review-details">
            <ReviewDetails
              claimantId={review.claimantId}
              claimantName={review.claimBy}
              conclusionScore={review.conclusionScore}
              conclusionStandardForm={review.conclusionStandardForm}
              factcheckerVerdict={review.factcheckerVerdict}
              fullReviewLink={review.reviewUrl}
              initialClaim={review.initialClaim}
              isFactcheckerAuthorized={isFactcheckerAuthorized}
              isLoggedIn={isLoggedIn}
              reviewBy={review.reviewBy}
              reviewByUrl={review.reviewByUrl}
              reviewTitle={review.review}
              standardClaim={review.standardClaim}
              topics={review.topicTags}
            />
          </div>
          <div className="reviewpage-desktop--dotted-list--content--body">
            <Typography
              className="reviewpage-desktop--dotted-list--content--body--title"
              color="primary-dark"
              variant="h3"
            >
              Appearances:
            </Typography>

            <Typography color="primary-dark" variant="body-text-4">
              Links from media and channels that quoted claim that have been
              fact-checked
            </Typography>

            {!showAddNewAppearanceForm ? (
              <>
                <div className="reviewpage-desktop--dotted-list--content--body--appearances-header">
                  <Typography color="primary-dark" variant="title-1">
                    {review.linkNumber} Links : {review.interactionNumber}{' '}
                    interactions
                  </Typography>
                  <div className="reviewpage-desktop--dotted-list--content--body--appearances-header--actions">
                    <AppearanceTagsSelectField
                      defaultValue={defaultTagLabel}
                      onChoose={e => onChooseTagLabel(e.target.value)}
                    />
                    {isLoggedIn && (isEditor || isFactcheckerAuthorized) && (
                      <Button
                        onClick={toggleAddNewAppearanceForm}
                        variant="ghost"
                      >
                        Add
                      </Button>
                    )}
                  </div>
                </div>
                <div className="reviewpage-desktop--dotted-list--content--body--appearances-list">
                  <QuotationsList
                    appearances={appearances}
                    appearancesChecked={appearancesChosen}
                    areLoading={claimAppearancesPageAreLoading}
                    fetchNext={fetchNextClaimAppearancesPage}
                    hasNext={hasNextClaimAppearancesPage}
                    isFactcheckerAuthorized={isFactcheckerAuthorized}
                    isLoggedIn={isLoggedIn}
                    onCheck={onSelectAppearance}
                    onContact={onContact}
                    onDeleteAppearanceVerdict={onDeleteAppearance}
                    onModifyAppearanceVerdict={onModifyAppearanceVerdict}
                    onReattributeAppearanceVerdict={onReattributeAppearance}
                    onRefetchClaimAppearances={onRefetchClaimAppearances}
                  />
                  {!isLoggedIn && appearances.length > 4 && (
                    <FullReport onSubmit={onContact} />
                  )}
                </div>
              </>
            ) : (
              <div className="reviewpage-desktop--dotted-list--content--body--add-appearance">
                <div className="reviewpage-desktop--dotted-list--content--body--add-appearance--header">
                  <Typography variant="title-1">Add new appearance:</Typography>

                  <Button
                    onClick={toggleAddNewAppearanceForm}
                    variant="default-link"
                  >
                    <Typography variant="title-1">Cancel</Typography>
                    <Icon icon="cross" />
                  </Button>
                </div>
                <div className="reviewpage-desktop--dotted-list--content--body--add-appearance--body">
                  <AddQuotation
                    appearanceUrls={appearanceUrls}
                    claimId={review.claimId}
                    claimOuterLinksCandidatesCount={
                      review.claimOuterLinksCandidatesCount
                    }
                    onAddSuggestedAppearances={addNewSuggestedAppearances}
                    onClose={toggleAddNewAppearanceForm}
                    onSubmit={addNewLink}
                    show={showAddNewAppearanceForm}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {(isFactcheckerAuthorized || isEditor) &&
        <>
          <EditQuotationsForm
            nbAppearances={appearancesChosen.length}
            onClose={onEditAppearanceClose}
            onCopy={onCopy}
            onFlag={onFlag}
            onUnflag={onUnflag}
            show={showEditAppearanceForm}
          />

          <ConfirmBar
            onClose={toggleShowNewAppearanceAddedConfimBar}
            show={showNewAppearanceAddedConfimBar}
            text="Link have been added"
          />

          <ConfirmDeleteQuotation
            isAppearanceDeleted={showAppearanceDeleted}
            onClose={toggleShowConfirmDeleteAppearance}
            onSubmit={onDeleteAppearanceConfirm}
            show={showConfirmDeleteAppearance}
          />

          <ConfirmBar
            onClose={toggleShowAppearanceReattributed}
            show={showAppearanceReattributed}
            text={appearanceReattributedMessage}
          />
        </>
      }
    </div>
  )
}

export default ReviewPage

ReviewPage.defaultProps = {
  appearances: [],
  claimAppearancesPageAreLoading: false,
  claimSuggestedAppearancesAreLoading: false,
  defaultTagLabel: '',
  fetchNextClaimAppearancesPage: () => null,
  hasNextClaimAppearancesPage: undefined,
  onAddAppearance: () => null,
  onAddSuggestedAppearances: () => null,
  onCreateConclusion: () => null,
  onDeleteAppearanceVerdict: () => null,
  onFlagAppearances: () => null,
  onModifyAppearanceVerdict: () => null,
  onModifyClaim: () => null,
  onModifyConclusion: () => null,
  onReattributeAppearanceVerdict: () => null,
  onResetAppearanceSharesVerdict: () => null,
  onSyncReview: () => null,
  review: {},
}

ReviewPage.propTypes = {
  appearances: PropTypes.arrayOf(PropTypes.shape({})),
  claimAppearancesPageAreLoading: PropTypes.bool,
  defaultTagLabel: PropTypes.string,
  fetchNextClaimAppearancesPage: PropTypes.func,
  hasNextClaimAppearancesPage: PropTypes.bool,
  onAddAppearance: PropTypes.func,
  onAddSuggestedAppearances: PropTypes.func,
  onChooseTagLabel: PropTypes.func.isRequired,
  onCreateConclusion: PropTypes.func,
  onDeleteAppearanceVerdict: PropTypes.func,
  onFlagAppearances: PropTypes.func,
  onModifyAppearanceVerdict: PropTypes.func,
  onModifyClaim: PropTypes.func,
  onModifyConclusion: PropTypes.func,
  onReattributeAppearanceVerdict: PropTypes.func,
  onResetAppearanceSharesVerdict: PropTypes.func,
  onSyncReview: PropTypes.func,
  review: PropTypes.shape({
    claimantId: PropTypes.string,
    claimId: PropTypes.string,
    claimBy: PropTypes.string,
    conclusionId: PropTypes.string,
    conclusionLabel: PropTypes.string,
    conclusionScore: PropTypes.number,
    conclusionStandardForm: PropTypes.string,
    factcheckerId: PropTypes.string,
    initialClaim: PropTypes.string,
    initialLabel: PropTypes.string,
    initialTagId: PropTypes.string,
    isConclusion: PropTypes.bool,
    reviewBy: PropTypes.string,
    standardClaim: PropTypes.string,
    standardClaimId: PropTypes.string,
    syncLastDate: PropTypes.string,
    topicTags: PropTypes.arrayOf(PropTypes.string),
  }),
}

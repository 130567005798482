import React, { useState } from 'react'

import { Dropdown, Tab, TabPanel, TabsContext } from 'components'
import FactcheckCsvImport from 'components/Business/Factchecks/FactcheckCsvImport'
import FactcheckScrapper from 'components/Business/Factchecks/FactcheckScrapper'
import { useFactcheckers } from 'hooks'
import './factcheckImport.scss'

export const FactcheckImport = () => {
  const { data: factcheckers } = useFactcheckers()
  const FACTCHECKERS_OPTIONS = factcheckers?.reduce((options, factchecker) => {
    return [
      ...options,
      {
        value: factchecker.name,
        text: factchecker.name,
      },
    ]
  }, [])

  const [factchecker, setFactchecker] = useState(null)

  const onChooseFactchecker = value => {
    const chosenFactchecker = factcheckers.find(
      factchecker => factchecker.name === value
    )
    setFactchecker(chosenFactchecker)
  }

  return (
    <div className="factcheck-import">
      <Dropdown
        defaultValue=""
        onChange={e => onChooseFactchecker(e.target.value)}
        options={FACTCHECKERS_OPTIONS}
        placeholder="Factchecker"
      />

      <TabsContext defaultTab="scrap-factcheck">
        <div className="factcheck-import--tabs">
          <Tab
            className="factcheck-import--tabs--tab"
            text="Factcheck article"
            value="scrap-factcheck"
          />
          <Tab
            className="factcheck-import--tabs--tab"
            text="CSV import tool:"
            value="csv-import"
          />
        </div>

        <TabPanel value="scrap-factcheck">
          <FactcheckScrapper factchecker={factchecker} />
        </TabPanel>

        <TabPanel value="csv-import">
          <FactcheckCsvImport factchecker={factchecker} />
        </TabPanel>
      </TabsContext>
    </div>
  )
}

export default FactcheckImport

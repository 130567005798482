import { useQuery } from 'react-query'

import { normalizeFeedbackRequest } from 'normalizer'
import { API_URL } from 'utils/config'

import { useAxios } from '../index'

export default function useFeedbackRequests() {
  const axios = useAxios()

  const getFeedbackRequest = async () => {
    const { data } = await axios.get(`${API_URL}/feedback-requests`)

    return {
      data,
    }
  }

  return useQuery(['feedback-request'], () => getFeedbackRequest(), {
    select: data => data.data.map(item => normalizeFeedbackRequest(item)),
  })
}

import PropTypes from 'prop-types'
import React from 'react'

import { Divider, Icon, Typography } from 'components'


const ValueCard = ({ number, title }) => {

  return (
    <div className="dashboard-statistic-card" role="navigation">
      <div className="dashboard-statistic-card--body dashboard-statistic-card--body--no-marging">
        <div className="dashboard-statistic-card--body--header">
          <Typography color="secondary-grey" variant="body-text-2">
            {title}
          </Typography>
        </div>
        <div className="dashboard-statistic-card--body--footer">
          <div className="dashboard-statistic-card--body--footer--item--before-divider">
            <Typography variant="title-1">{number}</Typography>
          </div>

          <div className="dashboard-statistic-card--body--footer--item--before-divider">
            <Divider
              borderStyle="dashed"
              className="dashboard-statistic-card--body--footer--divider"
              orientation="vertical"
              thickness="thin"
            />
            <Typography
              className="dashboard-statistic-card--add"
              variant="title-2"
            >
              Add <Icon icon="add" />
            </Typography>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ValueCard

ValueCard.defaultProps = {
  number: 0,
  title: '',
  total: 1,
}

ValueCard.propTypes = {
  number: PropTypes.number,
  title: PropTypes.string,
  total: PropTypes.number,
}

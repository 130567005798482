import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'

import Divider from 'components/Atomic/Atoms/Divider'
import Icon from 'components/Atomic/Atoms/Icon'
import Typography from 'components/Atomic/Atoms/Typography'
import ConclusionTag from 'components/Business/Conclusions/ConclusionTag'
import { numberShortener } from 'utils/shorteners'

import './claimCardMobile.scss'

const ClaimCardMobile = ({
  claim,
  claimId,
  claimBy,
  conclusionLabel,
  conclusionScore,
  linkNumber,
  interactionNumber,
  reviewId,
}) => {
  return (
    <div className="claim-card-mobile">
      <div className="claim-card-mobile--content">
        <Divider
          borderStyle="solid"
          orientation="vertical"
          score={conclusionScore}
          thickness="thick"
        />
        <div className="claim-card-mobile--content--section">
          <div className="claim-card-mobile--content--section--header">
            <Typography variant="title-1">Claim:</Typography>

            <ConclusionTag label={conclusionLabel} score={conclusionScore} />
          </div>

          <div className="claim-card-mobile--content--section--body">
            <Typography
              className="claim-card-mobile--content--section--body--claim"
              variant="body-text-4"
            >
              {claim}
            </Typography>

            <div className="claim-card-mobile--content--section--body--link">
              {claimBy && (
                <Typography variant="body-text-4">
                  claimed by &apos;{claimBy}&apos;
                </Typography>
              )}
            </div>

            <div className="claim-card-mobile--content--section--body--review-button">
              <Link to={`/review/${reviewId}`}>
                <Typography variant="title-3">
                  Read review <Icon icon="arrowright" />
                </Typography>
              </Link>
            </div>
          </div>

          <Divider borderStyle="dashed" thickness="thin" />

          <div className="claim-card-mobile--content--section--footer">
            {linkNumber !== undefined && interactionNumber !== undefined && (
              <Typography variant="title-3">
                {linkNumber} Links: {numberShortener(interactionNumber)}{' '}
                interactions &nbsp;
              </Typography>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

/**/

ClaimCardMobile.defaultProps = {
  claimBy: 'Unknown',
  conclusionLabel: 'Not rated',
  conclusionScore: null,
  factCheckContentUrl: null,
  interactionNumber: 0,
  linkNumber: 0,
  links: [],
  reviewId: null,
}

ClaimCardMobile.propTypes = {
  claim: PropTypes.string.isRequired,
  claimBy: PropTypes.string,
  conclusionLabel: PropTypes.string,
  conclusionScore: PropTypes.oneOf([0, 1, 2, 3, 4, 5]),
  factCheckContentUrl: PropTypes.string,
  interactionNumber: PropTypes.number,
  linkNumber: PropTypes.number,
  links: PropTypes.arrayOf(PropTypes.shape({})),
  reviewId: PropTypes.string,
}

export default ClaimCardMobile

import PropTypes from 'prop-types'
import React, { useState } from 'react'

import { Button, CsvDropZone, ErrorMessage, ConfirmBar } from 'components'
import { useAppearancesCsvValidation, useImportAppearancesCsv } from 'hooks'

import CsvInfo from './CsvInfo'
import './factcheckCsvImport.scss'

export const FactcheckCsvImport = ({ factchecker }) => {
  const csvValidation = useAppearancesCsvValidation()
  const {
    data: csvValidationData,
    error: csvValidationError,
    isError: csvValidationIsError,
    isLoading: csvValidationIsLoading,
    reset: csvValidationReset,
  } = csvValidation
  const importCsv = useImportAppearancesCsv()
  const [showFileIsImported, setShowFileIsImported] = useState(false)
  const toggleShowFileIsImported = () =>
    setShowFileIsImported(!showFileIsImported)
  const [file, setFile] = useState([])

  const formatFile = file => {
    let formData = new FormData()

    formData.append('facebook-file', file, file.name)

    return formData
  }

  const [step, setStep] = useState('validate') // validate or import

  const onDrop = acceptedFiles => {
    setFile(acceptedFiles)
  }

  const validateFile = () => {
    if (file.length > 0) {
      let formData = formatFile(file[0])
      csvValidation.mutate(formData, {
        onSuccess: () => {
          setStep('import')
        },
      })
    }
  }

  const uploadFile = () => {
    let formData = formatFile(file[0])
    formData.append('factchecker', factchecker.name)
    importCsv.mutate(formData, {
      onSuccess: () => {
        csvValidationReset()
        setStep('validate')
        toggleShowFileIsImported()
        setTimeout(() => {
          setShowFileIsImported(false)
        }, 3000)
      },
    })

    setFile([])
  }

  return (
    <>
      <div className="factcheck-csv-import">
        <CsvDropZone file={file} onDrop={onDrop} />
        <CsvInfo
          data={csvValidationData}
          error={csvValidationError}
          isError={csvValidationIsError}
          isLoading={csvValidationIsLoading}
        />
        {!factchecker && <ErrorMessage message="Please choose a factchecker" />}
        <div className="factcheck-csv-import--button">
          {step === 'import' ? (
            <Button
              disabled={
                !factchecker || file.length === 0 || csvValidation.error
              }
              onClick={uploadFile}
              variant="ghost"
            >
              Import data
            </Button>
          ) : (
            <Button
              disabled={!factchecker || file.length === 0}
              onClick={validateFile}
              variant="ghost"
            >
              Upload
            </Button>
          )}
        </div>
      </div>
      <ConfirmBar
        onClose={toggleShowFileIsImported}
        show={showFileIsImported}
        text="Your CSV will soon be uploaded to the database"
      />
    </>
  )
}

FactcheckCsvImport.defaultProps = {
  factchecker: null,
}

FactcheckCsvImport.propTypes = {
  factchecker: PropTypes.shape({
    name: PropTypes.string,
    scrappable: PropTypes.bool,
  }),
}

export default FactcheckCsvImport

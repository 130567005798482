import { useMutation } from 'react-query'

import { API_URL } from 'utils/config'

import { useAxios } from '../index'

export default function useCreateFeedbackRequest() {
  const axios = useAxios()

  const postClaim = async values => {
    const { data } = await axios.post(`${API_URL}/feedback-requests`, values)
    return data
  }

  return useMutation(data => postClaim(data))
}

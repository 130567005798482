import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'

import {
  Button,
  Dropdown,
  ConfirmBar,
  Icon,
  TextAreaField,
  TextField,
  Typography,
  SideBar,
} from 'components'

import './contactForm.scss'

export const ContactForm = ({
  show,
  onClose,
  onSubmit,
  defaultValue = 'REPORT',
}) => {
  const { handleSubmit, register, reset } = useForm()

  const [showMessageSent, setShowMessageSent] = useState(false)
  const toggleShowMessageSent = () => setShowMessageSent(!showMessageSent)

  const onSend = values => {
    onClose()
    onSubmit(values)
    reset()
    toggleShowMessageSent()
    setTimeout(() => {
      setShowMessageSent(false)
    }, 3000)
  }

  const SELECT_TAGS_OPTIONS = [
    { value: 'API', text: 'API' },
    { value: 'REPORT', text: 'REPORT' },
    { value: 'OTHER', text: 'OTHER' },
  ]

  return (
    <>
      <SideBar onClose={onClose} show={show}>
        <form className="contact-form" onSubmit={handleSubmit(onSend)}>
          <Typography
            className="contact-form--title"
            color="primary-white"
            variant="h2"
          >
            Contact us
          </Typography>
          <Dropdown
            defaultValue={defaultValue}
            options={SELECT_TAGS_OPTIONS}
            {...register('subjectType')}
            placeholder="Choose your subject*"
          />
          <div className="contact-form--row">
            <TextField
              placeholder="First name*"
              register={register}
              registerName="firstName"
              rounded={false}
            />
            <TextField
              placeholder="Last name*"
              register={register}
              registerName="lastName"
              rounded={false}
            />
          </div>
          <TextField
            placeholder="Your e-mail address*"
            register={register}
            registerName="email"
            rounded={false}
          />
          <TextAreaField
            placeholder="Your message*"
            register={register}
            registerName="message"
            rounded={false}
          />
          <div className="contact-form--buttons">
            <Button type="submit" variant="ghost-reverse">
              Send <Icon color="#FFFFFF" icon="arrowright" />
            </Button>
          </div>
        </form>
      </SideBar>
      <ConfirmBar
        onClose={toggleShowMessageSent}
        show={showMessageSent}
        text="Your message has been sent"
      />
    </>
  )
}

ContactForm.defaultProps = {
  show: false,
}

ContactForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  show: PropTypes.bool,
}

export default ContactForm

import { useMutation } from 'react-query'

import { useAxios } from 'hooks'

import { API_URL } from 'utils/config'

export default function useAppearancesCsvValidation(file) {
  const instances = useAxios()
  const postImportCsv = async file => {
    const { data } = await instances.post(
      `${API_URL}/appearances/facebook-file/validation`,
      file
    )
    return data
  }
  return useMutation(file => postImportCsv(file), file)
}

import { useState } from 'react'
import React from 'react'
import { useFormContext, useFieldArray } from 'react-hook-form'

import { Badge, Select, Typography } from 'components/index'
import { usePersons } from 'hooks'

export const SearchPersons = () => {
  const { control } = useFormContext()
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'owners',
  })

  const onAdd = value => {
    append(value)
  }

  const onRemove = value => {
    remove(value)
  }

  const [search, setSearch] = useState('')
  const { data, isLoading } = usePersons(search)
  const options = data?.pages[0].data || []

  const onSearch = search => {
    setSearch(search)
  }

  return (
    <div className="edit-source--section">
      <Typography color="primary-white" variant="title-3">
        Controlled by person(s):
      </Typography>
      <Select
        getOptionLabel={option => option.name}
        getOptionValue={option => option.id}
        isLoading={isLoading}
        isSearchable
        onChange={onAdd}
        onSearch={onSearch}
        options={options}
        placeholder="Search for a person"
      />

      {fields &&
        fields.length > 0 &&
        fields.map(person => (
          <Badge onDelete={() => onRemove(person)} text={person.name} />
        ))}
    </div>
  )
}

export default SearchPersons

import { useInfiniteQuery } from 'react-query'

import { API_URL } from 'utils/config'

import { useAxios } from '../../index'

export default function usePersons(search) {
  const axios = useAxios()

  const getPersons = async (search = '', { pageParam = 0 }) => {
    const { data, headers } = await axios.get(
      `${API_URL}/persons?page=${pageParam}&keywords=${search}&paginate=20`
    )
    return {
      data,
      hasNextPage: headers['has-more'] && pageParam + 1,
    }
  }

  return useInfiniteQuery(
    ['person', search],
    params => getPersons(search, params),
    {
      getNextPageParam: lastPage => lastPage.hasNextPage,
    }
  )
}

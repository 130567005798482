import PropTypes from 'prop-types'
import React, {
  useCallback,
  useEffect,
  useState,
} from 'react'
import { useForm } from 'react-hook-form'

import {
  Button,
  ConfirmBar,
  ErrorMessage,
  TextField,
  Typography,
} from 'components'
import { useScrapFactcheck } from 'hooks'
import capitalize from 'utils/capitalize'
import { validateUrl } from 'validator'

export const FactcheckLoad = ({
  factcheck,
  factchecker,
  isFormClear,
  setFactcheck,
  setIsFormClear,
}) => {
  const [buttonText, setButtonText] = useState('Get article info')
  const [buttonFunction, setButtonFunction] = useState('submit')
  const [errorMessage, setErrorMessage] = useState(null)

  const {
    handleSubmit,
    formState: {
      errors,
      isLoading,
      isSubmitting,
      isValid,
    },
    register,
    reset,
    setValue,
  } = useForm({ mode: 'onChange' })

  const scrapFactcheck = useScrapFactcheck(factcheck, 'scrape')

  const onScrapFactcheck = useCallback(async data => {
    scrapFactcheck.mutate(data, {
      onSuccess: data => {
        setFactcheck(data)
        setButtonText('Erase article info')
        setButtonFunction('reset')
      },

      onError: err => {
        const errorMessage = (Object.entries(err.response.data?.[0] || {})
                                    .map(([error, message]) => `${capitalize(error)}: ${message}`) || ["Unknown error"])
                                    ?.[0]
        setErrorMessage(errorMessage)
        setTimeout(() => {
          setErrorMessage(null)
        }, 5000)

      },
    })
  }, [
    scrapFactcheck,
    setFactcheck,
    setButtonFunction,
    setButtonText,
    setErrorMessage,
  ])

  const onSubmitForm = useCallback(values => {
    if (buttonFunction === 'submit') {
      const data = {
        ...values,
        factchecker,
      }
      setIsFormClear(false)
      onScrapFactcheck(data)
    } else {
      setFactcheck({})
      setIsFormClear(true)
    }
  }, [
    buttonFunction,
    factchecker,
    onScrapFactcheck,
    setFactcheck,
    setIsFormClear,
  ])

  useEffect(() => {
    if (isFormClear) {
      setButtonText('Get article info')
      setButtonFunction('submit')
      setValue('url', null)
      reset()
    }
  }, [
    isFormClear,
    reset,
    setButtonFunction,
    setButtonText,
    setValue,
  ])

  return (
    <>
      <form
        className="factcheck-scrapper"
        onSubmit={handleSubmit(onSubmitForm)}
      > 
        <Typography variant="title-3">
          Link*
        </Typography>
        <TextField
          maxLength="2000"
          placeholder="Paste your link"
          register={register}
          registerName="url"
          registerValidation={{
            required: true,
            maxLength: 2000,
            validate: validateUrl,
          }}
          rounded={false}
        />
        {errors?.url?.type === 'validate' && (
          <ErrorMessage message="Please enter a valid url (e.g https://open.feedback.org)" />
        )}
        {factchecker && !factchecker.scrappable && (
          <ErrorMessage message="Please choose a scrappable factchecker" />
        )}
        <div className="factcheck-scrapper--button">
          <Button
            disabled={!factchecker || !factchecker.scrappable || errors?.url || isLoading || isSubmitting || !isValid}
            type="submit"
            variant="ghost"
          >
            {buttonText}
          </Button>
        </div>
      </form>
      <ConfirmBar
        onClose={() => setErrorMessage(null)}
        show={errorMessage ? true : false}
        text={errorMessage}
      />
    </>
  )
}

FactcheckLoad.defaultProps = {
  factcheck: {},
  isFormClear: false,
  setFactcheck: () => {},
  setIsFormClear: () => {},
}

FactcheckLoad.propTypes = {
  factcheck: PropTypes.shape({}),
  factchecker: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    scrappable: PropTypes.bool,
    url: PropTypes.string,
  }).isRequired,
  isFormClear: PropTypes.bool,
  setFactcheck: PropTypes.func,
  setIsFormClear: PropTypes.func,
}

export default FactcheckLoad

import { useMutation } from 'react-query'

import { API_URL } from 'utils/config'

import { useAxios } from '../index'

export default function useCreateFeedback() {
  const axios = useAxios()

  const postReview = async body => {
    const { data } = await axios.post(`${API_URL}/feedbacks`, body)
    return data
  }

  return useMutation(data => postReview(data))
}

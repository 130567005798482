import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import { useDropzone } from 'react-dropzone'

import { Typography } from 'components'

const baseStyle = {
  /*alignItems: 'center',
  backgroundColor: '#fafafa',
  borderRadius: 2,
  color: '#bdbdbd',
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  outline: 'none',
  padding: '20px',
  transition: 'border .24s ease-in-out',
  width: '100%',*/
  cursor: 'pointer',
  borderWidth: 1,
  borderStyle: 'dashed',
  borderColor: '#5A7770',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '20px',
  gap: '30px',
}

const activeStyle = {
  borderColor: '#2196f3',
}

const acceptStyle = {
  borderColor: '#00e676',
}

const rejectStyle = {
  borderColor: '#ff1744',
}

const CsvDropZone = ({ file, onDrop }) => {
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ accept: 'text/csv', onDrop })

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  )

  const fileDropped = file.length > 0

  /* eslint-disable react/jsx-no-bind */
  return (
    <div {...getRootProps({ style })}>
      <input {...getInputProps()} />
      {fileDropped ? (
        <Typography variant="title-1">{file[0].name}</Typography>
      ) : (
        <>
          <Typography variant="title-1">Drag&Drop</Typography>
          <Typography variant="body-text-2">
            Drag and drop some files here or click to select files
          </Typography>
        </>
      )}
    </div>
  )
}

CsvDropZone.defaultProps = {
  file: [],
}

CsvDropZone.propTypes = {
  file: PropTypes.arrayOf(PropTypes.shape),
  onDrop: PropTypes.func.isRequired,
}

export default CsvDropZone

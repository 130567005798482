import PropTypes from 'prop-types'
import React, { useCallback, useState } from 'react'
import { useDropzone } from 'react-dropzone'

import { Button, Icon, TextAreaField, Typography } from 'components'

import './inputFile.scss'

const InputFile = ({
  onAdd,
  accept,
  inputRef,
  id,
  register,
  registerName,
  registerValidation,
  rounded,
  ...rest
}) => {
  const [myFiles, setMyFiles] = useState([])
  const [myDesc, setMyDesc] = useState('')

  const onDrop = useCallback(
    acceptedFiles => {
      setMyFiles([...myFiles, ...acceptedFiles])
    },
    [myFiles]
  )

  const { getRootProps, getInputProps } = useDropzone({ onDrop })

  const removeFile = () => {
    setMyFiles([])
    setMyDesc('')
  }

  const onAddFile = () => {
    onAdd(myFiles, myDesc)
    removeFile()
  }

  const handleChange = event => {
    setMyDesc(event.target.value)
  }

  if (myFiles.length > 0) {
    return (
      <div className="quotation-file--file-infos">
        <div className="quotation-file--file-infos--name">
          <Typography color="primary-dark" variant="body-text-2">
            {myFiles[0].name}
          </Typography>
          <Button onClick={removeFile} variant="default-link">
            <Icon color="black" icon="trash" />
          </Button>
        </div>
        <TextAreaField
          onChange={handleChange}
          placeholder="Give your file a description*"
          rounded={false}
          value={myDesc}
        />

        <div className="quotation-file--file-infos--action">
          <Button
            disabled={myDesc === ''}
            onClick={onAddFile}
            type="button"
            variant="light"
          >
            Add this file <Icon color="black" icon="add" />
          </Button>
        </div>
      </div>
    )
  }

  return (
    <div className="input-field" {...getRootProps()}>
      <label className="input-field--label" htmlFor={id}>
        Click here to select a file
        <Icon icon="file" />
      </label>

      <input
        accept={accept}
        className="input-field--input"
        {...getInputProps()}
      />
    </div>
  )
}

export default InputFile

InputFile.defaultProps = {
  accept: '',
  className: '',
  inputRef: null,
  register: null,
  registerName: '',
  registerValidation: {},
  rounded: true,
  variant: 'default',
}

InputFile.propTypes = {
  accept: PropTypes.string,
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  inputRef: PropTypes.func,
  register: PropTypes.func,
  registerName: PropTypes.string,
  rounded: PropTypes.bool,
  variant: PropTypes.oneOf(['default', 'ghost-reverse']),
}

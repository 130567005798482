import PropTypes from 'prop-types'
import React from 'react'

import './footerBar.scss'

const FooterBar = ({ show, children }) => {
  if (!show) return null

  return (
    <div className="footerBar" role="button" tabIndex={0}>
      <div className="footerBar--content">{children}</div>
    </div>
  )
}

export default FooterBar

FooterBar.defaultProps = {
  children: '',
}

FooterBar.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  show: PropTypes.bool.isRequired,
}

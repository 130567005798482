import React from 'react'

import { Button, Icon, Typography } from 'components'

import InputFile from './InputFile'

import './quotationFile.scss'

const QuotationFile = ({ register,
                         files,
                         setFiles,
                         descriptions,
                         setDescriptions,
                       }) => {
  const remove = (index) => {
    // assigning the list to temp variable
    const tempFile = [...files]
    const tempDesc = [...descriptions]

    // removing the element using splice
    tempDesc.splice(index, 1)
    tempFile.splice(index, 1)

    // updating the list
    setFiles(tempFile)
    setDescriptions(tempDesc)
  }
  const onAdd = (myFiles, myDesc) => {
    setFiles(file => [...file, ...myFiles])
    setDescriptions( desc => [...desc, myDesc])
  }

  return (
    <div className="quotation-file">
      <div className="quotation-file--container">
        {files.map((file, index) => (
          <div className="quotation-file--file" key={`${file.name}_${index}`}>
            <div className="quotation-file--file--info">
              <Typography
                className="quotation-file--file--name"
                variant="body-text-2"
              >
                {file.name}
              </Typography>
              <Typography variant="body-text-2">{descriptions[index]}</Typography>
            </div>
            <Button onClick={() => remove(index)} type="button" variant="light">
              <Icon icon="trash" />
            </Button>
          </div>
        ))}
      </div>

      <InputFile
        accept="image/png, image/jpeg"
        id="add-new-quotation-document-form-file-input"
        onAdd={onAdd}
        register={register}
        registerName="documents.file"
        rounded={false}
      />
    </div>
  )
}

export default QuotationFile

QuotationFile.propTypes = {}

import PropTypes from 'prop-types'
import React from 'react'

import './appearanceCardHeader.scss'

import { Typography } from 'components'

const AppearanceCardHeader = ({ appearanceArchiveUrl, appearanceUrl }) => {
  return (
    <div className="appearance-card-header">
      {appearanceUrl && (
        <a href={appearanceUrl} rel="noopener noreferrer" target="_blank">
          <Typography variant="link-text-3">Original link</Typography>
        </a>
      )}
      {appearanceUrl && appearanceArchiveUrl && ','}
      {appearanceArchiveUrl && (
        <a
          href={appearanceArchiveUrl}
          rel="noopener noreferrer"
          target="_blank"
        >
          <Typography variant="link-text-3">Archive link</Typography>
        </a>
      )}
    </div>
  )
}

export default AppearanceCardHeader

AppearanceCardHeader.defaultProps = {
  appearanceArchiveUrl: null,
}

AppearanceCardHeader.propTypes = {
  appearanceArchiveUrl: PropTypes.string,
}

import { useCallback, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { useAuth, useSources, useReviews, useRoles } from 'hooks'
import camelize from 'utils/camelize'

const REVIEWS_FILTERS = {
  'Sort by': [
    { value: 'date', text: 'Date' },
    { value: 'quotationsCount', text: 'Number of links' },
    { value: 'totalInteractions', text: 'Number of interactions' },
    { value: 'relevance', text: 'Relevance' },
  ],
  'Filter by Verdict': [
    { value: 'all', text: 'All' },
    { value: '0', text: '0' },
    { value: '1', text: '1' },
    { value: '2', text: '2' },
    { value: '3', text: '3' },
    { value: '4', text: '4' },
    { value: '5', text: '5' },
    { value: 'noRating', text: 'No rating' },
    { value: 'noStandardVerdict', text: 'Without standard verdict' },
  ],
  'Filter by Links number': [
    { value: 'all', text: 'All' },
    { value: 'withoutLinks', text: 'Without links' },
    { value: 'withAtLeastOneLink', text: 'At least one link' },
  ],
}

const SOURCES_FILTERS = {
  'Sort by': [
    { value: 'relevance', text: 'Relevance' },
    { value: 'totalLinks', text: 'Total links' },
    { value: 'misinformationAppearancesCount', text: 'Misinformation links' },
    { value: 'totalInteractions', text: 'Total interactions' },
    {
      value: 'misinformationInteractions',
      text: 'Interactions with misinformation',
    },
  ],
  'Filter by Source type': [
    { value: 'all', text: 'All' },
    { value: 'organization', text: 'Organization' },
    { value: 'media', text: 'Media' },
    { value: 'website', text: 'Web Channel' },
    { value: 'socialmedia', text: 'Social platform' },
  ],
}

export default () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const { auth } = useAuth()
  const { isEditor, isFactchecker } = useRoles(auth)

  const fromLocation = searchParams.get('fromLocation') || ''
  const urlCurrentTab = searchParams.get('section') || 'reviews'
  const urlFactcheckers = searchParams.get('filterByFactcheckers') || ''
  const urlDateStart = searchParams.get('filterByStartDate') || ''
  const urlDateEnd = searchParams.get('filterByEndDate') || ''
  const urlKeywords = searchParams.get('keywords') || ''
  const urlSourceType = searchParams.get('filterBySourceType') || 'all'
  const urlScore = searchParams.get('filterByVerdict') || 'all'
  const urlLinkNumber = searchParams.get('filterByLinksNumber') || 'all'
  const urlSortBy = searchParams.get('sortBy')

  const [currentTab, setTab] = useState(urlCurrentTab)
  const [search, setSearch] = useState(urlKeywords)
  const [currentFilters, setFilters] = useState(REVIEWS_FILTERS)
  const [dataLimit, setDataLimit] = useState('all')
  const [dataType, setDataType] = useState('data')

  const getSortBy = useCallback(
    tab => {
      if (tab === 'reviews') {
        return REVIEWS_FILTERS['Sort by']
          .map(filter => filter.value)
          .includes(urlSortBy)
          ? urlSortBy
          : 'date'
      } else {
        return SOURCES_FILTERS['Sort by']
          .map(filter => filter.value)
          .includes(urlSortBy)
          ? urlSortBy
          : 'misinformationInteractions'
      }
    },
    [urlSortBy]
  )

  const [selectedFilters, setSelectedFilters] = useState(
    isEditor || isFactchecker
      ? {
          reviews: {
            'Sort by': getSortBy('reviews'),
            'Filter by Verdict': urlScore,
            'Filter by Links number': urlLinkNumber,
            'Filter by Factcheckers': urlFactcheckers,
            'Filter by Start Date': urlDateStart,
            'Filter by End Date': urlDateEnd,
          },
          sources: {
            'Sort by': getSortBy('sources'),
            'Filter by Source type': urlSourceType,
          },
        }
      : {
          reviews: {
            'Sort by': getSortBy('reviews'),
            'Filter by Verdict': urlScore,
          },
          sources: {
            'Sort by': getSortBy('sources'),
            'Filter by Source type': urlSourceType,
          },
        }
  )

  const setData = useCallback(
    filter => {
      if (filter === 'relevance') {
        setDataLimit('all')
        setDataType('data')
      }
      if (filter.includes('misinformation')) {
        setDataLimit('misinformation')
      } else if (filter.includes('total')) {
        setDataLimit('all')
      }
      if (filter.includes('Links') || filter.includes('Appearances')) {
        setDataType('links')
      } else if (filter.includes('Interactions')) {
        setDataType('interactions')
      }
    },
    [setDataLimit, setDataType]
  )

  useEffect(() => {
    if (currentTab === 'reviews') {
      if (!isEditor) {
        delete REVIEWS_FILTERS['Filter by Links number']
        delete REVIEWS_FILTERS['Filter by Factcheckers']
      }
      setFilters(REVIEWS_FILTERS)
    } else {
      setFilters(SOURCES_FILTERS)
      setData(selectedFilters[currentTab]['Sort by'])
    }
    setSearchParams(
      {
        keywords: search,
        section: currentTab,
        ...Object.fromEntries(
          Object.entries(selectedFilters[currentTab]).map(([key, value]) => [
            camelize(key),
            value,
          ])
        ),
      },
      { replace: true }
    )

    if (fromLocation === 'home') {
      window.scrollTo(0, 0)
      searchParams.delete('fromLocation')
    }
  }, [
    currentTab,
    fromLocation,
    isEditor,
    setSearchParams,
    search,
    searchParams,
    selectedFilters,
    setData,
  ])

  const {
    data: reviews,
    fetchNextPage: fetchNextReviewPage,
    hasNextPage: hasNextReviewPage,
    isLoading: reviewIsLoading,
  } = useReviews({
    search,
    factcheckers: selectedFilters['reviews']['Filter by Factcheckers'],
    linkFilter: selectedFilters['reviews']['Filter by Links number'],
    dateStart: selectedFilters['reviews']['Filter by Start Date'],
    dateEnd: selectedFilters['reviews']['Filter by End Date'],
    score: selectedFilters['reviews']['Filter by Verdict'],
    sortBy: selectedFilters['reviews']['Sort by'],
  })

  const {
    data: sources,
    fetchNextPage: fetchNextSourcePage,
    hasNextPage: hasNextSourcePage,
    isLoading: sourceIsLoading,
  } = useSources(
    {
      search,
      sourceTypes: selectedFilters['sources']['Filter by Source type'],
      sortBy: selectedFilters['sources']['Sort by'],
    },
    dataLimit
  )

  const onFilterChoose = useCallback(
    (key, value) => {
      if (currentTab === 'reviews') {
        setSelectedFilters({
          [currentTab]: {
            ...selectedFilters[currentTab],
            [key]: value,
          },
          sources: selectedFilters['sources'],
        })
      } else {
        setSelectedFilters({
          reviews: selectedFilters['reviews'],
          [currentTab]: {
            ...selectedFilters[currentTab],
            [key]: value,
          },
        })
      }
      setSearchParams(
        { ...Object.fromEntries([...searchParams]) },
        { replace: true }
      )
    },
    [currentTab, searchParams, selectedFilters, setSearchParams]
  )

  const onSearch = useCallback(
    value => {
      setSelectedFilters({
        reviews: {
          ...selectedFilters['reviews'],
          'Sort by': value.search ? 'relevance' : 'date',
        },
        sources: {
          ...selectedFilters['sources'],
          'Sort by': value.search ? 'relevance' : 'misinformationInteractions',
        },
      })
      setSearchParams(
        { ...Object.fromEntries([...searchParams]), keywords: value.search },
        { replace: true }
      )
      setSearch(value.search)
    },
    [selectedFilters, setSearch, setSearchParams, searchParams]
  )

  return {
    currentFilters,
    currentTab,
    dataLimit,
    dataType,
    fetchNextReviewPage,
    fetchNextSourcePage,
    hasNextReviewPage,
    hasNextSourcePage,
    onFilterChoose,
    onSearch,
    setTab,
    reviewIsLoading,
    reviews,
    search,
    selectedFilters,
    sourceIsLoading,
    sources,
  }
}

import PropTypes from 'prop-types'
import React, { useState } from 'react'

import { Button, ConfirmBar, Dropdown, Icon, TextField } from 'components'
import ConclusionsTable from 'components/Business/Conclusions/ConclusionsTable'
import ConfirmDeleteConclusion from 'components/Business/Conclusions/ConfirmDeleteConclusion'
import './conclusionsPage.scss'

const ConclusionsPage = ({
  conclusions,
  defaultScore,
  defaultSearch,
  onChangePage,
  onDeleteGenericConclusion,
  onModifyGenericConclusion,
  onSearch,
  page,
}) => {
  const onNext = () => {
    onChangePage(page + 1)
  }

  const onPrev = () => {
    onChangePage(page - 1)
  }

  const [keyword, setKeyword] = useState(defaultSearch)
  const [score, setScore] = useState(defaultScore)

  const onChangeKeyword = e => {
    setKeyword(e.target.value)
  }
  const onSearchKeyword = e => {
    if (e.key === 'Enter') {
      onSearch(keyword, score)
    }
  }

  const onChooseScore = value => {
    setScore(value)
    onSearch(keyword, value)
  }

  const [selectedConclusion, setSelectedConclusion] = useState(null)

  const [showConfirmModifyConclusion, setShowConfirmModifyConclusion] =
    useState(false)
  const toggleShowConfirmModifyConclusion = () => {
    setShowConfirmModifyConclusion(!showConfirmModifyConclusion)
  }
  const [showDeleteConclusion, setShowDeleteConclusion] = useState(false)
  const [isConclusionDeleted, setIsConclusionDeleted] = useState(false)
  const toggleShowDeleteConclusion = () => {
    setShowDeleteConclusion(!showDeleteConclusion)
  }

  const onChooseConclusionToDelete = conclusion => {
    setSelectedConclusion(conclusion)
    setIsConclusionDeleted(false)
    toggleShowDeleteConclusion()
  }

  const onCloseEditConclusion = () => {
    setSelectedConclusion(null)
    toggleShowDeleteConclusion()
  }

  const onModifyConclusion = (conclusionId, data) => {
    onModifyGenericConclusion(conclusionId, data)
    setTimeout(() => {
      setShowConfirmModifyConclusion(false)
    }, 3000)
    toggleShowConfirmModifyConclusion()
  }

  const onDeleteConclusion = () => {
    onDeleteGenericConclusion(selectedConclusion.id)
    setIsConclusionDeleted(true)
    setTimeout(() => {
      setShowDeleteConclusion(false)
    }, 3000)
  }

  const SCORE_OPTIONS = [
    { value: '', text: 'All' },
    { value: 1, text: 1 },
    { value: 2, text: 2 },
    { value: 3, text: 3 },
    { value: 4, text: 4 },
    { value: 5, text: 5 },
  ]

  return (
    <>
      <div className="conclusion-page">
        <div className="conclusion-page--search">
          <TextField
            maxLength="255"
            onChange={onChangeKeyword}
            onKeyDown={onSearchKeyword}
            placeholder="Keywords: Type and press enter"
            value={keyword}
          />
          <Dropdown
            defaultValue={defaultScore}
            inputRef="score-select-field"
            onChange={e => onChooseScore(e.target.value)}
            options={SCORE_OPTIONS}
          />
        </div>
        <div className="conclusion-page--table">
          <ConclusionsTable
            conclusions={conclusions}
            onDeleteConclusion={onChooseConclusionToDelete}
            onModifyConclusion={onModifyConclusion}
          />
          <div className="conclusion-page--table--buttons">
            <Button disabled={page === 1} onClick={onPrev} variant="ghost">
              <Icon icon="arrowleft" />
            </Button>
            <Button onClick={onNext} variant="ghost">
              <Icon icon="arrowright" />
            </Button>
          </div>
        </div>
      </div>
      <ConfirmDeleteConclusion
        conclusion={selectedConclusion}
        isConclusionDeleted={isConclusionDeleted}
        onClose={onCloseEditConclusion}
        onSubmit={onDeleteConclusion}
        show={showDeleteConclusion}
      />

      <ConfirmBar
        show={showConfirmModifyConclusion}
        text="Conclusion has been modified"
      />
    </>
  )
}

export default ConclusionsPage

ConclusionsPage.defaultProps = {
  conclusions: [],
  defaultScore: '',
  defaultSearch: '',
  page: 1,
}

ConclusionsPage.propTypes = {
  conclusions: PropTypes.arrayOf(PropTypes.shape({})),
  defaultScore: PropTypes.string,
  defaultSearch: PropTypes.string,
  onChangePage: PropTypes.func.isRequired,
  onDeleteGenericConclusion: PropTypes.func.isRequired,
  onModifyGenericConclusion: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  page: PropTypes.number,
}

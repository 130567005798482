import { useMutation } from 'react-query'

import { API_URL } from 'utils/config'

import { useAxios } from '../index'

const useDeleteMember = () => {
  const axios = useAxios()

  const deleteMember = async ({organizationId, userId}) => {
    const { data } = await axios.delete(
      `${API_URL}/organizations/${organizationId}/factcheckers/${userId}/delete/`
    )
    return data
  }

  return useMutation(id => deleteMember(id))
}

export default useDeleteMember

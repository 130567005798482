import PropTypes from 'prop-types'
import React from 'react'

import {
  Divider,
  Loader,
  Metric,
  Typography,
  ConclusionTag,
  Button,
  Icon,
} from 'components'
import { numberShortener } from 'utils/shorteners'

import './quotationCardFooter.scss'
const QuotationCardFooter = ({
  appearanceArchiveUrl,
  conclusionLabel,
  conclusionScore,
  documents,
  interactionNumber,
  isLoggedIn,
  isSharesDocumentsListOpen,
  sharesNumber,
  showHeader,
  setIsSharesDocumentsListOpen,
  isOnSynchronizingShares,
  setEditAppearance,
}) => {
  const toggleSharesDocumentsList = () => {
    setIsSharesDocumentsListOpen(!isSharesDocumentsListOpen)
  }

  return (
    <div className="quotation-card-footer">
      <div className="quotation-card-footer--group-left">
        {appearanceArchiveUrl && !showHeader && (
          <Typography variant="body-text-4">
            <a
              href={appearanceArchiveUrl}
              rel="noopener noreferrer"
              target="_blank"
            >
              Archive link
            </a>
          </Typography>
        )}

        {interactionNumber !== undefined &&
          interactionNumber > 0 &&
          showHeader &&
          !isOnSynchronizingShares && (
            <>
              <Metric
                number={numberShortener(interactionNumber)}
                text="interactions"
              />
              <Divider
                borderStyle="dashed"
                className="quotation-card-footer--group-left--divider"
                orientation="vertical"
                thickness="thin"
              />
            </>
          )}

        {documents &&
          documents.length > 0 &&
          showHeader &&
          !isOnSynchronizingShares && (
            <>
              <Icon icon="file" size={12} />
              <Metric number={documents.length} text="" />
              <Divider
                borderStyle="dashed"
                className="quotation-card-footer--group-left--divider"
                orientation="vertical"
                thickness="thin"
              />
            </>
          )}

        {isLoggedIn &&
          ((sharesNumber !== undefined && sharesNumber > 0) ||
            (documents && documents.length > 0)) &&
          showHeader &&
          !isOnSynchronizingShares && (
            <Button onClick={toggleSharesDocumentsList} variant="default-link">
              <div className="quotation-card-footer--group-left--shares">
                {isSharesDocumentsListOpen ? (
                  <Icon icon="up" />
                ) : (
                  <Icon icon="down" />
                )}
              </div>
            </Button>
          )}

        {isOnSynchronizingShares && <Loader variant="small" />}
      </div>
      <div className="quotation-card-footer--group-right quotation-card-footer--group-right--animation">
        {interactionNumber !== undefined &&
          interactionNumber > 0 &&
          !showHeader && (
            <Metric
              number={numberShortener(interactionNumber)}
              text="interactions"
            />
          )}

        <div className="quotation-card-footer--group-right--conclusion">
          <ConclusionTag label={conclusionLabel} score={conclusionScore} />
        </div>

        <div className="quotation-card-footer--group-right--edit">
          <Divider
            borderStyle="dashed"
            className="quotation-card-footer--group-right--edit--divider"
            orientation="vertical"
            thickness="thin"
          />
          <Button onClick={setEditAppearance} type="button" variant="light">
            <Icon icon="edit" />
          </Button>
        </div>
      </div>
    </div>
  )
}

export default QuotationCardFooter

QuotationCardFooter.defaultProps = {
  appearanceArchiveUrl: null,
  conclusionLabel: null,
  conclusionScore: null,
  documents: [],
  interactionNumber: null,
  isLoggedIn: false,
  isOnSynchronizingShares: false,
  sharesNumber: null,
  showHeader: true,
}

QuotationCardFooter.propTypes = {
  appearanceArchiveUrl: PropTypes.string,
  conclusionLabel: PropTypes.string,
  conclusionScore: PropTypes.number,
  documents: PropTypes.arrayOf(PropTypes.object),
  interactionNumber: PropTypes.number,
  isLoggedIn: PropTypes.bool,
  isOnSynchronizingShares: PropTypes.bool,
  sharesNumber: PropTypes.number,
  showHeader: PropTypes.bool,
}

import { useQuery } from 'react-query'

import { API_URL } from 'utils/config'

import { normalizeSource } from '../../normalizer'
import { useAxios } from '../index'

const useSource = (sourceId, sourceType = {}) => {
  const axios = useAxios()

  const getSourceById = async () => {
    let url = `${API_URL}/${sourceType.toLowerCase()}s/${sourceId}`

    const { data } = await axios.get(url)

    return data
  }
  return useQuery(
    [`sources-${sourceType.toLowerCase()}`, sourceId],
    () => getSourceById(sourceId),
    {
      select: data => normalizeSource(data),
    }
  )
}

export default useSource

import React, {useState} from 'react'

import './teams.scss'

import { Link, useParams } from "react-router-dom"

import {Button, Divider, Typography, Icon } from 'components'
import { useOrganizationChannels, useOrganization, useOrganizationEdition, useAddMember, useDeleteMember } from "hooks"

import AddMember from "./AddMember"
import OrganizationForm from "./OrganizationForm"


const Teams = () => {
  const { organizationId } = useParams()

  const { data: channels } = useOrganizationChannels({
    keywords: '',
    type: "factchecker",
    organizationId: organizationId,
  })

  const { data: organization, refetch: refetchOrganization } = useOrganization(
    organizationId,
  )


  const [showAdd, setShowAdd] = useState(false)


  const deleteMember = useDeleteMember()
  const addMember = useAddMember()
  const editOrganization = useOrganizationEdition()





  const onOrganizationNameChange = (value) => {
    editOrganization.mutate({id: organizationId, name: value.name})
    refetchOrganization()

  }

  const onRemoveMember = (id) => {
    deleteMember.mutate({organizationId: organizationId, userId: id})
    refetchOrganization()
  }

  const onAddMember = (values) => {
    addMember.mutate({id: organizationId, value: values})
    setShowAdd(false)
    refetchOrganization()
  }



  return (
    <div className="teams">
      <div className="teams--section">
        <div  className="teams--section--title">
          <Link className="teams--section--title--button-back" to="/dashboard" variant="light">
            <Icon icon="arrowleft" size={24}/>
          </Link>

          <Typography variant="h3">{organization?.name}</Typography>
        </div>


        {organization?.name && <OrganizationForm name={organization?.name} onSubmit={onOrganizationNameChange} />}



        <table>
          <thead>
            <tr>
              <th>
                <Typography color="primary-white" variant="body-text-3">
                  Channels
                </Typography>
              </th>
            </tr>
          </thead>

          <tbody>
            {channels?.map(channel =>
              <tr key={channel.id}>
                <td>
                  <a
                    href={channel.url}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <Typography
                      color="secondary-grey"
                      variant="link-text-2"
                  >
                      {channel.name}
                    </Typography>
                  </a>
                </td>
              </tr>
          )}
          </tbody>
        </table>



      </div>
      <Divider borderStyle="dashed" orientation="vertical" />
      <div className="teams--section">
        <div className="teams--section--title">
          <Typography variant="h3">Members of {organization?.name}:</Typography>
        </div>

        <Typography className="teams--section--desc" color="secondary-grey" variant="body-text-2">You can invite new members of your organization. Indicate their e-mail for them to be invited to the platform.</Typography>


        <table>
          <thead>
            <tr>
              <th>
                <Typography color="primary-white" variant="body-text-3">
                  Name and Surname
                </Typography>
              </th>
              <th>
                <Typography color="primary-white" variant="body-text-3">
                  E-mail
                </Typography>
              </th>
              <th />
            </tr>
          </thead>

          <tbody>
            {organization?.factcheckingUsers.map(member =>
              <tr key={member.publicId}>
                <td>
                  <Typography
                    variant="body-text-2"
                  >
                    {member.firstName} {member.lastName}
                  </Typography>
                </td>
                <td>
                  <Typography
                    variant="body-text-2"
                  >
                    {member.email}
                  </Typography>
                </td>
                <td className="teams--table--btn-delete">
                  <Button
                    onClick={() => onRemoveMember(member.id)}
                    type="button"
                    variant="light"
                  >
                    <Icon color="black" icon="trash" />
                  </Button>
                </td>

              </tr>
          )}
          </tbody>
        </table>

        {!showAdd
          ?   <Button className="teams--btn-add" onClick={() => setShowAdd(true)} variant="ghost">
            Add new member &nbsp;<Icon icon="add"/>
          </Button>
          : <AddMember onAddMember={onAddMember}/>

        }
      </div>
    </div>
  )
}

export default Teams

Teams.defaultProps = {}

Teams.propTypes = {}

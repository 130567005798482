import PropTypes from 'prop-types'
import React from 'react'

import { ErrorMessage, Loader, Typography } from 'components'

import './csvInfo.scss'

const CsvInfo = ({ data, error, isError, isLoading }) => (
  <div className="csv-info">
    {isError && (
      <ErrorMessage
        className="csv-info--title"
        message={`Error: ${error.response.statusText} - ${error.response.data}`}
      />
    )}

    {data && data.error && (
      <ErrorMessage
        className="csv-info--title"
        message={`Error: ${data.error}`}
      />
    )}

    {isLoading && (
      <div className="csv-info--loader">
        <Loader />
        <Typography variant="body-text-2">Your file is uploading</Typography>
      </div>
    )}

    {data && !data.error && (
      <div className="csv-info--results">
        <div className="csv-info--results--title">
          <Typography variant="title-3">
            Your CSV have been validate, here a resume before upload :
          </Typography>
        </div>
        <div className="csv-info--results--body">
          <div className="csv-info--results--body--row">
            <Typography variant="body-text-2">
              Already existing fact check:
            </Typography>
            <Typography variant="body-text-2">
              {data.nbExistingFactchecks}
            </Typography>
          </div>
          <div className="csv-info--results--body--row">
            <Typography variant="body-text-2">New factcheck:</Typography>
            <Typography variant="body-text-2">
              {data.nbNonExistingFactchecks}
            </Typography>
          </div>
          <div className="csv-info--results--body--row">
            <Typography variant="body-text-2">
              Already existing appearances:
            </Typography>
            <Typography variant="body-text-2">
              {data.nbExistingAppearances}
            </Typography>
          </div>
          <div className="csv-info--results--body--row">
            <Typography variant="body-text-2">
              Already existing content (but not appearance):
            </Typography>
            <Typography variant="body-text-2">
              {data.nbExistingContentButNoAppearances}
            </Typography>
          </div>
          <div className="csv-info--results--body--row">
            <Typography variant="body-text-2">New appearances:</Typography>
            <Typography variant="body-text-2">
              {data.nbNotExistingContent}
            </Typography>
          </div>
          <div className="csv-info--results--body--row">
            <Typography variant="body-text-2">
              Appearances with bad verdict tag:
            </Typography>
            <Typography variant="body-text-2">
              {data.nbBadTagAppearances}
            </Typography>
          </div>
          <div className="csv-info--results--body--row">
            <Typography variant="body-text-2">
              Rows without content url:
            </Typography>
            <Typography variant="body-text-2">
              {data.nbNullContentUrl}
            </Typography>
          </div>
          <div className="csv-info--results--body--row">
            <Typography variant="body-text-2">Rows without rating:</Typography>
            <Typography variant="body-text-2">{data.nbNullRating}</Typography>
          </div>
          <div className="csv-info--results--body--row">
            <Typography variant="body-text-2">
              Rows without reference url:
            </Typography>
            <Typography variant="body-text-2">
              {data.nbNullReferenceUrl}
            </Typography>
          </div>
        </div>
      </div>
    )}
  </div>
)

CsvInfo.defaultProps = {
  data: null,
  error: null,
  isError: false,
  isLoading: false,
}

CsvInfo.propTypes = {
  data: PropTypes.shape({
    nbExistingFactchecks: PropTypes.number,
    nbNonExistingFactchecks: PropTypes.number,
    nbExistingAppearances: PropTypes.number,
    nbExistingContentButNoAppearances: PropTypes.number,
    nbNotExistingContent: PropTypes.number,
    nbBadTagAppearances: PropTypes.number,
    nbNullContentUrl: PropTypes.number,
    nbNullRating: PropTypes.number,
    nbNullReferenceUrl: PropTypes.number,
    error: PropTypes.string,
  }),
  error: PropTypes.shape({
    response: PropTypes.shape({
      statusText: PropTypes.string,
      data: PropTypes.string,
    }),
  }),
  isError: PropTypes.bool,
  isLoading: PropTypes.bool,
}

export default CsvInfo

import { useQuery } from 'react-query'

import { normalizeClaim } from 'normalizer'
import { API_URL } from 'utils/config'

import { useAxios } from '../index'

export default function useAppearanceClaims({
  appearanceId = '',
  appearanceType = '',
}) {
  const axios = useAxios()

  const getAppearanceClaim = async ({
    appearanceId = '',
    appearanceType = '',
  }) => {
    let url = `${API_URL}/appearances/${appearanceId}/claims`
    if (appearanceType === 'share') {
      url = `${API_URL}/shares/${appearanceId}/claims`
    }
    const { data } = await axios.get(url)
    return data
  }

  return useQuery(
    ['claim-appearances', { appearanceId, appearanceType }],
    () => getAppearanceClaim({ appearanceId, appearanceType }),
    {
      select: data => data.map(claim => normalizeClaim(claim)),
    }
  )
}

import PropTypes from 'prop-types'
import React from 'react'
import { useMediaQuery } from 'react-responsive'

import { Typography } from 'components'

import Footer from '../../Atomic/Organisms/Footer'
import OurApi from '../../Atomic/Organisms/OurApi'
import AboutDatabase from './AboutDatabase'
import AboutDatabaseBuild from './AboutDatabaseBuild'
import AboutOpenFeedbackUse from './AboutOpenFeedbackUse'

import './aboutUs.scss'

const AboutUs = ({ onContact }) => {
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' })

  return (
    <div className="about-us">
      <div className="about-us--open-feedback">
        <Typography color="primary-white" variant="h1">
          Open Feedback is building a collaborative database of feedback that
          assess the credibility of claims and the reliability of information
          sources.
        </Typography>
      </div>

      <AboutDatabase />

      <AboutDatabaseBuild onContact={onContact} />

      <AboutOpenFeedbackUse />

      <div className="about-us--desktop--section">
        {isMobile ? (
          <OurApi onSubmit={onContact} />
        ) : (
          <OurApi onSubmit={onContact} variant="white" />
        )}
      </div>

      <Footer onSubmit={onContact} />
    </div>
  )
}

export default AboutUs

AboutUs.propTypes = {
  onContact: PropTypes.func.isRequired,
}

import axios from 'axios'
import { useInfiniteQuery } from 'react-query'

import { API_URL } from 'utils/config'

const getChannels = async (search = '', type = '', organizationId = '', { pageParam = 1 }) => {
  const { data, headers } = await axios.get(
    `${API_URL}/channels?page=${pageParam}&keywords=${search}&type=${type}&organizationId=${organizationId}&paginate=10`
  )
  return {
    data,
    hasNextPage: headers['has-more'] === 'True' && pageParam + 1,
  }
}

export default function useChannels(search, type, organizationId) {
  return useInfiniteQuery(
    ['channel', search],
    params => getChannels(search, type, organizationId, params),
    {
      getNextPageParam: lastPage => lastPage.hasNextPage,
    }
  )
}

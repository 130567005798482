import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'

import 'swiper/swiper.min.css'

import { Typography } from 'components'

const AboutDatabaseMobile = () => (
  <div className="about-us--mobile--section" id="about-us-section-database">
    <div className="about-us--mobile--section--header">
      <Typography variant="h2">What the database contains?</Typography>
    </div>

    <div className="about-us--mobile--section--content">
      <Swiper
        className="about-us--mobile--section--content--swiper"
        slidesPerView={1.1}
      >
        <SwiperSlide className="about-us--mobile--section--content--swiper--slide">
          <Typography
            className="about-us--mobile--section--content--title"
            variant="title-1"
          >
            Reviews
          </Typography>
          <img
            alt="Reviews"
            className="about-us--mobile--section--content--swiper--slide--sketch-img"
            src="/static/assets/aboutUs/Reviews.svg"
          />
          <Typography
            className="about-us--mobile--section--content--swiper--slide--text"
            color="secondary-grey"
            variant="body-text-2"
          >
            As of now, the database contains a <b>list of items</b> that are
            usually individual claims but can also be images or entire
            articles/videos coupled with their reviews. Reviews are typically{' '}
            <b>fact-check articles</b> written by trustworthy organisations that
            assess the veracity of a claim or whether an article meme or video
            is misleading for its audience.
          </Typography>
        </SwiperSlide>
        <SwiperSlide className="about-us--mobile--section--content--swiper--slide">
          <Typography
            className="about-us--mobile--section--content--title"
            variant="title-1"
          >
            Appearances
          </Typography>
          <img
            alt="appearances"
            className="about-us--mobile--section--content--swiper--slide--sketch-img"
            src="/static/assets/aboutUs/Appearances.svg"
          />
          <Typography
            className="about-us--mobile--section--content--swiper--slide--text"
            color="secondary-grey"
            variant="body-text-2"
          >
            In addition to these items and their reviews, the database compiles
            a list of ‘appearances’ that are <b>media publications</b>{' '}
            containing the claim reviewed or an equivalent; appearances are
            usually associated with a URL (of an article or a social media post)
            but can also be an offline publication (a book, TV show…).
          </Typography>
        </SwiperSlide>
        <SwiperSlide className="about-us--mobile--section--content--swiper--slide">
          <Typography
            className="about-us--mobile--section--content--title"
            variant="title-1"
          >
            Credibility assessments
          </Typography>
          <img
            alt="Credibility assessments"
            className="about-us--mobile--section--content--swiper--slide--sketch-img"
            src="/static/assets/aboutUs/CredibilityAssessments.svg"
          />
          <Typography
            className="about-us--mobile--section--content--swiper--slide--text"
            color="secondary-grey"
            variant="body-text-2"
          >
            The reviews issue a <b>‘verdict’</b> on the credibility of the
            items, for instance a fact-check article might conclude that a claim
            is unsupported by available evidence. If an article contains the
            above claim, it will be attributed a <b>credibility ‘rating’</b>.
            For instance, the rating can be ‘Missing context’ if the article
            fails to include all necessary information.
          </Typography>
        </SwiperSlide>
      </Swiper>
    </div>
  </div>
)

export default AboutDatabaseMobile

import React from 'react'
import { Route, Routes } from 'react-router-dom'

import {
  DashboardPage,
  NotFound,
} from 'components'
import { useAuth, useStats, useRoles } from 'hooks'
import Conclusions from 'modules/Conclusions'
import FactcheckerAddReview from 'modules/Dashboard/pages/FactcheckerAddReview'
import ReviewsWithoutAppearances from 'modules/ReviewsWithoutAppearances'
import ReviewsWithoutConclusions from 'modules/ReviewsWithoutConclusions'

import {
  Factchecker,
  Scientist,
  Teams,
} from './pages'


const DashboardRouter = () => {
  const { auth } = useAuth()
  const { isScientist, isFactchecker } = useRoles(auth)
  const isLoggedIn = !!auth.user
  const models = [
    'conclusions',
    'channels',
    'medias',
    'organizations',
    'persons',
    'social-media-channels',
    'verdictsApproved',
    'verdictsWithoutAppearance',
    'verdictsWithoutConclusion',
    'website-channels',
  ]
  const { data: statArrays } = useStats(models.join(','))

  const stats = statArrays?.reduce((stats, item) => {
    if (models.includes(item.modelName)) {
      stats[item.modelName] = item.count
      return stats
    }

    return null
  }, {})

  if (!isLoggedIn) return <NotFound />

  // Determine the content to display based on user's role
  let dashboardContent = <DashboardPage stats={stats} />
  if (isFactchecker) {
    dashboardContent = <Factchecker auth={auth} />
  } else if (isScientist) {
    dashboardContent = <Scientist auth={auth} />
  }

  return (
    <Routes>
      <Route element={dashboardContent} index />
      <Route element={<Conclusions />} path="conclusions" />
      <Route element={<FactcheckerAddReview auth={auth} />} path="factchecker/add-review" />
      <Route path="reviews">
        <Route element={<ReviewsWithoutAppearances />} path="without-appearances" />
        <Route element={<ReviewsWithoutConclusions />} path="without-conclusions" />
      </Route>
      <Route element={<Teams />} path="teams/:organizationId" />
    </Routes>
  )
}

export default DashboardRouter

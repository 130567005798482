import { numberShortener } from '../../utils/shorteners'

const normalizeInteractionData = (source, dataLimit = 'all') => {
  const {
    appearancesCount,
    appearancesCountNoScore: noScoreCount,
    appearancesCountScore0: score0Count,
    appearancesCountScore1: score1Count,
    appearancesCountScore2: score2Count,
    appearancesCountScore3: score3Count,
    appearancesCountScore4: score4Count,
    appearancesCountScore5: score5Count,
    unknownVerdictAppearancesCount: unknownScoreCount,
    appearancesTotalInteractionsSum,
    appearancesTotalInteractionsSumNoScore,
    appearancesTotalInteractionsSumScore0,
    appearancesTotalInteractionsSumScore1,
    appearancesTotalInteractionsSumScore2,
    appearancesTotalInteractionsSumScore3,
    appearancesTotalInteractionsSumScore4,
    appearancesTotalInteractionsSumScore5,
    unknownVerdictAppearancesTotalInteractionsSum,
  } = source

  const darkGreen = '#1A7A4C'
  const green = '#66B564'
  const yellow = '#FCE28C'
  const orange = '#FFA951'
  const red = '#E85241'
  const blue = '#5778C7'
  const gray = '#F4F5F6'

  const numberLinksPerLabel = {
    False: score1Count, // False
    'Partly false': score2Count, // Partly false
    'Missing context': score3Count, // Missing context
    'Mostly true': score4Count, // Mostly true
    True: score5Count, // True
    Satire: noScoreCount, // Satire / Not eligible
    'Not eligible': noScoreCount, // Satire / Not eligible
    Undefined: unknownScoreCount, // Undefined
  }

  const statsLink = {
    credible: score4Count + score5Count,
    misinformation: score0Count + score1Count + score2Count + score3Count,
    satire: noScoreCount,
    undefined: unknownScoreCount,
  }

  const statsInteraction = {
    credible: numberShortener(
      appearancesTotalInteractionsSumScore4 +
        appearancesTotalInteractionsSumScore5
    ),
    misinformation: numberShortener(
      appearancesTotalInteractionsSumScore0 +
        appearancesTotalInteractionsSumScore1 +
        appearancesTotalInteractionsSumScore2 +
        appearancesTotalInteractionsSumScore3
    ),
    satire: numberShortener(appearancesTotalInteractionsSumNoScore),
    undefined: numberShortener(unknownVerdictAppearancesTotalInteractionsSum),
  }

  let interactionData

  if (appearancesTotalInteractionsSum > 0) {
    interactionData = [
      {
        title: appearancesTotalInteractionsSumScore5,
        value: appearancesTotalInteractionsSumScore5,
        color: darkGreen,
        score: 5,
      },
      {
        title: appearancesTotalInteractionsSumScore4,
        value: appearancesTotalInteractionsSumScore4,
        color: green,
        score: 4,
      },
      {
        title: appearancesTotalInteractionsSumScore3,
        value: appearancesTotalInteractionsSumScore3,
        color: yellow,
        score: 3,
      },
      {
        title: appearancesTotalInteractionsSumScore2,
        value: appearancesTotalInteractionsSumScore2,
        color: orange,
        score: 2,
      },
      {
        title: appearancesTotalInteractionsSumScore1,
        value: appearancesTotalInteractionsSumScore1,
        color: red,
        score: 1,
      },
      {
        title: appearancesTotalInteractionsSumScore0,
        value: appearancesTotalInteractionsSumScore0,
        color: red,
        score: 0,
      },
      {
        title: numberShortener(appearancesTotalInteractionsSumNoScore),
        value: appearancesTotalInteractionsSumNoScore,
        color: blue,
      },
      {
        title: numberShortener(unknownVerdictAppearancesTotalInteractionsSum),
        value: unknownVerdictAppearancesTotalInteractionsSum,
        color: gray,
      },
    ]
  } else {
    interactionData = [{ title: 'No data', value: 100, color: 'transparent' }]
  }

  let linkData

  if (appearancesCount > 0) {
    linkData = [
      { title: score5Count, value: score5Count, color: darkGreen },
      { title: score4Count, value: score4Count, color: green },
      { title: score3Count, value: score3Count, color: yellow },
      { title: score2Count, value: score2Count, color: orange },
      { title: score1Count, value: score1Count, color: red },
      { title: score0Count, value: score0Count, color: red },
      { title: noScoreCount, value: noScoreCount, color: blue },
      { title: unknownScoreCount, value: unknownScoreCount, color: gray },
    ]
  } else {
    linkData = [{ title: '0', value: 100, color: 'transparent' }]
  }

  if (dataLimit === 'misinformation') {
    interactionData
      .filter(
        data =>
          data.value === appearancesTotalInteractionsSumScore4 ||
          data.value === appearancesTotalInteractionsSumScore5 ||
          data.value === appearancesTotalInteractionsSumNoScore ||
          data.value === unknownVerdictAppearancesTotalInteractionsSum
      )
      .forEach(data => (data.color = 'transparent'))
    linkData
      .filter(
        data =>
          data.value === score4Count ||
          data.value === score5Count ||
          data.value === noScoreCount ||
          data.value === unknownScoreCount
      )
      .forEach(data => (data.color = 'transparent'))
  } else if (dataLimit === 'credible') {
    interactionData
      .filter(
        data =>
          data.value === appearancesTotalInteractionsSumScore0 ||
          data.value === appearancesTotalInteractionsSumScore1 ||
          data.value === appearancesTotalInteractionsSumScore2 ||
          data.value === appearancesTotalInteractionsSumScore3
      )
      .forEach(data => (data.color = 'transparent'))
    linkData
      .filter(
        data =>
          data.value === score0Count ||
          data.value === score1Count ||
          data.value === score2Count ||
          data.value === score3Count
      )
      .forEach(data => (data.color = 'transparent'))
  }

  return {
    statsLink,
    statsInteraction,
    interactionData,
    linkData,
    numberLinksPerLabel,
  }
}

export default normalizeInteractionData

import PropTypes from 'prop-types'
import React, { Suspense } from 'react'
import { Link } from 'react-router-dom'

import {
  Button,
  Divider,
  Icon,
  Loader,
  StatisticCard,
  ValueCard,
  Typography
} from 'components'
import FactcheckCsvImport from 'components/Business/Factchecks/FactcheckCsvImport'
import {
  useFactcheckerAuth,
  useFactcheckers,
  useFactcheckerStats
} from 'hooks'

import './factcheckerDashboard.scss'


const FactCheckerDashboard = ({ user }) => {
  const { data: allFactcheckers, error, isLoading } = useFactcheckers()
  const isFactcheckerAuthorized = useFactcheckerAuth((allFactcheckers || []).map(channel => channel.id))

  const factcheckerOrganizations = user?.factcheckingOrganizations
  const factcheckers = (user?.factcheckingChannels || []).map(channel => channel.name.toLowerCase())
  const factcheckerNames = factcheckers.map(factchecker => factchecker.name)

  const models = [
    'verdicts',
    'verdictsWithoutAppearance',
    'verdictsWithoutConclusion',
    "conclusions",
    "members"
  ]
  const { data: statArrays } = useFactcheckerStats(models.join(','))

  const stats = statArrays?.reduce((stats, item) => {
    if (models.includes(item.modelName)) {
      stats[item.modelName] = item.count
      return stats
    }
    return null
  }, {})

  if (!isFactcheckerAuthorized || error?.response?.status >= 400) {
    return (
      <div className="factchecker-dashboard">
        { isLoading ?
          <Loader />
          :
          <div className="factchecker-dashboard--factchecks">
            <p>User is not authorized for this page.</p>
          </div>
        }
      </div>
    )
  }

  return (
    <div className="factchecker-dashboard">
      <div className="factchecker-dashboard--factchecks">
        <Typography variant="h2">Open Feedback import tool:</Typography>
        <Typography color="secondary-grey" variant="body-text-2">
          You can import a list of appearances from your organization in a CSV file.
        </Typography>

        <FactcheckCsvImport
          factchecker={factcheckers}
        />
      </div>

      <Divider
        borderStyle="dashed"
        className="factchecker-dashboard--divider"
        orientation="vertical"
        thickness="thin"
      />

      <div className="factchecker-dashboard--metrics">
        <div className="factchecker-dashboard--metrics--header">
          <Typography variant="h2">Your Reviews:</Typography>
          <Link className="factchecker-dashboard--metrics--header--link" to={`/search?filterByFactcheckers=${factcheckerNames.join(',')}`}>
            <Typography variant="link-text-1">
              {stats?.verdicts}
            </Typography>
            <Icon icon="search" />
          </Link>
          <Link to="/dashboard/factchecker/add-review">
            <Button className="factchecker-dashboard--metrics--header--button" type="internalLink">
              Add review
            </Button>
          </Link>
        </div>
        <div className="factchecker-dashboard--metrics--statistics-container">
          <Suspense fallback={<div>Loading...</div>}>
            <Link
              className="factchecker-dashboard--metrics--statistics-container--statistic-card"
              to={`/dashboard/reviews/without-appearances?filterByFactcheckers=${factcheckerNames.join(',')}`}
              >
              <StatisticCard
                number={stats?.verdictsWithoutAppearance}
                title="Without appearance"
                total={stats?.verdicts}
                />
            </Link>
          </Suspense>
          <Suspense fallback={<div>Loading...</div>}>
            <Link
              className="factchecker-dashboard--metrics--statistics-container--statistic-card"
              to={`/dashboard/reviews/without-conclusions?filterByFactcheckers=${factcheckerNames.join(',')}`}
            >
              <StatisticCard
                className="factchecker-dashboard--metrics--statistics-container--statistic-card"
                number={stats?.verdictsWithoutConclusion}
                title="Without conclusion"
                total={stats?.verdicts}
              />
            </Link>
          </Suspense>


          <div className="factchecker-dashboard--metrics--verdicts-metrics-container--card dashboard--metrics--verdicts-metrics-container--card--clickable">
            <Link to="/factchecker/verdicts">
              <Typography variant="body-text-2">
                Standard verdict options:
              </Typography>
              <div className="factchecker-dashboard--metrics--verdicts-metrics-container--card--footer">
                <div className="factchecker-dashboard--metrics--verdicts-metrics-container--card--footer--item--before-divider">
                  <Typography variant="title-1">{stats?.conclusions}</Typography>
                </div>
                <div className="factchecker-dashboard--metrics--verdicts-metrics-container--card--footer--item--before-divider">
                  <Divider
                    borderStyle="dashed"
                    className="dashboard-statistic-card--body--footer--divider"
                    orientation="vertical"
                    thickness="thin"
                  />

                  <Typography
                    className="factchecker-dashboard-statistic-card--add"
                    variant="title-2"
                  >
                    Assign <Icon icon="add" />
                  </Typography>
                </div>
              </div>
            </Link>
          </div>
        </div>

        <div className="factchecker-dashboard--metrics--header factchecker-dashboard--teams">
          <Typography variant="h2">Your Teams:</Typography>

          {
            factcheckerOrganizations && factcheckerOrganizations.length > 0 && (
              <Link to={`/dashboard/teams/${factcheckerOrganizations[0].id}`}>
                <Button className="factchecker-dashboard--metrics--header--button" variant="ghost">
                  Org Profile <Icon icon="arrowright" />
                </Button>
              </Link>
            )
          }
        </div>
        <div className="factchecker-dashboard--metrics--statistics-container">
          <Link
            className="factchecker-dashboard--metrics--statistics-container--statistic-card"
            to={`/dashboard/teams/${factcheckerOrganizations[0].id}`}
              >
            <ValueCard
              className="factchecker-dashboard--metrics--statistics-container--statistic-card"
              number={stats?.members}
              title="Number of members"
                  />
          </Link>
        </div>
      </div>
    </div>
  )
}


FactCheckerDashboard.propTypes = {
  user: PropTypes.shape({
    factcheckingChannels: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        scrappable: PropTypes.bool,
        url: PropTypes.string,
      })
    ),
    factcheckingOrganizations: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
      })
    ),
  }).isRequired,
}


export default FactCheckerDashboard

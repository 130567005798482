import PropTypes from 'prop-types'
import React, { useState } from 'react'

import Button from '../../Atoms/Button'
import Typography from '../../Atoms/Typography'
import ContactForm from '../ContactForm'

import './fullReport.scss'

export const FullReport = ({ onSubmit }) => {
  const [show, setShow] = useState(false)
  const toggleContactForm = () => setShow(!show)
  const typoColor = 'primary-dark'

  return (
    <div className="full-report">
      <Typography color={typoColor} variant="h2">
        This is a sample. Do you need access to more data?
      </Typography>

      <Button onClick={toggleContactForm} variant="ghost">
        Contact us
      </Button>
      <ContactForm
        onClose={toggleContactForm}
        onSubmit={onSubmit}
        show={show}
      />
    </div>
  )
}

FullReport.propTypes = {
  onSubmit: PropTypes.func.isRequired,
}

export default FullReport

import PropTypes from 'prop-types'
import React from 'react'

import Dropdown from 'components/Atomic/Atoms/Dropdown'

const AppearanceTagsSelectField = ({
  defaultValue,
  onChoose,
  inputRef,
  undefinedText,
  selectTagsOptions,
  variant,
}) => {
  const SELECT_TAGS_OPTIONS = [
    { value: '', text: undefinedText },
    ...selectTagsOptions,
  ]

  return (
    <Dropdown
      defaultValue={defaultValue}
      onChange={onChoose}
      options={SELECT_TAGS_OPTIONS}
      variant={variant}
    />
  )
}

export default AppearanceTagsSelectField

AppearanceTagsSelectField.defaultProps = {
  defaultValue: '',
  inputRef: 'select-field',
  undefinedText: 'All ratings',
  variant: 'default',
  selectTagsOptions: [
    { value: 'False', text: 'False' },
    { value: 'Partly false', text: 'Partly false' },
    { value: 'Missing context', text: 'Missing context' },
    { value: 'True', text: 'True' },
    { value: 'Satire', text: 'Satire' },
    { value: 'Not eligible', text: 'Not eligible' },
    { value: 'Undefined', text: 'Undefined' },
  ],
}

AppearanceTagsSelectField.propTypes = {
  defaultValue: PropTypes.string,
  inputRef: PropTypes.string,
  onChoose: PropTypes.func.isRequired,
  undefinedText: PropTypes.string,
  variant: PropTypes.oneOf(['default', 'ghost']),
  selectTagsOptions: PropTypes.arrayOf(PropTypes.shape),
}

import { useQuery } from 'react-query'

import { API_URL } from 'utils/config'

import { useAxios } from '../../index'

export default function useOrganizationChannels({
  organizationId = '',
  search = '',
  type = '',
}) {
  const axios = useAxios()

  const getOrganizationChannels = async ({
    organizationId = '',
    search = '',
    type = '',
  }) => {
    let url = `${API_URL}/channels?keywords=${search}&type=${type}&organizationId=${organizationId}`
    const { data } = await axios.get(url)
    return data
  }

  return useQuery(
    ['organization-channels', { organizationId, type }],
    () => getOrganizationChannels({ organizationId, search, type}),
    {
      select: data => {
        return data
      },
    }
  )
}
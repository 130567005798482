import React, { useState } from 'react'

import { Typography, Button, Icon } from 'components/index'

import './partners.scss'

const Partners = () => {
  const [page, setPage] = useState(1)

  return (
    <div className="partners-list-desktop">
      <div className="partners-list-desktop--text">
        <Typography className="partners-list-desktop--text--title" variant="h2">
          Partners
        </Typography>
        {page === 1 && (
          <Typography color="secondary-grey" variant="body-text-2">
            The project is led by staff from Science Feedback in collaboration
            with several fact-checking organizations.
            <br />
            <br />
            It received the help of volunteers – notably from Microsoft through
            the Share AI program – and support from the Google News Initiative
            and the International Fact-Checking Network (IFCN).
          </Typography>
        )}
        {page === 2 && (
          <Typography color="secondary-grey" variant="body-text-2">
            The database of claims, reviews and flagged URLs is used by partners
            working to inform Internet users and scientists studying
            misinformation.
          </Typography>
        )}

        <div className="partners-list-desktop--text--pagination">
          <Typography variant="body-text-3"> Partners {page}/2 </Typography>
          <div className="partners-list-desktop--text--pagination--actions">
            <Button onClick={() => setPage(1)} variant="ghost">
              <Icon icon="arrowleft" />
            </Button>
            <Button onClick={() => setPage(2)} variant="ghost">
              <Icon icon="arrowright" />
            </Button>
          </div>
        </div>
      </div>

      <div className="partners-list-desktop--partners">
        {page === 1 && (
          <>
            <div className="partners-list-desktop--partners--row">
              <img
                alt="Google-Initiative"
                src="/static/assets/partners/Google%20Nezs%20Initiative.png"
              />
            </div>
            <div className="partners-list-desktop--partners--row">
              <div className="partners-list-desktop--partners--row--element">
                <img
                  alt="Google-Initiative"
                  src="/static/assets/partners/ShareAI.png"
                />
              </div>
              <div className="partners-list-desktop--partners--row--divider" />
              <div className="partners-list-desktop--partners--row--element">
                <img
                  alt="Google-Initiative"
                  src="/static/assets/partners/ScienceFeedback.svg"
                />
              </div>
            </div>
            <div className="partners-list-desktop--partners--row">
              <img
                alt="Google-Initiative"
                src="/static/assets/partners/international%20Fact-checking.png"
              />
            </div>
          </>
        )}

        {page === 2 && (
          <>
            <div className="partners-list-desktop--partners--row">
              <img
                alt="Google-Initiative"
                src="/static/assets/partners/SciencesPo.png"
              />
            </div>
            <div className="partners-list-desktop--partners--row">
              <img
                alt="Google-Initiative"
                src="/static/assets/partners/internetArchive.png"
              />
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default Partners

import PropTypes from 'prop-types'
import React from 'react'

import './image.scss'
import defaultImg from './assets/default_img.jpg'
import defaultImgMedia from './assets/default_img_media.jpg'

const Image = ({ defaultImage, url, name }) => {
  const defaultImageSource =
    defaultImage === 'media' ? defaultImgMedia : defaultImg
  return (
    <img
      alt={name}
      className="image"
      onError={({ currentTarget }) => {
        currentTarget.onerror = null
        currentTarget.src = defaultImageSource
      }}
      src={url ? url : defaultImageSource}
    />
  )
}

Image.defaultProps = {
  defaultImage: 'default',
  name: '',
  url: '',
}

Image.propTypes = {
  name: PropTypes.string,
  url: PropTypes.string,
  defaultImage: PropTypes.string,
}

export default Image

import axios from 'axios'
import { useInfiniteQuery } from 'react-query'

import { API_URL } from 'utils/config'

const getMedias = async (search = '', { pageParam = 1 }) => {
  const { data, headers } = await axios.get(
    `${API_URL}/organizations?page=${pageParam}&keywords=${search}`
  )
  return {
    data,
    hasNextPage: headers['has-more'] === 'True' && pageParam + 1,
  }
}

export default function useOrganizations(search) {
  return useInfiniteQuery(
    ['organization', search],
    params => getMedias(search, params),
    {
      getNextPageParam: lastPage => lastPage.hasNextPage,
    }
  )
}

import { useInfiniteQuery } from 'react-query'

import { normalizeReview } from 'normalizer'
import { API_URL } from 'utils/config'

import { useAxios } from '../index'

export default function useReviews(userParams) {
  const axios = useAxios()

  const getReviews = async (
    {
      search = '',
      factcheckers = 'all',
      score = 'all',
      linkFilter = 'all',
      onlyNotTreated = '',
      sortBy = 'dateCreated',
      dateStart = '',
      dateEnd = '',
      paginate = 5,
    },
    { pageParam = 1 }
  ) => {
    const { data, headers } = await axios.get(
      `${API_URL}/verdicts?page=${pageParam}&keywords=${search}&factcheckers=${factcheckers}&linkFilter=${linkFilter}&score=${score}&sortBy=${sortBy}&dateStart=${dateStart}&dateEnd=${dateEnd}&onlyNotTreated=${onlyNotTreated}&paginate=${paginate}`
    )

    return {
      data,
      hasNextPage: headers['has-more'] === 'True' && pageParam + 1,
    }
  }

  return useInfiniteQuery(
    ['reviews', userParams],
    params => getReviews(userParams, params), //Api Calls
    {
      select: data => {
        const dataFormatted = data.pages.flatMap(page =>
          page.data.map(review => normalizeReview(review))
        )
        return dataFormatted
      },
      getNextPageParam: lastPage => lastPage.hasNextPage,
    }
  )
}

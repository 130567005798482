import React, { useState } from 'react'
import { useFormContext } from 'react-hook-form'

import { Badge, Select, Typography } from 'components/index'
import { useChannels } from 'hooks'

export const SearchCanonicalChannel = ({ originalSourceId }) => {
  const [search, setSearch] = useState('')
  const { setValue, getValues, watch } = useFormContext()
  const { data, isLoading } = useChannels(search)
  const defaultValue = getValues('canonicalChannelId')
    ? {
        id: getValues('canonicalChannelId'),
        name: getValues('canonicalChannelName'),
      }
    : null
  const options =
    data?.pages[0].data.filter(item => item.id !== originalSourceId) || []

  const onSearch = search => {
    setSearch(search)
  }

  const onChange = value => {
    setValue('canonicalChannelName', value.name)
    setValue('canonicalChannelId', value.id)
  }

  const canonicalChannel = watch('canonicalChannelName')

  return (
    <>
      <Typography color="primary-white" variant="title-3">
        Canonical channel:
      </Typography>

      <Select
        defaultValue={defaultValue}
        getOptionLabel={option => option.name}
        getOptionValue={option => option.id}
        isLoading={isLoading}
        isSearchable
        onChange={onChange}
        onSearch={onSearch}
        options={options}
        placeholder="Search for an channel"
      />

      {canonicalChannel && (
        <Badge
          onDelete={() => onChange({ id: null, name: null })}
          text={canonicalChannel}
        />
      )}
    </>
  )
}

export default SearchCanonicalChannel

import { useMutation } from 'react-query'

import { API_URL } from 'utils/config'

import { useAxios } from '../index'

export default function useEditFeedback(feedbackId) {
  const axios = useAxios()

  const patchReview = async body => {
    const { data } = await axios.patch(
      `${API_URL}/feedbacks/${feedbackId}`,
      body
    )
    return data
  }

  return useMutation(data => patchReview(data))
}

import PropTypes from 'prop-types'
import React from 'react'
import { useForm, useController } from 'react-hook-form'

import './searchBar.scss'
import Button from 'components/Atomic/Atoms/Button'
import TextField from 'components/Atomic/Atoms/TextField'

import Icon from '../../Atoms/Icon'

const SearchBar = ({ onSearch, variant, placeholder, defaultSearch }) => {
  const { handleSubmit, control } = useForm()
  const {
    field: { onChange, onBlur, name, value, ref },
  } = useController({
    name: 'search',
    control,
    defaultValue: defaultSearch,
  })

  return (
    <form className="search-bar" onSubmit={handleSubmit(onSearch)}>
      <TextField
        className="search-bar--input"
        inputRef={ref}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        placeholder={placeholder}
        value={value}
      />
      <div className="search-bar--icon">
        <Icon icon="search" />
      </div>
      <Button data-cy="search-bar-submit" type="submit" variant={variant}>
        Search
      </Button>
    </form>
  )
}

export default SearchBar

SearchBar.defaultProps = {
  defaultSearch: '',
  placeholder: '',
  variant: 'default',
}

SearchBar.propTypes = {
  defaultSearch: PropTypes.string,
  onSearch: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  variant: PropTypes.oneOf(['default', 'ghost-reverse', 'ghost']),
}

import { useMutation } from 'react-query'

import { useAxios } from 'hooks'

import { API_URL } from '../../utils/config'

export default function useModifyAppearances(data) {
  const instances = useAxios()
  const modifyAppearances = async appearancesData => {
    const { data } = await instances.put(
      `${API_URL}/appearances`,
      appearancesData
    )
    return data
  }
  return useMutation(data => modifyAppearances(data), data)
}

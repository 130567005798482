import { useMutation } from 'react-query'

import { useAxios } from 'hooks'

import { API_URL } from '../../utils/config'

export default function useDeleteAppearance(data) {
  const instances = useAxios()
  const deleteAppearance = async appearanceId => {
    const { data } = await instances.delete(
      `${API_URL}/appearances/${appearanceId}`
    )
    return data
  }
  return useMutation(data => deleteAppearance(data), data)
}

import React from 'react'
import { default as ReactInfiniteScrollComponent } from 'react-infinite-scroll-component'

import Loader from 'components/Atomic/Atoms/Loader'
import NoData from 'components/Atomic/Atoms/NoData'

import './infiniteScroll.scss'

const InfiniteScroll = ({
  children,
  className,
  dataLength,
  hasMore,
  isLoading,
  next,
  noDataTitle,
  noDataDescription,
  scrollThreshold,
  scrollableTarget,
}) => {
  if (dataLength === 0) {
    if (typeof hasMore === 'boolean' && !hasMore) {
      return (
        <div className="infiniteScroll--no-data">
          <NoData title={noDataTitle} description={noDataDescription} />
        </div>
      )
    } else if (isLoading) {
      return <Loader />
    }
  }

  return (
    <ReactInfiniteScrollComponent
      className={className}
      dataLength={dataLength}
      hasMore={hasMore}
      loader={<Loader />}
      next={next}
      scrollThreshold={scrollThreshold}
      scrollableTarget={scrollableTarget}
    >
      {children}
    </ReactInfiniteScrollComponent>
  )
}

export default InfiniteScroll

InfiniteScroll.defaultProps = {
  dataLength: 0,
  noDataTitle: 'Sorry, we couldn’t find any matches for your search.',
  noDataDescription: 'Please try searching with another term.',
}

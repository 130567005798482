import React from 'react'

import { Typography } from 'components'

const AboutDatabaseDesktop = () => (
  <div className="about-us--desktop--section" id="about-us-section-database">
    <div className="about-us--desktop--section--header">
      <Typography variant="h2">What the database contains?</Typography>
    </div>

    <div className="about-us--desktop--section--content">
      <div className="about-us--desktop--section--content--dataType">
        <Typography
          className="about-us--desktop--section--content--title"
          variant="title-1"
        >
          Reviews
        </Typography>
        <img alt="Reviews" src="/static/assets/aboutUs/Reviews.svg" />
        <Typography color="secondary-grey" variant="body-text-2">
          As of now, the database contains a <b>list of items</b> that are
          usually individual claims but can also be images or entire
          articles/videos coupled with their reviews. Reviews are typically{' '}
          <b>fact-check articles</b> written by trustworthy organisations that
          assess the veracity of a claim or whether an article meme or video is
          misleading for its audience.
        </Typography>
      </div>

      <div className="about-us--desktop--section--content--dataType">
        <Typography
          className="about-us--desktop--section--content--title"
          variant="title-1"
        >
          Appearances
        </Typography>
        <img alt="appearances" src="/static/assets/aboutUs/Appearances.svg" />
        <Typography color="secondary-grey" variant="body-text-2">
          In addition to these items and their reviews, the database compiles a
          list of ‘appearances’ that are <b>media publications</b> containing
          the claim reviewed or an equivalent; appearances are usually
          associated with a URL (of an article or a social media post) but can
          also be an offline publication (a book, TV show…).
        </Typography>
      </div>

      <div className="about-us--desktop--section--content--dataType">
        <Typography
          className="about-us--desktop--section--content--title"
          variant="title-1"
        >
          Credibility assessments
        </Typography>
        <img
          alt="Credibility assessments"
          src="/static/assets/aboutUs/CredibilityAssessments.svg"
        />
        <Typography color="secondary-grey" variant="body-text-2">
          The reviews issue a <b>‘verdict’</b> on the credibility of the items,
          for instance a fact-check article might conclude that a claim is
          unsupported by available evidence. If an article contains the above
          claim, it will be attributed a <b>credibility ‘rating’</b>. For
          instance, the rating can be ‘Missing context’ if the article fails to
          include all necessary information.
        </Typography>
      </div>

      <div className="about-us--desktop--section--content--dataType">
        <Typography
          className="about-us--desktop--section--content--title"
          variant="title-1"
        >
          Information sources
        </Typography>
        <img
          alt="Information sources"
          src="/static/assets/aboutUs/InformationSources.svg"
        />
        <Typography color="secondary-grey" variant="body-text-2">
          All the appearances are attached to their source. Information sources
          are individuals or media organizations that are{' '}
          <b>responsible for the publication</b> of contents that have been
          reviewed.
        </Typography>
      </div>
    </div>
  </div>
)

export default AboutDatabaseDesktop

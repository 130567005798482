const appearanceSocialMediaInteractions = (appearance, source) => {
  const capitalizedSource = source.charAt(0).toUpperCase() + source.slice(1)
  const interactions =
    appearance.linkingContent?.[`total${capitalizedSource}Interactions`]
  const shareInteractions =
    appearance[`totalShares${capitalizedSource}Interactions`]
  if (interactions !== undefined && interactions >= shareInteractions) {
    return interactions
  } else {
    return shareInteractions
  }
}

const appearanceTotalInteractionsNumber = appearance => {
  const source = [
    'facebook',
    'twitter',
    'youtube',
    'reddit',
    'instagram',
    'tiktok',
  ]
  return source.reduce((acc, curr) => {
    const capitalizedSource = curr.charAt(0).toUpperCase() + curr.slice(1)
    const interactions =
      appearance.linkingContent?.[`total${capitalizedSource}Interactions`]
    const shareInteractions =
      appearance[`totalShares${capitalizedSource}Interactions`]
    if (interactions !== undefined && interactions >= shareInteractions) {
      return acc + interactions
    } else {
      return acc + shareInteractions
    }
  }, 0)
}

const normalizeSize = size => {
  if (size < 1000) {
    return `${size} B`
  } else if (size < 1000000) {
    return `${(size / 1000).toFixed(1)} KB`
  } else if (size < 1000000000) {
    return `${(size / 1000000).toFixed(1)} MB`
  } else {
    return `${(size / 1000000000).toFixed(1)} GB`
  }
}

const getTypeIconFrom = filetype => {
  switch (filetype) {
    case 'video':
      return 'video'
    case 'image':
      return 'image'
    default:
      return 'document'
  }
}

const normalizeAppearanceDocument = document => {
  return {
    ...document,
    filesize: normalizeSize(document.filesize),
    typeIcon: getTypeIconFrom(document.filetype),
  }
}

const normalizeAppearance = appearance => {
  return {
    appearanceArchiveUrl: appearance.linkingContent?.archiveUrl,
    appearanceContentId: appearance.linkingContent?.id,
    appearanceId: appearance.id,
    appearanceImageUrl: appearance.linkingContent?.externalThumbUrl,
    appearanceIsFlaggedOnFacebook: appearance.flaggedOnFacebook,
    appearanceSource: appearance.linkingContent?.source,
    appearanceTitle: appearance.linkingContent?.title,
    appearanceType: appearance.subType,
    appearanceUrl: appearance.linkingContent?.url,
    appearanceUrlType: appearance.linkingContent?.urlType,
    channelDisplayName: appearance.channelDisplayName,
    channelId: appearance.linkingContent?.channel?.id,
    channelUrl: appearance.linkingContent?.channel?.url,
    conclusionLabel: appearance.reviewTag,
    conclusionScore: appearance.reviewTagScore,
    contentType: appearance.linkingContent?.type,
    documents: appearance.linkingContent?.documents.map(
      normalizeAppearanceDocument
    ),
    id: appearance.id,
    interactionNumber: appearanceTotalInteractionsNumber(appearance),
    sharesNumber: appearance.linkingContent?.sharesCount,
    interactions: [
      {
        type: 'facebook',
        interactions: appearanceSocialMediaInteractions(appearance, 'facebook'),
      },
      {
        type: 'twitter',
        interactions: appearanceSocialMediaInteractions(appearance, 'twitter'),
      },
      {
        type: 'youtube',
        interactions: appearanceSocialMediaInteractions(appearance, 'youtube'),
      },
      {
        type: 'redd.it',
        interactions: appearanceSocialMediaInteractions(appearance, 'reddit'),
      },
      {
        type: 'instagram',
        interactions: appearanceSocialMediaInteractions(
          appearance,
          'instagram'
        ),
      },
      {
        type: 'tiktok',
        interactions: appearanceSocialMediaInteractions(appearance, 'tiktok'),
      },
    ],
  }
}

export default normalizeAppearance

import PropTypes from 'prop-types'
import React from 'react'

import { Typography, EditSource, Icon } from 'components'

import './sourcePageHeader.scss'
const SourcePageHeader = ({
  isAuthorizedToEdit,
  isLoggedIn,
  onRefetchSource,
  source,
}) => {
  let icon = source.sourceType
  let type = source.sourceType
  if (source.channelType) {
    icon = source.channelType
    type = `${source.channelType} ${source.sourceType}`
  }
  return (
    <div className="source-page-header">
      <div className="source-page-header--source--info">
        <div className="source-page-header--info--sourcetype">
          <div className="source-page-header--info--sourcetype--icon">
            <Icon icon={icon} />
            <Typography color="secondary-grey" variant="body-text-4">
              {type}
            </Typography>
          </div>

          <Typography variant="h2">{source.name}</Typography>
        </div>
        {source.url && (
          <div className="source-page-header--url">
            <Typography variant="body-text-2">{source.url}</Typography>
          </div>
        )}
      </div>
      <div className="source-page-header--actions">
        {source.id && isLoggedIn && isAuthorizedToEdit && (
          <EditSource source={source} onRefetchSource={onRefetchSource} />
        )}
      </div>
    </div>
  )
}

SourcePageHeader.defaultProps = {
  isAuthorizedToEdit: false,
  onRefetchSource: () => {},
  source: {
    channels: null,
    media: null,
    owners: [],
  },
}

SourcePageHeader.propTypes = {
  isAuthorizedToEdit: PropTypes.bool,
  isLoggedIn: PropTypes.bool.isRequired,
  onRefetchSource: PropTypes.func,
  source: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    channels: PropTypes.arrayOf(PropTypes.shape({})),
    linkNumber: PropTypes.number,
    interactionNumber: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    media: PropTypes.arrayOf(PropTypes.shape({})),
    owners: PropTypes.arrayOf(PropTypes.shape({})),
    sourceType: PropTypes.string,
    stats: PropTypes.shape({}),
  }),
}

export default SourcePageHeader

import PropTypes from 'prop-types'
import React, { useState } from 'react'

import { Button, Divider, ConfirmBar, ReviewDetails } from 'components'
import EditConclusion from 'components/Business/Conclusions/EditConclusion'
import {
  useReview,
  useReviewEdition,
  useCreateVerdictConclusion,
  useModifyConclusion,
} from 'hooks'

import './editConclusion.scss'

const EditReviewConclusion = ({ reviewId, resetReview, refetchReviews }) => {
  const [showConclusionsAddedConfirmBar, setShowConclusionsAddedConfirmBar] =
    useState(false)
  const toggleShowConclusionsAddedConfirmBar = () =>
    setShowConclusionsAddedConfirmBar(!showConclusionsAddedConfirmBar)

  const { data: review, refetch: refetchReview } = useReview({ id: reviewId })

  const editReview = useReviewEdition(reviewId)
  const onMarkReviewAsTreated = () => {
    editReview.mutate({
      'isTreated': true,
    }, {
      onSuccess: () => {
        resetReview()
        refetchReviews()
      }
    })
  }

  const createConclusion = useCreateVerdictConclusion()
  const onCreateConclusion = async (
    factcheckerId,
    initialTagId,
    conclusionData
  ) => {
    const data = {
      verdictId: review.id,
      conclusionData: {
        channelId: factcheckerId,
        tagId: initialTagId,
        ...conclusionData,
      },
    }
    createConclusion.mutate(data, {
      onSuccess: () => refetchReview(),
    })
  }

  const modifyConclusion = useModifyConclusion()
  const onModifyConclusion = async (conclusionId, conclusionData) => {
    const data = {
      conclusionId: conclusionId,
      conclusionData: conclusionData,
    }
    modifyConclusion.mutate(data, {
      onSuccess: () => refetchReview(),
    })
  }

  const onEditConclusion = values => {
    if (review.isConclusion) {
      onModifyConclusion(review.conclusionId, values)
    } else {
      onCreateConclusion(review.factcheckerId, review.initialTagId, values)
    }
  }

  const onClose = () => {
    toggleShowConclusionsAddedConfirmBar()
    setTimeout(() => {
      setShowConclusionsAddedConfirmBar(false)
    }, 3000)
  }

  if (!review) return null

  return (
    <div className="reviews-with-no-conclusions-page--review-profile">
      <ReviewDetails
        claimantId={review.claimantId}
        claimantName={review.claimBy}
        conclusionScore={review.conclusionScore}
        conclusionStandardForm={review.conclusionStandardForm}
        factcheckerVerdict={review.factcheckerVerdict}
        fullReviewLink={review.reviewUrl}
        id={review.id}
        initialClaim={review.initialClaim}
        isLoggedIn
        linkToPage
        reviewBy={review.reviewBy}
        reviewByUrl={review.reviewByUrl}
        reviewTitle={review.review}
        standardClaim={review.standardClaim}
        topics={review.topicTags}
      />

      <EditConclusion
        cancel={false}
        conclusionStandardForm={review.conclusionStandardForm}
        factchecker={review.reviewBy}
        factcheckerVerdict={review.factcheckerVerdict}
        onClose={onClose}
        onSubmit={onEditConclusion}
        score={Number(review.conclusionScore)}
      />
      <Divider className="reviews-with-no-conclusions-page--review-profile--divider" />


      <div className="reviews-with-no-conclusions-page--review-profile--treated-button">
        <Button
          onClick={onMarkReviewAsTreated}
          type="button"
          variant="ghost"
        >
          Mark as treated
        </Button>
      </div>

      <ConfirmBar
        onClose={toggleShowConclusionsAddedConfirmBar}
        show={showConclusionsAddedConfirmBar}
        text="Conclusion have been added"
      />
    </div>
  )
}

export default EditReviewConclusion

EditReviewConclusion.defaultProps = {}

EditReviewConclusion.propTypes = {
  reviewId: PropTypes.string.isRequired,
}

import PropTypes from 'prop-types'
import React, { useState } from 'react'

import './suggestedQuotationCard.scss'

import Button from 'components/Atomic/Atoms/Button'
import Checkbox from 'components/Atomic/Atoms/Checkbox'
import Icon from 'components/Atomic/Atoms/Icon'
import Image from 'components/Atomic/Atoms/Image'
import Typography from 'components/Atomic/Atoms/Typography'
import AppearanceTagsSelectField from 'components/Business/Appearances/AppearanceTagsSelectField'
import { numberShortener } from 'utils/shorteners'

const SuggestedQuotationCard = ({
  edit,
  facebookAngryCount,
  facebookCommentCount,
  facebookHahaCount,
  facebookLikeCount,
  facebookLoveCount,
  facebookSadCount,
  facebookShares,
  facebookWowCount,
  imageUrl,
  interactionNumber,
  onAdd,
  onModify,
  onRemove,
  pinterestShares,
  redditEngagements,
  title,
  twitterShares,
  semanticScore,
  syntacticScore,
  url,
  urlDisplayed,
  youtubeCommentCount,
  youtubeDislikeCount,
  youtubeLikeCount,
  youtubeTrendingScore,
}) => {
  const [isFlaggedOnFacebook, setIsFlaggedOnFacebook] = useState(false)
  const onCheckboxClick = () => {
    setIsFlaggedOnFacebook(!isFlaggedOnFacebook)
    onModify(url, null, !isFlaggedOnFacebook)
  }

  const onClickAdd = () => {
    onAdd({
      // Buzzsumo interactions returned
      facebookAngryCount: facebookAngryCount,
      facebookCommentCount: facebookCommentCount,
      facebookHahaCount: facebookHahaCount,
      facebookLikeCount: facebookLikeCount,
      facebookLoveCount: facebookLoveCount,
      facebookSadCount: facebookSadCount,
      facebookShares: facebookShares,
      facebookWowCount: facebookWowCount,
      pinterestShares: pinterestShares,
      redditEngagements: redditEngagements,
      twitterShares: twitterShares,
      youtubeCommentCount: youtubeCommentCount,
      youtubeDislikeCount: youtubeDislikeCount,
      youtubeLikeCount: youtubeLikeCount,
      youtubeTrendingScore: youtubeTrendingScore,

      title: title,
      url: url,
    })
  }

  const onClickCancel = () => {
    onRemove(url)
  }

  const onChooseTagLabel = tagLabel => {
    onModify(url, tagLabel, null)
  }
  return (
    <div className="suggested-quotation-card">
      <div className="suggested-quotation-card--image">
        <Image name="content" url={imageUrl} />
      </div>

      <div className="suggested-quotation-card--body">
        <div className="suggested-quotation-card--body--section">
          <div className="suggested-quotation-card--body--section--header">
            <Typography variant="body-text-4">
              <a href={url} rel="noopener noreferrer" target="_blank">
                {urlDisplayed}
              </a>
            </Typography>

            {!edit ? (
              <Button onClick={onClickAdd} variant="default-link">
                <Typography variant="title-1">Add</Typography>
                <Icon icon="add" />
              </Button>
            ) : (
              <Button onClick={onClickCancel} variant="default-link">
                <Typography variant="title-1">Cancel</Typography>
                <Icon icon="cross" />
              </Button>
            )}
          </div>
          <div className="suggested-quotation-card--body--section--content">
            <Typography variant="body-text-2">
              {title ? title : 'No title'}
            </Typography>
          </div>
        </div>
        {!edit ? (
          <div className="suggested-quotation-card--body--footer">
            <div className="suggested-quotation-card--body--footer--group-left">
              {!!interactionNumber && interactionNumber > 0 && (
                <Typography variant="numbers">
                  <span>
                    <b>{numberShortener(interactionNumber)}</b> interactions
                  </span>
                </Typography>
              )}
            </div>
            <div className="suggested-quotation-card--body--footer--group-right">
              {!!syntacticScore && syntacticScore !== 0 && (
                <Typography variant="numbers">
                  Syntactic score: <b>{syntacticScore}</b>
                </Typography>
              )}

              {!!semanticScore && semanticScore !== 0 && (
                <Typography variant="numbers">
                  Semantic score: <b>{semanticScore}</b>
                </Typography>
              )}
            </div>
          </div>
        ) : (
          <div className="suggested-quotation-card--body--form">
            <AppearanceTagsSelectField
              defaultValue="False"
              inputRef="add-suggested-appearances-tags-select"
              onChoose={e => onChooseTagLabel(e.target.value)}
            />

            <div className="suggested-quotation-card--body--form--checkbox">
              <Checkbox
                checked={isFlaggedOnFacebook}
                onChange={onCheckboxClick}
              />
              <Typography variant="body-text-2">
                Link has been flagged on Facebook
              </Typography>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default SuggestedQuotationCard

SuggestedQuotationCard.defaultProps = {
  edit: false,
  imageUrl: null,
  interactionNumber: null,
  semanticScore: null,
  syntacticScore: null,
  title: null,
  url: null,
  urlDisplayed: null,
}

SuggestedQuotationCard.propTypes = {
  edit: PropTypes.bool,
  imageUrl: PropTypes.string,
  interactionNumber: PropTypes.number,
  onAdd: PropTypes.func.isRequired,
  onModify: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  semanticScore: PropTypes.number,
  syntacticScore: PropTypes.number,
  title: PropTypes.string,
  url: PropTypes.string,
  urlDisplayed: PropTypes.string,
}

import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'

import {
  InfiniteScroll,
  Loader,
  ReviewAndClaim,
  Search,
  SearchPageFilter,
  SourceCard,
  Tab,
  TabPanel,
  TabsContext,
  Typography,
} from 'components'

import './searchPage.scss'

const SearchPageDesktop = ({
  currentFilters,
  dataLimit,
  dataType,
  defaultSearch,
  defaultTab,
  fetchNextReviewPage,
  fetchNextSourcePage,
  hasNextReviewPage,
  hasNextSourcePage,
  onFilterChoose,
  onSearch,
  onTabChange,
  sources,
  reviews,
  reviewIsLoading,
  sourceIsLoading,
  selectedFilters,
}) => {
  const [scroll, setScroll] = useState(false)
  useEffect(() => {
    window.addEventListener('scroll', () => {
      setScroll(window.scrollY > 155)
    })
  }, [])

  return (
    <div className="searchPage">
      <TabsContext defaultTab={defaultTab}>
        <Typography className="searchPage--title" variant="h2">
          Search Open Feedback
        </Typography>

        <div className={scroll ? 'searchPage--fixed' : undefined}>
          <div className="searchPage--search">
            <Search
              defaultSearch={defaultSearch}
              onSearch={onSearch}
              placeholder="Type a title, key word.. "
              variant="ghost"
            />
          </div>
          <div className="searchPage--panel">
            <div className="searchPage--panel--tabs">
              <Tab
                callback={onTabChange}
                className="searchPage--panel--tabs--tab"
                text="Reviews & Claims"
                value="reviews"
              />
              <Tab
                callback={onTabChange}
                className="searchPage--panel--tabs--tab"
                dataCy="tab-media-channel"
                text="Media & Channels"
                value="sources"
              />
            </div>
            <SearchPageFilter
              className="searchPage--panel--filter"
              onFilterChoose={onFilterChoose}
            />
          </div>
        </div>

        <div className="searchPage--filter-container">
          <TabPanel
            className="searchPage--filter-container--tab"
            value="reviews"
          >
            <InfiniteScroll
              className="searchPage--review-list"
              dataLength={reviews.length}
              hasMore={hasNextReviewPage}
              isLoading={reviewIsLoading}
              loader={<Loader />}
              next={fetchNextReviewPage}
            >
              {reviews.map(source => (
                <div
                  className="searchPage--review-list--item"
                  key={`review-${source.id}`}
                >
                  <ReviewAndClaim {...source} />
                </div>
              ))}
            </InfiniteScroll>
          </TabPanel>

          <TabPanel
            className="searchPage--filter-container--tab"
            value="sources"
          >
            <InfiniteScroll
              className="searchPage--source-list"
              dataLength={sources.length}
              hasMore={hasNextSourcePage}
              isLoading={sourceIsLoading}
              loader={Loader}
              next={fetchNextSourcePage}
            >
              {sources.map(source => (
                <div
                  className="searchPage--source-list--item"
                  key={`${source.sourceType}-${source.id}`}
                >
                  <SourceCard
                    {...source}
                    variant={`${dataLimit}-${dataType}`}
                  />
                </div>
              ))}
            </InfiniteScroll>
          </TabPanel>
          <div
            className={`searchPage--filter-container--filters ${
              scroll
                ? 'searchPage--filter-container--filters--fixed'
                : undefined
            }`}
          >
            <SearchPageFilter
              className="searchPage--filter-container--filters--sort"
              currentFilters={currentFilters}
              onFilterChoose={onFilterChoose}
              selectedFilters={selectedFilters}
            />
          </div>
        </div>
      </TabsContext>
    </div>
  )
}
export default SearchPageDesktop

SearchPageDesktop.defaultProps = {
  currentFilters: {},
  defaultSearch: '',
  defaultTab: 'reviews',
  hasNextReviewPage: undefined,
  hasNextSourcePage: undefined,
  onTabChange: () => null,
  reviews: [],
  selectedFilters: {},
  sources: [],
}

SearchPageDesktop.propTypes = {
  currentFilters: PropTypes.shape({}),
  defaultSearch: PropTypes.string,
  defaultTab: PropTypes.string,
  fetchNextReviewPage: PropTypes.func.isRequired,
  fetchNextSourcePage: PropTypes.func.isRequired,
  hasNextReviewPage: PropTypes.bool,
  hasNextSourcePage: PropTypes.bool,
  onSearch: PropTypes.func.isRequired,
  onTabChange: PropTypes.func,
  reviews: PropTypes.arrayOf(PropTypes.shape({})),
  selectedFilters: PropTypes.shape({}),
  sources: PropTypes.arrayOf(PropTypes.shape({})),
}

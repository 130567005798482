import PropTypes from 'prop-types'
import React from 'react'
import './typography.scss'

/* eslint-disable react/button-has-type */
const Typography = ({ children, variant, className, color }) => {
  switch (variant) {
    case 'h1':
      return (
        <h1
          className={`typography typography-h1 ${className}`}
          data-color={color}
        >
          {children}
        </h1>
      )
    case 'h2':
      return (
        <h2
          className={`typography typography-h2 ${className}`}
          data-color={color}
        >
          {children}
        </h2>
      )
    case 'h3':
      return (
        <h2
          className={`typography typography-h3 ${className}`}
          data-color={color}
        >
          {children}
        </h2>
      )
    default:
      return (
        <p
          className={`typography typography-${variant} ${className}`}
          data-color={color}
        >
          {children}
        </p>
      )
  }
}

export default Typography

Typography.defaultProps = {
  children: null,
  className: '',
  color: 'primary-dark',
  variant: 'text',
}
Typography.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  className: PropTypes.string,
  color: PropTypes.oneOf([
    'primary-dark',
    'primary-white',
    'primary-red',
    'primary-blue',
    'secondary-grey',
  ]),
  variant: PropTypes.oneOf([
    'h1',
    'h2',
    'h3',
    'title-1',
    'title-2',
    'title-3',
    'title-4',
    'body-text-1',
    'body-text-2',
    'body-text-3',
    'body-text-4',
    'menu-text-1',
    'menu-text-2',
    'btn-text',
    'link-text-1',
    'link-text-2',
    'link-text-3',
    'numbers',
  ]),
}

import PropTypes from 'prop-types'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import './reviewAndClaimMobile.scss'

import Divider from 'components/Atomic/Atoms/Divider'
import Claim from 'components/Business/Claims/Claim'
import Verdict from 'components/Business/Review/Review'

const ReviewAndClaimMobile = ({
  id,
  reviewBy,
  reviewByUrl,
  review,
  reviewDate,
  reviewUrl,
  claim,
  claimBy,
  conclusionLabel,
  conclusionScore,
  linkNumber,
  interactionNumber,
}) => {
  let navigate = useNavigate()
  const clickOnReviewPage = event => {
    if (event.target.tagName.toLowerCase() !== 'a') {
      navigate(`/review/${id}`)
    }
  }

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
    <div className="review-card-mobile" onClick={clickOnReviewPage}>
      <div className="review-card-mobile--verdict-section">
        <Verdict
          review={review}
          reviewBy={reviewBy}
          reviewByUrl={reviewByUrl}
          reviewDate={reviewDate}
          reviewUrl={reviewUrl}
          variant="ghost"
        />
        <div className="review-card-mobile--verdict-section--divider">
          <Divider borderStyle="dashed" thickness="thin" />
        </div>
      </div>
      <div className="review-card-mobile--claim-section">
        <Divider
          borderStyle="solid"
          orientation="vertical"
          score={conclusionScore}
          thickness="thick"
        />
        <div className="review-card-mobile--claim-section--claim">
          <Claim
            claim={claim}
            claimBy={claimBy}
            conclusionLabel={conclusionLabel}
            conclusionScore={conclusionScore}
            interactionNumber={interactionNumber}
            linkNumber={linkNumber}
          />
        </div>
      </div>
    </div>
  )
}

export default ReviewAndClaimMobile

ReviewAndClaimMobile.defaultProps = {
  claim: '',
  claimBy: '',
  conclusionLabel: '',
  conclusionScore: null,
  id: null,
  interactionNumber: 0,
  linkNumber: 0,
  review: '',
  reviewBy: '',
  reviewByUrl: '',
  reviewDate: '',
  reviewUrl: '',
}

ReviewAndClaimMobile.propTypes = {
  claim: PropTypes.string,
  claimBy: PropTypes.string,
  conclusionLabel: PropTypes.string,
  conclusionScore: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  id: PropTypes.string,
  interactionNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  linkNumber: PropTypes.number,
  review: PropTypes.string,
  reviewBy: PropTypes.string,
  reviewByUrl: PropTypes.string,
  reviewDate: PropTypes.string,
  reviewUrl: PropTypes.string,
}

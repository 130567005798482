import PropTypes from 'prop-types'
import React from 'react'

import iconsMap from './iconsMap'

const Icon = ({ icon, color, size }) => {
  const iconData = iconsMap[icon?.toLowerCase()] || iconsMap['cross']
  const colorAttr = iconData.fill ? { fill: color } : { stroke: color }

  return (
    <svg fill="none" height={size} viewBox="0 0 15 15" width={size}>
      <path d={iconData.path} {...colorAttr} />
    </svg>
  )
}

Icon.defaultProps = {
  className: '',
  color: '#18201E',
  icon: 'cross',
  size: 16,
}

Icon.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  icon: PropTypes.string,
  size: PropTypes.number,
}

export default Icon

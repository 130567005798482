import React, { useState } from 'react'
import { useFormContext } from 'react-hook-form'

import { Select, TextField, Typography } from 'components/index'
import { useWikiData } from 'hooks'

export const Wikimedia = () => {
  const { register, setValue, getValues } = useFormContext()
  const [wikiSearch, setWikiSearch] = useState(getValues('name'))
  const { data, isLoading } = useWikiData(wikiSearch)

  const onSearch = search => {
    setWikiSearch(search)
  }

  const onChange = value => {
    setValue('name', value.label)
    setValue('wikidataIdentifier', value.id)
    setWikiSearch(value.label)
  }

  return (
    <>
      <div className="edit-source--section">
        <Typography
          className="edit-source--label"
          color="primary-white"
          variant="title-2"
        >
          Wikimedia search:
        </Typography>

        <Select
          className="edit-source--input"
          isLoading={isLoading}
          onChange={onChange}
          onSearch={onSearch}
          options={data}
          placeholder="Search for ?"
        />
      </div>

      <div className="edit-source--section">
        <Typography
          className="edit-source--label"
          color="primary-white"
          variant="title-2"
        >
          Wikidata identifier:
        </Typography>

        <TextField
          className="edit-source--input"
          placeholder="Wikidata identifier"
          readOnly="readonly"
          register={register}
          registerName="wikidataIdentifier"
          rounded={false}
        />
      </div>
    </>
  )
}

export default Wikimedia

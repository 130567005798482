import React, { useState } from 'react'
import { useMediaQuery } from 'react-responsive'

import { useAuth, useLogin } from 'hooks'

import LoginForm from '../LoginForm'
import HeaderDesktop from './HeaderDesktop'
import HeaderMobile from './HeaderMobile'

const Header = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 1200px)' })

  const [showLogin, setShowLogin] = useState(false)
  const toggleLoginForm = () => setShowLogin(!showLogin)

  const { isAuth } = useAuth()
  const { login, logout, loginData } = useLogin()

  const onLoginClick = () => {
    toggleLoginForm()
  }

  const onLogoutClick = () => {
    logout()
  }
  const onSubmit = values => {
    login(values)
  }

  return (
    <>
      {isMobile ? (
        <HeaderMobile
          isAuth={isAuth}
          onLoginClick={onLoginClick}
          onLogoutClick={onLogoutClick}
        />
      ) : (
        <HeaderDesktop
          isAuth={isAuth}
          onLoginClick={onLoginClick}
          onLogoutClick={onLogoutClick}
        />
      )}
      <LoginForm
        error={JSON.stringify(loginData.error?.response.data[0])}
        isSuccess={loginData.isSuccess}
        onClose={toggleLoginForm}
        onSubmit={onSubmit}
        show={showLogin}
      />
    </>
  )
}

export default Header

import dayjs from 'dayjs'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'

import { Typography, Icon, Loader } from 'components'
import { useAppearanceShares } from 'hooks'

import './QuotationShares.scss'

import { numberShortener } from '../../../../utils/shorteners'
import Button from '../../../Atomic/Atoms/Button'
import Tab from '../../../Atomic/Molecules/Tabs/Tab'
import TabPanel from '../../../Atomic/Molecules/Tabs/TabPanel'
import TabsContext from '../../../Atomic/Molecules/Tabs/TabsContext'

const LIMIT = 5
const QuotationShares = ({ appearanceId, isLoggedIn, interactions }) => {
  const interactionsFiltered = interactions.sort((r1, r2) =>
    r1.interactions < r2.interactions
      ? 1
      : r1.interactions > r2.interactions
      ? -1
      : 0
  )
  const [activePlatform, setActivePlatform] = useState(
    interactionsFiltered[0].type
  )

  const { data, isLoading } = useAppearanceShares({
    appearanceId,
    isLoggedIn,
    platform: activePlatform,
  })

  const shares = data?.data.data || []

  useEffect(() => {
    setList(shares.slice(0, LIMIT))
  }, [shares])

  const [showMore, setShowMore] = useState(true)
  const [index, setIndex] = useState(LIMIT)
  const [list, setList] = useState(shares.slice(0, LIMIT))
  const loadMore = () => {
    const newIndex = index + LIMIT
    const newShowMore = newIndex < shares.length - 1
    const newList = shares.slice(0, newIndex)
    setIndex(newIndex)
    setList(newList)
    setShowMore(newShowMore)
  }

  return (
    <div className="appearance-shares">
      <TabsContext defaultTab={activePlatform}>
        <div className="appearance-shares--header">
          {interactions.map(
            sharesType =>
              sharesType.interactions > 0 && (
                <Tab
                  callback={() => setActivePlatform(sharesType.type)}
                  className="appearance-shares--header--tab"
                  key={sharesType.type}
                  value={sharesType.type}
                >
                  <Icon icon={sharesType.type} /> &nbsp;
                  <Typography color="secondary-grey" variant="body-text-4">
                    {numberShortener(sharesType.interactions)} int.
                  </Typography>
                </Tab>
              )
          )}
        </div>

        <div className="appearance-shares--body">
          {interactions.map(sharesType => (
            <TabPanel key={sharesType.type} value={sharesType.type}>
              <Typography
                className="appearance-shares--body--total"
                variant="body-text-3"
              >
                <Icon icon="barchart" /> &nbsp;Total interactions on{' '}
                {sharesType.type}: {sharesType.interactions}
              </Typography>

              <Typography
                className="appearance-shares--body--post"
                variant="body-text-4"
              >
                <Icon icon="arrowright" /> &nbsp;
                {shares.length === 0 ? `No` : shares.length} public shares of
                that appearance on {sharesType.type}
              </Typography>

              <table>
                <thead>
                  <tr>
                    <th>
                      <Typography color="primary-white" variant="body-text-4">
                        Date
                      </Typography>
                    </th>
                    <th>
                      <Typography color="primary-white" variant="body-text-4">
                        Source
                      </Typography>
                    </th>
                    <th>
                      <Typography color="primary-white" variant="body-text-4">
                        Comment
                      </Typography>
                    </th>
                    <th>
                      <Typography color="primary-white" variant="body-text-4">
                        Interactions
                      </Typography>
                    </th>
                    <th className="last"> Link </th>
                  </tr>
                </thead>

                <tbody>
                  {isLoading ? (
                    <tr>
                      {' '}
                      <td colSpan="5">
                        <Loader />
                      </td>
                    </tr>
                  ) : (
                    list.map(share => (
                      <tr key={share.id}>
                        <td>
                          <Typography
                            color="secondary-grey"
                            variant="body-text-4"
                          >
                            {dayjs(share.shareDate).format('DD/MM/YYYY')}
                          </Typography>
                        </td>
                        <td>
                          <a href={`/channel/${share.channelId}`}>
                            <Typography variant="link-text-3">
                              {share.channelDisplayName}
                            </Typography>
                          </a>
                        </td>
                        <td>
                          <Typography
                            color="secondary-grey"
                            variant="body-text-4"
                          >
                            {share.shareSummary}
                          </Typography>
                        </td>
                        <td>
                          <Typography variant="body-text-2">
                            {share.interactionNumber}
                          </Typography>
                        </td>
                        <td className="appearance-shares--body--link last">
                          <a href={share.shareUrl}>
                            {' '}
                            <Icon icon="arrowup45" />{' '}
                          </a>
                        </td>
                      </tr>
                    ))
                  )}
                  {shares.length === 0 && (
                    <tr>
                      <td>
                        <Typography
                          className="appearance-shares--body--post"
                          variant="body-text-4"
                        >
                          No data
                        </Typography>
                      </td>

                      <td />
                      <td />
                      <td />
                      <td />
                    </tr>
                  )}
                </tbody>
              </table>
              {showMore && (
                <Button onClick={loadMore} variant="default-link">
                  <Typography color="primary-blue" variant="link-text-3">
                    Load More
                  </Typography>
                </Button>
              )}
            </TabPanel>
          ))}
        </div>
      </TabsContext>
    </div>
  )
}

export default QuotationShares

QuotationShares.defaultProps = {
  appearanceId: null,
  isLoggedIn: false,
}

QuotationShares.propTypes = {
  appearanceId: PropTypes.string,
  isLoggedIn: PropTypes.bool,
}

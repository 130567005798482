import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'

import { Metric, Typography, InteractionCircle } from 'components/index'

import './mediaCard.scss'

const MediaCard = ({
  channelsCount,
  interactionData,
  interactionNumber,
  id,
  sourceName,
}) => (
  <Link className="media-card" to={`/media/${id}`}>
    <InteractionCircle data={interactionData} label={interactionNumber} />
    <div className="media-card--body">
      <Typography className="media-card--body--name" variant="title-1">
        {sourceName}
      </Typography>
      <Metric number={channelsCount} text="channels" />
    </div>
  </Link>
)

export default MediaCard

MediaCard.defaultProps = {
  channelsCount: 0,
  id: null,
  interactionData: [{ title: 'Unknown', value: 10, color: '#FFA951' }],
  interactionNumber: '0',
  sourceName: 'Unknown',
}

MediaCard.propTypes = {
  channelsCount: PropTypes.number,
  id: PropTypes.string,
  interactionData: PropTypes.arrayOf(PropTypes.shape({})),
  interactionNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  sourceName: PropTypes.string,
}

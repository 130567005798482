import axios from 'axios'
import React from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { Provider } from 'react-redux'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'

import NotFound from 'components/Pages/NotFound'
import AboutUs from 'modules/AboutUs'
import DashboardRouter from 'modules/Dashboard'
import CredibilityFeedbackRouter from 'modules/Feedback'
import FactcheckerVerdicts from 'modules/FactcheckerVerdicts'
import HomePage from 'modules/HomePage/pages/HomePage'
import ResetPassword from 'modules/Auth/ResetPassword'
import ReviewRouter from 'modules/Review'
import Search from 'modules/Search'
import configureStore from 'redux/store'

import { Source } from '../modules/Sources/pages'
import { AuthProvider } from './AuthProvider'
import Helmet from './Helmet'
import Layout from './Layout'

const { persistor, store } = configureStore()
const queryClient = new QueryClient()

axios.interceptors.request.use(function (config) {
  config.withCredentials = true
  return config
})

const Index = () => (
  <QueryClientProvider client={queryClient}>
    <Provider store={store}>
      <AuthProvider>
        <PersistGate loading={null} persistor={persistor}>
          <BrowserRouter>
            <Helmet>
              <Routes>
                <Route element={<Layout />} path="/">
                  <Route element={<HomePage />} index />
                  <Route element={<AboutUs />} path="about" />
                  <Route element={<Search />} path="search" />

                  <Route path="channel">
                    <Route element={<Search />} index />
                    <Route element={<Source />} path=":sourceId" />
                  </Route>
                  <Route path="organization">
                    <Route element={<Search />} index />
                    <Route element={<Source />} path=":sourceId" />
                  </Route>
                  <Route path="media">
                    <Route element={<Search />} index />
                    <Route element={<Source />} path=":sourceId" />
                  </Route>

                  <Route element={<ReviewRouter />} path="review/*" />

                  <Route
                    element={<CredibilityFeedbackRouter />}
                    path="feedback/*"
                  />
                  <Route element={<DashboardRouter />} path="dashboard/*" />

                  <Route element={<FactcheckerVerdicts />} path="factchecker/verdicts" />

                  <Route element={<ResetPassword />} path="reset-password" />

                  <Route element={<NotFound />} path="*" />
                </Route>
              </Routes>
            </Helmet>
          </BrowserRouter>
        </PersistGate>
      </AuthProvider>
    </Provider>
    <ReactQueryDevtools />
  </QueryClientProvider>
)

export default Index

import PropTypes from 'prop-types'
import React, { useCallback, useState } from 'react'
import { useForm, FormProvider } from 'react-hook-form'

import './editFeedbackRequest.scss'

import { SideBar, Button, TextField, Typography } from 'components'
import { useEditFeedbackRequest } from 'hooks'

const EditFeedbackRequest = ({ feedbackRequest, onSuccess }) => {
  const {
    id,
    context,
    publicQuestion,
    claim: { text },
    content: { url },
  } = feedbackRequest

  const methods = useForm({
    defaultValues: {
      claim: { text },
      content: { url },
      context,
      publicQuestion,
    },
  })

  const editFeedbackRequest = useEditFeedbackRequest(id)

  const [show, setShow] = useState(false)

  const onClose = useCallback(() => {
    setShow(false)
  }, [setShow])

  const onSend = useCallback(
    values => {
      onClose()
      editFeedbackRequest.mutate(values, {
        onSuccess: () => onSuccess(),
      })
    },
    [editFeedbackRequest, onClose, onSuccess]
  )

  return (
    <div className="edit-feedback-request">
      <div className="edit-feedback-request--edit-button">
        <Button onClick={() => setShow(!show)} variant="ghost-reverse">
          <Typography onClick variant="btn-text">
            Edit
          </Typography>
        </Button>
      </div>

      <SideBar onClose={onClose} show={show}>
        <Typography
          className="edit-feedback-request--title"
          color="primary-white"
          variant="h2"
        >
          Edit Feedback Request:
        </Typography>

        <FormProvider {...methods}>
          <form
            className="edit-feedback-request--form"
            onSubmit={methods.handleSubmit(onSend)}
          >
            <Typography
              className="edit-feedback-request--label"
              color="primary-white"
              variant="title-2"
            >
              Public question:
            </Typography>

            <TextField
              placeholder="Short version of your question (publicly visible)"
              register={methods.register}
              registerName="publicQuestion"
              registerValidation={{ required: true }}
              rounded={false}
            />

            <Typography
              className="edit-feedback-request--label"
              color="primary-white"
              variant="title-2"
            >
              Context:
            </Typography>

            <TextField
              placeholder="Explain the context for your question or claim verification request (private: only shown to scientists)"
              register={methods.register}
              registerName="context"
              registerValidation={{ required: true }}
              rounded={false}
            />

            <Typography
              className="edit-feedback-request--label"
              color="primary-white"
              variant="title-2"
            >
              Claim:
            </Typography>

            <TextField
              placeholder="The claim you want to check, if any"
              register={methods.register}
              registerName="claim.text"
              registerValidation={{ required: true }}
              rounded={false}
            />

            <Typography
              className="edit-feedback-request--label"
              color="primary-white"
              variant="title-2"
            >
              URL:
            </Typography>

            <TextField
              placeholder="Link to the article/social media post containing the claim (archived version prefered)"
              register={methods.register}
              registerName="content.url"
              registerValidation={{ required: true }}
              rounded={false}
            />

            <div className="edit-feedback-request--actions">
              <Button onClick={onClose} type="button" variant="ghost-reverse">
                Cancel
              </Button>

              <Button type="submit" variant="ghost-reverse">
                Save
              </Button>
            </div>
          </form>
        </FormProvider>
      </SideBar>
    </div>
  )
}

EditFeedbackRequest.propTypes = {
  feedbackRequest: PropTypes.shape({
    claim: PropTypes.shape({
      text: PropTypes.string,
    }).isRequired,
    content: PropTypes.shape({
      url: PropTypes.string,
    }).isRequired,
    context: PropTypes.string,
    id: PropTypes.string.isRequired,
    publicQuestion: PropTypes.string.isRequired,
  }).isRequired,
  onSuccess: PropTypes.func.isRequired,
}

export default EditFeedbackRequest

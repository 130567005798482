import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'

import Button from 'components/Atomic/Atoms/Button'
import Divider from 'components/Atomic/Atoms/Divider'
import Icon from 'components/Atomic/Atoms/Icon'
import Typography from 'components/Atomic/Atoms/Typography'
import FooterBar from 'components/Atomic/Molecules/FooterBar'
import './editQuotationsForm.scss'

const EditQuotationsForm = ({
  nbAppearances,
  show,
  onClose,
  onCopy,
  onFlag,
  onUnflag,
}) => {
  const [showconfirmation, setShowconfirmation] = useState(null)

  const onCopyClick = values => {
    onCopy(values)
    setShowconfirmation(`Copied !`)
  }

  const onFlagClick = values => {
    onFlag(values)
    setShowconfirmation(`${nbAppearances} Appearances flagged !`)
  }

  const onUnflagClick = values => {
    onUnflag(values)
    setShowconfirmation(`${nbAppearances} Appearances unflagged !`)
  }

  useEffect(() => {
    if (showconfirmation !== null) {
      setTimeout(() => {
        setShowconfirmation(null)
      }, 1000)
    }
  }, [showconfirmation])

  return (
    <FooterBar show={show}>
      <div className="edit-quotations">
        {showconfirmation ? (
          <Typography color="primary-white" variant="title-1">
            {showconfirmation}
          </Typography>
        ) : (
          <>
            <div className="edit-quotations--text">
              <Typography color="primary-white" variant="title-1">
                {nbAppearances}{' '}
                {nbAppearances > 1 ? 'appearances' : 'appearance'} selected
              </Typography>
            </div>
            <div className="edit-quotations--actions">
              <div className="edit-quotations--actions--action">
                <Button onClick={onCopyClick} variant="ghost-reverse-link">
                  <Typography
                    className="edit-quotations--actions--action--text"
                    color="primary-white"
                    variant="title-3"
                  >
                    Copy URLs
                  </Typography>
                  <Icon color="#FFFFFB" icon="copy" />
                </Button>
              </div>
              <div className="edit-quotations--actions--action">
                <Button onClick={onFlagClick} variant="ghost-reverse-link">
                  <Typography
                    className="edit-quotations--actions--action--text"
                    color="primary-white"
                    variant="title-3"
                  >
                    Mark as flagged
                  </Typography>
                  <Icon color="#FFFFFB" icon="valid" />
                </Button>
              </div>
              <div className="edit-quotations--actions--action edit-quotations--actions--action--before-divider">
                <Button onClick={onUnflagClick} variant="ghost-reverse-link">
                  <Typography
                    className="edit-quotations--actions--action--text "
                    color="primary-white"
                    variant="title-3"
                  >
                    Mark as unflagged
                  </Typography>
                  <Icon color="#FFFFFB" icon="remove" />
                </Button>
              </div>

              <Divider
                borderStyle="dashed"
                className="edit-quotations--actions--divider"
                orientation="vertical"
                thickness="thin"
              />

              <Button onClick={onClose} variant="ghost-reverse-link">
                <Typography
                  className="edit-quotations--actions--action"
                  color="primary-white"
                  variant="title-3"
                >
                  Close
                </Typography>
              </Button>
            </div>
          </>
        )}
      </div>
    </FooterBar>
  )
}

EditQuotationsForm.defaultProps = {
  nbAppearances: 1,
  show: false,
}

EditQuotationsForm.propTypes = {
  nbAppearances: PropTypes.number,
  onClose: PropTypes.func.isRequired,
  onCopy: PropTypes.func.isRequired,
  onFlag: PropTypes.func.isRequired,
  onUnflag: PropTypes.func.isRequired,
  show: PropTypes.bool,
}

export default EditQuotationsForm

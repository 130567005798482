import { useMutation } from 'react-query'

import { useAxios } from 'hooks'

import { API_URL } from '../../utils/config'

export default function useModifyClaim(data) {
  const instances = useAxios()
  const modifyClaim = async modifyClaimData => {
    const { data } = await instances.patch(
      `${API_URL}/claims/${modifyClaimData.claimId}`,
      modifyClaimData.claimData
    )
    return data
  }
  return useMutation(data => modifyClaim(data), data)
}

import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'

import Button from '../../Atoms/Button'
import TextField from '../../Atoms/TextField'
import Typography from '../../Atoms/Typography'
import SideBar from '../../Molecules/SideBar'
import './loginForm.scss'

export const LoginForm = ({ show, onClose, onSubmit, error, isSuccess }) => {
  const { handleSubmit, register } = useForm()

  const onSend = values => {
    onSubmit(values)
  }

  useEffect(() => {
    if (isSuccess) {
      onClose()
    }
  }, [isSuccess, onClose])

  return (
    <SideBar onClose={onClose} show={show}>
      <form className="login-form" onSubmit={handleSubmit(onSend)}>
        <Typography
          className="login-form--title"
          color="primary-white"
          variant="h2"
        >
          Sign In
        </Typography>
        <Typography color="primary-white" variant="title-2">
          Your mail:
        </Typography>
        <TextField
          placeholder="Your login mail"
          register={register}
          registerName="identifier"
          rounded={false}
        />
        <Typography color="primary-white" variant="title-2">
          Password:
        </Typography>
        <TextField
          placeholder="Your login password"
          register={register}
          registerName="password"
          rounded={false}
          type="password"
        />

        <Typography color="primary-red" variant="body-text-4">
          {error}
        </Typography>
        <div className="login-form--submit-button">
          <Button data-cy="login-submit" type="submit" variant="ghost-reverse">
            Sign in
          </Button>
        </div>
      </form>
    </SideBar>
  )
}

LoginForm.defaultProps = {
  error: null,
  isSuccess: false,
  show: false,
}

LoginForm.propTypes = {
  error: PropTypes.string,
  isSuccess: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  show: PropTypes.bool,
}

export default LoginForm

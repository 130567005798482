import { useMutation } from 'react-query'

import { useAxios } from 'hooks'

import { API_URL } from '../../utils/config'

export default function useReattributeAppearance(data) {
  const instances = useAxios()
  const reattributeAppearance = async appearanceId => {
    const { data } = await instances.post(
      `${API_URL}/appearances/${appearanceId}/reattribute/`,
      {}
    )
    return data
  }
  return useMutation(data => reattributeAppearance(data), data)
}

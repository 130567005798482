import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'

import {
  AppearanceCard,
  AppearanceTagsSelectField,
  Icon,
  InfiniteScroll,
  Loader,
  Typography,
} from 'components/index'

import './sourcePageAppearances.scss'

const SourcePageAppearances = ({
  appearances,
  appearancesAreLoading,
  defaultTagLabel,
  fetchNextAppearancePage,
  hasNextAppearancePage,
  isLoggedIn,
  onChooseTagLabel,
  source,
}) => {
  const SELECT_TAGS_OPTIONS = [
    { value: 'False', text: 'False' },
    { value: 'Partly false', text: 'Partly false' },
    { value: 'Missing context', text: 'Missing context' },
    { value: 'Mostly true', text: 'Mostly true' },
    { value: 'True', text: 'True' },
    { value: 'Satire', text: 'Satire' },
    { value: 'Not eligible', text: 'Not eligible' },
    { value: 'Undefined', text: 'Undefined' },
  ].filter(option => source.numberLinksPerLabel?.[option.value] > 0)

  return (
    <div className="sourcePageAppearances">
      <div className="sourcePageAppearances--title">
        <Typography className="sourcePageAppearances--title--text" variant="h3">
          Appearances:
        </Typography>

        <Link to="/about#about-us-section-database">
          <Icon icon="info" />
        </Link>
      </div>

      <Typography
        className="sourcePageAppearances--subtitle"
        color="secondary-grey"
        variant="body-text-4"
      >
        Links from related media and channels containing claims that have been
        fact-checked
      </Typography>

      <div className="sourcePageAppearances--header">
        <Typography variant="title-1">
          {source.linkNumber} Links:&nbsp;
          {source.interactionNumber} interactions
        </Typography>

        {isLoggedIn && (
          <AppearanceTagsSelectField
            defaultValue={defaultTagLabel}
            inputRef="filter-appearances-tags"
            onChoose={e => onChooseTagLabel(e.target.value)}
            selectTagsOptions={SELECT_TAGS_OPTIONS}
          />
        )}
      </div>

      <InfiniteScroll
        className="sourcePageAppearances--infinite-scroll"
        dataLength={appearances.length}
        hasMore={hasNextAppearancePage}
        isLoading={appearancesAreLoading}
        loader={<Loader />}
        next={fetchNextAppearancePage}
        noDataDescription=""
        noDataTitle="No appearances here yet"
      >
        {appearances.map(appearance => (
          <div
            className="sourcePageAppearances--appearance"
            key={appearance.id}
          >
            <AppearanceCard
              {...appearance}
              appearanceId={appearance.id}
              isLoggedIn={isLoggedIn}
              sourceId={source.id}
              sourceType={source.sourceType}
              tagLabel={defaultTagLabel}
            />
          </div>
        ))}
      </InfiniteScroll>
    </div>
  )
}

SourcePageAppearances.defaultProps = {
  appearances: [],
  appearancesAreLoading: false,
  fetchNextAppearancePage: () => null,
  hasNextAppearancePage: undefined,
  isLoggedIn: false,
  onContact: () => null,
  source: {
    channels: null,
    media: null,
    owners: [],
    statsLink: {},
  },
}

SourcePageAppearances.propTypes = {
  appearances: PropTypes.arrayOf(PropTypes.shape({})),
  appearancesAreLoading: PropTypes.bool,
  fetchNextAppearancePage: PropTypes.func,
  hasNextAppearancePage: PropTypes.bool,
  isLoggedIn: PropTypes.bool,
  onContact: PropTypes.func,
  source: PropTypes.shape({
    id: PropTypes.string,
    channels: PropTypes.arrayOf(PropTypes.shape({})),
    linkNumber: PropTypes.number,
    interactionNumber: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    media: PropTypes.arrayOf(PropTypes.shape({})),
    owners: PropTypes.arrayOf(PropTypes.shape({})),
    sourceType: PropTypes.string,
    statsLink: PropTypes.shape({}),
    statsInteraction: PropTypes.shape({}),
  }),
}

export default SourcePageAppearances

import { useContext, useDebugValue } from 'react'

import AuthContext from '../../App/AuthProvider'

const useAuth = () => {
  const { auth } = useContext(AuthContext)
  useDebugValue(auth, auth =>
    auth?.user && Date.now() <= Date.parse(auth.exp)
      ? 'Logged In'
      : 'Logged Out'
  )

  return useContext(AuthContext)
}

export default useAuth

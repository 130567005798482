import React from 'react'

import './addMember.scss'

import {useForm} from "react-hook-form"

import {Button, TextField, Typography } from 'components/index'




const AddMember = ({onAddMember}) => {

  const { handleSubmit, register } = useForm()

  return (
    <form className="add-member" onSubmit={handleSubmit(onAddMember)}>
      <Typography className="add-member--title" variant="h3">Add a new Member</Typography>

      <Typography className="add-member--label" variant="title-3">First name*</Typography>
      <TextField
        maxLength="2000"
        placeholder="First name"
        register={register}
        registerName="firstName"
        registerValidation={{
          maxLength: 2000,
          required: true,
        }}
        rounded={false}
      />

      <Typography className="add-member--label" variant="title-3">Last name*</Typography>
      <TextField
        maxLength="2000"
        placeholder="Last name"
        register={register}
        registerName="lastName"
        registerValidation={{
          maxLength: 2000,
          required: true,
        }}
        rounded={false}
      />

      <Typography className="add-member--label" variant="title-3">Email*</Typography>
      <TextField
        maxLength="2000"
        placeholder="Email"
        register={register}
        registerName="email"
        registerValidation={{
          maxLength: 2000,
          required: true,
        }}
        rounded={false}
        type="email"
      />

      <Button className="teams--btn-save" type="sumbit" variant="ghost">
        Save
      </Button>
    </form>
  )
}

export default AddMember

import dayjs from 'dayjs'
import PropTypes from 'prop-types'
import React from 'react'

import './review.scss'

import Button from 'components/Atomic/Atoms/Button'
import Typography from 'components/Atomic/Atoms/Typography'

const Review = ({
  reviewBy,
  reviewByUrl,
  review,
  reviewDate,
  reviewUrl,
  fullReviewLink,
  reviewVariant,
  variant,
}) => {
  const textColor = variant === 'default' ? 'primary-white' : 'primary-dark'

  return (
    <div className="review" data-variant={variant}>
      <div className="review--header">
        <div className="review--header--title">
          <Typography variant="title-2">Review</Typography>
        </div>

        <Typography
          className="review--header--claimBy"
          color={textColor}
          variant="body-text-4"
        >
          by{' '}
          <a href={reviewByUrl} rel="noopener noreferrer" target="_blank">
            {reviewBy}
          </a>
        </Typography>
      </div>

      <div className="review--body">
        <Typography
          className="review--body--review"
          color={textColor}
          variant={reviewVariant}
        >
          {review}
        </Typography>
      </div>
      <div className="review--footer">
        <Typography
          color={variant === 'default' ? 'primary-white' : 'secondary-grey'}
          variant="body-text-4"
        >
          {dayjs(reviewDate).format('D MMM YYYY')}
        </Typography>

        {fullReviewLink && (
          <a href={reviewUrl} rel="noopener noreferrer" target="_blank">
            <Button
              variant={variant === 'default' ? 'ghost-reverse' : 'default'}
            >
              Full review →
            </Button>
          </a>
        )}
      </div>
    </div>
  )
}

export default Review

Review.defaultProps = {
  fullReviewLink: false,
  review: '',
  reviewBy: '',
  reviewByUrl: '',
  reviewDate: '',
  reviewUrl: '',
  reviewVariant: 'h2',
  variant: 'default',
}

Review.propTypes = {
  fullReviewLink: PropTypes.bool,
  review: PropTypes.string,
  reviewBy: PropTypes.string,
  reviewByUrl: PropTypes.string,
  reviewDate: PropTypes.string,
  reviewUrl: PropTypes.string,
  reviewVariant: PropTypes.oneOf(['h2', 'title-1']),
  variant: PropTypes.oneOf(['default', 'ghost']),
}

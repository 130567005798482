import { useMutation } from 'react-query'

import { useAxios } from 'hooks'

import { API_URL } from '../../utils/config'

export default function useAddAppearanceDocument(data) {
  const instances = useAxios()
  const addAppearanceDocument = async addAppearanceDocumentData => {
    const { files, descriptions, ...appearanceData } = addAppearanceDocumentData

    let formData = new FormData()
    formData.append('contentId', appearanceData.contentId)
    formData.append('nbDocuments', files.length)
    files.forEach((document, index) => {
      formData.append(`file_${index}`, document)
      formData.append(`filesize_${index}`, document.size)
      formData.append(`description_${index}`, descriptions[index])
    })

    const { data } = await instances.post(
      `${API_URL}/content-documents/`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    )
    return data
  }
  return useMutation(data => addAppearanceDocument(data), data)
}

import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'

import { FeedbackEditor, ErrorMessage, Select } from 'components'
import { Typography, Dropdown, Button } from 'components'
import { useEditFeedback } from 'hooks'

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import 'draft-js/dist/Draft.css'
import './editFeedback.scss'

const SCORE_OPTIONS = [
  { value: null, text: '' },
  { value: -2, text: -2 },
  { value: -1, text: -1 },
  { value: 0, text: 0 },
  { value: 1, text: 1 },
  { value: 2, text: 2 },
]

const TAG_OPTIONS = [
  { value: 1, label: 'Accurate' },
  { value: 2, label: 'Alarmist' },
  { value: 3, label: 'Biased' },
  { value: 4, label: 'Derogatory' },
  { value: 3, label: 'Flawed reasoning' },
  { value: 5, label: 'Imprecise' },
  { value: 6, label: 'Inaccurate' },
  { value: 7, label: 'Inappropriate backing' },
  { value: 8, label: 'Insightful' },
  { value: 9, label: 'Misleading' },
  { value: 10, label: 'Sound reasoning' },
  { value: 11, label: 'Unbiased' },
]

const EditFeedback = ({ feedback, onSuccess }) => {
  const { id, comment, rating, scientistFeedbackTags } = feedback

  const [tagsSelected, setTagsSelected] = useState(
    scientistFeedbackTags?.map(({ tag }) => tag) || []
  )

  const { control, handleSubmit, register } = useForm({
    defaultValues: {
      id,
      comment,
      rating,
    },
  })
  const editFeedback = useEditFeedback(id)

  useEffect(() => {
    if (editFeedback.isSuccess) {
      onSuccess()
    }
  }, [onSuccess, editFeedback.isSuccess])

  const onSelectChange = useCallback(
    value => {
      setTagsSelected(value)
    },
    [setTagsSelected]
  )

  const onSubmitFeedback = values => {
    const data = {
      ...values,
      tags: tagsSelected.map(tag => tag.label),
      rating: +values.rating,
    }
    editFeedback.mutate(data)
  }

  return (
    <div className="edit-feedback">
      {editFeedback.isError && (
        <ErrorMessage message="Oops ! Something went Wrong" />
      )}
      <form onSubmit={handleSubmit(onSubmitFeedback)}>
        <Controller
          control={control}
          defaultValue=""
          name="comment"
          render={({ field: { onChange, value } }) => (
            <FeedbackEditor onChange={onChange} value={value} />
          )}
        />

        <Typography className="edit-feedback--label" variant="title-2">
          Credibility:
        </Typography>
        <div className="edit-feedback--line">
          <Dropdown
            className="edit-feedback--select-score"
            defaultValue={0}
            options={SCORE_OPTIONS}
            {...register('rating')}
          />

          <Select
            className="edit-feedback--select-tag"
            defaultValue={scientistFeedbackTags?.map(({ tag }) => tag) || []}
            getOptionValue={value => value.label}
            isMulti
            name="tags"
            onChange={onSelectChange}
            options={TAG_OPTIONS}
            value={tagsSelected}
          />
        </div>
        <div className="edit-feedback--action">
          <Button variant="ghost">Save</Button>
        </div>
      </form>
    </div>
  )
}

EditFeedback.propTypes = {
  feedback: PropTypes.shape({
    id: PropTypes.string.isRequired,
    comment: PropTypes.string,
    rating: PropTypes.number,
    scientistFeedbackTags: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  onSuccess: PropTypes.func.isRequired,
}

export default EditFeedback

import PropTypes from 'prop-types'
import React, {useState} from 'react'
import {
  useForm,
} from 'react-hook-form'

import { Button, SideBar, Typography } from 'components'

import SideBarBody from '../../../Atomic/Molecules/SideBar/SideBarBody'
import SideBarFooter from '../../../Atomic/Molecules/SideBar/SideBarFooter'
import SideBarHeader from '../../../Atomic/Molecules/SideBar/SideBarHeader'

import './addQuotationsForm.scss'
import QuotationFile from "../AddQuotation/AddNewQuotation/QuotationFile"

export const AddQuotationDocumentForm = ({
                                           contentId,
                                           onAddAppearanceDocument,
                                           onClose,
                                           show,
                                         }) => {
  const { register, handleSubmit} = useForm({
    defaultValues: {
      contentId: contentId,
      documents: [],
    },
    mode: 'onChange',
  })

  const [files, setFiles] = useState([])
  const [descriptions, setDescriptions] = useState([])

  const onSubmitForm = values => {
    const data = {
      ...values,
      descriptions,
      files
    }
    onAddAppearanceDocument(data)
  }

  return (
    <div className="edit-appearance-document">
      <SideBar onClose={onClose} show={show}>
        <form
          className="edit-appearance-document--form"
          onSubmit={handleSubmit(onSubmitForm)}
        >
          <SideBarHeader>
            <Typography color="primary-white" variant="h2">
              Add appearance document:
            </Typography>
          </SideBarHeader>
          <SideBarBody>
            <div className="edit-appearance-document--section">
              <QuotationFile
                descriptions={descriptions}
                files={files}
                register={register}
                setDescriptions={setDescriptions}
                setFiles={setFiles}
              />
            </div>
          </SideBarBody>

          <SideBarFooter>
            <Button
              onClick={onClose}
              type="button"
              variant="ghost-reverse-link"
            >
              Cancel
            </Button>
            <Button type="submit" variant="ghost-reverse">
              Upload
            </Button>
          </SideBarFooter>
        </form>
      </SideBar>
    </div>
)
}

AddQuotationDocumentForm.defaultProps = {
  claimantId: '',
  claimantName: '',
  initialClaim: '',
  show: false,
  standardClaim: '',
}

AddQuotationDocumentForm.propTypes = {
  claimantId: PropTypes.string,
  claimantName: PropTypes.string,
  initialClaim: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool,
  standardClaim: PropTypes.string,
}

export default AddQuotationDocumentForm

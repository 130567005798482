import React from 'react'
import {useForm} from "react-hook-form"

import {Button, TextField, Typography } from 'components/index'




const OrganizationForm = ({ name, onSubmit }) => {

  const { handleSubmit, register } = useForm({
    defaultValues: {
      name: name,
    }
  })

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Typography className="teams--label" variant="title-3">Name*</Typography>
      <TextField
        maxLength="2000"
        placeholder="Organization name"
        register={register}
        registerName="name"
        registerValidation={{
          maxLength: 2000,
          required: true,
        }}
        rounded={false}
      />

      <Button className="teams--btn-save" type="sumbit" variant="ghost">
        Save
      </Button>
    </form>

  )
}

export default OrganizationForm

import PropTypes from 'prop-types'
import React, { useMemo, useState } from 'react'
import { useTable } from 'react-table'

import { Typography, TextField, SearchConclusion } from 'components'
import './verdictsTable.scss'

export const VerdictsTable = ({
  onModifyConclusion,
  verdicts,
}) => {
  const [editableConclusion, setEditableConclusion] = useState({
    id: null,
    standardForm: null,
  })

  const columns = useMemo(
    () => [
      {
        Header: 'Your verdict',
        accessor: 'label', // accessor is the "key" in the data
      },
      {
        Header: 'Standard verdict option',
        accessor: 'conclusionStandardForm',
        isEditable: true,
      },
      {
        Header: 'Rating',
        accessor: 'conclusionScore',
      },
    ],
    []
  )

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns: columns, data: verdicts })

  const handleClickEditRow = row => {
    if (editableConclusion.id === row.original.id) {
      setEditableConclusion({ id: null })
    } else {
      setEditableConclusion({
        id: row.original.conclusionId,
      })
    }
  }
  const onChooseConclusion = (conclusion) => {
    onModifyConclusion(editableConclusion.id, {
      standardForm: conclusion.standardForm,
      score: Number(conclusion.score),
    })
    setEditableConclusion({ id: null })
  }

  return (
    <table {...getTableProps()} className="verdicts-table">
      <thead className="verdicts-table--head">
        {headerGroups.map(headerGroup => (
          <tr
            {...headerGroup.getHeaderGroupProps()}
            key={`headerGroupe-${headerGroup.id}`}
          >
            {headerGroup.headers.map(column => (
              <th
                {...column.getHeaderProps()}
                className="verdicts-table--head--cell"
                key={`headerProp-${column.id}`}
              >
                <Typography color="primary-white" variant="title-3">
                  {column.render('Header')}
                </Typography>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map(row => {
          prepareRow(row)
          return (
            <tr
              {...row.getRowProps()}
              className="verdicts-table--body--row"
              key={`rowProp-${row.id}`}
            >
              {row.cells.map(cell => {
                if (
                  editableConclusion.id === row.original.conclusionId &&
                  cell.column.isEditable
                ) {
                  return (
                    <td
                      {...cell.getCellProps()}
                      className="verdicts-table--body--row--cell"
                    >
                      <SearchConclusion
                        addButton={false}
                        onChange={onChooseConclusion}
                      />
                    </td>
                  )
                } else {
                  return (
                    <td
                      {...cell.getCellProps()}
                      className="verdicts-table--body--row--cell verdicts-table--body--row--cell--clickable"
                      onClick={() => handleClickEditRow(row)}
                    >
                      <Typography color="primary-dark" variant="body-text-2">
                        {cell.render('Cell')}
                      </Typography>
                    </td>
                  )
                }
              })}
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

VerdictsTable.defaultProps = {
  verdicts: [],
}

VerdictsTable.propTypes = {
  verdicts: PropTypes.arrayOf(PropTypes.shape({})),
}

export default VerdictsTable

import PropTypes from 'prop-types'
import React from 'react'
import './checkbox.scss'

// https://www.w3schools.com/howto/howto_css_custom_checkbox.asp
const Checkbox = ({ border, checked, disabled, onChange }) => (
  <label className="checkbox">
    <input
      checked={checked}
      className="checkbox--input"
      disabled={disabled}
      onChange={onChange}
      type="checkbox"
    />
    <div
      className="checkbox--checkmark"
      data-bordered={border}
      data-disabled={disabled}
    />
  </label>
)

export default Checkbox

Checkbox.defaultProps = {
  border: true,
  checked: false,
  disabled: false,
}

Checkbox.propTypes = {
  border: PropTypes.bool,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
}

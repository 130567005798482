import React from 'react'
import { createRoot } from 'react-dom/client'

import initSentry from 'utils/sentry'
import 'utils/styles'
import { register as registerCacheServiceWorker } from 'workers/cache'

import App from './App'

initSentry()

const container = document.getElementById('root')
const root = createRoot(container)
root.render(<App />)
registerCacheServiceWorker()

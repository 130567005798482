import PropTypes from 'prop-types'
import React from 'react'

import './reviewHeader.scss'

import { useMediaQuery } from 'react-responsive'

import Divider from 'components/Atomic/Atoms/Divider'
import Claim from 'components/Business/Claims/Claim'
import Verdict from 'components/Business/Review/Review'

const ReviewHeader = ({
  reviewBy,
  reviewByUrl,
  review,
  reviewDate,
  reviewUrl,
  claim,
  claimBy,
  conclusionLabel,
  conclusionScore,
  linkNumber,
  interactionNumber,
}) => {
  const isMobile = useMediaQuery({ query: '(max-width: 810px)' })

  return (
    <div className="review-header">
      <div className="review-header--verdict-section">
        <Verdict
          fullReviewLink
          review={review}
          reviewBy={reviewBy}
          reviewByUrl={reviewByUrl}
          reviewDate={reviewDate}
          reviewUrl={reviewUrl}
        />
      </div>

      <Divider
        borderStyle="solid"
        className="review-header--divider"
        orientation={isMobile ? 'horizontal' : 'vertical'}
        score={conclusionScore}
        thickness="thick"
      />
      <div className="review-header--claim-section">
        <Claim
          claim={claim}
          claimBy={claimBy}
          conclusionLabel={conclusionLabel}
          conclusionScore={conclusionScore}
          displayInteraction={false}
          interactionNumber={interactionNumber}
          linkNumber={linkNumber}
          variant="dark"
        />
      </div>
    </div>
  )
}

export default ReviewHeader

ReviewHeader.defaultProps = {
  claim: '',
  claimBy: '',
  conclusionLabel: '',
  conclusionScore: null,
  interactionNumber: 0,
  linkNumber: 0,
  review: '',
  reviewBy: '',
  reviewByUrl: '',
  reviewDate: '',
  reviewUrl: '',
}

ReviewHeader.propTypes = {
  claim: PropTypes.string,
  claimBy: PropTypes.string,
  conclusionLabel: PropTypes.string,
  conclusionScore: PropTypes.number,
  interactionNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  linkNumber: PropTypes.number,
  review: PropTypes.string,
  reviewBy: PropTypes.string,
  reviewByUrl: PropTypes.string,
  reviewDate: PropTypes.string,
  reviewUrl: PropTypes.string,
}

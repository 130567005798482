import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'

import {
  AddSource,
  Button,
  Checkbox,
  Dropdown,
  ErrorMessage,
  TextField,
  Typography,
  Icon,
} from 'components'
import { validateUrl } from 'validator'

import QuotationFile from './QuotationFile'
import SearchChannel from './SearchChannel'
import './addNewQuotation.scss'

const AddNewQuotation = ({ onSubmitForm }) => {
  const RATING_TAGS_OPTIONS = [
    { value: 'True', text: 'True' },
    { value: 'Partly false', text: 'Partly false' },
    { value: 'False', text: 'False' },
    { value: 'Missing context', text: 'Missing context' },
    { value: 'Satire', text: 'Satire' },
    { value: 'Not eligible', text: 'Not eligible' },
  ]
  const [showAdd, setShowAdd] = useState(false)

  const {
    handleSubmit,
    register,
    getValues,
    setValue,
    formState: { errors },
  } = useForm()
  const [files, setFiles] = useState([])
  const [descriptions, setDescriptions] = useState([])

  const [isFlaggedOnFacebook, setIsFlaggedOnFacebook] = useState(false)
  const onFlaggedOnFacebookCheckboxClick = () => {
    setIsFlaggedOnFacebook(!isFlaggedOnFacebook)
  }

  const [isMedia, setIsMedia] = useState(false)
  const onIsMediaCheckboxClick = () => {
    setIsMedia(!isMedia)
  }

  const onCloseAdd = () => {
    setShowAdd(false)
  }

  const [channelSelected, setChannelSelected] = useState({
    name: null,
    url: null,
  })

  const onChannelAdded = channel => {
    setValue('linkingContent.channelId', channel.id)
    setValue('linkingContent.channelName', channel.name)
    setChannelSelected({
      id: channel.id,
      name: channel.name,
      url: channel.url,
    })
  }

  const onSend = (values) => {
    const valuesWithFile = {
      ...values,
      documents: files,
      descriptions: descriptions,
      isMedia: isMedia,
      flaggedOnFacebook: isFlaggedOnFacebook,
    }
    onSubmitForm(valuesWithFile)
  }

  return (
    <>
      <form className="add-new-quotation" onSubmit={handleSubmit(onSend)}>
        <div className="add-new-quotation--row">
          <div className="add-new-quotation--info">
            <Icon icon="info" size={14} />

            <Typography variant="body-text-2">
              If you want to add an appearance without a URL link (e.g. image,
              TV video, content deleted from the web), tick the ‘Without link’
              box.
            </Typography>
          </div>

          <div className="add-new-quotation--row--checkbox">
            <Checkbox checked={isMedia} onChange={onIsMediaCheckboxClick} />
            <Typography variant="body-text-2">Without link</Typography>
          </div>
        </div>

        {!isMedia ? (
          <div className="add-new-quotation--row">
            <div className="add-new-quotation--row--label">
              <Typography variant="title-3">Appearance link:</Typography>
            </div>
            <TextField
              maxLength="2000"
              placeholder="Paste your link*"
              register={register}
              registerName="linkingContent.url"
              registerValidation={{
                required: true,
                maxLength: 2000,
                validate: validateUrl,
              }}
              rounded={false}
            />
            {errors.linkingContent?.url?.type === 'required' && (
              <ErrorMessage message="Url is required" />
            )}
            {errors.linkingContent?.url?.type === 'validate' && (
              <ErrorMessage message="Please enter a valid url (e.g https://open.feedback.org)" />
            )}

            <div className="add-new-quotation--row--checkbox">
              <Checkbox
                checked={isFlaggedOnFacebook}
                onChange={onFlaggedOnFacebookCheckboxClick}
              />
              <Typography variant="body-text-2">
                Link has been flagged on Facebook
              </Typography>
            </div>
          </div>
          )
          :
          (
            <div className="add-new-quotation--row">
              <div className="add-new-quotation--row--label">
                <Typography variant="title-2">Appearance title:*</Typography>
                <Typography variant="body-text-2">Give a unique title to your appearance summarising its content</Typography>
              </div>
              <TextField
                maxLength="2000"
                placeholder="e.g. ‘US Senate testimony of Mr Steve Kirsch on the supposed dangers of vaccination’"
                register={register}
                registerName="linkingContent.title"
                registerValidation={{
                  required: true,
                  maxLength: 2000,
                }}
                rounded={false}
              />
              {errors.linkingContent?.title?.type === 'required' && (
                <ErrorMessage message="Title is required" />
              )}
            </div>
          )
        }


        { isMedia ?
          (
            <div className="add-new-quotation--row">
              <div className="add-new-quotation--row--label">
                <Typography variant="title-2">Source:*</Typography>
                <Typography variant="body-text-2">Identify the channel if one exists (e.g. GB News Live) or the origin of the file (Whatsapp message)</Typography>
              </div>
              <TextField
                maxLength="2000"
                placeholder="e.g. GB News Live"
                register={register}
                registerName="linkingContent.source"
                registerValidation={{
                  maxLength: 2000,
                }}
                rounded={false}
              />
            </div>
          )
          :
          (
            <SearchChannel
              channelSelected={channelSelected}
              getValues={getValues}
              setChannelSelected={setChannelSelected}
              setShowAdd={setShowAdd}
              setValue={setValue}
            />
        )}

        <div className="add-new-quotation--row">
          <div className="add-new-quotation--row--label">
            <Typography variant="title-3">Rating:</Typography>
          </div>
          <Dropdown
            defaultValue="False"
            options={RATING_TAGS_OPTIONS}
            placeholder="Choose your rating tag"
            {...register('tag.facebookFlag')}
          />
        </div>

        <div className="add-new-quotation--row">
          <div className="add-new-quotation--row--label">
            <Typography variant="title-3">Media content:</Typography>
          </div>

          <QuotationFile
            descriptions={descriptions}
            files={files}
            register={register}
            setDescriptions={setDescriptions}
            setFiles={setFiles}
          />
        </div>

        <div className="add-new-quotation--button">
          <Button
            type="submit"
            variant="light"
          >
            Add this link &nbsp;
            <Icon icon="add" />
          </Button>
        </div>
      </form>
      <AddSource
        onClose={onCloseAdd}
        onSourceAdded={onChannelAdded}
        show={showAdd}
        sourceType="channel"
      />
    </>
  )
}

export default AddNewQuotation

AddNewQuotation.propTypes = {
  onSubmitForm: PropTypes.func.isRequired,
}

import React from 'react'
import { Outlet, Route, Routes } from 'react-router-dom'

import Search from '../Search'
import { Review } from './pages'

const ReviewRouter = () => (
  <>
    <Routes>
      <Route element={<Search />} index />
      <Route path=":reviewId">
        <Route element={<Review />} index />
      </Route>
    </Routes>

    <Outlet />
  </>
)

export default ReviewRouter

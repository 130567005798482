import React from 'react'

import { Teams as TeamsPage } from 'components'

const Teams =() => {
  return (
    <TeamsPage />
  )
}

export default Teams

import { useQuery } from 'react-query'

import { normalizeConclusions } from 'normalizer'
import { API_URL } from 'utils/config'

import { useAxios } from '../index'

export default function useConclusions(searchParams) {
  const axios = useAxios()

  const getConclusions = async ({
    keywords = '',
    distinctStandardFormScore = null,
    score = null,
    page = 1,
  }) => {
    let url = `${API_URL}/conclusions?page=${page}&keywords=${keywords}`
    if (distinctStandardFormScore) {
      url = `${url}&distinctStandardFormScore=${distinctStandardFormScore}`
    }
    if (score) {
      url = `${url}&score=${score}`
    }

    const { data } = await axios.get(url)
    return data
  }
  return useQuery(
    ['conclusions', searchParams],
    () => getConclusions(searchParams), //Api Calls
    {
      select: data => {
        const dataFormatted = data.map(source => normalizeConclusions(source))

        return dataFormatted
      },
    }
  )
}

import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'

import './shareCard.scss'

import { Metric, Typography } from 'components'

const ShareCard = ({
  shareArchiveUrl,
  shareTitle,
  channelDisplayName,
  channelId,
  interactionNumber,
  sharesNumber,
}) => {
  return (
    <div className="share-card-desktop">
      <div className="share-card-desktop--body">
        <div className="share-card-desktop--body--section">
          <div className="share-card-desktop--body--section--header">
            <div className="share-card-desktop--body--section--header--channel">
              <Typography variant="body-text-4">
                {channelId ? (
                  <Link to={`/channel/${channelId}`}>{channelDisplayName}</Link>
                ) : (
                  <span>{channelDisplayName}</span>
                )}
              </Typography>
            </div>
            {shareArchiveUrl && (
              <Typography variant="body-text-4">
                <a
                  href={shareArchiveUrl}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Archive link
                </a>
              </Typography>
            )}
          </div>
          <div className="share-card-desktop--body--section--content">
            <Typography
              className="share-card-desktop--body--section--content--title"
              variant="body-text-2"
            >
              {shareTitle ? shareTitle : 'No title'}
            </Typography>
          </div>
        </div>
        <div className="share-card-desktop--body--footer">
          <div className="share-card-desktop--body--footer--group-left">
            {interactionNumber !== undefined && interactionNumber > 0 && (
              <Metric number={interactionNumber} text="interactions" />
            )}

            {sharesNumber !== undefined && sharesNumber > 0 && (
              <Metric number={sharesNumber} text="shares" />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ShareCard

ShareCard.defaultProps = {
  shareArchiveUrl: null,
  shareId: null,
  shareTitle: null,
  shareUrl: null,
  channelDisplayName: null,
  channelId: null,
  interactionNumber: null,
  sharesNumber: null,
}

ShareCard.propTypes = {
  shareArchiveUrl: PropTypes.string,
  shareId: PropTypes.string,
  shareTitle: PropTypes.string,
  shareUrl: PropTypes.string,
  channelDisplayName: PropTypes.string,
  channelId: PropTypes.string,
  interactionNumber: PropTypes.number,
  sharesNumber: PropTypes.number,
}

import { ContentState, convertToRaw, EditorState } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import React, { useEffect, useState } from 'react'
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

import './feedbackEditor.scss'

const FeedbackEditor = ({ onChange, value, editorClassName }) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty())
  const [updated, setUpdated] = useState(false)

  useEffect(() => {
    if (!updated) {
      const defaultValue = value ? value : ''
      const blocksFromHtml = htmlToDraft(defaultValue)
      const contentState = ContentState.createFromBlockArray(
        blocksFromHtml.contentBlocks,
        blocksFromHtml.entityMap
      )
      const newEditorState = EditorState.createWithContent(contentState)
      setEditorState(newEditorState)
    }
  }, [value, updated])

  const onEditorStateChange = editorState => {
    setUpdated(true)
    setEditorState(editorState)

    return onChange(draftToHtml(convertToRaw(editorState.getCurrentContent())))
  }

  return (
    <div className="feedback-editor">
      <Editor
        editorClassName={`${editorClassName} feedback-editor--editor`}
        editorState={editorState}
        onEditorStateChange={onEditorStateChange}
        spellCheck
      />
    </div>
  )
}

export default FeedbackEditor

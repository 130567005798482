import PropTypes from "prop-types"
import React, {
  useCallback,
  useMemo,
  useState,
} from 'react'
import { useFieldArray } from 'react-hook-form'

import { Badge, Select, Typography } from 'components/index'

import './searchResources.scss'

export const SearchResources = ({
  className,
  control,
  getOptionLabel,
  getOptionValue,
  getOptions,
  isMulti,
  name,
  onChange,
  onRemove,
  onSearch,
  placeholder,
  title,
  useResources,
}) => {
  const {
    append,
    fields,
    remove,
    replace,
  } = useFieldArray({
    control,
    name,
  })

  const handleOnChange = useCallback(value => {
    if (isMulti){
      append(value)
    } else {
      replace(value)
    }
    onChange()
  }, [
    append,
    isMulti,
    onChange,
    replace,
  ])

  const handleOnRemove = useCallback(value => {
    remove(value)
    onRemove()
  }, [
    onRemove,
    remove,
  ])

  const [search, setSearch] = useState('')
  const { data, isLoading } = useResources(search)
  const options = useMemo(() => {
    if (getOptions) {
      return getOptions(data)
    } else {
      return (data?.pages[0].data || [])
    }
  }, [
    data,
    getOptions,
  ])

  const handleOnSearch = useCallback(search => {
    setSearch(search)
    onSearch(search)
  }, [
    onSearch,
    setSearch,
  ])

  return (
    <div className={`${className} search-resources--section`}>
      {title && (
        <Typography variant="title-2">
          {title}
        </Typography>
      )}
      <Select
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
        isLoading={isLoading}
        isMulti={isMulti}
        isSearchable
        onChange={handleOnChange}
        onSearch={handleOnSearch}
        options={options}
        placeholder={placeholder}
      />

      {fields &&
        fields.length > 0 &&
        fields.map(resource => (
          <Badge
            key={getOptionValue(resource)}
            onDelete={() => handleOnRemove(resource)}
            text={getOptionLabel(resource)}
          />
        ))}
    </div>
  )
}

SearchResources.propTypes = {
  className: PropTypes.string,
  control: PropTypes.shape({}).isRequired,
  getOptionLabel: PropTypes.func,
  getOptionValue: PropTypes.func,
  getOptions: PropTypes.func,
  isMulti: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func,
  onRemove: PropTypes.func,
  onSearch: PropTypes.func,
  placeholder: PropTypes.string,
  title: PropTypes.string,
  useResources: PropTypes.func.isRequired,
}

SearchResources.defaultProps = {
  className: "",
  getOptionLabel: option => option.label,
  getOptionValue: option => option.value,
  getOptions: data => (data?.pages[0].data || []),
  isMulti: true,
  name: "resources",
  onChange: () => null,
  onRemove: () => null,
  onSearch: () => null,
  placeholder: "Search for a resource",
  title: null,
}

export default SearchResources

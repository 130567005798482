import React, { useState } from 'react'
import { useParams } from 'react-router-dom'

import { Button, Typography, FeedbackRequest, Loader } from 'components'
import { useAuth, useFeedbackRequest, useRoles } from 'hooks'

import './feedback.scss'
import FeedbackCard from '../../../../components/Business/Feedback/FeedbackCard'
import AddFeedback from '../AddFeedback/AddFeedback'

const Feedback = () => {
  const { feedbackRequestId } = useParams()

  const { auth } = useAuth()
  const { isEditor, isScientist } = useRoles(auth)
  const { data, isLoading, refetch } = useFeedbackRequest({
    id: feedbackRequestId,
  })

  const [showAddFeedback, setShowAddFeedback] = useState(false)

  const toggleShowAddFeedback = () => {
    setShowAddFeedback(!showAddFeedback)
  }

  const onSuccess = () => {
    refetch()
    toggleShowAddFeedback()
  }

  if (isLoading) {
    return <Loader />
  }

  return (
    <div className="feedback">
      <div className="feedback--border">
        <div className="feedback--request-section">
          <FeedbackRequest
            onSuccess={refetch}
            request={data}
            variant="header"
          />
        </div>
        <div className="feedback--list">
          <div className="feedback--list--title">
            <Typography variant="h2">Scientists’ Feedback:</Typography>
            {(isEditor || isScientist) && (
              <Button onClick={toggleShowAddFeedback} variant="ghost-reverse">
                <Typography variant="btn-text">Add Feedback +</Typography>
              </Button>
            )}
          </div>

          {showAddFeedback && <AddFeedback onSuccess={onSuccess} />}

          <div className="feedback--list--feedback">
            {data?.scientistFeedbacks.map(feedback => (
              <FeedbackCard
                feedback={feedback}
                key={feedback.id}
                onEditSuccess={refetch}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Feedback

import PropTypes from 'prop-types'
import React from 'react'

import Typography from '../Typography'

import './errorMessage.scss'

const ErrorMessage = ({ message }) => (
  <div className="error-message">
    <Typography color="primary-red" variant="body-text-4">
      {message}
    </Typography>
  </div>
)

export default ErrorMessage

ErrorMessage.defaultProps = {
  message: null,
}

ErrorMessage.propTypes = {
  message: PropTypes.string,
}

import PropTypes from 'prop-types'
import React from 'react'

import { Button, Icon, Typography } from 'components'

import './badge.scss'

const Badge = ({ onDelete, text }) => (
  <div className="badge">
    <div className="badge--text">
      <Typography color="primary-white" variant="title-3">
        {text}
      </Typography>
    </div>
    <div className="badge--icon">
      <Button onClick={onDelete} type="button" variant="default-link">
        <Icon color="#FFFFFB" icon="cross" />
      </Button>
    </div>
  </div>
)

export default Badge

Badge.defaultProps = {
  onDelete: () => null,
  text: '',
}

Badge.propTypes = {
  onDelete: PropTypes.func,
  text: PropTypes.string,
}

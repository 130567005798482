import axios from 'axios'
import { useEffect } from 'react'

import { API_URL } from 'utils/config'

import useAuth from './useAuth'

const instance = axios.create({
  baseURL: API_URL,
})

const useAxios = () => {
  const { auth } = useAuth()
  useEffect(() => {
    const requestIntercept = instance.interceptors.request.use(
      config => {
        if ('accessToken' in auth && !config.headers['x-access-tokens']) {
          config.headers['x-access-tokens'] = `${auth?.accessToken}`
        }
        return config
      },
      error => Promise.reject(error)
    )

    const responseIntercept = instance.interceptors.response.use(
      response => response,
      async error => {
        return Promise.reject(error)
      }
    )

    return () => {
      instance.interceptors.request.eject(requestIntercept)
      instance.interceptors.response.eject(responseIntercept)
    }
  }, [auth])

  return instance
}

export default useAxios

import React from 'react'

import Typography from '../Typography'

/* eslint-disable react/button-has-type */
const Copyright = () => (
  <Typography color="primary-white" variant="body-text-4">
    © Open Feedback. All rights reserved.
  </Typography>
)

export default Copyright

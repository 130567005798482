import React, { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import { useLocation } from 'react-router-dom'

import { Header } from 'components'
import { useUser } from 'hooks'
import './layout.scss'

const Layout = () => {
  useUser()
  const location = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location.pathname])

  return (
    <div className="layout">
      <Header />
      <div className="layout--content">
        <Outlet />
      </div>
    </div>
  )
}

export default Layout

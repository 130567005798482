import React from 'react'
import { useMediaQuery } from 'react-responsive'

import AboutOpenFeedbackUseDesktop from './AboutOpenFeedbackUseDesktop'
import AboutOpenFeedbackUseMobile from './AboutOpenFeedbackUseMobile'

const AboutOpenFeedbackUse = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' })

  if (isMobile) {
    return <AboutOpenFeedbackUseMobile />
  } else {
    return <AboutOpenFeedbackUseDesktop />
  }
}

export default AboutOpenFeedbackUse

import PropTypes from 'prop-types'
import React, { useContext } from 'react'

import { SearchFactchecker } from 'components'
import Dropdown from 'components/Atomic/Atoms/Dropdown'
import { tabContext } from 'components/Atomic/Molecules/Tabs/TabsContext'
import { useAuth, useRoles } from 'hooks'

import { TextField, Typography } from '../../../Atomic'

import './searchPageFilter.scss'
const SearchPageFilter = ({
  className,
  currentFilters,
  onFilterChoose,
  selectedFilters,
}) => {
  const { auth } = useAuth()
  const { isEditor, isFactchecker } = useRoles(auth)
  const [tabValue] = useContext(tabContext)
  const onChange = event =>
    onFilterChoose(event.target.title, event.target.value)

  const onChangeStartDate = e => {
    if (e.key === 'Enter') {
      onFilterChoose('Filter by Start Date', e.target.value)
    }
  }

  const onChangeEndDate = e => {
    if (e.key === 'Enter') {
      onFilterChoose('Filter by End Date', e.target.value)
    }
  }

  return (
    <>
      {Object.entries(currentFilters).map(([title, options]) => {
        return (
          <div className={className} key={title}>
            <Typography
              className="SearchPageFilter--title"
              variant="body-text-2"
            >
              {title}:&nbsp;
            </Typography>
            <div className={`${className}--dropdown-container`}>
              <Dropdown
                onChange={onChange}
                options={options}
                selectedValue={selectedFilters[tabValue][title]}
                title={title}
              />
            </div>
          </div>
        )
      })}
      {(isEditor || isFactchecker) && tabValue === 'reviews' && (
        <>
          <div className={className}>
            <Typography variant="body-text-2">
              Filter by fact-checking org.:&nbsp;
            </Typography>
            <SearchFactchecker
              factcheckers={selectedFilters[tabValue]?.['Filter by Factcheckers']
                ?.split(',')
                .filter(fc => fc)
                .map(fc => ({ name: fc, id: fc }))}
              onChange={onFilterChoose}
            />
          </div>
          <div className={className}>
            <Typography variant="body-text-2">
              Filter by start date:&nbsp;
            </Typography>
            <TextField
              data-rounded={false}
              type="date"
              onKeyDown={onChangeStartDate}
              placeholder="Start date (YYYY-MM-DD)"
              defaultValue={selectedFilters[tabValue]?.['Filter by Start Date']}
            />
          </div>
          <div className={className}>
            <Typography variant="body-text-2">
              Filter by end date:&nbsp;
            </Typography>
            <TextField
              data-rounded={false}
              type="date"
              onKeyDown={onChangeEndDate}
              placeholder="End date (YYYY-MM-DD)"
              defaultValue={selectedFilters[tabValue]?.['Filter by End Date']}
            />
          </div>
        </>
      )}
    </>
  )
}

SearchPageFilter.propTypes = {
  className: PropTypes.string,
  currentFilters: PropTypes.shape({}),
  onFilterChoose: PropTypes.func,
  selectedFilters: PropTypes.shape({}),
}

SearchPageFilter.defaultProps = {
  className: null,
  currentFilters: {},
  onFilterChoose: () => null,
  selectedFilters: {},
}

export default SearchPageFilter

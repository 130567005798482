const urlShortener = url => {
  url = url.replace(/(https?:\/\/)?(www.)?/i, '')

  if (url.indexOf('/') !== -1) {
    return url.split('/')[0]
  }

  return url
}

export default urlShortener

const feedbackRequestColorScore = score => {
  if (!score) {
    return score
  } else {
    // score is between -2 and 2 : add 2 and round
    return Math.round(score + 2)
  }
}

const normalizeFeedbackRequest = feedbackRequest => {
  return {
    id: feedbackRequest.id,
    claim: {
      text: feedbackRequest.claim?.text,
    },
    content: {
      url: feedbackRequest.content?.url,
    },
    context: feedbackRequest.context,
    publicQuestion: feedbackRequest.publicQuestion,
    scientistFeedbacks: feedbackRequest.scientistFeedbacks,
    score: feedbackRequestColorScore(feedbackRequest.score),
    scoreLabel: feedbackRequest.scoreLabel,
  }
}

export default normalizeFeedbackRequest

import PropTypes from 'prop-types'
import React from 'react'

import Typography from '../../../Atomic/Atoms/Typography'

import './conclusionTag.scss'

const ConclusionTag = ({ color, label, score }) => {
  const displayedLabel =
    label && label.length > 34 ? `${label.substring(0, 31)}...` : label

  return (
    <div className="conclusionTag">
      <Typography color={color} variant="title-2">
        {displayedLabel}
      </Typography>
      {displayedLabel && (
        <div
          className={`conclusionTag--scoreSquare conclusionTag--scoreSquare-${score}`}
        />
      )}
    </div>
  )
}

export default ConclusionTag

ConclusionTag.defaultProps = {
  color: null,
  label: null,
  score: null,
}

ConclusionTag.propTypes = {
  color: PropTypes.oneOf(['primary-dark', 'primary-white', 'secondary-grey']),
  label: PropTypes.string,
  score: PropTypes.oneOf([null, 'other', 'pants on fire', 'satire', 0, 1, 2, 3, 4, 5]),
}

import PropTypes from 'prop-types'
import React, { useState } from 'react'

import Button from '../../Atoms/Button'
import Typography from '../../Atoms/Typography'
import ContactForm from '../ContactForm'

import './ourApi.scss'

export const OurApi = ({ onSubmit, variant }) => {
  const [show, setShow] = useState(false)
  const toggleContactForm = () => setShow(!show)
  const typoColor = variant === 'white' ? 'primary-dark' : 'primary-white'
  const buttonVariant = variant === 'white' ? 'ghost' : 'ghost-reverse'

  return (
    <div className="our-api" data-variant={variant} id="anchor-api">
      <div
        className="our-api--border our-api--border--top"
        data-variant={variant}
      />
      <Typography color={typoColor} variant="h2">
        Do you want to use our database or our API?
      </Typography>

      <div className="our-api--details">
        <Typography color={typoColor} variant="body-text-1">
          If you need to use the data for your research or for a project to
          improve the reliability of online information, please contact us.
        </Typography>
      </div>
      <Button onClick={toggleContactForm} variant={buttonVariant}>
        Contact us
      </Button>
      <ContactForm
        defaultValue="API"
        onClose={toggleContactForm}
        onSubmit={onSubmit}
        show={show}
      />
      <div
        className="our-api--border our-api--border--bottom"
        data-variant={variant}
      />
    </div>
  )
}

OurApi.defaultProps = {
  variant: 'dark',
}

OurApi.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  variant: PropTypes.oneOf(['white', 'dark']),
}

export default OurApi

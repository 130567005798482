import PropTypes from 'prop-types'
import React from 'react'
import { useMediaQuery } from 'react-responsive'

import ClaimCardDesktop from './ClaimCardDesktop'
import ClaimCardMobile from './ClaimCardMobile'

const ClaimCard = claimObject => {
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' })

  if (isMobile) {
    return <ClaimCardMobile {...claimObject} />
  } else {
    return <ClaimCardDesktop {...claimObject} />
  }
}

export default ClaimCard

ClaimCard.defaultProps = {
  claimObject: {},
}

ClaimCard.propTypes = {
  claimObject: PropTypes.shape({}),
}

import { useMutation } from 'react-query'

import { API_URL } from '../../utils/config'
import { useAxios } from '../index'

const useCreateFactcheck = () => {
  const axios = useAxios()

   const postFactcheck = async values => {
    let formData = new FormData()

    values.appearances.forEach((appearance, index) => {
      if (appearance?.document?.file) {
        Object.keys(appearance.document.file).forEach(fileIndex => {
          formData.append(`appearance[${index}][files][${fileIndex}]`, appearance.document.file[fileIndex] || '')
          formData.append(`appearance[${index}][files][${fileIndex}][size]`, appearance.document.file[fileIndex].size || '')
        })
        formData.append(`appearance[${index}][description]`, appearance.document?.description || '')
        formData.append(`appearance[${index}][source]`, appearance.document?.source || '')
      }
      formData.append(`appearance[${index}][author]`, appearance?.author || '')
      formData.append(`appearance[${index}][authorType]`, appearance?.authorType || '')
      formData.append(`appearance[${index}][channel]`, appearance.channel?.length ? JSON.stringify(appearance.channel[0]) : '')
      formData.append(`appearance[${index}][tag]`, appearance.tag || '')
      formData.append(`appearance[${index}][title]`, appearance?.title || '')
      formData.append(`appearance[${index}][url]`, appearance?.url || '')
    })

    formData.append("data", JSON.stringify(values))

    const { data } = await axios.post(
      `${API_URL}/factchecks`,
      formData,
      {
        headers: {
          "Content-Type": "form-data/multipart",
        }
      }
    )
    return data
  }

  return useMutation(data => postFactcheck(data))
}

export default useCreateFactcheck

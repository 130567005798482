import PropTypes from 'prop-types'
import React from 'react'
import './textAreaField.scss'

const TextAreaField = ({
  className,
  inputRef,
  register,
  registerName,
  registerValidation,
  rounded,
  rows,
  ...rest
}) => (
  <textarea
    className={`text-area-field ${className}`}
    data-rounded={rounded}
    rows={rows}
    {...rest}
    {...(inputRef
      ? { ...{ ref: inputRef } }
      : register
      ? { ...register(registerName, registerValidation) }
      : {})}
  />
)

TextAreaField.defaultProps = {
  className: "",
  inputRef: null,
  register: null,
  registerName: '',
  registerValidation: {},
  rounded: true,
  rows: 5,
}

TextAreaField.propTypes = {
  className: PropTypes.string,
  inputRef: PropTypes.func,
  register: PropTypes.func,
  registerName: PropTypes.string,
  registerValidation: PropTypes.shape({}),
  rounded: PropTypes.bool,
  rows: PropTypes.number,
}

export default TextAreaField

import PropTypes from 'prop-types'
import React from 'react'
import { Link, useLocation } from 'react-router-dom'

import Button from '../../../Atoms/Button'
import Logo from '../../../Atoms/Logo'
import Typography from '../../../Atoms/Typography'
import './headerDesktop.scss'
import { Divider, Icon } from '../../../Atoms'

export const HeaderDesktop = ({ isAuth, onLoginClick, onLogoutClick }) => {
  const { pathname } = useLocation()
  const isActive = linkPathname =>
    pathname === linkPathname ? 'header--navlink--active' : ''

  return (
    <header className="header-desktop">
      <div className="header-desktop--content">
        <Link className="header-desktop--logo" to="/">
          <Logo />
        </Link>

        {!isAuth && (
          <div className="header-desktop--navlink header-desktop--center-link">
            <Typography className={isActive('/')} variant="menu-text-1">
              <Link to="/">Home</Link>
            </Typography>
            <Typography className={isActive('/search')} variant="menu-text-1">
              <Link to="/search">Search</Link>
            </Typography>
            <Typography className={isActive('/about')} variant="menu-text-1">
              <Link className="header-desktop--navlink--lastlink" to="/about">
                About
              </Link>
            </Typography>
            {/*<Typography className={isActive('/api')} variant="menu-text-1">
          <Link to="/api">API</Link>
        </Typography>
        <Typography className={isActive('/contact')} variant="menu-text-1">
          <Link to="/contact">Contact Us</Link>
        </Typography>*/}
          </div>
        )}

        <div className="header-desktop--login">
          {isAuth ? (
            <div className="header-desktop--editorlink">
              <Typography
                className={isActive('/dashboard')}
                variant="menu-text-1"
              >
                <Link
                  className="header-desktop--navlink--lastlink"
                  to="/search"
                >
                  Search
                </Link>
              </Typography>
              <Divider borderStyle="dashed" orientation="vertical" />
              <Typography
                className={isActive('/dashboard')}
                variant="menu-text-1"
              >
                <Link
                  className="header-desktop--navlink--lastlink"
                  to="/dashboard"
                >
                  Dashboard
                </Link>
              </Typography>
              <Divider borderStyle="dashed" orientation="vertical" />
              <div
                className="header-desktop--logout"
                onClick={onLogoutClick}
                role="button"
              >
                <Typography variant="menu-text-1">
                  Logout <Icon icon="logout" />
                </Typography>
              </div>
            </div>
          ) : (
            <Button data-cy="login" onClick={onLoginClick}>
              Sign in
            </Button>
          )}
        </div>
      </div>
    </header>
  )
}

HeaderDesktop.defaultProps = {
  isAuth: false,
}

HeaderDesktop.propTypes = {
  isAuth: PropTypes.bool,
  onLoginClick: PropTypes.func.isRequired,
  onLogoutClick: PropTypes.func.isRequired,
}

export default HeaderDesktop

import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'

import { Button, ReviewAndClaim, Typography, Loader } from 'components/index'

import './reviewsList.scss'
const ReviewsList = ({ reviews }) => (
  <div className="reviewsList">
    <div className="reviewsList--dotted-list">
      <div className="reviewsList--dotted-list--header">
        <div className="reviewsList--dotted-list--header--content">
          <Typography
            className="reviewsList--dotted-list--header--content--title"
            variant="h2"
          >
            Recent Reviews
          </Typography>
        </div>
      </div>
      <div className="reviewsList--dotted-list--body">
        <div className="reviewsList--dotted-list--body--content">
          {reviews.length > 0 ? (
            reviews.map(review => (
              <div
                className="reviewsList--dotted-list--body--content--row"
                key={`review-${review.id}`}
              >
                <ReviewAndClaim {...review} />
              </div>
            ))
          ) : (
            <Loader />
          )}
        </div>
      </div>
    </div>

    <div className="reviewsList--all-reviews-link">
      <Link to="/search?section=reviews">
        <Button type="internalLink" variant="ghost">
          All reviews
        </Button>
      </Link>
    </div>
  </div>
)

export default ReviewsList

ReviewsList.defaultProps = {
  reviews: [],
}

ReviewsList.propTypes = {
  reviews: PropTypes.arrayOf(PropTypes.shape({})),
}

import { useMutation } from 'react-query'

import { useAxios } from 'hooks'

import { API_URL } from '../../utils/config'

export default function useResetPassword() {
  const axios = useAxios()

  const resetPassword = async values => {
    const { token, password } = values 
    const { data } = await axios.put(`${API_URL}/users/reset-password?token=${token}`, { password })
    return data
  }

  return useMutation(data => resetPassword(data))
}

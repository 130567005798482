import PropTypes from 'prop-types'
import React from 'react'

import './button.scss'

/* eslint-disable react/button-has-type */
const Button = ({ children, variant, className, ...props }) => (
  <button
    className={`component-button ${className}`}
    data-variant={variant}
    {...props}
  >
    {children}
  </button>
)

export default Button

Button.defaultProps = {
  children: null,
  variant: 'default',
}

Button.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  variant: PropTypes.oneOf([
    'default',
    'default-link',
    'ghost-reverse',
    'ghost-reverse-link',
    'ghost',
    'light',
  ]),
}

import PropTypes from 'prop-types'
import React, { useCallback, useState } from 'react'

import { Badge, Select } from 'components/index'
import { useChannels } from 'hooks'

export const SearchFactchecker = ({ factcheckers, onChange }) => {
  const [search, setSearch] = useState('')
  const { data, isLoading } = useChannels(search, 'factchecker')
  const [selectedFactcheckers, setSelectedFactcheckers] = useState(factcheckers)

  const options = data?.pages[0].data || []

  const onSearch = useCallback(
    search => {
      setSearch(search)
    },
    [setSearch]
  )

  const handleOnChange = useCallback(
    ([change]) => {
      if (change) {
        const { id, name } = change
        setSelectedFactcheckers([
          ...selectedFactcheckers.map(factchecker => factchecker),
          { name, id },
        ])
        onChange(
          'Filter by Factcheckers',
          [
            ...selectedFactcheckers.map(factchecker => factchecker.name),
            name,
          ].join(',')
        )
      }
    },
    [onChange, selectedFactcheckers]
  )

  const onDelete = useCallback(
    factcheckerId => () => {
      const remainingFactcheckers = selectedFactcheckers.filter(
        factchecker => factchecker.id !== factcheckerId
      )
      setSelectedFactcheckers(remainingFactcheckers)
      onChange(
        'Filter by Factcheckers',
        remainingFactcheckers.map(factchecker => factchecker.name).join(',')
      )
    },
    [onChange, selectedFactcheckers]
  )

  return (
    <>
      <Select
        getOptionLabel={option => `${option.name} - ${option.url}`}
        getOptionValue={option => option.id}
        isLoading={isLoading}
        isMulti
        isSearchable
        onChange={handleOnChange}
        onSearch={onSearch}
        options={options}
        placeholder="Search for a factchecker"
      />
      {selectedFactcheckers.map(
        factchecker =>
          factchecker.name && (
            <Badge
              key={factchecker.id}
              onDelete={onDelete(factchecker.id)}
              text={factchecker.name}
            />
          )
      )}
    </>
  )
}

SearchFactchecker.defaultProps = {
  factcheckers: [],
  onChange: () => null,
}

SearchFactchecker.propTypes = {
  factcheckers: PropTypes.arrayOf(PropTypes.shape({})),
  onChange: PropTypes.func,
}

export default SearchFactchecker

import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'

import { Divider, Icon, Typography } from 'components'

import './sourceStatisticCard.scss'

const SourceStatisticCard = ({ icon, link, title, total }) => {
  return (
    <div className="dashboard-source-statistic-card" role="navigation">
      <div className="dashboard-source-statistic-card--header">
        <Icon icon={icon} />
        <Typography variant="body-text-2">{title}</Typography>
      </div>

      <div className="dashboard-source-statistic-card--body">
        <div className="dashboard-source-statistic-card--body--item--before-divider">
          <Typography variant="title-1">{total}</Typography>
        </div>

        <div className="dashboard-source-statistic-card--body--item--before-divider">
          <Divider
            borderStyle="dashed"
            className="dashboard-source-statistic-card--body--divider"
            orientation="vertical"
            thickness="thin"
          />
          <Typography
            className="dashboard-source-statistic-card--body--link"
            variant="title-2"
          >
            <Link to={link}>
              <Icon icon="arrowup45" />
            </Link>
          </Typography>
        </div>
      </div>
    </div>
  )
}

export default SourceStatisticCard

SourceStatisticCard.defaultProps = {
  icon: '',
  link: '',
  title: '',
  total: 1,
}

SourceStatisticCard.propTypes = {
  icon: PropTypes.string,
  link: PropTypes.string,
  title: PropTypes.string,
  total: PropTypes.number,
}

import { useInfiniteQuery } from 'react-query'

import { API_URL } from 'utils/config'
import { useAuth } from 'hooks'
import { useAxios } from '../index'

export default function useSourceAppearances(id, sourceType, tagLabel) {
  const axios = useAxios()

  const { auth } = useAuth()

  const getSourceAppearances = async (
    id,
    sourceType,
    tagLabel,
    { isLoggedIn = false, pageParam = 1 }
  ) => {
    let url = `${API_URL}/${sourceType.toLowerCase()}s/${id}/appearances?page=${pageParam}`
    if (tagLabel) {
      url += `&tagLabel=${tagLabel}`
    }

    const { data, headers } = await axios.get(url)

    return {
      data,
      hasNextPage:
        headers['has-more'] === 'True' && isLoggedIn && pageParam + 1,
    }
  }

  return useInfiniteQuery(
    ['source-appearances', `${id}-${sourceType}-${tagLabel}`],
    params =>
      getSourceAppearances(id, sourceType, tagLabel, {
        isLoggedIn: !!auth,
        ...params,
      }),
    {
      select: data => data.pages.flatMap(page => page.data),
      getNextPageParam: lastPage => lastPage.hasNextPage,
    }
  )
}

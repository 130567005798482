import { useMutation, useQueryClient } from 'react-query'

import { API_URL } from 'utils/config'

import { useAxios } from '../index'

export default function useEditUser() {
  const instances = useAxios()
  const queryClient = useQueryClient()
  const editUser = async userData => {
    const { data } = await instances.patch(`${API_URL}/users/current`, {
      ...userData,
    })
    return data
  }

  return useMutation(data => editUser(data), {
    onSuccess: () => {
      queryClient.invalidateQueries(['user-current'])
    },
  })
}

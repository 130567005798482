import PropTypes from 'prop-types'
import React, { useState } from 'react'

import {
  AppearanceClaims,
  Button,
  Icon,
  Metric,
  Typography,
  Image,
  ConclusionTag,
} from 'components/index'
import { numberShortener } from 'utils/shorteners'

import './appearanceCardMobile.scss'

const QuotationCard = ({
  appearanceArchiveUrl,
  appearanceId,
  appearanceImageUrl,
  appearanceTitle,
  appearanceType,
  conclusionLabel,
  conclusionScore,
  interactionNumber,
}) => {
  const [isClaimsListOpen, setIsClaimsListOpen] = useState(false)

  return (
    <>
      <div className="appearance-card-mobile">
        <div className="appearance-card-mobile--content">
          <div className="appearance-card-mobile--content--image">
            <Image name="content" url={appearanceImageUrl} />
          </div>
          <div className="appearance-card-mobile--content--information">
            {appearanceArchiveUrl && (
              <Typography variant="body-text-4">
                <a
                  href={appearanceArchiveUrl}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Archive link
                </a>
              </Typography>
            )}
            <div className="appearance-card-mobile--content--information--title">
              <Typography variant="body-text-4">
                {appearanceTitle ? appearanceTitle : 'No title'}
              </Typography>
            </div>
          </div>
        </div>
        <div className="appearance-card-mobile--footer">
          <div>
            {interactionNumber !== undefined && interactionNumber > 0 && (
              <Metric
                number={numberShortener(interactionNumber)}
                text="interactions"
              />
            )}
          </div>
          <div>
            <Button
              onClick={() => setIsClaimsListOpen(!isClaimsListOpen)}
              variant="default-link"
            >
              <div className="appearance-card-footer--group-left--claims">
                <ConclusionTag
                  label={conclusionLabel}
                  score={conclusionScore}
                />
                {isClaimsListOpen ? <Icon icon="up" /> : <Icon icon="down" />}
              </div>
            </Button>
          </div>
        </div>
      </div>
      {isClaimsListOpen && (
        <div className="appearance-card-desktop--claims-list">
          <AppearanceClaims
            appearanceId={appearanceId}
            appearanceType={appearanceType}
          />
        </div>
      )}
    </>
  )
}

export default QuotationCard

QuotationCard.defaultProps = {
  appearanceArchiveUrl: null,
  appearanceImageUrl: null,
  appearanceTitle: null,
  conclusionLabel: null,
  conclusionScore: null,
  interactionNumber: null,
}

QuotationCard.propTypes = {
  appearanceArchiveUrl: PropTypes.string,
  appearanceImageUrl: PropTypes.string,
  appearanceTitle: PropTypes.string,
  conclusionLabel: PropTypes.string,
  conclusionScore: PropTypes.number,
  interactionNumber: PropTypes.number,
}

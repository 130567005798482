import axios from 'axios'
import { useQuery } from 'react-query'

import { API_URL } from 'utils/config'

const getMediaWikiData = async search => {
  const { data } = await axios.get(`${API_URL}/wikidata/search?query=${search}`)
  return data
}

export default function useMediaWikiData(search) {
  return useQuery(['media-wikidata', search], () => getMediaWikiData(search), {
    enabled: !!search,
  })
}

import React, { useState } from 'react'

import { Select } from 'components/index'
import { useVerdictConclusions } from 'hooks'

export const SearchConclusion = ({ addButton, onAdd, onChange }) => {
  const [search, setSearch] = useState('')
  const { data, isLoading } = useVerdictConclusions({
    search: search,
  })

  const options = data || []
  const onSearch = search => {
    setSearch(search)
  }

  return (
    <Select
      addButton={addButton}
      addButtonText="Add new conclusion"
      getOptionLabel={option => option.standardForm}
      getOptionValue={option => option.standardForm}
      isLoading={isLoading}
      isSearchable
      onAdd={onAdd}
      onChange={onChange}
      onSearch={onSearch}
      options={options}
      placeholder="Search a conclusion"
    />
  )
}

export default SearchConclusion

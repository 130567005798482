import { useMutation, useQueryClient } from 'react-query'

import { API_URL } from '../../utils/config'
import { useAxios } from '../index'

const useReviewEdition = id => {
  const queryClient = useQueryClient()
  const axios = useAxios()

  const editSources = async values => {
    let url = `${API_URL}/verdicts/${id}`

    const { data } = await axios.patch(url, { ...values })
    return data
  }

  return useMutation(editSources, {
    onMutate: async updatedReview => {
      await queryClient.cancelQueries([`review`, id])
      const previousSource = queryClient.getQueryData(['review', id])
      return { previousSource, updatedReview }
    },
    onError: (err, updatedReview, context) => {
      queryClient.setQueryData(['review', id], context.previousSource)
    },
    onSettled: updatedReview => {
      queryClient.invalidateQueries(['review', id])
    },
  })
}

export default useReviewEdition

import React, {
  useCallback,
  useState,
} from 'react'
import { useFormContext } from 'react-hook-form'

import { Badge, Select } from 'components/index'
import { useTopicTag } from 'hooks'

export const SearchTopic = () => {

  const [search, setSearch] = useState('')
  const { data, isLoading } = useTopicTag({search})
  const {
    setValue,
    watch,
  } = useFormContext()

  const topicTags = watch('topicTags')
  const options = (data || []).filter(option => !topicTags?.includes(option.label))

  const onSearch = useCallback(search => {
    setSearch(search)
  }, [setSearch])

  const handleOnChange = useCallback(([change]) => {
    setValue('topicTags', [...(topicTags || []).filter(existingTopic => existingTopic !== change.label), change.label])
  }, [setValue, topicTags])

  const onDelete = useCallback(label => () => {
    const remainingTopics = topicTags?.filter(topic => topic !== label)
    setValue('topicTags', remainingTopics)
  }, [setValue, topicTags])

  return (
    <>
      <Select
        getOptionLabel={option => option.label}
        getOptionValue={option => option.label}
        isLoading={isLoading}
        isMulti
        isSearchable
        onChange={handleOnChange}
        onSearch={onSearch}
        options={options}
        placeholder="Tags of the review topics"
      />
      <div className="search-topic--badge">
        { topicTags?.map(topic => topic && (
          <Badge
            key={topic}
            onDelete={onDelete(topic)}
            text={topic}
          />
        ))}
      </div>
    </>
  )
}

export default SearchTopic

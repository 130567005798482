const normalizeFactchecker = factchecker => {
  return {
    id: factchecker.id,
    name: factchecker.name.toLowerCase(),
    scrappable: factchecker.websiteIsScrappable || true,
    url: factchecker.url,
  }
}

export default normalizeFactchecker

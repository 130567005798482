import { useInfiniteQuery } from 'react-query'

import { normalizeConclusions } from 'normalizer'
import { API_URL } from 'utils/config'

import { useAxios } from '../index'

export default function useVerdictConclusions({ search = '' }) {
  const axios = useAxios()

  const getConclusions = async (search = '', { pageParam = 1 }) => {
    const { data, headers } = await axios.get(
      `${API_URL}/conclusions?page=${pageParam}&conclusion-start=${search}`
    )
    return {
      data,
      hasNextPage: headers['has-more'] === 'True' && pageParam + 1,
    }
  }

  return useInfiniteQuery(
    ['conclusions', search],
    params => getConclusions(search, params),
    {
      select: data => {
        const dataFormatted = data.pages.flatMap(page =>
          page.data.map(review => normalizeConclusions(review))
        )
        return dataFormatted
      },
      getNextPageParam: lastPage => lastPage.hasNextPage,
    }
  )
}

import PropTypes from 'prop-types'
import React, { useMemo } from 'react'

import {
  ConclusionTag,
  Typography,
  ChannelCard,
  Divider,
  MediaCard,
  SourceStats,
  FullReport,
  SourceStatisticsRecalculate,
  Icon,
  Button,
} from 'components'
import {
  useAuth,
  useFactcheckerAuth,
  useRoles,
} from 'hooks'

import SourcePageAppearances from './SourcePageAppearances'
import SourcePageHeader from './SourcePageHeader'
import SourcePageOwners from './SourcePageOwners'

import './sourcePageDesktop.scss'
const SourcePageDesktop = ({
  appearances,
  appearancesAreLoading,
  defaultTagLabel,
  fetchNextAppearancePage,
  hasNextAppearancePage,
  onChooseTagLabel,
  onContact,
  onRefetchSource,
  source,
}) => {
  const { auth } = useAuth()
  const { isEditor } = useRoles(auth)
  const isLoggedIn = !!auth.user
  const [entityId, entityType] = useMemo(() => {
    if (source?.sourceType?.toLowerCase() === "media") {
      return [source?.organization?.id, "organization"]
    } else {
      return [source.id, source?.sourceType?.toLowerCase()]
    }
  }, [source])
  const isAuthorizedFactchecker = useFactcheckerAuth(entityId, entityType)

  return (
    <div className="source-page-desktop">
      <div className="source-page-desktop--border-ext">
        <div className="source-page-desktop--border-int">
          <div className="source-page-desktop--header">
            <SourcePageHeader
              isAuthorizedToEdit={isEditor || isAuthorizedFactchecker}
              isLoggedIn={isLoggedIn}
              onRefetchSource={onRefetchSource}
              source={source}
            />
          </div>
          <Divider borderStyle="dashed" thickness="thin" />

          {(source.owners?.length > 0 ||
            source.organization ||
            source.medium) && (
            <>
              <SourcePageOwners
                media={source.medium}
                organization={source.organization}
                persons={source.owners}
              />
              <Divider borderStyle="dashed" thickness="thin" />
            </>
          )}

          <div>
            <SourceStats
              interactionData={source.interactionData}
              interactionNumber={source.interactionNumber}
              linkData={source.linkData}
              linkNumber={source.linkNumber}
              misinformationInteractionNumber={
                source.misinformationInteractionNumber
              }
              misinformationLinkNumber={source.misinformationLinkNumber}
              sourceType={source.sourceType}
              statsInteraction={source.statsInteraction}
              statsLink={source.statsLink}
            />
            {source.id && isLoggedIn && isAuthorizedFactchecker &&(
              <SourceStatisticsRecalculate source={source} />
            )}

            {source.mbfcFactualReporting && (
              <div className="source-page-desktop--mbfc">
                <div className="source-page-desktop--mbfc--header">
                  <Typography variant="title-1">Factual Reporting :</Typography>
                  <ConclusionTag
                    label={source.mbfcFactualReporting}
                    score={source.mbfcScore}
                  />
                </div>
                <div className="source-page-desktop--mbfc--value">
                  <Typography color="secondary-grey" variant="body-text-4">
                    Media Bias Fact Check says the factual reporting of this
                    website is {source.mbfcFactualReporting}
                  </Typography>

                  <a
                    href={source.mbfcUrl}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <Button variant="light">
                      <Typography variant="title-3">Read more</Typography>
                      <Icon icon="arrowup45" />
                    </Button>
                  </a>
                </div>
              </div>
            )}
          </div>

          <Divider borderStyle="dashed" thickness="thin" />

          {source.media && (
            <>
              <div className="source-page-desktop--related-media">
                <Typography
                  className="source-page-desktop--related-media--title"
                  variant="h3"
                >
                  Related media:
                </Typography>

                {source.media.map(media => (
                  <MediaCard key={media.id} {...media} />
                ))}
              </div>

              <Divider borderStyle="dashed" thickness="thin" />
            </>
          )}

          {source.channels && (
            <>
              <div className="source-page-desktop--related-channel">
                <Typography
                  className="source-page-desktop--related-channel--title"
                  variant="h3"
                >
                  Related channels:
                </Typography>
                {source.channels.map(channel => (
                  <ChannelCard key={channel.id} {...channel} />
                ))}
              </div>

              <Divider borderStyle="dashed" thickness="thin" />
            </>
          )}

          <SourcePageAppearances
            appearances={appearances}
            appearancesAreLoading={appearancesAreLoading}
            defaultTagLabel={defaultTagLabel}
            fetchNextAppearancePage={fetchNextAppearancePage}
            hasNextAppearancePage={hasNextAppearancePage}
            isLoggedIn={isLoggedIn}
            onChooseTagLabel={onChooseTagLabel}
            source={source}
          />
        </div>
      </div>

      {!isLoggedIn && <FullReport onSubmit={onContact} />}
    </div>
  )
}

SourcePageDesktop.defaultProps = {
  appearances: [],
  appearancesAreLoading: false,
  fetchNextAppearancePage: () => null,
  hasNextAppearancePage: undefined,
  onContact: () => null,
  onRefetchSource: () => null,
  source: {
    channels: null,
    media: null,
    owners: [],
    statsLink: {},
  },
}

SourcePageDesktop.propTypes = {
  appearances: PropTypes.arrayOf(PropTypes.shape({})),
  appearancesAreLoading: PropTypes.bool,
  fetchNextAppearancePage: PropTypes.func,
  hasNextAppearancePage: PropTypes.bool,
  onContact: PropTypes.func,
  onRefetchSource: PropTypes.func,
  source: PropTypes.shape({
    id: PropTypes.string,
    channels: PropTypes.arrayOf(PropTypes.shape({})),
    linkNumber: PropTypes.number,
    interactionNumber: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    media: PropTypes.arrayOf(PropTypes.shape({})),
    owners: PropTypes.arrayOf(PropTypes.shape({})),
    sourceType: PropTypes.string,
    statsLink: PropTypes.shape({}),
    statsInteraction: PropTypes.shape({}),
  }),
}

export default SourcePageDesktop

import { normalizeConclusion } from 'normalizer'

import { numberShortener } from '../utils/shorteners'

const normalizeReview = review => {
  const { initialLabel, label, score } = normalizeConclusion({
    conclusionTag: review.conclusionTag,
    conclusion: review.conclusion,
  })

  return {
    id: review.id,
    reviewBy: review.factCheckContent.channel?.name,
    reviewByUrl: review.factCheckContent?.channel?.url,
    review: review.factCheckContent.title,
    reviewDate: review.factCheckContent.publishedDate,
    reviewUrl: review.factCheckContent.url,
    claim: review.claim.textDisplayed,
    claimOuterLinksCandidatesCount: review.claim.outerLinksCandidatesCount,
    initialClaim: review.claim.text,
    claimantId: review.claim.claimant?.id,
    claimBy: review.claim.claimant?.name
      ? review.claim.claimant.name
      : review.claim.claimantProposal,
    claimId: review.claim.id,
    interactionNumber: numberShortener(review.claim.totalInteractions) || 0,
    linkNumber: review.claim.quotationsCount,
    conclusionId: review.conclusion?.id,
    conclusionLabel: label,
    conclusionScore: score,
    conclusionStandardForm: review.conclusion?.standardForm,
    isConclusion: review.conclusion ? true : false,
    factcheckerVerdict: initialLabel,
    initialTagId: review.conclusionTag?.id,
    factcheckerId: review.factCheckContent?.channel?.id,
    standardClaim: review.claim.standardClaim?.text,
    standardClaimId: review.claim.standardClaim?.id,
    syncLastDate: review.syncLastDate,
    topicTags: review.factCheckContent.contentTopicTags.map(
      item => item.tag.label
    ),
  }
}

export default normalizeReview

import dayjs from 'dayjs'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'

import { Button, Checkbox, ConfirmBar, Icon, Typography } from 'components'

import './syncReview.scss'

export const SyncReview = ({ syncLastDate, onSubmit }) => {
  const [showSyncForm, setShowSyncForm] = useState(false)
  const toggleShowSyncForm = () => setShowSyncForm(!showSyncForm)

  const [showSyncFormConfirm, setShowSyncFormConfirm] = useState(false)
  const toggleShowSyncFormConfirm = () =>
    setShowSyncFormConfirm(!showSyncFormConfirm)

  const onSync = values => {
    onSubmit(values)
    toggleShowSyncForm()
    toggleShowSyncFormConfirm()
    setTimeout(() => {
      setShowSyncFormConfirm(false)
    }, 3000)
  }

  const { handleSubmit, getValues, setValue } = useForm({
    defaultValues: {
      targets: {
        factcheck_content: false,
        quotations: false,
      },
      tasks: {
        buzzsumo: false,
        crowdtangle: false,
        newspaper: false,
        factchecker: false,
        screenshotmachine: false,
        waybackmachine: false,
        tiktok: false,
      },
    },
  })

  const [checkboxValues, setCheckboxValues] = useState({
    factcheck_content: false,
    quotations: false,
    buzzsumo: false,
    crowdtangle: false,
    newspaper: false,
    factchecker: false,
    screenshotmachine: false,
    waybackmachine: false,
    tiktok: false,
  })

  const onCheckboxClick = (type, value) => {
    setValue(`${type}.${value}`, !getValues(`${type}.${value}`))
    setCheckboxValues({
      ...checkboxValues,
      [value]: !checkboxValues[value],
    })
  }

  let syncLastDateDate = null
  if (syncLastDate) {
    syncLastDateDate = new Date(syncLastDate)
  }
  return (
    <div className="sync-review">
      <div className="sync-review--header">
        <div>
          {syncLastDate && (
            <Typography variant="body-text-1">
              Last synchronized {dayjs(syncLastDateDate).format('DD.MM.YYYY')}{' '}
              at {dayjs(syncLastDateDate).format('HH:mm')}
            </Typography>
          )}
        </div>
        <div>
          {showSyncForm ? (
            <Button onClick={toggleShowSyncForm} variant="default-link">
              <Typography variant="title-1">Cancel</Typography>
              <Icon icon="cross" />
            </Button>
          ) : (
            <Button onClick={toggleShowSyncForm} variant="default">
              Sync
            </Button>
          )}
        </div>
      </div>

      {showSyncForm && (
        <form className="sync-review--form" onSubmit={handleSubmit(onSync)}>
          <div className="sync-review--form--row">
            <Typography variant="title-2">Targets:</Typography>
            <div className="sync-review--form--row--checkboxes">
              <div className="sync-review--form--row--checkboxes--checkbox">
                <Checkbox
                  checked={checkboxValues.factcheck_content}
                  onChange={() =>
                    onCheckboxClick('targets', 'factcheck_content')
                  }
                />
                <Typography variant="body-text-4">Factcheck content</Typography>
              </div>
              <div className="sync-review--form--row--checkboxes--checkbox">
                <Checkbox
                  checked={checkboxValues.quotations}
                  onChange={() => onCheckboxClick('targets', 'quotations')}
                />
                <Typography variant="body-text-4">Appearances</Typography>
              </div>
            </div>
          </div>

          <div className="sync-review--form--row">
            <Typography variant="title-2">Tasks:</Typography>
            <div className="sync-review--form--row--checkboxes">
              <div className="sync-review--form--row--checkboxes--checkbox">
                <Checkbox
                  checked={checkboxValues.buzzsumo}
                  onChange={() => onCheckboxClick('tasks', 'buzzsumo')}
                />
                <Typography variant="body-text-4">Buzzsumo</Typography>
              </div>
              <div className="sync-review--form--row--checkboxes--checkbox">
                <Checkbox
                  checked={checkboxValues.crowdtangle}
                  onChange={() => onCheckboxClick('tasks', 'crowdtangle')}
                />
                <Typography variant="body-text-4">Crowdtangle</Typography>
              </div>
              <div className="sync-review--form--row--checkboxes--checkbox">
                <Checkbox
                  checked={checkboxValues.newspaper}
                  onChange={() => onCheckboxClick('tasks', 'newspaper')}
                />
                <Typography variant="body-text-4">Newspaper</Typography>
              </div>
              <div className="sync-review--form--row--checkboxes--checkbox">
                <Checkbox
                  checked={checkboxValues.factchecker}
                  onChange={() => onCheckboxClick('tasks', 'factchecker')}
                />
                <Typography variant="body-text-4">
                  External factchecker
                </Typography>
              </div>
              <div className="sync-review--form--row--checkboxes--checkbox">
                <Checkbox
                  checked={checkboxValues.screenshotmachine}
                  onChange={() => onCheckboxClick('tasks', 'screenshotmachine')}
                />
                <Typography variant="body-text-4">
                  Screenshot Machine
                </Typography>
              </div>
              <div className="sync-review--form--row--checkboxes--checkbox">
                <Checkbox
                  checked={checkboxValues.waybackmachine}
                  onChange={() => onCheckboxClick('tasks', 'waybackmachine')}
                />
                <Typography variant="body-text-4">Wayback Machine</Typography>
              </div>
              <div className="sync-review--form--row--checkboxes--checkbox">
                <Checkbox
                  checked={checkboxValues.tiktok}
                  onChange={() => onCheckboxClick('tasks', 'tiktok')}
                />
                <Typography variant="body-text-4">Tiktok</Typography>
              </div>
            </div>
          </div>

          <div className="sync-review--form--button">
            <Button type="submit" variant="ghost">
              Sync
            </Button>
          </div>
        </form>
      )}

      <ConfirmBar
        onClose={toggleShowSyncFormConfirm}
        show={showSyncFormConfirm}
        text="Synchronization has been launched"
      />
    </div>
  )
}

export default SyncReview

SyncReview.defaultProps = {
  syncLastDate: null,
}

SyncReview.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  syncLastDate: PropTypes.string,
}

import { useQuery } from 'react-query'

import { API_URL } from 'utils/config'

import { useAxios } from '../index'

export default function useStats(modelTypes) {
  const axios = useAxios()

  const getStats = async modelTypes => {
    const { data } = await axios.get(
      `${API_URL}/statistics?modelTypes=${modelTypes}`
    )
    return data
  }

  return useQuery(['stats', modelTypes], () => getStats(modelTypes))
}

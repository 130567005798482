import PropTypes from 'prop-types'
import React from 'react'

import { Button, ConfirmBar, FooterBar, Typography } from 'components'

import './confirmDeleteConclusion.scss'

const ConfirmDeleteConclusion = ({
  conclusion,
  isConclusionDeleted,
  show,
  onClose,
  onSubmit,
}) => {
  const onSend = values => {
    onSubmit(values)
  }

  if (isConclusionDeleted) {
    return <ConfirmBar show={show} text="Conclusion was deleted" />
  } else {
    return (
      <FooterBar onClose={onClose} onSubmit={onSend} show={show}>
        <div className="delete-conclusion">
          <div className="delete-conclusion--text">
            <Typography color="primary-white" variant="title-1">
              Delete this conclusion ({conclusion?.standardForm} -{' '}
              {conclusion?.score}) ?
            </Typography>
          </div>
          <div className="delete-conclusion--actions">
            <Button onClick={onClose} variant="ghost-reverse-link">
              <Typography
                className="delete-conclusion--actions--cancel"
                color="primary-white"
                variant="title-3"
              >
                Cancel
              </Typography>
            </Button>
            <Button onClick={onSubmit} variant="ghost-reverse">
              Delete
            </Button>
          </div>
        </div>
      </FooterBar>
    )
  }
}

ConfirmDeleteConclusion.defaultProps = {
  conclusion: {
    standardForm: null,
    score: null,
  },
  isConclusionDeleted: false,
  show: false,
}

ConfirmDeleteConclusion.propTypes = {
  conclusion: PropTypes.shape({
    score: PropTypes.number,
    standardForm: PropTypes.string,
  }),
  isConclusionDeleted: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  show: PropTypes.bool,
}

export default ConfirmDeleteConclusion

import { useMutation } from 'react-query'

import { useAxios } from 'hooks'

import { API_URL } from '../../utils/config'

export default function useScrapFactcheck(initialData, mode='import') {
  const instances = useAxios()
  const scrapFactcheck = async scrapFactcheckData => {
    const url = `${API_URL}/factchecks/${mode}`

    const { data } = await instances.post(
      url,
      scrapFactcheckData
    )

    return data
  }
  return useMutation(data => scrapFactcheck(data), initialData)
}

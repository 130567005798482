import { useMutation } from 'react-query'

import { API_URL } from 'utils/config'

import { useAxios } from '../index'

const useDeleteFeedbackRequest = feedbackRequestId => {
  const axios = useAxios()

  const patchClaim = async () => {
    const { data } = await axios.delete(
      `${API_URL}/feedback-requests/${feedbackRequestId}`
    )
    return data
  }

  return useMutation(data => patchClaim(data))
}

export default useDeleteFeedbackRequest

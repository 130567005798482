import React from 'react'
import { useMediaQuery } from 'react-responsive'

import ReviewAndClaimDesktop from './ReviewAndClaimDesktop'
import ReviewAndClaimMobile from './ReviewAndClaimMobile'

const ReviewAndClaim = review => {
  const isMobile = useMediaQuery({ query: '(max-width: 1200px)' })

  if (isMobile) {
    return <ReviewAndClaimMobile {...review} />
  } else {
    return <ReviewAndClaimDesktop {...review} />
  }
}

export default ReviewAndClaim

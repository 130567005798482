import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useFormContext } from 'react-hook-form'

import { Badge, Select } from 'components/index'
import { usePersons } from 'hooks'

export const SearchPerson = ({
  labelField,
  valueField,
}) => {
  const [search, setSearch] = useState('')
  const { setValue, getValues, watch } = useFormContext()
  const { data, isLoading } = usePersons(search)
  const defaultValue = {
    id: getValues(valueField),
    name: getValues(labelField),
  }

  const options = data?.pages[0].data || []
  const onSearch = search => {
    setSearch(search)
  }

  const onChange = value => {
    setValue(valueField, value.id)
    setValue(labelField, value.name)
  }

  const claimant = watch(labelField)

  return (
    <>
      <Select
        defaultValue={defaultValue}
        getOptionLabel={option => option.name}
        getOptionValue={option => option.id}
        isLoading={isLoading}
        onChange={onChange}
        onSearch={onSearch}
        options={options}
        placeholder="The author of the claim"
      />

      {claimant && (
        <Badge
          onDelete={() => onChange({ id: null, name: null })}
          text={claimant}
        />
      )}
    </>
  )
}

SearchPerson.propTypes = {
  labelField: PropTypes.string,
  valueField: PropTypes.string,
}

SearchPerson.defaultProps = {
  labelField: 'claim.claimantName',
  valueField: 'claim.claimantId',
}

export default SearchPerson

import PropTypes from 'prop-types'
import React from 'react'

import { Icon } from 'components'

import './inputFileField.scss'

const InputFileField = ({
  accept,
  inputRef,
  id,
  onChange,
  register,
  registerName,
  registerValidation,
  rounded,
  variant,
  ...rest
}) => {
  const iconColor = variant === 'ghost-reverse' ? 'white' : 'black'
  return (
    <div className="file-field" data-variant={variant}>
      <label className="file-field--label" htmlFor={id}>
        Choose a file
        <Icon color={iconColor} icon="file" />
      </label>
      <input
        accept={accept}
        className="file-field--input"
        id={id}
        type="file"
        {...(inputRef
        ? { ...{ ref: inputRef } }
        : register
        ? { ...register(registerName, {...registerValidation, onChange}) }
        : {})}
    />
    </div>
  )
}

export default InputFileField

InputFileField.defaultProps = {
  accept: '',
  className: '',
  inputRef: null,
  onChange: () => null,
  register: null,
  registerName: '',
  registerValidation: {},
  rounded: true,
  variant: 'default',
}

InputFileField.propTypes = {
  accept: PropTypes.string,
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  inputRef: PropTypes.func,
  onChange: PropTypes.func,
  register: PropTypes.func,
  registerName: PropTypes.string,
  registerValidation: PropTypes.shape({}),
  rounded: PropTypes.bool,
  variant: PropTypes.oneOf(['default', 'ghost-reverse']),
}

import React from 'react'

import { SearchPage } from 'components'
import { useSearchFilter } from 'hooks'

const Search = () => {
  const {
    currentFilters,
    currentTab,
    dataLimit,
    dataType,
    fetchNextReviewPage,
    fetchNextSourcePage,
    hasNextReviewPage,
    hasNextSourcePage,
    onFilterChoose,
    onSearch,
    setTab,
    reviewIsLoading,
    reviews,
    search,
    selectedFilters,
    sourceIsLoading,
    sources,
  } = useSearchFilter()

  return (
    <SearchPage
      currentFilters={currentFilters}
      dataLimit={dataLimit}
      dataType={dataType}
      defaultSearch={search}
      defaultTab={currentTab}
      fetchNextReviewPage={fetchNextReviewPage}
      fetchNextSourcePage={fetchNextSourcePage}
      hasNextReviewPage={hasNextReviewPage}
      hasNextSourcePage={hasNextSourcePage}
      onFilterChoose={onFilterChoose}
      onSearch={onSearch}
      onTabChange={setTab}
      reviewIsLoading={reviewIsLoading}
      reviews={reviews}
      selectedFilters={selectedFilters}
      sourceIsLoading={sourceIsLoading}
      sources={sources}
    />
  )
}

export default Search

import PropTypes from 'prop-types'
import React, { useState } from 'react'

import Copyright from '../../../Atoms/Copyright'
import Logo from '../../../Atoms/Logo'
import ContactForm from '../../ContactForm'
import FooterMenu from '../FooterMenu'

import './footerdesktop.scss'

export const FooterDesktop = ({ onSubmit }) => {
  const [show, setShow] = useState(false)
  const toggleContactForm = () => setShow(!show)
  return (
    <footer className="footer-desktop">
      <div>
        <Logo variant="white" />
        <Copyright />
      </div>
      <div className="footer-desktop--navlink">
        <FooterMenu contactClick={toggleContactForm} />
      </div>
      <ContactForm
        onClose={toggleContactForm}
        onSubmit={onSubmit}
        show={show}
      />
    </footer>
  )
}

FooterDesktop.propTypes = {
  onSubmit: PropTypes.func.isRequired,
}

export default FooterDesktop

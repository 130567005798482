import PropTypes from 'prop-types'
import React from 'react'

import { InteractionCircle, Tab, TabPanel, TabsContext } from 'components'
import Typography from 'components/Atomic/Atoms/Typography'
import LegendStatistic from 'components/Atomic/Molecules/LegendStatistic'
import NoSourceStats from './NoSourceStats'
import './sourceStats.scss'

const SourceStats = ({
  interactionNumber,
  linkNumber,
  stats,
  linkData,
  interactionData,
  misinformationLinkNumber,
  misinformationInteractionNumber,
  sourceType,
  statsLink,
  statsInteraction,
}) => {
  return (
    <div className="source-stats">
      <Typography variant="h3">Factcheck statistics:</Typography>

      <div className="source-stats--body">
        <TabsContext defaultTab="links">
          <div className="source-stats--tabs">
            <Tab className="source-stats--tabs--tab" value="links">
              <Typography color="secondary-grey" variant="body-text-4">
                {linkNumber} links
              </Typography>
            </Tab>
            <Tab className="source-stats--tabs--tab" value="int">
              <Typography color="secondary-grey" variant="body-text-4">
                {interactionNumber} interactions
              </Typography>
            </Tab>
          </div>

          <TabPanel className="source-stats--tabs--content" value="links">
            {linkNumber > 0 ? (
              <>
                <Typography color="secondary-grey" variant="body-text-4">
                  {misinformationLinkNumber} links shared by this{' '}
                  {sourceType.toLowerCase()} contain misleading information
                  according to fact-checkers.
                </Typography>

                <div className="source-stats--tabs--content--stats">
                  <InteractionCircle
                    data={linkData}
                    label={linkNumber}
                    unit="links"
                    variant="big"
                  />
                  <div className="source-stats--tabs--content--stats--details">
                    <div>
                      <LegendStatistic
                        count={statsLink.misinformation}
                        type="Links"
                        variant="misinformation"
                      />
                      <div className="source-stats--tabs--content--stats--details--divider" />
                      <LegendStatistic
                        count={statsLink.credible}
                        type="Links"
                        variant="credible"
                      />
                    </div>
                    <div>
                      <LegendStatistic
                        count={statsLink.satire}
                        type="Links"
                        variant="satire"
                      />
                      <div className="source-stats--tabs--content--stats--details--divider" />
                      <LegendStatistic
                        count={statsLink.undefined}
                        type="Links"
                        variant="undefined"
                      />
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <NoSourceStats label="?" unit="links" />
            )}
          </TabPanel>

          <TabPanel className="source-stats--tabs--content" value="int">
            {interactionNumber !== 0 ? (
              <>
                <Typography color="secondary-grey" variant="body-text-4">
                  Links containing misinformation have generated{' '}
                  {misinformationInteractionNumber} interactions on social media
                </Typography>

                <div className="source-stats--tabs--content--stats">
                  <InteractionCircle
                    data={interactionData}
                    label={interactionNumber}
                    variant="big"
                  />
                  <div className="source-stats--tabs--content--stats--details">
                    <div>
                      <LegendStatistic
                        count={statsInteraction.misinformation}
                        type="Interactions"
                        variant="misinformation"
                      />
                      <div className="source-stats--tabs--content--stats--details--divider" />
                      <LegendStatistic
                        count={statsInteraction.credible}
                        type="Interactions"
                        variant="credible"
                      />
                    </div>
                    <div>
                      <LegendStatistic
                        count={statsInteraction.satire}
                        type="Interactions"
                        variant="satire"
                      />
                      <div className="source-stats--tabs--content--stats--details--divider" />
                      <LegendStatistic
                        count={statsInteraction.undefined}
                        type="Interactions"
                        variant="undefined"
                      />
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <NoSourceStats label="?" unit="Int." />
            )}
          </TabPanel>
        </TabsContext>
      </div>
    </div>
  )
}

SourceStats.defaultProps = {
  sourceType: '',
  statsInteraction: {
    credible: 0,
    misinformation: 0,
    satire: 0,
    undefined: 0,
  },
  statsLink: {
    credible: 0,
    misinformation: 0,
    satire: 0,
    undefined: 0,
  },
}

SourceStats.propTypes = {
  sourceType: PropTypes.string,
  statsInteraction: PropTypes.shape({
    credible: PropTypes.number | PropTypes.string,
    misinformation: PropTypes.number | PropTypes.string,
    satire: PropTypes.number | PropTypes.string,
    undefined: PropTypes.number | PropTypes.string,
  }),
  statsLink: PropTypes.shape({
    credible: PropTypes.number | PropTypes.string,
    misinformation: PropTypes.number | PropTypes.string,
    satire: PropTypes.number | PropTypes.string,
    undefined: PropTypes.number | PropTypes.string,
  }),
}

export default SourceStats

import React from 'react'

import {
  ScientistDashboard,
} from 'components'


const Scientist = ({ auth }) => {
  return (
    <ScientistDashboard user={auth.user} />
  )
}

export default Scientist

import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useForm, FormProvider } from 'react-hook-form'

import {
  Badge,
  Button,
  Checkbox,
  ConfirmBar,
  Divider,
  Dropdown,
  SearchConclusion,
  SearchPerson,
  SideBar,
  TextAreaField,
  Typography,
} from 'components'
import { useReviewEdition } from 'hooks'

import SideBarBody from '../../../Atomic/Molecules/SideBar/SideBarBody'
import SideBarFooter from '../../../Atomic/Molecules/SideBar/SideBarFooter'
import SideBarHeader from '../../../Atomic/Molecules/SideBar/SideBarHeader'

import './editReview.scss'
import { useParams } from 'react-router-dom'

const SCORE_OPTIONS = [
  { value: null, text: '' },
  { value: 0, text: 0 },
  { value: 1, text: 1 },
  { value: 2, text: 2 },
  { value: 3, text: 3 },
  { value: 4, text: 4 },
  { value: 5, text: 5 },
]
export const EditReview = ({
  claimantId,
  claimantName,
  factcheckerVerdict,
  fullReviewLink,
  initialClaim,
  standardClaim,
  reviewBy,
  reviewTitle,
  conclusionStandardForm,
  onClose,
  show,
  conclusionScore,
}) => {
  const methods = useForm({
    defaultValues: {
      test: 'test',
      title: reviewTitle,
      claim: {
        claimantId: claimantId,
        claimantName: claimantName,
        standardClaim: {
          text: standardClaim,
        },
      },
      factcheckContent: {
        url: fullReviewLink,
        title: reviewTitle,
      },
      conclusion: {
        standardForm: conclusionStandardForm,
        score: conclusionScore,
      },
    },
  })
  const { setValue, register } = methods
  const { reviewId } = useParams()
  const editReview = useReviewEdition(reviewId)

  const [showReviewEdited, setShowReviewEdited] = useState(false)
  const toggleShowReviewEdited = () => setShowReviewEdited(!showReviewEdited)

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    methods.reset({
      claim: {
        claimantId: claimantId || null,
        claimantName: claimantName || null,
        standardClaim: {
          text: standardClaim,
        },
      },
      factcheckContent: {
        url: fullReviewLink,
        title: reviewTitle,
      },
      conclusion: {
        standardForm: conclusionStandardForm,
        score: conclusionScore,
      },
    })
  }, [reviewTitle, fullReviewLink, standardClaim])

  const onSubmitForm = values => {
    editReview.mutate(values)
    onClose()
    toggleShowReviewEdited()
    setTimeout(() => {
      setShowReviewEdited(false)
    }, 3000)
  }

  const [conclusionWithoutScore, setConclusionWithoutScore] = useState(false)

  const onCheckboxClick = () => {
    setConclusionWithoutScore(!conclusionWithoutScore)
    if (!conclusionWithoutScore) {
      setValue('conclusion.score', null)
    }
  }

  const [standardFormChosen, setStandardFormChosen] = useState(
    conclusionStandardForm
  )

  useEffect(() => {
    if (conclusionStandardForm) {
      setStandardFormChosen(conclusionStandardForm)
    }
  }, [conclusionStandardForm])

  const reinitializeInitialConclusion = () => {
    setStandardFormChosen(conclusionStandardForm)
  }

  const onStandardFormSelectChange = value => {
    setValue('conclusion.standardForm', value.standardForm)
    if (reviewBy in value.channelScores) {
      setValue('conclusion.score', value.channelScores[reviewBy])
    }
    setStandardFormChosen(value.standardForm)
  }

  const onStandardFormAdd = value => {
    setValue('conclusion.standardForm', value)
    setStandardFormChosen(value)
  }

  return (
    <div className="edit-review">
      <SideBar onClose={onClose} show={show}>
        <FormProvider {...methods}>
          <form
            className="edit-review--form"
            onSubmit={methods.handleSubmit(onSubmitForm)}
          >
            <SideBarHeader>
              <Typography color="primary-white" variant="h2">
                Edit review details:
              </Typography>
            </SideBarHeader>
            <SideBarBody>
              <div className="edit-review--section">
                <Typography color="primary-white" variant="title-1">
                  Review
                </Typography>
                <Typography color="primary-white" variant="body-text-2">
                  This review is made by {reviewBy}
                </Typography>
              </div>
              <div className="edit-review--section">
                <Typography color="primary-white" variant="title-3">
                  Title
                </Typography>
                <TextAreaField
                  maxLength="2000"
                  placeholder="Write here"
                  register={methods.register}
                  registerName="factcheckContent.title"
                  rounded={false}
                  rows={4}
                />
              </div>

              <div className="edit-review--section">
                <Typography color="primary-white" variant="title-3">
                  Link:
                </Typography>
                <TextAreaField
                  maxLength="2000"
                  placeholder="Write here"
                  register={methods.register}
                  registerName="factcheckContent.url"
                  rounded={false}
                  rows={4}
                />
              </div>

              <Divider className="edit-review--divider" />

              <div className="edit-review--section">
                <Typography color="primary-white" variant="title-1">
                  Claim
                </Typography>
                <Typography color="primary-white" variant="body-text-2">
                  {initialClaim}
                </Typography>
              </div>

              <div className="edit-review--section">
                <Typography color="primary-white" variant="title-3">
                  Standard claim:
                </Typography>
                <TextAreaField
                  maxLength="2000"
                  placeholder="Write here"
                  register={methods.register}
                  registerName="claim.standardClaim.text"
                  rounded={false}
                  rows={4}
                />
              </div>

              <div className="edit-review--section">
                <Typography color="primary-white" variant="title-3">
                  Claimant:
                </Typography>
                <SearchPerson />
              </div>

              <Divider className="edit-review--divider" />

              <div className="edit-review--section">
                <Typography color="primary-white" variant="title-1">
                  Factchecker verdict
                </Typography>
                <Typography color="primary-white" variant="body-text-2">
                  {factcheckerVerdict}
                </Typography>
              </div>

              <div className="edit-review--section">
                <Typography color="primary-white" variant="title-3">
                  Standard form:
                </Typography>
                <SearchConclusion
                  addButton={true}
                  onAdd={onStandardFormAdd}
                  onChange={onStandardFormSelectChange}
                />
                {standardFormChosen && (
                  <Badge
                    onDelete={reinitializeInitialConclusion}
                    text={standardFormChosen}
                  />
                )}

                <Typography color="primary-white" variant="title-3">
                  Score:
                </Typography>

                <Dropdown
                  className="edit-review--dropdown"
                  defaultValue={conclusionScore}
                  options={SCORE_OPTIONS}
                  {...register('conclusion.score')}
                />

                <div className="edit-review--noscore">
                  <Checkbox
                    checked={conclusionWithoutScore}
                    onChange={onCheckboxClick}
                  />
                  <Typography color="primary-white" variant="body-text-2">
                    Conclusion without score
                  </Typography>
                </div>
              </div>
            </SideBarBody>

            <SideBarFooter>
              <Button
                onClick={onClose}
                type="button"
                variant="ghost-reverse-link"
              >
                Cancel
              </Button>
              <Button type="submit" variant="ghost-reverse">
                Save changes
              </Button>
            </SideBarFooter>
          </form>
        </FormProvider>
      </SideBar>
      <ConfirmBar
        checkAnimation
        onClose={toggleShowReviewEdited}
        show={showReviewEdited}
        text="Review updated"
      />
    </div>
  )
}

export default EditReview

EditReview.defaultProps = {
  claimantId: '',
  claimantName: '',
  initialClaim: '',
  show: false,
  standardClaim: '',
}

EditReview.propTypes = {
  claimantId: PropTypes.string,
  claimantName: PropTypes.string,
  initialClaim: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool,
  standardClaim: PropTypes.string,
}

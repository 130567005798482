import { useMutation } from 'react-query'

import { useAxios } from 'hooks'

import { API_URL } from '../../utils/config'

export default function useResetAppearanceShares(data) {
  const instances = useAxios()
  const resetAppearanceShares = async appearanceId => {
    const { data } = await instances.post(
      `${API_URL}/appearances/${appearanceId}/shares/reset?platform=facebook&platform=instagram&platform=reddit`,
      {}
    )
    return data
  }
  return useMutation(data => resetAppearanceShares(data), data)
}

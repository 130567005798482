import PropTypes from 'prop-types'
import React, { useState } from 'react'

import { AppearanceClaims, Typography, Image } from 'components'

import AppearanceCardFooter from '../AppearanceCardFooter/AppearanceCardFooter'
import AppearanceCardHeader from '../AppearanceCardHeader'

import './appearanceCardDesktop.scss'

const AppearanceCard = ({
  appearanceArchiveUrl,
  appearanceId,
  appearanceImageUrl,
  appearanceTitle,
  appearanceType,
  appearanceUrl,
  conclusionLabel,
  conclusionScore,
  interactionNumber,
  isLoggedIn,
}) => {
  const [isClaimsListOpen, setIsClaimsListOpen] = useState(false)

  return (
    <div className="appearance-card-desktop">
      <div className="appearance-card-desktop--image">
        <Image name="content" url={appearanceImageUrl} />
      </div>

      <div className="appearance-card-desktop--body">
        <div className="appearance-card-desktop--body--content">
          <AppearanceCardHeader
            appearanceArchiveUrl={appearanceArchiveUrl}
            appearanceId={appearanceId}
            appearanceUrl={isLoggedIn ? appearanceUrl : null}
          />

          <div className="appearance-card-desktop--body--section--content">
            <Typography
              className="appearance-card-desktop--body--section--content--title"
              variant="body-text-2"
            >
              {appearanceTitle ? appearanceTitle : 'No title'}
            </Typography>
          </div>
        </div>
        <AppearanceCardFooter
          appearanceArchiveUrl={appearanceArchiveUrl}
          conclusionLabel={conclusionLabel}
          conclusionScore={conclusionScore}
          interactionNumber={interactionNumber}
          isClaimsListOpen={isClaimsListOpen}
          setIsClaimsListOpen={setIsClaimsListOpen}
        />
        {isClaimsListOpen && (
          <AppearanceClaims
            appearanceId={appearanceId}
            appearanceType={appearanceType}
          />
        )}
      </div>
    </div>
  )
}

export default AppearanceCard

AppearanceCard.defaultProps = {
  appearanceArchiveUrl: null,
  appearanceId: null,
  appearanceImageUrl: null,
  appearanceTitle: null,
  appearanceUrl: null,
  conclusionLabel: null,
  conclusionScore: null,
  interactionNumber: null,
  isLoggedIn: false,
}

AppearanceCard.propTypes = {
  appearanceArchiveUrl: PropTypes.string,
  appearanceId: PropTypes.string,
  appearanceImageUrl: PropTypes.string,
  appearanceTitle: PropTypes.string,
  appearanceUrl: PropTypes.string,
  conclusionLabel: PropTypes.string,
  conclusionScore: PropTypes.number,
  interactionNumber: PropTypes.number,
  isLoggedIn: PropTypes.bool,
}

import PropTypes from 'prop-types'
import React from 'react'

import Button from 'components/Atomic/Atoms/Button'
import Typography from 'components/Atomic/Atoms/Typography'
import FooterBar from 'components/Atomic/Molecules/FooterBar'
import './confirmAddQuotations.scss'

const ConfirmAddQuotations = ({
  isAppearanceAdded,
  nbAppearances,
  show,
  onClose,
  onSubmit,
}) => {
  if (isAppearanceAdded) return null

  return (
    <FooterBar show={show}>
      <div className="add-quotations">
        <div className="add-quotations--text">
          <Typography color="primary-white" variant="title-1">
            Add {nbAppearances}{' '}
            {nbAppearances > 1 ? 'appearances' : 'appearance'}
          </Typography>
        </div>
        <div className="add-quotations--actions">
          <Button onClick={onClose} variant="ghost-reverse-link">
            <Typography
              className="add-quotations--actions--cancel"
              color="primary-white"
              variant="title-3"
            >
              Cancel
            </Typography>
          </Button>
          <Button onClick={onSubmit} variant="ghost-reverse">
            Add
          </Button>
        </div>
      </div>
    </FooterBar>
  )
}

ConfirmAddQuotations.defaultProps = {
  isAppearanceAdded: false,
  nbAppearances: 1,
  show: false,
}

ConfirmAddQuotations.propTypes = {
  isAppearanceAdded: PropTypes.bool,
  nbAppearances: PropTypes.number,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  show: PropTypes.bool,
}

export default ConfirmAddQuotations

import PropTypes from 'prop-types'
import React from 'react'
import { useForm } from 'react-hook-form'

import {
  SideBar,
  Button,
  Typography,
  TextField,
  ErrorMessage,
} from 'components'
import { useAddSource } from 'hooks'

import './addSource.scss'

export const AddSource = ({ onClose, onSourceAdded, show, sourceType }) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm()
  const { mutate } = useAddSource()

  const onSend = (values, e) => {
    e.preventDefault()
    e.stopPropagation()
    mutate(values, {
      onSuccess: result => {
        onSourceAdded(result)
      },
    })
    onClose()
  }

  return (
    <SideBar onClose={onClose} show={show}>
      <Typography
        className="add-source--title"
        color="primary-white"
        variant="h2"
      >
        Add {sourceType}:
      </Typography>

      <form className="add-source--form" onSubmit={handleSubmit(onSend)}>
        <input
          defaultValue={sourceType}
          type="hidden"
          {...register('sourceType')}
        />

        <Typography
          className="add-source--label"
          color="primary-white"
          variant="title-2"
        >
          Name:
        </Typography>

        <TextField
          className="add-source--input"
          placeholder="Name"
          register={register}
          registerName="name"
          registerValidation={{ required: true }}
          rounded={false}
        />
        {errors?.name?.type === 'required' && (
          <ErrorMessage message="Name is required" />
        )}

        <Typography
          className="add-source--label"
          color="primary-white"
          variant="title-2"
        >
          Url:
        </Typography>

        <TextField
          className="add-source--input"
          placeholder="URL"
          register={register}
          registerName="url"
          //registerValidation={{ required: true }}
          rounded={false}
        />

        {/* errors?.url?.type === 'required' && (
          <ErrorMessage message="Url is required" />
        )*/}

        <div className="add-source--actions">
          <Button onClick={onClose} type="button" variant="ghost-reverse">
            Cancel
          </Button>

          <Button type="submit" variant="ghost-reverse">
            Save
          </Button>
        </div>
      </form>
    </SideBar>
  )
}

AddSource.defaultProps = {
  onSourceAdded: () => {},
  sourceType: '',
}

AddSource.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSourceAdded: PropTypes.func,
  show: PropTypes.bool.isRequired,
  sourceType: PropTypes.string,
}

export default AddSource

import { useMutation } from 'react-query'

import { useAxios } from 'hooks'

import { API_URL } from '../../utils/config'

export default function useAddAppearance(data) {
  const instances = useAxios()
  const addAppearance = async addAppearanceData => {
    const { documents, descriptions, ...appearanceData } = addAppearanceData.appearanceData
    const appearanceResponse = await instances.post(
      `${API_URL}/claims/${addAppearanceData.claimId}/appearances`,
      [appearanceData]
    )

    if (documents) {
      let formData = new FormData()
      formData.append('contentId', appearanceResponse.data[0].linkingContentId)
      formData.append('nbDocuments', documents.length)
      documents.forEach((file, index) => {
        formData.append(`file_${index}`, file)
        formData.append(`filesize_${index}`, file.size)
        formData.append(`description_${index}`, descriptions[index])
      })

      await instances.post(`${API_URL}/content-documents/`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
    }

    return appearanceResponse.data
  }
  return useMutation(data => addAppearance(data), data)
}

import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'

import './channelCard.scss'
import InteractionCircle from 'components/Atomic/Atoms/InteractionCircle'
import Typography from 'components/Atomic/Atoms/Typography'

const ChannelCard = ({
  interactionData,
  interactionNumber,
  id,
  sourceName,
  url,
}) => (
  <Link className="channel-card" to={`/channel/${id}`}>
    <InteractionCircle data={interactionData} label={interactionNumber} />
    <div className="channel-card--body">
      <Typography className="channel-card--body--name" variant="title-1">
        {sourceName}
      </Typography>
      <Typography variant="body-text-4">{url}</Typography>
    </div>
  </Link>
)

ChannelCard.defaultProps = {
  interactionData: [{ title: 'Unknown', value: 10, color: '#FFA951' }],
  interactionNumber: '0',
  id: null,
  sourceName: 'Unknown',
  url: null,
}

ChannelCard.propTypes = {
  interactionData: PropTypes.arrayOf(PropTypes.shape({})),
  interactionNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  id: PropTypes.string,
  sourceName: PropTypes.string,
  url: PropTypes.string,
}

export default ChannelCard

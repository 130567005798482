import React from 'react'

import { FactcheckerDashboard } from 'components'

const Factchecker =({ auth }) => {
  return (
    <FactcheckerDashboard user={auth.user} />
  )
}

export default Factchecker

import PropTypes from 'prop-types'
import React from 'react'
import { Typography, FooterBar } from 'components'

import './confirmBar.scss'

const ConfirmBar = ({ checkAnimation, onClose, show, text }) => (
  <FooterBar onClose={onClose} show={show}>
    <div className="confirm-bar">
      <Typography color="primary-white" variant="title-1">
        {text}
      </Typography>

      {checkAnimation && (
        <img
          className="confirm-bar--check-animation"
          alt="check"
          src="/static/assets/new/animation_check.gif"
        />
      )}
    </div>
  </FooterBar>
)

export default ConfirmBar

ConfirmBar.defaultProps = {
  checkAnimation: false,
  onClose: () => null,
  text: '',
}

ConfirmBar.propTypes = {
  checkAnimation: PropTypes.bool,
  onClose: PropTypes.func,
  show: PropTypes.bool.isRequired,
  text: PropTypes.string,
}

import React, { useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import ReviewsWithNoConclusionPage from 'components/Pages/ReviewsWithNoConclusionPage'
import { useReviews } from 'hooks'

const ReviewsWithoutConclusions = () => {
  let navigate = useNavigate()

  const [searchParams] = useSearchParams()
  const urlKeywords = searchParams.get('keywords') || ''
  const urlFactcheckers = searchParams.get('filterByFactcheckers') || ''

  const [search, setSearch] = useState(urlKeywords)

  const {
    data: reviews,
    fetchNextPage: fetchNextReviewPage,
    hasNextPage: hasNextReviewPage,
    isLoading: reviewIsLoading,
    refetch: refetchReviews
  } = useReviews({ factcheckers: urlFactcheckers, search: search, score: 'noStandardVerdict', onlyNotTreated: true })

  const onSearch = value => {
    navigate(`/dashboard/reviews/without-conclusions?keywords=${value.search}&filterByFactcheckers=${urlFactcheckers}`)
    setSearch(value.search)
  }

  return (
    <ReviewsWithNoConclusionPage
      defaultSearch={search}
      fetchNextReviewPage={fetchNextReviewPage}
      hasNextReviewPage={hasNextReviewPage}
      onSearch={onSearch}
      refetchReviews={refetchReviews}
      reviewIsLoading={reviewIsLoading}
      reviews={reviews}
    />
  )
}

export default ReviewsWithoutConclusions

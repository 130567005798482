import PropTypes from 'prop-types'
import React from 'react'

import Typography from '../Typography'

import './metric.scss'

const Metric = ({ number, text }) => (
  <div className="metrics">
    <Typography variant="numbers">
      <b>{number}</b>
    </Typography>
    &nbsp;
    <Typography variant="body-text-4">{text}</Typography>
  </div>
)

export default Metric

Metric.defaultProps = {
  number: '',
  text: 'default',
}

Metric.propTypes = {
  number: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  text: PropTypes.string,
}

import dayjs from 'dayjs'
import PropTypes from 'prop-types'
import React from 'react'

import {
  Icon,
  Typography
} from 'components'

import { useDownloadAppearanceDocument } from 'hooks'

import './AppearanceDocuments.scss'

const AppearanceDocuments = ({ contentDocuments }) => {
  const downloadAppearanceDocument = useDownloadAppearanceDocument()
  const onDownloadAppearanceDocument = async data => {
    downloadAppearanceDocument.mutate(data, {
      onSuccess: () => {},
    })
  }

  return (
    <div className="appearance-documents">
      <div className="appearance-documents--body">
        <Typography
          variant="title-1"
        >
          Attachments
        </Typography>
        <table className="appearance-documents--body--table">
          <thead>
            <tr>
              <th>
                <Typography color="primary-white" variant="body-text-4">
                  Date
                </Typography>
              </th>
              <th>
                <Typography color="primary-white" variant="body-text-4">
                  Type & size
                </Typography>
              </th>
              <th>
                <Typography color="primary-white" variant="body-text-4">
                  Description
                </Typography>
              </th>
              <th>
                <Typography color="primary-white" variant="body-text-4">
                  Uploader
                </Typography>
              </th>
              <th className="last"> Link </th>
            </tr>
          </thead>

          <tbody>
            {contentDocuments.map(contentDocument => (
              <tr key={contentDocument.id}>
                <td>
                  <Typography
                    color="secondary-grey"
                    variant="body-text-4"
                >
                    {dayjs(contentDocument.uploadedDate).format('DD/MM/YYYY')}
                  </Typography>
                </td>
                <td>
                  <div className="appearance-documents--body--table--type-size">
                    <Icon
                      icon={contentDocument.typeIcon}
                    />
                    <Typography
                      color="secondary-grey"
                      variant="body-text-4"
                    >
                      {contentDocument.filesize}
                    </Typography>
                  </div>
                </td>
                <td>
                  <Typography variant="body-text-4">
                    {contentDocument.description}
                  </Typography>
                </td>
                <td>
                  <Typography
                    color="secondary-grey"
                    variant="body-text-4"
                >
                    {contentDocument.uploader.email}
                  </Typography>
                </td>
                <td
                  className="last"
                  onClick={() => onDownloadAppearanceDocument({id: contentDocument.id, filename: contentDocument.filename})}
                >
                  <Icon icon="download" />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default AppearanceDocuments

AppearanceDocuments.defaultProps = {
  contentDocuments: [],
}

AppearanceDocuments.propTypes = {
  contentDocuments: PropTypes.arrayOf(PropTypes.shape({})),
}

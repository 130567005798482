import PropTypes from 'prop-types'
import React from 'react'

import { Loader, InfiniteScroll } from 'components'
import QuotationCard from 'components/Business/Quotations/QuotationCard'

import './quotationsList.scss'

const QuotationsList = ({
  appearances,
  appearancesChecked,
  areLoading,
  fetchNext,
  hasNext,
  isFactcheckerAuthorized,
  isLoggedIn,
  onCheck,
  onDeleteAppearanceVerdict,
  onModifyAppearanceVerdict,
  onReattributeAppearanceVerdict,
  onRefetchClaimAppearances,
}) => (
  <InfiniteScroll
    className="quotationslist"
    dataLength={appearances.length}
    hasMore={hasNext}
    isLoading={areLoading}
    loader={<Loader />}
    next={fetchNext}
    noDataDescription=""
    noDataTitle="No appearances here yet"
  >
    {appearances.map(appearance => (
      <div className="quotationslist--appearance" key={appearance.id}>
        <QuotationCard
          {...appearance}
          appearancesChecked={appearancesChecked}
          isFactcheckerAuthorized={isFactcheckerAuthorized}
          isLoggedIn={isLoggedIn}
          onCheck={onCheck}
          onDeleteAppearanceVerdict={onDeleteAppearanceVerdict}
          onModifyAppearanceVerdict={onModifyAppearanceVerdict}
          onReattributeAppearanceVerdict={onReattributeAppearanceVerdict}
          onRefetchClaimAppearances={onRefetchClaimAppearances}
          variant="ghost"
        />
      </div>
    ))}
  </InfiniteScroll>
)

export default QuotationsList

QuotationsList.defaultProps = {
  appearances: [],
  appearancesChecked: [],
  areLoading: false,
  fetchNext: () => null,
  hasNext: undefined,
  isFactcheckerAuthorized: false,
  isLoggedIn: false,
  onCheck: () => null,
  onDeleteAppearanceVerdict: () => null,
  onModifyAppearanceVerdict: () => null,
  onReattributeAppearanceVerdict: () => null,
  onRefetchClaimAppearances: () => null,
  onResetAppearanceSharesVerdict: () => null,
}

QuotationsList.propTypes = {
  appearances: PropTypes.arrayOf(PropTypes.shape({})),
  appearancesChecked: PropTypes.arrayOf(PropTypes.shape({})),
  areLoading: PropTypes.bool,
  fetchNext: PropTypes.func,
  hasNext: PropTypes.bool,
  isFactcheckerAuthorized: PropTypes.bool,
  isLoggedIn: PropTypes.bool,
  onCheck: PropTypes.func,
  onDeleteAppearanceVerdict: PropTypes.func,
  onModifyAppearanceVerdict: PropTypes.func,
  onReattributeAppearanceVerdict: PropTypes.func,
  onRefetchClaimAppearances: PropTypes.func,
  onResetAppearanceSharesVerdict: PropTypes.func,
}

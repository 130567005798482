export default claim => {
  const factCheckVerdict = claim.verdicts.find(
    verdict => verdict.factCheckContentId !== null
  )
  const { factCheckContent, id: reviewId } = factCheckVerdict || {}

  return {
    id: claim.id,
    claim: claim.text,
    claimBy: claim.claimant?.name,
    conclusionLabel: factCheckVerdict?.conclusion?.standardForm,
    conclusionScore: factCheckVerdict?.conclusion?.score,
    factCheckContentUrl: factCheckContent?.url,
    linkNumber: claim.quotationsCount,
    interactionNumber: claim.totalInteractions,
    reviewId,
    scientistFeedbacks: claim.scientistFeedbacks,
  }
}

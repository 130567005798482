import PropTypes from 'prop-types'
import React from 'react'
import './logo.scss'

/* eslint-disable react/button-has-type */
const Logo = ({ title, variant }) => (
  <div className={`logo logo-${variant}`}>
    {title ? (
      <img alt="logo" src="/static/assets/new/logo_name.svg" />
    ) : (
      <img alt="logo" src="/static/assets/new/logo.svg" />
    )}
  </div>
)

export default Logo

Logo.defaultProps = {
  title: true,
  variant: 'dark',
}

Logo.propTypes = {
  title: PropTypes.bool,
  variant: PropTypes.oneOf(['dark', 'white']),
}

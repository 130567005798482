import { useQuery } from 'react-query'

import { useAxios, useAuth } from 'hooks'

import { API_URL } from '../../utils/config'

export default function useUser() {
  const instances = useAxios()
  const { auth, isAuth, setAuth } = useAuth()
  const getCurrentUser = async () => {
    if (isAuth) {
      const { data, status } = await instances.get(`${API_URL}/users/current`)
      if (status === 200) {
        setAuth({
          ...auth,
          user: data,
        })
      }
    } else {
      setAuth({})
    }
  }

  return useQuery(['user-current'], getCurrentUser)
}

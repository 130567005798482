import React, { useState } from 'react'
import { useFormContext, useFieldArray } from 'react-hook-form'

import { Badge, Select, Typography } from 'components/index'
import { useChannels } from 'hooks'

export const SearchChannels = ({ setShowAdd }) => {
  const { control } = useFormContext()
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'channels',
  })

  const onAdd = value => {
    append(value)
  }

  const onRemove = value => {
    remove(value)
  }

  const [search, setSearch] = useState('')
  const { data, isLoading } = useChannels(search)
  const options = data?.pages[0].data || []

  const onSearch = search => {
    setSearch(search)
  }

  return (
    <div className="edit-source--section">
      <Typography color="primary-white" variant="title-3">
        Related channel(s):
      </Typography>
      <Select
        getOptionLabel={option => option.name}
        getOptionValue={option => option.id}
        isLoading={isLoading}
        isMulti
        isSearchable
        onChange={onAdd}
        onSearch={onSearch}
        options={options}
        placeholder="Search for a channel"
      />

      {fields &&
        fields.length > 0 &&
        fields.map(channel => (
          <Badge
            key={channel.id}
            onDelete={() => onRemove(channel)}
            text={channel.name}
          />
        ))}
    </div>
  )
}

export default SearchChannels

import React, { useState } from 'react'

import './scientistDashboard.scss'
import { Controller, useForm } from 'react-hook-form'

import { Button, Select, TextField, Typography } from 'components'
import { useEditUser } from 'hooks'

const EXPERTISE_TAG = [
  { value: 1, label: 'EXPLOSION' },
  { value: 2, label: 'REACT' },
  { value: 3, label: 'PHP' },
  { value: 4, label: 'SCIENCES' },
  { value: 4, label: 'EARTH' },
]
const ScientistDashboard = ({ user }) => {
  const [editMode, setEditMode] = useState(false)
  const editUser = useEditUser()
  const { handleSubmit, register, control } = useForm({
    defaultValues: {
      firstName: user.firstName,
      lastName: user.lastName,
      expertiseTags: user.expertiseTags,
    },
  })

  const onEdit = values => {
    const data = {
      ...values,
      expertiseTags: values.expertiseTags
        ? values.expertiseTags.map(tag => tag.label)
        : [],
    }
    editUser.mutate(data)
  }

  return (
    <div className="scientist-dashboard">
      <Typography variant="h1">Your Dashboard</Typography>
      <div className="scientist-dashboard--section" />
      <Typography variant="h2">General info:</Typography>
      {user.firstName} {user.lastName}
      <Typography variant="h2">Expertise Tag</Typography>
      {user.expertiseTags}
      <Button onClick={() => setEditMode(true)}> Edit </Button>
      {editMode === true && (
        <form onSubmit={handleSubmit(onEdit)}>
          <Typography className="add-feedback--label" variant="title-2">
            Your info:
          </Typography>
          <div className="add-feedback--line">
            <TextField
              placeholder="First Name"
              register={register}
              registerName="firstName"
              registerValidation={{ required: true }}
              rounded={false}
            />
            <TextField
              placeholder="Last Name"
              register={register}
              registerName="lastName"
              registerValidation={{ required: true }}
              rounded={false}
            />
          </div>

          <Typography className="add-feedback--label" variant="title-2">
            Your Expertise:
          </Typography>
          <Controller
            control={control}
            name="expertiseTags[]"
            render={({ field: { onChange, value, ref } }) => {
              return (
                <Select
                  inputRef={ref}
                  isMulti
                  onChange={onChange}
                  options={EXPERTISE_TAG}
                  value={value}
                />
              )
            }}
          />

          <div className="add-feedback--action">
            <Button variant="ghost"> Save </Button>
          </div>
        </form>
      )}
    </div>
  )
}

export default ScientistDashboard

ScientistDashboard.defaultProps = {}

ScientistDashboard.propTypes = {}

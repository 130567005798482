import PropTypes from 'prop-types'
import React from 'react'

import { Divider, Icon, Typography, InteractionCircle } from 'components'

import './statisticCard.scss'

const StatisticCard = ({ number, title, total }) => {
  const percent = Number((number * 100) / total)
  const data = [
    { title: title, value: percent, color: '#3A5DAE' },
    { title: title, value: 100 - percent, color: '#F4F5F6' },
  ]

  return (
    <div className="dashboard-statistic-card" role="navigation">
      <InteractionCircle
        data={data}
        label={`${percent.toFixed(0)}%`}
        unit={null}
      />
      <div className="dashboard-statistic-card--body">
        <div className="dashboard-statistic-card--body--header">
          <Typography color="secondary-grey" variant="body-text-2">
            {title}
          </Typography>
        </div>
        <div className="dashboard-statistic-card--body--footer">
          <div className="dashboard-statistic-card--body--footer--item--before-divider">
            <Typography variant="title-1">{number}</Typography>
          </div>

          <div className="dashboard-statistic-card--body--footer--item--before-divider">
            <Divider
              borderStyle="dashed"
              className="dashboard-statistic-card--body--footer--divider"
              orientation="vertical"
              thickness="thin"
            />
            <Typography
              className="dashboard-statistic-card--add"
              variant="title-2"
            >
              Add <Icon icon="add" />
            </Typography>
          </div>
        </div>
      </div>
    </div>
  )
}

export default StatisticCard

StatisticCard.defaultProps = {
  number: 0,
  title: '',
  total: 1,
}

StatisticCard.propTypes = {
  number: PropTypes.number,
  title: PropTypes.string,
  total: PropTypes.number,
}

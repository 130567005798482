import { useMutation } from 'react-query'

import { useAxios } from 'hooks'

import { API_URL } from '../../utils/config'

export default function useModifyConclusion(data) {
  const instances = useAxios()
  const modifyConclusion = async modifyConclusionData => {
    const { data } = await instances.patch(
      `${API_URL}/conclusions/${modifyConclusionData.conclusionId}`,
      modifyConclusionData.conclusionData
    )
    return data
  }
  return useMutation(data => modifyConclusion(data), data)
}

import React from 'react'

import { Typography } from 'components'

import './notFound.scss'
import Icon from '../../Atomic/Atoms/Icon'

import { Link } from 'react-router-dom'

const NotFound = () => {
  return (
    <div className="not-found">
      <img alt="" src="/static/assets/notFound/notFound.svg" />
      <Typography color="primary-white" variant="h2">
        Sorry, we were unable to find that page.
      </Typography>
      <div className="not-found--redirection">
        <Typography color="primary-white" variant="title-3">
          Start from&nbsp;
        </Typography>
        <Typography color="primary-white" variant="link-text-2">
          <Link to="/">
            Home page <Icon color="white" icon="arrowright" />
          </Link>
        </Typography>
      </div>
    </div>
  )
}

export default NotFound

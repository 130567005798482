import React, { useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

import { FactcheckerVerdictsPage, NotFound} from 'components'
import {
  useAuth,
  useFactcheckerVerdicts,
  useModifyConclusion,
} from 'hooks'

const FactcheckerVerdicts = () => {
  const { auth } = useAuth()
  const isLoggedIn = !!auth.user

  
  let navigate = useNavigate()
  const { search: urlSearch } = useLocation()

  const urlVerdictsPage =
    Number(new URLSearchParams(urlSearch).get('page')) || 1
  
  const urlVerdictsKeywords =
    new URLSearchParams(urlSearch).get('keywords') || ''
  const urlConclusionScore = new URLSearchParams(urlSearch).get('score') || ''
  
  const [page, setPage] = useState(urlVerdictsPage)
  const [searchVerdicts, setSearchVerdicts] = useState(
    urlVerdictsKeywords
  )
  const [scoreConclusion, setScoreConclusion] = useState(urlConclusionScore)
  
  const { data: verdicts, refetch: refetchVerdicts } = useFactcheckerVerdicts({
    keywords: searchVerdicts,
    page: page,
    score: scoreConclusion,
  })

  const onSearch = (keywords, score) => {
    navigate(
      `/factchecker/verdicts?page=1&keywords=${keywords}`
    )
    setPage(1)
    setSearchVerdicts(keywords)
    setScoreConclusion(score)
    refetchVerdicts()
  }
  
  const onChangePage = newPage => {
    setPage(newPage)
    navigate(
      `/factchecker/verdicts?page=${newPage}`
    )
    refetchVerdicts()
  }
  
  const modifyConclusion = useModifyConclusion()
  const onModifyConclusion = async (conclusionId, conclusionData) => {
    const data = {
      conclusionId: conclusionId,
      conclusionData: conclusionData,
    }
    modifyConclusion.mutate(data, {
      onSuccess: () => refetchVerdicts(),
    })
  }

  if (!isLoggedIn) return <NotFound />

  return (
    <FactcheckerVerdictsPage
      defaultScore={scoreConclusion}
      defaultSearch={searchVerdicts}
      onChangePage={onChangePage}
      onModifyGenericConclusion={onModifyConclusion}
      onSearch={onSearch}
      page={page}
      user={auth.user}
      verdicts={verdicts}
    />
  )
}

export default FactcheckerVerdicts

import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'

import { ClaimCard, Divider, Icon, Loader } from 'components'
import { useAppearanceClaims } from 'hooks'

import './appearanceClaims.scss'
import Button from '../../../Atomic/Atoms/Button'
import Typography from '../../../Atomic/Atoms/Typography'

const AppearanceClaims = ({ appearanceId, appearanceType }) => {
  const {
    data: appearanceClaimsData,
    isLoading: appearanceClaimsPageAreLoading,
  } = useAppearanceClaims({
    appearanceId,
    appearanceType,
  })

  return (
    <div className="appearance-claims">
      {appearanceClaimsPageAreLoading && <Loader />}
      {appearanceClaimsData?.map(claim => (
        <>
          <Divider
            borderStyle="dashed"
            className="appearance-claims--divider"
            thickness="thin"
          />
          <ClaimCard {...claim} showHeader={false} variant="light" />
          {claim.reviewId && (
            <Link
              to={`/review/${claim.reviewId}`}
              className="appearance-claims--readmore"
            >
              <Button variant="light">
                <Typography variant="title-3">Read more</Typography>
                <Icon icon="arrowup45" />
              </Button>
            </Link>
          )}
        </>
      ))}
    </div>
  )
}

export default AppearanceClaims

AppearanceClaims.defaultProps = {
  appearanceId: null,
}

AppearanceClaims.propTypes = {
  appearanceId: PropTypes.string,
}

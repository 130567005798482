import PropTypes from 'prop-types'
import React, { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import './deleteFeedbackRequest.scss'

import { Button, Typography } from 'components'
import FooterBar from 'components/Atomic/Molecules/FooterBar'
import { useDeleteFeedbackRequest } from 'hooks'

const DeleteFeedbackRequest = ({ feedbackRequest, onSuccess }) => {
  const { id } = feedbackRequest

  const navigate = useNavigate()
  const deleteFeedbackRequest = useDeleteFeedbackRequest(id)

  const [show, setShow] = useState(false)

  const onClose = useCallback(() => {
    setShow(false)
  }, [setShow])

  const onDelete = useCallback(
    values => {
      deleteFeedbackRequest.mutate(values, {
        onSuccess: () => {
          onSuccess()
          navigate('/feedback')
        },
      })
    },
    [deleteFeedbackRequest, navigate, onSuccess]
  )

  return (
    <>
      <div className="delete-feedback-request--delete-button">
        <Button onClick={() => setShow(!show)} variant="ghost-reverse">
          <Typography onClick variant="btn-text">
            Delete
          </Typography>
        </Button>
      </div>

      <FooterBar show={show}>
        <div className="delete-feedback-request">
          <div className="delete-feedback-request--text">
            <Typography color="primary-white" variant="title-1">
              Delete this feedback request ?
            </Typography>
          </div>
          <div className="delete-feedback-request--actions">
            <Button onClick={onClose} variant="default">
              Cancel
            </Button>
            <Button onClick={onDelete} variant="ghost-reverse">
              Delete
            </Button>
          </div>
        </div>
      </FooterBar>
    </>
  )
}

DeleteFeedbackRequest.propTypes = {
  feedbackRequest: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  onSuccess: PropTypes.func.isRequired,
}

export default DeleteFeedbackRequest

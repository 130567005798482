import { useMemo, useRef } from 'react'

import capitalize from 'utils/capitalize'

import useAuth from './useAuth'


export default (entityIds, entityType = 'channel') => {
  const { auth } = useAuth()
  const entityIdsRef = useRef(entityIds)

  return useMemo(() => {
    entityIdsRef.current = Array.isArray(entityIds) ? entityIds : [entityIds]

    if (!auth?.user?.factcheckingChannels && ! auth?.user?.factcheckingOrganizations) {
      return false
    }

    return entityIdsRef.current.some(entityId => (auth?.user?.[`factchecking${capitalize(entityType)}s`] || [])
                                                    .map(entity => entity.id)
                                                    .includes(entityId))
  }, [auth, entityIds, entityType])
}

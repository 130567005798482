import React, { useContext } from 'react'

import { tabContext } from './TabsContext'

export const TabPanel = ({ value, children, className }) => {
  const [tabValue] = useContext(tabContext)

  if (value !== tabValue) return null
  return <div className={className}>{children}</div>
}

export default TabPanel

export const numberShortener = value => {
  if (!value) {
    return 0
  } else {
    if (value > 1e9) {
      return `${(value / 1e9).toFixed(1)}b`
    } else if (value > 1e6) {
      return `${(value / 1e6).toFixed(1)}m`
    } else if (value > 1e3) {
      return `${(value / 1e3).toFixed(0)}k`
    } else {
      return value
    }
  }
}

export const stringShortener = (string, lengthLimit = 80) => {
  if (!string || string.length <= lengthLimit) return string

  return `${string.slice(0, lengthLimit)}...`
}

export const urlShortener = url => {
  url = url.replace(/(https?:\/\/)?(www.)?/i, '')

  if (url.indexOf('?') !== -1) {
    return url.split('?')[0]
  }
  if (url[url.length - 1] === '/') {
    url = url.slice(0, -1)
  }

  return url
}

import { useMutation } from 'react-query'

import { useAxios } from 'hooks'

import { API_URL } from '../../utils/config'

export default function useModifyAppearancesVerdicts(data) {
  const instances = useAxios()
  const modifyAppearancesVerdicts = async appearancesData => {
    const { data } = await instances.patch(
      `${API_URL}/appearances/edit/verdicts`,
      appearancesData
    )
    return data
  }
  return useMutation(data => modifyAppearancesVerdicts(data), data)
}

import PropTypes from 'prop-types'
import React from 'react'

import { Icon } from 'components'

import './buttonIcon.scss'

/* eslint-disable react/button-has-type */
const ButtonIcon = ({ variant, icon, ...props }) => (
  <button className="component-button-icon" data-variant={variant} {...props}>
    <Icon icon={icon} />
  </button>
)

export default ButtonIcon

ButtonIcon.defaultProps = {
  variant: 'side',
}

ButtonIcon.propTypes = {
  icon: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(['up', 'navigation', 'square']),
}
